import { ENTITY_KEY } from 'entities/entity/entity.key'
import { entityEn } from 'entities/entity/locale/entity.en'
import {
  getDocumentWithLabelData,
  getProfileInfoWithLabelData,
} from 'entities/entity/model/entity-label-data.model'
import { Args } from 'entities/entity/model/entity.model'

import { useI18n } from 'shared/i18n'

import { useEntityDataQuery } from '../entity-data/entity-data.query'

export const useEntityLabelDataState = ({ entityId }: Args) => {
  const { data: entity } = useEntityDataQuery(entityId, 'base64')
  const t = useI18n(ENTITY_KEY, { keys: entityEn })

  const documentWithLabelData = getDocumentWithLabelData({
    documents: entity?.individual?.documents?.IDENTITY,
    individual: entity?.individual,
    singleAddress: true,
    t,
  })

  const personalInfoWithLabelData = getProfileInfoWithLabelData({
    individual: entity?.individual,
    singleAddress: false,
    t,
  })
  return { documentWithLabelData, personalInfoWithLabelData }
}
