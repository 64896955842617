import React, { ReactNode } from 'react'

import { DataGridPro } from '@mui/x-data-grid-pro'

import { FrankieLoader } from 'frankify/src'

import { WithProps } from 'shared/hoc'
import { useI18n } from 'shared/i18n'

import { APPLICANT_RISK_SCORE_KEY } from '../../applicant-risk-score.key'
import { applicantRiskScoreEn } from '../../locale/applicant-risk-score.en'
import { ApplicantRiskScoreRecord } from '../../model/applicant-risk-score.model'
import { applicantRiskScoreDataGridQa } from '../../qa/applicant-risk-score.qa'
import {
  RiskScoreDataGridCell,
  riskScoreDataGridCellWidth,
  RiskScoreDataGridColumn,
  RiskScoreDataGridLoadingOverlay,
  RiskScoreDataGridRow,
  riskScoreDataGridSx,
} from '../applicant-risk-score-data-grid-helper/applicant-risk-score-data-grid-helper'

type Props = {
  className?: string
  heading: string
  data: ApplicantRiskScoreRecord[]
  loading?: boolean
  children?: ReactNode
  testId?: { container?: string }
}

export function ApplicantRiskScoreDataGrid({
  className = '',
  heading,
  data,
  loading = false,
  children,
  testId,
}: Props) {
  const t = useI18n([APPLICANT_RISK_SCORE_KEY], {
    keys: applicantRiskScoreEn,
  })

  const columns: RiskScoreDataGridColumn = [
    {
      field: 'factor',
      headerName: t('field.factor'),
      ...riskScoreDataGridCellWidth(240),
    },
    {
      field: 'value',
      headerName: t('field.value'),
      ...riskScoreDataGridCellWidth(240),
    },
    {
      field: 'policy',
      headerName: t('field.policy'),
      ...riskScoreDataGridCellWidth(120),
    },
    {
      field: 'score',
      headerName: t('field.score'),
      ...riskScoreDataGridCellWidth(100),
    },
    {
      field: 'level',
      headerName: t('field.level'),
      ...riskScoreDataGridCellWidth(140),
    },
  ]

  return (
    <div className={className} data-qa={testId?.container}>
      <div className="text-lg font-bold text-tertiary-grey-800 mb-1">
        {heading}
      </div>
      <div className="text-tertiary-grey-500 mb-4">{t('subheading')}</div>
      {data.length ? (
        <DataGridPro
          className="border-tertiary-grey-200 overflow-hidden"
          getRowId={row => row.id}
          rows={data}
          slots={{
            loadingOverlay: RiskScoreDataGridLoadingOverlay,
            row: RiskScoreDataGridRow,
          }}
          columns={columns.map(item => ({
            // Adding default properties for every column
            renderCell: WithProps(RiskScoreDataGridCell, { field: item.field }),
            headerClassName:
              '!p-0 text-xs !border-tertiary-grey-200 font-semibold bg-neutral-30 text-tertiary-grey-500 !outline-none',
            cellClassName:
              '!outline-none !border-tertiary-grey-200 text-sm break-words !min-h-[60px]',
            sortable: false,
            hideSortIcons: true,
            resizable: false,
            disableColumnMenu: true,
            disableReorder: true,

            ...item,
          }))}
          loading={loading}
          isRowSelectable={() => false}
          isCellEditable={() => false}
          getRowHeight={() => 'auto'}
          columnHeaderHeight={40}
          disableRowSelectionOnClick
          disableColumnSelector
          disableColumnFilter
          disableColumnMenu
          disableDensitySelector
          showCellVerticalBorder={false}
          showColumnVerticalBorder={false}
          hideFooterPagination
          hideFooter
          hideFooterSelectedRowCount
          autoHeight
          autoPageSize
          sx={riskScoreDataGridSx}
        />
      ) : (
        <FrankieLoader
          className={`text-tertiary-grey-800 text-md font-semibold ${
            loading ? 'min-h-[80px]' : ''
          }`}
          loading={loading}
          label={t('loading')}
        >
          <div
            data-qa={applicantRiskScoreDataGridQa.noData}
            className="font-normal text-sm text-center"
          >
            {t('noData')}
          </div>
        </FrankieLoader>
      )}
      {!!data.length && children}
    </div>
  )
}
