import { AddressCategoryTypes } from 'entities/applicant'
import { countryList } from 'entities/country'
import { DocumentIdTypes, KycDocumentCategoryTypes } from 'entities/document'
import { CountryDocumentOptions, PageData } from 'entities/session'

import { Nullable } from 'shared/typescript'

import {
  ConsentTypes,
  IDocumentsInputs,
  IIndividualProfileInputs,
  IndividualProfileInputTypes,
  consentsArray,
} from './form.model'
import {
  Address,
  ExtraData,
  IndividualProfilePayloadTypes,
  Document,
} from './individual-profile-payload.model'

type AddressTypes =
  | IndividualProfileInputTypes.CurrentResidentialAddress
  | IndividualProfileInputTypes.PreviousResidentialAddress
  | IndividualProfileInputTypes.MailingAddress

const addressCategory = {
  [IndividualProfileInputTypes.CurrentResidentialAddress]:
    AddressCategoryTypes.current,
  [IndividualProfileInputTypes.PreviousResidentialAddress]:
    AddressCategoryTypes.previous,
  [IndividualProfileInputTypes.MailingAddress]: AddressCategoryTypes.mailing,
}

/**
 * if date format is MM-YYYY then convert it to YYYY-MM
 * else return date
 */
const formatDate = (date: string) => {
  if (date.length === 7) {
    return date.split('-').reverse().join('-')
  }
  return date
}

export function extractLongForm(address: Address): string {
  const {
    buildingName,
    unitNumber,
    streetNumber,
    streetName,
    streetType,
    suburb,
    state,
    postalCode,
    country,
    town,
  } = address

  function getCountryDetails(code3: string) {
    const country = countryList.find(c => c.alpha3code === code3)
    const countryObject = {
      label: country?.name || code3,
    }
    return countryObject
  }
  const countryName = (country && getCountryDetails(country).label) || null

  if (
    ![
      // if missing important information, return longform OR null
      streetName,
      postalCode,
      countryName,
    ].every(Boolean)
  )
    return address.longForm || ''

  const streetNumberLine = [unitNumber, streetNumber]
    .filter(Boolean)
    .join(' / ')
  const unitLine = [buildingName, streetNumberLine].filter(Boolean).join(', ')
  const streetLine = [unitLine, streetName, streetType]
    .filter(Boolean)
    .join(' ')
  const stateLine = [town, state, postalCode].filter(Boolean).join(' ')
  const suburbResolved: Nullable<string> = country === 'AUS' ? '' : suburb

  return [streetLine, suburbResolved, stateLine, countryName]
    .filter(Boolean)
    .join(', ')
}

export const mapAddress = (
  formData: IIndividualProfileInputs,
  addressType: AddressTypes,
) => {
  const address =
    formData[addressType][IndividualProfileInputTypes.ManualAddress]
  const addressData = {
    buildingName: address[IndividualProfileInputTypes.PropertyName] || null,
    postalCode: address[IndividualProfileInputTypes.PostalCode],
    state: address[IndividualProfileInputTypes.State],
    streetNumber: address[IndividualProfileInputTypes.StreetNumber],
    streetType: address[IndividualProfileInputTypes.StreetType],
    suburb: address[IndividualProfileInputTypes.Suburb] || null,
    town: address[IndividualProfileInputTypes.Town] || null,
    unitNumber: address[IndividualProfileInputTypes.UnitNumber] || null,
    addressType: addressCategory[addressType],
    country: formData[addressType][IndividualProfileInputTypes.Country],
    addressId: formData[addressType][IndividualProfileInputTypes.AddressId],
    data: {
      longForm: null,
      // formData[addressType][IndividualProfileInputTypes.Search],
    },
    isAddedByCustomer: null,
    streetName: address[IndividualProfileInputTypes.StreetName],
    longForm: formData[addressType][IndividualProfileInputTypes.Search],
  }
  const longForm = extractLongForm(addressData)
  return {
    ...addressData,
    longForm, // add extra field
  }
}

export const getAddressData = (formData: IIndividualProfileInputs) => {
  const addressData = []

  addressData.push(
    mapAddress(formData, IndividualProfileInputTypes.CurrentResidentialAddress),
  )

  if (
    formData[IndividualProfileInputTypes.PreviousResidentialAddress][
      IndividualProfileInputTypes.isActive
    ]
  ) {
    addressData.push(
      mapAddress(
        formData,
        IndividualProfileInputTypes.PreviousResidentialAddress,
      ),
    )
  }

  /**
   * when mailing address is same as current address
   */

  if (
    formData[IndividualProfileInputTypes.MailingAddress][
      IndividualProfileInputTypes.SameAsCurrent
    ]
  ) {
    addressData.push({
      ...mapAddress(
        formData,
        IndividualProfileInputTypes.CurrentResidentialAddress,
      ),
      addressType: addressCategory[IndividualProfileInputTypes.MailingAddress],
    })
  } else if (
    formData[IndividualProfileInputTypes.MailingAddress][
      IndividualProfileInputTypes.isActive
    ]
  ) {
    addressData.push(
      mapAddress(formData, IndividualProfileInputTypes.MailingAddress),
    )
  }

  return addressData
}

const getPersonalData = (formData: IIndividualProfileInputs) => ({
  personal: {
    customerReference:
      formData[IndividualProfileInputTypes.PersonalInfo][
        IndividualProfileInputTypes.CustomerId
      ],
    dateOfBirth:
      formData[IndividualProfileInputTypes.PersonalInfo][
        IndividualProfileInputTypes.Dob
      ],
    msisdn: {
      documentId: '',
      value:
        formData[IndividualProfileInputTypes.PersonalInfo][
          IndividualProfileInputTypes.PhoneNumber
        ],
    },
    email: {
      documentId: '',
      value:
        formData[IndividualProfileInputTypes.PersonalInfo][
          IndividualProfileInputTypes.Email
        ],
    },
    name: {
      givenName:
        formData[IndividualProfileInputTypes.PersonalInfo][
          IndividualProfileInputTypes.FirstName
        ],
      middleName:
        formData[IndividualProfileInputTypes.PersonalInfo][
          IndividualProfileInputTypes.MiddleName
        ],
      familyName:
        formData[IndividualProfileInputTypes.PersonalInfo][
          IndividualProfileInputTypes.LastName
        ],
    },
    profile: formData[IndividualProfileInputTypes.Recipe],
    selfie: {
      image: '',
      video: '',
      documentId: '',
      country: '',
    },
    extraData: {
      'dob.Buddhist': '',
    },
    addresses: getAddressData(formData),
    consents: formData[IndividualProfileInputTypes.Consent]
      ? consentsArray
      : (formData[IndividualProfileInputTypes.SeparateConsent].filter(
          i => i,
        ) as ConsentTypes[]),
  },
})

// eslint-disable-next-line complexity
export const getExtraData = (
  formData: IIndividualProfileInputs,
  document: IDocumentsInputs,
): ExtraData => {
  const documentType = document[IndividualProfileInputTypes.IdType]
  switch (documentType) {
    case DocumentIdTypes.PASSPORT: {
      return {
        extraData: {
          kyc_document_category: 'primary_photographic',
          document_number: document[IndividualProfileInputTypes.DocumentNumber],
        },
        idExpiry: formatDate(
          document[IndividualProfileInputTypes.Passport][
            IndividualProfileInputTypes.IdExpiry
          ],
        ),
      }
    }
    case DocumentIdTypes.DRIVERS_LICENSE: {
      return {
        region: document[IndividualProfileInputTypes.State] || null,
        extraData: {
          document_number: document[IndividualProfileInputTypes.DocumentNumber],
          licence_version: document[IndividualProfileInputTypes.LicenceVersion],
          home_country_fullname:
            document[IndividualProfileInputTypes.HomeCountryFullName],
          kyc_document_category: 'primary_photographic',
        },
      }
    }
    case DocumentIdTypes.VISA: {
      return {
        extraData: {
          kyc_document_category:
            document[IndividualProfileInputTypes.DocumentCategory],
        },
      }
    }
    case DocumentIdTypes.IMMIGRATION: {
      return {
        extraData: {
          kyc_document_category:
            document[IndividualProfileInputTypes.DocumentCategory],
        },
      }
    }
    case DocumentIdTypes.BIRTH_CERT: {
      return {
        region: document[IndividualProfileInputTypes.State] || null,
        extraData: {
          registration_date:
            document[IndividualProfileInputTypes.BirthCertificate][
              IndividualProfileInputTypes.RegistrationDate
            ] || '',
          registration_district:
            document[IndividualProfileInputTypes.BirthCertificate][
              IndividualProfileInputTypes.RegistrationDistrict
            ] ?? null,
          certificate_number:
            document[IndividualProfileInputTypes.BirthCertificate][
              IndividualProfileInputTypes.CertificateNumber
            ] ?? null,
          certificate_date_of_print:
            document[IndividualProfileInputTypes.BirthCertificate][
              IndividualProfileInputTypes.DateOfPrint
            ],
          registered_name_family:
            document[IndividualProfileInputTypes.BirthCertificate][
              IndividualProfileInputTypes.FamilyName
            ] || null,
          registered_name_first_given:
            document[IndividualProfileInputTypes.BirthCertificate][
              IndividualProfileInputTypes.RegistrationName
            ] || null,
          registered_name_other_given:
            document[IndividualProfileInputTypes.BirthCertificate][
              IndividualProfileInputTypes.OtherName
            ] || null,
          kyc_document_category:
            document[IndividualProfileInputTypes.DocumentCategory] ?? null,
        },
      }
    }
    case DocumentIdTypes.MEDICARE_CARD: {
      return {
        extraData: {
          reference:
            document[IndividualProfileInputTypes.MedicareCard][
              IndividualProfileInputTypes.Reference
            ],
          kyc_document_category:
            document[IndividualProfileInputTypes.DocumentCategory],
          display_middle_name:
            document[IndividualProfileInputTypes.MedicareCard][
              IndividualProfileInputTypes.MiddleNameDisplay
            ],
          display_name_line1:
            document[IndividualProfileInputTypes.MedicareCard][
              IndividualProfileInputTypes.DisplayNameLine
            ],
        },
        idExpiry: formatDate(
          document[IndividualProfileInputTypes.MedicareCard][
            IndividualProfileInputTypes.IdExpiry
          ],
        ),
        idSubType:
          document[IndividualProfileInputTypes.MedicareCard][
            IndividualProfileInputTypes.IdSubType
          ],
      }
    }
    case DocumentIdTypes.CITIZENSHIP: {
      return {
        extraData: {
          acquisition_date:
            document[IndividualProfileInputTypes.CitizenShipCertificate][
              IndividualProfileInputTypes.AcquisitionDate
            ],
          primary_country:
            document[IndividualProfileInputTypes.CitizenShipCertificate][
              IndividualProfileInputTypes.PrimaryCountry
            ],
          secondary_country:
            document[IndividualProfileInputTypes.CitizenShipCertificate][
              IndividualProfileInputTypes.SecondaryCountry
            ],
          kyc_document_category:
            document[IndividualProfileInputTypes.DocumentCategory],
        },
      }
    }
    case DocumentIdTypes.NAME_CHANGE: {
      return {
        extraData: {
          certificate_date_of_registration:
            document[IndividualProfileInputTypes.ChangeNameCertificate][
              IndividualProfileInputTypes.CertificateDateOfRegistration
            ],
          certificate_number:
            document[IndividualProfileInputTypes.ChangeNameCertificate][
              IndividualProfileInputTypes.CertificateNumber
            ],
          certificate_date_of_print:
            document[IndividualProfileInputTypes.ChangeNameCertificate][
              IndividualProfileInputTypes.DateOfPrint
            ],
          registered_name_family:
            document[IndividualProfileInputTypes.ChangeNameCertificate][
              IndividualProfileInputTypes.FamilyName
            ],
          registered_name_first_given:
            document[IndividualProfileInputTypes.ChangeNameCertificate][
              IndividualProfileInputTypes.RegistrationName
            ],
          registered_name_other_given:
            document[IndividualProfileInputTypes.ChangeNameCertificate][
              IndividualProfileInputTypes.OtherName
            ],
          kyc_document_category:
            document[IndividualProfileInputTypes.DocumentCategory],
        },
      }
    }
    case DocumentIdTypes.MARRIAGE_CERT: {
      return {
        extraData: {
          registration_year:
            document[IndividualProfileInputTypes.MarriageCertificate][
              IndividualProfileInputTypes.RegistrationYear
            ],
          marriage_date:
            document[IndividualProfileInputTypes.MarriageCertificate][
              IndividualProfileInputTypes.MarriageDate
            ],

          certificate_date_of_registration:
            document[IndividualProfileInputTypes.MarriageCertificate][
              IndividualProfileInputTypes.CertificateDateOfRegistration
            ],
          certificate_number:
            document[IndividualProfileInputTypes.MarriageCertificate][
              IndividualProfileInputTypes.CertificateNumber
            ],
          certificate_date_of_print:
            document[IndividualProfileInputTypes.MarriageCertificate][
              IndividualProfileInputTypes.DateOfPrint
            ],
          bride_name_family:
            document[IndividualProfileInputTypes.MarriageCertificate][
              IndividualProfileInputTypes.Bride
            ][IndividualProfileInputTypes.FamilyName],
          bride_name_first_given:
            document[IndividualProfileInputTypes.MarriageCertificate][
              IndividualProfileInputTypes.Bride
            ][IndividualProfileInputTypes.FirstName],
          bride_name_other_given:
            document[IndividualProfileInputTypes.MarriageCertificate][
              IndividualProfileInputTypes.Bride
            ][IndividualProfileInputTypes.OtherName],
          groom_name_family:
            document[IndividualProfileInputTypes.MarriageCertificate][
              IndividualProfileInputTypes.Groom
            ][IndividualProfileInputTypes.FamilyName],
          groom_name_first_given:
            document[IndividualProfileInputTypes.MarriageCertificate][
              IndividualProfileInputTypes.Groom
            ][IndividualProfileInputTypes.FirstName],
          groom_name_other_given:
            document[IndividualProfileInputTypes.MarriageCertificate][
              IndividualProfileInputTypes.Groom
            ][IndividualProfileInputTypes.OtherName],
          kyc_document_category:
            document[IndividualProfileInputTypes.DocumentCategory],
        },
      }
    }
    case DocumentIdTypes.TAX_ID: {
      return {
        extraData: {
          kyc_document_category:
            document[IndividualProfileInputTypes.DocumentCategory],
        },
        idSubType:
          document[IndividualProfileInputTypes.TaxId][
            IndividualProfileInputTypes.IdSubType
          ],
      }
    }
    case DocumentIdTypes.BANK_STATEMENT: {
      return {
        extraData: {
          national_id: document[IndividualProfileInputTypes.NationalId],
          kyc_document_category:
            document[IndividualProfileInputTypes.DocumentCategory],
          home_country_fullname:
            document[IndividualProfileInputTypes.HomeCountryFullName],
        },
      }
    }
    case DocumentIdTypes.VOTER_ID: {
      return {
        extraData: {
          gender: document[IndividualProfileInputTypes.Gender],
        },
        idSubType: 'EPIC',
      }
    }
    case DocumentIdTypes.PAN: {
      return {
        extraData: {},
        idSubType: DocumentIdTypes.PAN,
      }
    }
    case DocumentIdTypes.NATIONAL_ID: {
      return {
        extraData: {
          paternal_familyname:
            document[IndividualProfileInputTypes.PaternalFamilyName],
          gender: document[IndividualProfileInputTypes.Gender],
          home_country_fullname:
            document[IndividualProfileInputTypes.HomeCountryFullName],
          maternal_familyname:
            document[IndividualProfileInputTypes.MaternalFamilyName],
          laser_code: document[IndividualProfileInputTypes.LaserCode],
          kyc_document_category:
            document[IndividualProfileInputTypes.DocumentCategory],
        },
      }
    }

    default:
      return {
        extraData: {
          kyc_document_category:
            document[IndividualProfileInputTypes.DocumentCategory],
        },
      }
  }
}

export const getDocumentTypesData = (
  formData: IIndividualProfileInputs,
): IndividualProfilePayloadTypes['document'] =>
  formData[IndividualProfileInputTypes.Document]
    .filter(doc => doc[IndividualProfileInputTypes.IdType])
    .map((doc, idx) => ({
      documentData: {
        country: doc[IndividualProfileInputTypes.Country],
        region: doc[IndividualProfileInputTypes.State] || null,
        idNumber: doc[IndividualProfileInputTypes.IdNumber] || null,
        ...getExtraData(
          formData,
          formData[IndividualProfileInputTypes.Document][idx],
        ),
      },
      validation: {
        manual: {
          isValid: true,
        },
        electronic: {
          validationReport: '',
          isValid: null,
        },
      },
      idType: [DocumentIdTypes.PAN, DocumentIdTypes.VOTER_ID, ''].includes(
        doc[IndividualProfileInputTypes.IdType],
      )
        ? DocumentIdTypes.NATIONAL_ID
        : doc[IndividualProfileInputTypes.IdType],
      scans: doc[IndividualProfileInputTypes.Scan][
        IndividualProfileInputTypes.UploadedUUID
      ]
        ? [
            {
              mimeType:
                doc[IndividualProfileInputTypes.Scan][
                  IndividualProfileInputTypes.FileType
                ],
              fileUploadUuid:
                doc[IndividualProfileInputTypes.Scan][
                  IndividualProfileInputTypes.UploadedUUID
                ],
            },
          ]
        : [],
    }))

export const transformData = (
  formData: IIndividualProfileInputs,
): IndividualProfilePayloadTypes => {
  const personalInfo = formData[IndividualProfileInputTypes.PersonalInfo]
  const currentAddress =
    formData[IndividualProfileInputTypes.CurrentResidentialAddress][
      IndividualProfileInputTypes.ManualAddress
    ]
  return {
    checkSummary: {
      checkTypes: [],
      checkDate: '',
      personalChecks: {
        name: null,
        dateOfBirth: null,
        phoneNumber: null,
        email: null,
        chineseName: '',
        addresses: {},
      },
      status: {
        type: 'unchecked',
        label: 'Unchecked',
        key: 'UNCHECKED',
      },
      blocklistPotentialMatches: [],
      duplicatePotentialMatches: [],
      duplicateBlocklistMatches: [],
      risk: {
        level: null,
        class: null,
      },
      alertList: [],
      checkCounts: {
        name: null,
        address: {},
        document: {},
        dob: null,
      },
      kycMethod: formData[IndividualProfileInputTypes.KycMethod],
      issues: {},
      checkResults: [],
      rulesetsMatches: [],
    },
    business: null,
    ...getPersonalData(formData),
    entityType: 'INDIVIDUAL',
    document: getDocumentTypesData(formData),
    consent: formData[IndividualProfileInputTypes.Consent]
      ? consentsArray
      : (formData[IndividualProfileInputTypes.SeparateConsent].filter(
          i => i,
        ) as ConsentTypes[]),
    kycMethod: formData[IndividualProfileInputTypes.KycMethod],
    comment: formData[IndividualProfileInputTypes.Comment],
    property_status: {
      personal_changes: {
        customerReference: [
          '',
          personalInfo[IndividualProfileInputTypes.CustomerId],
        ],
        'address.current.buildingName': [
          null,
          currentAddress[IndividualProfileInputTypes.PropertyName],
        ],
        'address.current.postalCode': [
          null,
          currentAddress[IndividualProfileInputTypes.PostalCode],
        ],
        'address.current.state': [
          null,
          currentAddress[IndividualProfileInputTypes.State],
        ],
        'address.current.streetNumber': [
          null,
          currentAddress[IndividualProfileInputTypes.StreetNumber],
        ],
        'address.current.streetType': [
          null,
          currentAddress[IndividualProfileInputTypes.StreetType],
        ],
        'address.current.town': [
          null,
          currentAddress[IndividualProfileInputTypes.Town],
        ],
        'address.current.unitNumber': [
          null,
          currentAddress[IndividualProfileInputTypes.UnitNumber],
        ],
        'address.current._streetName': [
          null,
          currentAddress[IndividualProfileInputTypes.StreetName],
        ],
        dateOfBirth: ['', personalInfo[IndividualProfileInputTypes.Dob]],
        'msisdn.value': [
          '',
          personalInfo[IndividualProfileInputTypes.PhoneNumber],
        ],
        'email.value': ['', personalInfo[IndividualProfileInputTypes.Email]],
        'name.givenName': [
          '',
          personalInfo[IndividualProfileInputTypes.FirstName],
        ],
        'name.middleName': [
          '',
          personalInfo[IndividualProfileInputTypes.MiddleName],
        ],
        'name.familyName': [
          '',
          personalInfo[IndividualProfileInputTypes.LastName],
        ],
        profile: ['auto', formData[IndividualProfileInputTypes.Recipe]],
      },
      document_changes: {
        created: formData[IndividualProfileInputTypes.Document].map(
          doc => doc[IndividualProfileInputTypes.IdType],
        ),
        changed: [],
        removed: [],
      },
    },
  }
}

// copied from vue
// may god have mercy

export const validateMKYCDocuments = (
  documents: IndividualProfilePayloadTypes['document'],
  mkycRules: PageData['mkyc_rules'],
  isManualKyc: boolean,
) => {
  if (!mkycRules) return true
  if (!isManualKyc) return true

  const goalScore = mkycRules.passing_score || 100
  const ruleset = mkycRules
  const maxCount = mkycRules.max_documents || 2

  const getCountryRuleset = (country: string) =>
    country in ruleset.countries
      ? ruleset.countries[country]
      : ruleset.countries.default

  const getDocCountry = (
    docData: IndividualProfilePayloadTypes['document'][0],
  ) => {
    if (!docData.documentData.country) return 'default'
    return docData.documentData.country // add whatever possible variations of structure there may be to find country in doc data
  }

  const getPoints = (
    ruleset: CountryDocumentOptions,
    category: KycDocumentCategoryTypes,
  ) => ruleset[category]?.points || 0

  const isOfCategory = (
    ruleset: CountryDocumentOptions,
    category: KycDocumentCategoryTypes,
    docData: IndividualProfilePayloadTypes['document'][0],
  ) => {
    const userInputCategory =
      docData.documentData.extraData.kyc_document_category === category
    const kycRulesCategory = (ruleset[category]?.types || []).includes(
      docData.idType as DocumentIdTypes,
    )
    return userInputCategory || kycRulesCategory
  }

  const isPhotographic = (
    ruleset: CountryDocumentOptions,
    docData: IndividualProfilePayloadTypes['document'][0],
  ) =>
    isOfCategory(
      ruleset,
      KycDocumentCategoryTypes.PRIMARY_PHOTOGRAPHIC,
      docData,
    )

  const isNonPhotographic = (
    ruleset: CountryDocumentOptions,
    docData: IndividualProfilePayloadTypes['document'][0],
  ) =>
    isOfCategory(
      ruleset,
      KycDocumentCategoryTypes.PRIMARY_NON_PHOTOGRAPHIC,
      docData,
    )

  const isSecondary = (
    ruleset: CountryDocumentOptions,
    docData: IndividualProfilePayloadTypes['document'][0],
  ) => isOfCategory(ruleset, KycDocumentCategoryTypes.SECONDARY, docData)

  const findPointsForDoc = (
    docData: IndividualProfilePayloadTypes['document'][0],
  ) => {
    let points = 0
    const country = getDocCountry(docData)
    const ruleset = getCountryRuleset(country)

    if (isPhotographic(ruleset, docData)) {
      points = getPoints(ruleset, KycDocumentCategoryTypes.PRIMARY_PHOTOGRAPHIC)
    } else if (isNonPhotographic(ruleset, docData)) {
      points = getPoints(
        ruleset,
        KycDocumentCategoryTypes.PRIMARY_NON_PHOTOGRAPHIC,
      )
    } else if (isSecondary(ruleset, docData)) {
      points = getPoints(ruleset, KycDocumentCategoryTypes.SECONDARY)
    }
    return points
  }

  const sortDocumentsByPoints = (documentsAndIndexes: [Document, number][]) => {
    const filePoints = (doc: IndividualProfilePayloadTypes['document'][0]) =>
      doc.scans?.[0]?.fileUploadUuid || doc.scans?.[0]?.scanId ? 1 : 0

    const docWithPoints: [
      IndividualProfilePayloadTypes['document'][0],
      number,
      number,
    ][] = documentsAndIndexes.map(([d, originalIndex]) => [
      d,
      findPointsForDoc(d),
      originalIndex,
    ])

    return docWithPoints
      .sort(([d, _], [d2]) => filePoints(d2) - filePoints(d)) // give priority to documents with scan
      .sort(([__, points], [_, points2]) => points2 - points) // more points come first
  }

  const getPassingDocuments = (
    documentsAndPoints: [Document, number, number][],
  ) => {
    let passingDocuments: { documentData: Document; originalIndex: number }[] =
      []
    let currPoints = 0
    const getDiff = () => goalScore - currPoints

    documentsAndPoints.forEach(([doc, points, originalIndex], i) => {
      const diff = getDiff()

      if (diff > 0) {
        passingDocuments.push({
          documentData: doc,
          originalIndex,
        })
        currPoints += points
      }
    })
    if (getDiff() > 0) passingDocuments = []

    return passingDocuments
  }

  let passingDocuments = []

  const newDocuments: [Document, number][] = documents.map(
    (d, originalIndex) => [d, originalIndex],
  )

  passingDocuments = (documents => {
    const docsWithFile = documents.filter(
      ([doc]) => !!doc.scans?.[0]?.fileUploadUuid,
    )

    const topDocumentsWithFiles = sortDocumentsByPoints(docsWithFile).slice(
      0,
      maxCount,
    )

    const passingWithFiles = getPassingDocuments(topDocumentsWithFiles)

    if (passingWithFiles.length > 0) return passingWithFiles

    const topDocuments = sortDocumentsByPoints(documents).slice(0, maxCount)
    const passingDocuments = getPassingDocuments(topDocuments)

    return passingDocuments
  })(newDocuments)

  const failed = passingDocuments.length <= 0
  if (failed) {
    return false
  }
  return true
}
