import React, { useMemo } from 'react'

import { FrankieIconName } from 'frankify/src'

import {
  AddressCategoryTypes,
  ApplicantResponse,
  hasSardineCheckResult,
} from 'entities/applicant'

import { DateFormatTypes, formatDate } from 'shared/date-time'
import { useI18n } from 'shared/i18n'
import { Nullable } from 'shared/typescript'

import { APPLICANT_PERSONAL_INFO_KEYS } from '../../applicant-persona-info.keys'
import { applicantPersonalInfoEn } from '../../locale/applicant-personal-info.en'
import {
  DetailViewTypes,
  GenderMapTypes,
  checkManualKYCPass,
  getAddress,
  getApplicantName,
  getUboAddressLongForm,
  getUboDisplayName,
  isAddressAddedByCustomer,
} from '../../model/applicant-detail-view.model'
import { personalInfoQa } from '../../qa/applicant-personal-info.qa'
import { ApplicantDetailView } from '../applicant-detail-view/applicant-detail-view'

type Props = {
  applicantData: ApplicantResponse
  isLegacyProfile: boolean
  testId?: { container?: string }
}

type PersonalInfoTypes = {
  label: string
  value: Nullable<string>
  iconName: FrankieIconName
  checkCount?: number
  secondaryLabel?: string | undefined
  secondaryValue?: string | undefined
  countSource?: Nullable<unknown[]>
  isManualKYCPass: boolean
  isEntityPassed: boolean
  isVerified?: Nullable<boolean>
  isManualKYC: boolean
  isAddedByCustomer?: boolean
  uboValue?: Nullable<string>
  uboTitle?: Nullable<string>
}

export function PersonalInfo({
  applicantData,
  isLegacyProfile,
  testId,
}: Props) {
  const t = useI18n(APPLICANT_PERSONAL_INFO_KEYS, {
    keys: applicantPersonalInfoEn,
  })

  // eslint-disable-next-line complexity
  const personalInfo: PersonalInfoTypes[] = useMemo(() => {
    const currentAddress = getAddress(
      applicantData,
      AddressCategoryTypes.current,
    )
    const previousAddress = getAddress(
      applicantData,
      AddressCategoryTypes.previous,
    )
    const mailingAddress = getAddress(
      applicantData,
      AddressCategoryTypes.mailing,
    )
    const { nativeName } = applicantData.applicantDetails
    const hasDualName = Boolean(nativeName)
    const hasUboName =
      applicantData.applicantDetails.originalUboDetails?.name.givenName ||
      applicantData.applicantDetails.originalUboDetails?.name.middleName ||
      applicantData.applicantDetails.originalUboDetails?.name.familyName
    const { personalChecks } = applicantData.checkSummary
    const { checkResults, kycMethod } = applicantData.checkSummary
    const isManualKYCPass = checkManualKYCPass(kycMethod, checkResults)
    const isEntityPassed = applicantData.checkSummary.status.key === 'PASS'
    const isManualKYC = applicantData.checkSummary.kycMethod === 'manual'

    const isSardineCheckResult = hasSardineCheckResult(
      applicantData.checkSummary.checkResults,
    )

    return [
      {
        label: hasDualName
          ? `${t('personalInfo.nativeName')}`
          : t('personalInfo.name'),
        secondaryLabel: hasDualName ? t('personalInfo.englishName') : undefined,
        value: getApplicantName(applicantData.applicantDetails.name),
        secondaryValue: hasUboName
          ? `${
              applicantData.applicantDetails.originalUboDetails?.name
                .givenName ?? ''
            } ${
              applicantData.applicantDetails.originalUboDetails?.name
                .middleName ?? ''
            } ${
              applicantData.applicantDetails.originalUboDetails?.name
                .familyName ?? ''
            }`
          : undefined,
        iconName: 'mdiAccountOutline',
        checkCount: applicantData.checkSummary.checkCounts.name?.length ?? 0,
        countSource: applicantData.checkSummary.checkCounts.name ?? null,
        isVerified: personalChecks.name ?? null,
        isManualKYCPass,
        isEntityPassed,
        isManualKYC,
        addedByCustomer:
          applicantData.applicantDetails.originalUboDetails
            ?.isNameAddedByCustomer,
        uboTitle: t('uboTitleName'),
        uboValue: getUboDisplayName(
          applicantData.applicantDetails.originalUboDetails?.name ?? null,
        ),
      },
      {
        label:
          applicantData.applicantDetails.dateOfBirth &&
          applicantData.applicantDetails.dateOfBirth.length <= 4
            ? t('personalInfo.yearOfBirth')
            : t('personalInfo.dob'),
        value: applicantData.applicantDetails.dateOfBirth
          ? formatDate(
              applicantData.applicantDetails.dateOfBirth,
              DateFormatTypes.DateNumbers,
            )
          : null,
        iconName: 'mdiCalendarRange',
        checkCount: applicantData.checkSummary.checkCounts.dob?.length ?? 0,
        countSource: applicantData.checkSummary.checkCounts.dob,
        isVerified: personalChecks.dateOfBirth ?? null,
        isManualKYCPass,
        isEntityPassed,
        isManualKYC,
        addedByCustomer:
          applicantData.applicantDetails.originalUboDetails
            ?.isDobAddedByCustomer,
        uboTitle: applicantData.applicantDetails.originalUboDetails?.dateOfBirth
          ? t('uboTitleDob')
          : null,
        uboValue: applicantData.applicantDetails.originalUboDetails?.dateOfBirth
          ? formatDate(
              applicantData.applicantDetails.originalUboDetails.dateOfBirth,
              DateFormatTypes.DateNumbers,
            )
          : null,
      },
      {
        label: t('personalInfo.currentResidentialAddress'),
        value:
          getAddress(applicantData, AddressCategoryTypes.current)?.longForm ??
          applicantData.applicantDetails.addresses?.[0]?.longForm ??
          '',
        iconName: 'mdiHomeOutline',
        checkCount:
          currentAddress?.addressId &&
          Object.hasOwn(
            applicantData.checkSummary.checkCounts.address,
            currentAddress.addressId,
          )
            ? applicantData.checkSummary.checkCounts.address[
                currentAddress.addressId
              ].length
            : 0,
        countSource: currentAddress?.addressId
          ? applicantData.checkSummary.checkCounts.address[
              currentAddress.addressId
            ]
          : null,

        isVerified:
          personalChecks.addresses && currentAddress?.addressId
            ? personalChecks.addresses[currentAddress.addressId]
            : null,
        isManualKYCPass,
        isEntityPassed,
        isManualKYC,
        isAddressAddedByCustomer: !!isAddressAddedByCustomer(
          applicantData,
          AddressCategoryTypes.current,
        ),
        uboTitle: t('uboTitleAddress'),
        uboValue: getUboAddressLongForm(
          applicantData,
          AddressCategoryTypes.current,
        ),
      },
      {
        label: t('personalInfo.previousResidentialAddress'),
        value:
          getAddress(applicantData, AddressCategoryTypes.previous)?.longForm ??
          '',
        iconName: 'mdiHomeOutline',
        checkCount:
          previousAddress?.addressId &&
          Object.hasOwn(
            applicantData.checkSummary.checkCounts.address,
            previousAddress.addressId,
          )
            ? applicantData.checkSummary.checkCounts.address[
                previousAddress.addressId
              ].length
            : 0,
        countSource: currentAddress?.addressId
          ? applicantData.checkSummary.checkCounts.address[
              previousAddress?.addressId as string
            ]
          : null,

        isVerified:
          personalChecks.addresses && previousAddress?.addressId
            ? personalChecks.addresses[previousAddress.addressId]
            : null,
        isManualKYCPass,
        isEntityPassed,
        isManualKYC,
        isAddressAddedByCustomer: !!isAddressAddedByCustomer(
          applicantData,
          AddressCategoryTypes.previous,
        ),
        uboTitle: t('uboTitleAddress'),
        uboValue: getUboAddressLongForm(
          applicantData,
          AddressCategoryTypes.previous,
        ),
      },
      {
        label: t('personalInfo.mailingAddress'),
        value:
          getAddress(applicantData, AddressCategoryTypes.mailing)?.longForm ??
          '',
        iconName: 'mdiEmailOutline',
        checkCount:
          mailingAddress?.addressId &&
          Object.hasOwn(
            applicantData.checkSummary.checkCounts.address,
            mailingAddress.addressId,
          )
            ? applicantData.checkSummary.checkCounts.address[
                mailingAddress.addressId
              ].length
            : 0,
        countSource: mailingAddress?.addressId
          ? applicantData.checkSummary.checkCounts.address[
              mailingAddress.addressId
            ]
          : null,

        isVerified:
          personalChecks.addresses && mailingAddress?.addressId
            ? personalChecks.addresses[mailingAddress.addressId]
            : null,
        isManualKYCPass,
        isEntityPassed,
        isManualKYC,
        isAddressAddedByCustomer: !!isAddressAddedByCustomer(
          applicantData,
          AddressCategoryTypes.mailing,
        ),
        uboTitle: t('uboTitleAddress'),
        uboValue: getUboAddressLongForm(
          applicantData,
          AddressCategoryTypes.mailing,
        ),
      },
      {
        label: t('personalInfo.gender'),
        value: applicantData.applicantDetails.gender
          ? (GenderMapTypes[
              applicantData.applicantDetails.gender.toUpperCase() as keyof typeof GenderMapTypes
            ] as string)
          : '',
        iconName: 'mdiGenderMaleFemale',
        isManualKYCPass,
        isEntityPassed,
        isManualKYC,
      },
      {
        label: t('personalInfo.email'),
        value: isSardineCheckResult
          ? null
          : applicantData.applicantDetails.email.idNumber,
        iconName: 'mdiAt',
        isManualKYCPass,
        isEntityPassed,
        isManualKYC,
      },
      {
        label: t('personalInfo.maritalStatus'),
        value: applicantData.applicantDetails.extraData?.marital_status ?? null,
        iconName: 'mdiAt',
        isManualKYCPass,
        isEntityPassed,
        isManualKYC,
      },
      {
        label: t('personalInfo.phoneNumber'),
        value: isSardineCheckResult
          ? null
          : applicantData.applicantDetails.phoneNumber.idNumber,
        iconName: 'mdiCellphone',
        isManualKYCPass,
        isEntityPassed,
        isManualKYC,
      },
    ]
  }, [applicantData, t])

  const nonEmptyPersonalInfo = personalInfo.filter(item => item.value)

  return (
    <div data-qa={testId?.container} className="text-tertiary-grey-700">
      <div>
        {nonEmptyPersonalInfo.map((info, idx) => (
          <ApplicantDetailView
            isLast={idx === nonEmptyPersonalInfo.length - 1}
            key={info.label}
            label={info.label}
            showMatchesText={idx === 0}
            iconName={info.iconName}
            checkCount={info.checkCount}
            checkSource={info.countSource}
            secondaryLabel={info.secondaryLabel}
            secondaryValue={info.secondaryValue}
            value={info.value}
            isVerified={info.isVerified}
            isManualKYCPass={info.isManualKYCPass}
            isEntityPassed={info.isEntityPassed}
            isManualKYC={info.isManualKYC}
            isLegacyProfile={isLegacyProfile}
            isAddedByCustomer={info.isAddedByCustomer}
            uboTitle={info.uboTitle}
            uboValue={info.uboValue}
            type={DetailViewTypes.PERSONAL_INFO}
            testId={{ container: personalInfoQa.applicantDetailView }}
          />
        ))}
      </div>
    </div>
  )
}
