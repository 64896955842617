import { useQuery } from '@tanstack/react-query'

import { entityApi } from '../../api/entity.api'
import { ServiceProfile } from '../../model/entity.model'
import mockData from '../../model/mockData.json'

export type Args = {
  entityId: string
  disabled?: boolean
}

export const EntityWorkflowDataQueryKey = 'entity-workflow-data-query'

export const useGetWorkflowEventsData = ({ entityId, disabled }: Args) => {
  const searchParams = new URLSearchParams(window.location.search)
  const mock = !!searchParams.get('mock')
  return useQuery({
    queryKey: [EntityWorkflowDataQueryKey, { entityId }],
    enabled: !!entityId && !disabled,
    queryFn: async () => {
      if (mock) return mockData as ServiceProfile
      const res = await entityApi.getWorkflowEvents(entityId)
      return res.data.serviceProfile
    },
  })
}
