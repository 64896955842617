import { AddressCategoryTypes } from 'entities/applicant'
import { ConsentType, DocumentIdTypes } from 'entities/entity'

import { MCareCard } from '../assets'
import { individualProfileF2En } from '../locale/individual-profile-f2.en'

export type IIndividualProfileF2FormType = {
  entityId?: string
  entityType: string
  createdAt?: string
  schemaVersion?: number
  addresses?: Partial<Address>[]
  phoneNumbers?: IPhoneNumber[]
  emailAddresses?: Array<IEmailAddress>
  customAttributes?: ICustomAttributes
  externalReferences?: IExternalReference[]
  internalExtraData?: ICustomAttributes
  sourceId?: string
  consents?: IConsent[]
  name: Partial<IName>
  alternateNames?: IName[]
  dateOfBirth?: IDateOfBirth
  alternateDatesOfBirth?: IDateOfBirth[]
  gender?: IGender
  placeOfBirth?: Address
  nationality?: string
} & {
  // recipe is not a real property will be used in form transformation only
  documents: DocumentF2FormType[]
  confirmation: boolean
  eKyc: boolean
  sameAsCurrent: boolean
  comments: string
  saveOnly?: boolean
  service: string
  workflow: string
  customerId: string
}

interface IGender {
  gender: string
  sourceId: string
}

interface IDateOfBirth {
  normalized: string
  year: string
  month: string
  day: string
  unstructured: string
  type: string
  sourceId: string
}

interface IName {
  language: string
  givenName: string
  middleName: string
  familyName: string
  otherName: string
  prefix: string
  suffix: string
  sourceId: string
  displayName: string
  type: string
}

interface IConsent {
  type: ConsentType
  schemaVersion: number
  sourceId: string
}

interface IExternalReference {
  type: string
  sourceId: string
  name: string
  value: string
  description: string
}

type ICustomAttributes = Record<string, IAdditionalProp>

interface IAdditionalProp {
  type: string
  value: string
}

interface IEmailAddress {
  type: 'OTHER' | 'WORK' | 'PERSONAL' | 'BUSINESS'
  sourceId: string
  email: string
  isPreferred: boolean
  linkedAddresses: ILinkedAddress[]
}

interface IPhoneNumber {
  isPreferred: boolean
  type: 'OTHER' | 'WORK' | 'HOME' | 'MOBILE' | 'BUSINESS' | 'FAX'
  sourceId: string
  country: string
  number: string
  linkedAddresses: ILinkedAddress[]
}

interface ILinkedAddress {
  addressId: string
}

export type Address = {
  sourceId: string
  addressId: string
  type: AddressCategoryTypes
  typeDescription: string
  format: string
  validFrom: IValidFrom
  validTo: IValidFrom
  longForm: string
  unstructuredLongForm: string
  unitNumber: string
  buildingName: string
  streetName: string
  streetNumber: string
  streetType: string
  neighborhood: string
  locality: string
  district: string
  subdivision: string
  country: string
  postalCode: string
  careOf: string
  status: string
  // custom attributes
  search?: string
}

interface IValidFrom {
  normalized: string
  year: string
  month: string
  day: string
  unstructured: string
  type: string
}

export type DocumentF2FormType = {
  documentId?: string
  entityId?: string
  expiryDate?: IExpiryDate
  issueDate?: IExpiryDate
  primaryIdentifier?: string
  secondaryIdentifier?: string
  type?: DocumentIdTypes | ''
  subtype?: string
  subdivision?: string
  country?: string
  class?: string
  requestedAt?: string
  createdAt?: string
  validFrom?: IExpiryDate
  validTo?: IExpiryDate
  sourceId?: string
  supplementaryData?: { [x: string]: string }
  customAttributes?: ICustomAttributes
  attachments?: IAttachment[]
} & {
  state?: string
}

export interface IAttachment {
  attachmentId?: string
  sourceId?: string
  createdAt?: string
  filename?: string
  mimeType?: string
  pageNumber?: number
  side?: 'FRONT' | 'BACK'
  type?: string
  size?: number
  data: { base64: string }
  lastMalwareScanAt?: string
  retrievalStatus?: string
}

interface IExpiryDate {
  normalized: string
  year: string
  month: string
  day: string
  unstructured: string
  type: string
}

export const documentIdTypesOption: {
  tKey: keyof (typeof individualProfileF2En)['idType']
  value: DocumentIdTypes
  isDefault: boolean
}[] = [
  {
    tKey: 'driverLicense',
    value: DocumentIdTypes.DRIVERS_LICENSE,
    isDefault: false,
  },
  {
    tKey: 'passport',
    value: DocumentIdTypes.PASSPORT,
    isDefault: true,
  },
  {
    tKey: 'medicareCard',
    value: DocumentIdTypes.NATIONAL_HEALTH_ID,
    isDefault: true,
  },
]

export const cardValue = {
  green: 'G',
  blue: 'B',
  yellow: 'Y',
}

export const mCareCardOption = [
  {
    icon: MCareCard.green,
    value: cardValue.green,
  },
  {
    icon: MCareCard.blue,
    value: cardValue.blue,
  },
  {
    icon: MCareCard.yellow,
    value: cardValue.yellow,
  },
]

export const displayMiddleName = {
  fullName: 'fullName',
  noName: 'noName',
  initial: 'initial',
}
