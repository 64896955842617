import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useApplicantAssociateBusinessMutation } from 'features/applicant-business-ownership'
import { ApplicantBusinessOwnershipQueryKey } from 'features/applicant-business-ownership/state/applicant-business-ownership-query/applicant-business-ownership.query'

import { ApplicantDataQueryKey } from 'entities/applicant'
import { TrustDeedTypes } from 'entities/applicant/model/applicant-trust-analyser.model'

import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'

import { applicantSupportingDocumentApi } from '../../api/applicant-supporting-document.api'
import { APPLICANT_SUPPORTING_DOCUMENTS_KEY } from '../../applicant-supporting-documents.key'
import { applicantSupportingDocumentsEn } from '../../locale/applicant-supporting-documents.en'
import {
  applicantTrustDeedDocumentQueryKey,
  useQueryUpdater,
} from '../trust-deeds-manage/trust-deeds-manage'
import { trustDeedAssociatedPartiesQueryKey } from '../trust-deeds-query/trust-deeds-query'

type uploadApiParam = Parameters<
  typeof applicantSupportingDocumentApi.uploadTrustDeedFile
>

type AnalyseApiParam = Parameters<
  typeof applicantSupportingDocumentApi.analyseTrustDeed
>

type ConfirmApiParam = Parameters<
  typeof applicantSupportingDocumentApi.confirmAnalysisResult
>

type UploadMutateProps = {
  entityId: uploadApiParam[0]
  payload: uploadApiParam[1]
}

type AnalyseMutateProps = {
  entityId: AnalyseApiParam[0]
  payload: AnalyseApiParam[1]
}

type ConfirmAnalysisMutateProps = {
  entityId: ConfirmApiParam[0]
  documentId: ConfirmApiParam[1]
  payload: ConfirmApiParam[2]
}

type DeleteTrustDocumentMutateProps = {
  applicantId: string
  documentId: string
}

export const useTrustDeedUploadFile = () => {
  const queryClient = useQueryClient()

  const t = useI18n([APPLICANT_SUPPORTING_DOCUMENTS_KEY], {
    keys: applicantSupportingDocumentsEn,
  })

  const mutation = useMutation({
    mutationFn: async ({ entityId, payload }: UploadMutateProps) => {
      const { data } = await applicantSupportingDocumentApi.uploadTrustDeedFile(
        entityId,
        payload,
      )
      return data
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries([applicantTrustDeedDocumentQueryKey])
      await queryClient.invalidateQueries([ApplicantDataQueryKey])

      notification.success(t('documentUploaded'))
    },
    onError: () => {
      notification.error(t('errorUploadingDoc'))
    },
  })

  return mutation
}

export const useDeleteTrustDeedDocument = () => {
  const { removeTrustListQuery } = useQueryUpdater()
  const t = useI18n([APPLICANT_SUPPORTING_DOCUMENTS_KEY], {
    keys: applicantSupportingDocumentsEn,
  })
  return useMutation({
    mutationFn: async ({
      applicantId,
      documentId,
    }: DeleteTrustDocumentMutateProps) => {
      const { data } =
        await applicantSupportingDocumentApi.deleteApplicantSupportDocument(
          applicantId,
          documentId,
        )
      return data
    },
    onSuccess: (_, req) => {
      removeTrustListQuery(req.applicantId, req.documentId)
      notification.success(t('documentDeleted'))
    },
    onError: () => {
      notification.error(t('errorDeletingDoc'))
    },
  })
}

export const useTrustDeedAnalyser = () => {
  const {
    updateStatusQuery,
    updateTrustListStatusQuery,
    updateAnalysisResultTypeQuery,
  } = useQueryUpdater()

  const t = useI18n([APPLICANT_SUPPORTING_DOCUMENTS_KEY], {
    keys: applicantSupportingDocumentsEn,
  })

  const mutation = useMutation({
    mutationFn: async ({ entityId, payload }: AnalyseMutateProps) => {
      const { data } = await applicantSupportingDocumentApi.analyseTrustDeed(
        entityId,
        payload,
      )
      return data
    },
    onMutate: ({ payload }) => {
      updateStatusQuery(TrustDeedTypes.PROCESSING, payload.documentId)
    },
    onSuccess: (res, req) => {
      const { type } = req.payload
      const { entityId, analysis } = res
      const { documentId, status, analysisId } = analysis
      const ids = { entityId, documentId, analysisId }

      updateTrustListStatusQuery(status as TrustDeedTypes, entityId, documentId)
      updateStatusQuery(status as TrustDeedTypes, documentId)
      updateAnalysisResultTypeQuery(type, ids)

      notification.success(t('startAnalyse'))
    },
    onError: (_: Error, res: AnalyseMutateProps) => {
      updateStatusQuery(
        TrustDeedTypes.FAILED as TrustDeedTypes,
        res.payload.documentId,
      )
      notification.error(t('analysisFailed'))
    },
  })

  return mutation
}

export const useTrustDeedConfirmAnalysis = () => {
  const { updateStatusQuery, updateAnalysisResultQuery } = useQueryUpdater()

  const t = useI18n([APPLICANT_SUPPORTING_DOCUMENTS_KEY], {
    keys: applicantSupportingDocumentsEn,
  })

  const mutation = useMutation({
    mutationFn: async ({
      entityId,
      documentId,
      payload,
    }: ConfirmAnalysisMutateProps) => {
      const { data } =
        await applicantSupportingDocumentApi.confirmAnalysisResult(
          entityId,
          documentId,
          payload,
        )
      return data
    },
    onSuccess: (_, req) => {
      updateStatusQuery(TrustDeedTypes.CONFIRMED, req.documentId)
      updateAnalysisResultQuery(req.payload, req.entityId, req.documentId)

      notification.success(t('confirmAnalysisSuccess'))
    },
    onError: () => {
      notification.error(t('confirmAnalysisFailed'))
    },
  })

  return mutation
}

export const useAssociateNewPartyMutation = (applicantId = '') => {
  const queryClient = useQueryClient()
  const t = useI18n([APPLICANT_SUPPORTING_DOCUMENTS_KEY], {
    keys: applicantSupportingDocumentsEn,
  })

  return useApplicantAssociateBusinessMutation({
    applicantId,
    associationType: 'new',
    onSuccessCallback: async () => {
      notification.success(
        t('trustAnalysisAssociateParty.success.createAssociatedParty'),
      )
      await queryClient.invalidateQueries([trustDeedAssociatedPartiesQueryKey])
      await queryClient.invalidateQueries([
        ApplicantBusinessOwnershipQueryKey,
        applicantId,
      ])
    },
  })
}

export const useAssociateExistingPartyMutation = (applicantId = '') => {
  const queryClient = useQueryClient()
  const t = useI18n([APPLICANT_SUPPORTING_DOCUMENTS_KEY], {
    keys: applicantSupportingDocumentsEn,
  })

  return useApplicantAssociateBusinessMutation({
    applicantId,
    associationType: 'existing',
    onSuccessCallback: async () => {
      notification.success(
        t('trustAnalysisAssociateParty.success.addAssociatedParty'),
      )
      await queryClient.invalidateQueries([trustDeedAssociatedPartiesQueryKey])
      await queryClient.invalidateQueries([
        ApplicantBusinessOwnershipQueryKey,
        applicantId,
      ])
    },
  })
}

export const useRemoveAssociatedPartyMutation = (applicantId = '') => {
  const queryClient = useQueryClient()
  const t = useI18n([APPLICANT_SUPPORTING_DOCUMENTS_KEY], {
    keys: applicantSupportingDocumentsEn,
  })

  return useApplicantAssociateBusinessMutation({
    applicantId,
    associationType: 'remove',
    onSuccessCallback: async () => {
      notification.success(
        t('trustAnalysisAssociateParty.success.removeAssociatedParty'),
      )
      await queryClient.invalidateQueries([trustDeedAssociatedPartiesQueryKey])
      await queryClient.invalidateQueries([
        ApplicantBusinessOwnershipQueryKey,
        applicantId,
      ])
    },
  })
}

export const useUpdateAssociatedPartyMutation = (applicantId = '') => {
  const queryClient = useQueryClient()
  const t = useI18n([APPLICANT_SUPPORTING_DOCUMENTS_KEY], {
    keys: applicantSupportingDocumentsEn,
  })
  return useApplicantAssociateBusinessMutation({
    applicantId,
    associationType: 'updating',
    onSuccessCallback: async () => {
      notification.success(
        t('trustAnalysisAssociateParty.success.editAssociatedParty'),
      )
      await queryClient.invalidateQueries([trustDeedAssociatedPartiesQueryKey])
      await queryClient.invalidateQueries([
        ApplicantBusinessOwnershipQueryKey,
        applicantId,
      ])
    },
  })
}
