import React from 'react'

import { useI18n } from 'shared/i18n'

import { INDIVIDUAL_AML_RESULT_KEY } from '../../../individual-aml-result.key'
import { individualAmlResultEn } from '../../../locale/individual-aml-result.en'
import { amlEntityCardQa } from '../../../qa/individual-aml-result.qa'
import { useLabelAndSrc } from '../../../state/individual-aml-helper.state'

type Props = {
  entityId: string
}
export function IndividualAmlPepCard({ entityId }: Props) {
  const t = useI18n(INDIVIDUAL_AML_RESULT_KEY, {
    keys: individualAmlResultEn,
  })

  const { pepSrcLabel } = useLabelAndSrc({ t, entityId })

  return (
    <>
      <div
        data-qa={amlEntityCardQa.container}
        className="border border-tertiary-grey-200 rounded-[4px] pt-5 px-6 pb-6"
      >
        <div className="">
          <div
            data-qa={amlEntityCardQa.title}
            className="font-semibold text-sm leading-5 text-tertiary-grey-700 mb-3"
          >
            Australia Department of the Prime Minister and Cabinet Officials
          </div>

          <div className="flex shrink flex-col w-full gap-3">
            <div className="grid grid-flow-col !auto-rows-auto  gap-3 grid-rows-[repeat(6,_auto)] grid-cols-2">
              {pepSrcLabel.map(item => (
                <div className="flex gap-4" key={item.value}>
                  <div
                    data-qa={amlEntityCardQa.sourceLabel}
                    className="min-w-[140px] font-medium text-sm leading-5 text-tertiary-grey-800"
                  >
                    {item.label}
                  </div>

                  {item.isUrl ? (
                    <a
                      className="underline text-primary-800"
                      href={item.value}
                      data-qa={amlEntityCardQa.sourceUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {item.value}
                    </a>
                  ) : (
                    <div
                      data-qa={amlEntityCardQa.sourceValue}
                      className="text-sm leading-5 text-tertiary-grey-700"
                    >
                      {item.value}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div
        data-qa={amlEntityCardQa.container}
        className="border border-tertiary-grey-200 rounded-[4px] pt-5 px-6 pb-6"
      >
        <div className="">
          <div
            data-qa={amlEntityCardQa.title}
            className="font-semibold text-sm leading-5 text-tertiary-grey-700 mb-3"
          >
            Australia Department of the Prime Minister and Cabinet Officials
          </div>

          <div className="flex shrink flex-col w-full gap-3">
            <div className="grid grid-flow-col !auto-rows-auto  gap-3 grid-rows-[repeat(6,_auto)] grid-cols-2">
              {pepSrcLabel.map(item => (
                <div className="flex gap-4" key={item.value}>
                  <div
                    data-qa={amlEntityCardQa.sourceLabel}
                    className="min-w-[140px] font-medium text-sm leading-5 text-tertiary-grey-800"
                  >
                    {item.label}
                  </div>

                  {item.isUrl ? (
                    <a
                      className="underline text-primary-800"
                      href={item.value}
                      data-qa={amlEntityCardQa.sourceUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {item.value}
                    </a>
                  ) : (
                    <div
                      data-qa={amlEntityCardQa.sourceValue}
                      className="text-sm leading-5 text-tertiary-grey-700"
                    >
                      {item.value}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
