import React from 'react'

import { IndividualAmlMatchTabs } from './individual-aml-match-tabs/individual-aml-match-tabs'
import { IndividualAmlPersonalInfo } from './individual-aml-personal-info/individual-aml-personal-info'
import { amlMatchDatQa } from '../../qa/individual-aml-result.qa'

type Props = {
  entityId: string
  processResultId?: string
}

export function IndividualAmlMatchData({ entityId, processResultId }: Props) {
  return (
    <div
      className="flex-grow flex flex-col gap-6"
      data-qa={amlMatchDatQa.container}
    >
      <IndividualAmlPersonalInfo />
      <IndividualAmlMatchTabs entityId={entityId} />
    </div>
  )
}
