import React, { useCallback } from 'react'

import { FrankieButton } from 'frankify/src'

import { ApplicantId } from 'entities/applicant'

import { useI18n } from 'shared/i18n'
import { useOverlay } from 'shared/overlay'

import { APPLICANT_SUPPORTING_DOCUMENTS_KEY } from '../../applicant-supporting-documents.key'
import { applicantSupportingDocumentsEn } from '../../locale/applicant-supporting-documents.en'
import { trustDeedsDocQa } from '../../qa/applicant-support-documents.qa'
import { TrustAnalyzerUpload } from '../trust-analyzer-upload/trust-analyzer-upload'
import { TrustUploadedDocuments } from '../trust-uploaded-documents/trust-uploaded-documents'

const PARAM_KEY = 'trustId'

type Props = {
  applicantId: ApplicantId
}

export function TrustAnalyzer({ applicantId }: Props) {
  const t = useI18n([APPLICANT_SUPPORTING_DOCUMENTS_KEY], {
    keys: applicantSupportingDocumentsEn,
  })

  const [createOverlay, closeOverlay] = useOverlay()

  const onClickTrustDeed = useCallback(() => {
    createOverlay(
      <TrustAnalyzerUpload applicantId={applicantId} onClose={closeOverlay} />,
      {
        closeButtonClassName: '!top-4 !right-5',
        className: 'p-6 w-[600px] h-[380px] ',
      },
    )
  }, [createOverlay, applicantId, closeOverlay])

  return (
    <div
      className="basis-full text-tertiary-grey-700 overflow-x-hidden px-3"
      data-qa={trustDeedsDocQa.wrapper}
    >
      <div className="mb-4" data-qa={trustDeedsDocQa.notDoc}>
        <div className="text-xl text-secondary-900 font-bold pb-2">
          {t('addTrustDeedDocument')}
        </div>
        <div>{t('pdfFileTypeOnly')}</div>
      </div>

      <FrankieButton
        intent="secondary"
        size="xs"
        onClick={onClickTrustDeed}
        testId={{ button: trustDeedsDocQa.uploadFileButton }}
      >
        <p className="font-semibold text-sm">{t('uploadTrustDeed')}</p>
      </FrankieButton>

      <TrustUploadedDocuments
        paramKey={PARAM_KEY}
        applicantId={applicantId}
        className="my-8"
        data-qa={trustDeedsDocQa.notDoc}
      />
    </div>
  )
}
