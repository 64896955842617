/* eslint-disable complexity */
import React from 'react'

import { FrankieAvatar, FrankieDivider, FrankieLoader } from 'frankify/src'

import { NotificationEventTypes } from 'fwidgets/suprsend-notifications/model/suprsend-notifications.model'

import {
  ApplicantEntityTypes,
  ApplicantId,
  ApplicantResponse,
  FrankieRawApplicant,
  useApplicantDataQuery,
} from 'entities/applicant'
import { useHasFeatureFlag } from 'entities/session'

import { formatDistanceToNow } from 'shared/date-time'
import { useI18n } from 'shared/i18n'
import { FrankieLink } from 'shared/react-router'
import { TrackingEventsTypes, trackingManager } from 'shared/tracking'

import { Message } from './message/message'
import { SUPRSEND_KEY, suprsendEn } from '../../locale/en'
import { notificationWidgetQa } from '../../qa/suprsend-notifications.qa'

type Props = {
  applicants: FrankieRawApplicant[]
  getApplicantPath: (id: ApplicantId) => string
  isLoading: boolean
  notificationData: {
    created_on: number
    seen_on?: number
    message?: {
      url?: string
      text?: string
      avatar?: {
        avatar_url: string
      }
      extra_data?: string // JSON
    }
  }
}

type ExtraData = {
  entity_id?: ApplicantId
  event?: string
  status?: string
  risk_from?: string
  risk_to?: string
  document_name?: string
}

export function NotificationCard({
  notificationData,
  applicants,
  getApplicantPath,
  isLoading,
}: Props) {
  const t = useI18n([SUPRSEND_KEY], { keys: suprsendEn })
  const { created_on, seen_on, message } = notificationData
  const { extra_data, text } = message || {}

  const { trustAnalyser } = useHasFeatureFlag({
    trustAnalyser: 'trustAnalyser',
  })

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const dataParsed: ExtraData = JSON.parse(extra_data || '{}')
  const { entity_id, event, status, risk_from, risk_to, document_name } =
    dataParsed

  let applicant: FrankieRawApplicant | ApplicantResponse | undefined =
    applicants.find(item => item.entityId === entity_id)

  const { data, isInitialLoading: isLoadingSingle } = useApplicantDataQuery({
    applicantId: entity_id,
    enabled: !isLoading && !applicant,
  })

  if (!isLoading && !applicant) {
    applicant = data
  }

  let name = ''
  let type = ''
  if (applicant) {
    if ('entityType' in applicant) {
      type = applicant.entityType
      name = applicant.entityName ?? ''
    }
    if ('applicantDetails' in applicant) {
      const { givenName, middleName, familyName, displayName } =
        applicant.applicantDetails.name

      type = applicant.businessInfo
        ? ApplicantEntityTypes.Organisation
        : ApplicantEntityTypes.Individual
      name =
        applicant.businessInfo?.businessName ||
        displayName ||
        [givenName, middleName, familyName].filter(str => str).join(' ')
    }
  }

  const handleClick = () => {
    trackingManager.track(TrackingEventsTypes.NotificationClick)
  }

  // handle dont show notif for trust analysis when the feature flag is not enabled
  if (
    !trustAnalyser &&
    event === NotificationEventTypes.TRUST_ANALYSIS_COMPLETE
  )
    return null

  return (
    <FrankieLoader size="xs" loading={isLoading || isLoadingSingle}>
      <div>
        <FrankieLink
          notALink={!entity_id}
          onClick={handleClick}
          to={getApplicantPath(entity_id || '')}
          className="flex flex-row p-4 gap-2 hover:bg-tertiary-grey-50"
          data-qa={notificationWidgetQa.notificationItem}
        >
          <div className="shrink-0">
            <FrankieAvatar
              name="avatar"
              size="lg"
              className="bg-tertiary-grey-100"
              icon={{
                size: 'md',
                name:
                  type === ApplicantEntityTypes.Organisation
                    ? 'mdiOfficeBuildingOutline'
                    : 'mdiAccountOutline',
                className: 'text-tertiary-grey-700',
              }}
            />
          </div>
          <div className="basis-full">
            <div
              className="mb-1.5 leading-tight text-sm text-tertiary-grey-700"
              data-qa={notificationWidgetQa.notificationItemText}
            >
              {event ? (
                <Message
                  entityId={entity_id || ''}
                  name={name}
                  event={event}
                  riskFrom={risk_from}
                  riskTo={risk_to}
                  status={status}
                  documentName={document_name}
                />
              ) : (
                text
              )}
            </div>
            <div className="text-tertiary-grey-500 leading-none text-xs">
              {t('format.ago', {
                date: formatDistanceToNow(new Date(created_on)),
              })}
            </div>
          </div>
          {!seen_on && (
            <div>
              <div className="w-3 h-3 rounded-full bg-primary-800" />
            </div>
          )}
        </FrankieLink>
        <FrankieDivider />
      </div>
    </FrankieLoader>
  )
}
