import React from 'react'

import { FrankieAvatarStyle } from './avatar.theme'
import { IFrankieAvatarProps } from './avatar.types'
import { FrankieIcon } from '../icon'
import { FrankieImage } from '../image'

export function FrankieAvatar({
  image = '',
  icon,
  size = 'md',
  name,
  testId = {
    image: '',
    initial: '',
  },
  className,
}: IFrankieAvatarProps): JSX.Element {
  const getInitials = (name: string) => {
    const names = name.trim().split(' ')
    return names.reduce((previous, current, index) => {
      const indexFirstName = 0
      const indexLastName = names.length - 1
      if (index === indexFirstName || index === indexLastName) {
        return `${previous}${current.charAt(0).toUpperCase()}`
      }
      return previous
    }, '')
  }

  const sizedInitials =
    size === 'sm' ? getInitials(name).charAt(0) : getInitials(name)

  if (image) {
    return (
      <FrankieImage
        className={FrankieAvatarStyle({ container: size })}
        alt={name}
        title={name}
        src={image}
        testId={{ image: testId.image }}
      />
    )
  }
  if (icon) {
    return (
      <div className={FrankieAvatarStyle({ container: size, className })}>
        <FrankieIcon {...icon} />
      </div>
    )
  }
  return (
    <div className={FrankieAvatarStyle({ container: size, className })}>
      <div
        data-qa={testId.initial}
        className={FrankieAvatarStyle({ content: size })}
      >
        {sizedInitials}
      </div>
    </div>
  )
}
