import React, { useMemo } from 'react'

import {
  DataGridPro,
  GridColDef,
  GridExpandMoreIcon,
  GridKeyboardArrowRight,
  GridRenderCellParams,
  GridRowParams,
} from '@mui/x-data-grid-pro'
import { Trans } from 'react-i18next'

import { FrankieIcon } from 'frankify/src'

import { ApplicantResponse } from 'entities/applicant'
import {
  RulesetMatchFields,
  RulesetsMatches,
} from 'entities/applicant/model/applicant-response.model'

import { useI18n } from 'shared/i18n'

import { APPLICANT_PERSONAL_INFO_KEYS } from '../../applicant-persona-info.keys'
import { applicantPersonalInfoEn } from '../../locale/applicant-personal-info.en'
import { getStatusIcon } from '../../model/applicant-detail-view.model'
import {
  GetRowRequiredDataDetails,
  RulesetsRowData,
  getRowRequiredDataDetails,
  getRowValue,
  getStatus,
  getVerifyColorOutcome,
  verifiedBgColor,
} from '../../model/applicant-matched-rulesets.model'

type RulesetMatchedCellProps = GridRenderCellParams<RulesetsRowData>

export function RulesetMatchedCell({
  row,
  value,
  colDef,
}: RulesetMatchedCellProps) {
  const field = colDef.field as keyof RulesetMatchFields
  const matchTypeName = row.rulesetMatchField[field]
  return (
    <div className="text-tertiary-grey-700">
      <div
        className={`flex justify-center items-center font-bold  m-auto text-center text-[13px] w-[16px] h-[16px] p-1 ${verifiedBgColor(
          matchTypeName,
          row.result,
          value as string,
        )}`}
      >
        {value}
      </div>
    </div>
  )
}

type RowDetailViewProps = GridRowParams<RulesetsRowData>

export function RowDetailView({ row }: RowDetailViewProps) {
  const details = getRowRequiredDataDetails(row.rulesetMatchField)
  const dataFields = Object.keys(details)

  return (
    <div className="text-[13px] bg-tertiary-grey-100 rounded mx-3 my-2 p-6 m-3">
      <div className="flex justify-start items-center">
        <FrankieIcon
          name="mdiInformationOutline"
          size="sm"
          className="text-tertiary-grey-400 mr-2"
        />
        <span>
          <Trans
            i18nKey="applicant_personal_info:rulesets.rowDetails"
            components={[<strong />]}
            tOptions={{
              name: row.ruleset,
            }}
          />
        </span>
      </div>
      {!!dataFields.length && (
        <div>
          <ul className="!list-disc ml-8">
            {dataFields.map((field, idx) => (
              <li key={field}>
                <span>
                  <Trans
                    i18nKey="applicant_personal_info:rulesets.requiredData"
                    components={[<strong />]}
                    tOptions={{
                      countValue:
                        details[field as keyof GetRowRequiredDataDetails].count,
                      field:
                        details[field as keyof GetRowRequiredDataDetails].name,
                    }}
                  />
                </span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}

type RulesetOutcomeCellProps = GridRenderCellParams<RulesetsRowData>

export function RulesetOutcomeCell({ row }: RulesetOutcomeCellProps) {
  const status = getStatus(row.result)
  const iconName = getStatusIcon(status.status, null)

  return (
    <div className="text-sm flex justify-start items-center font-bold ">
      <FrankieIcon
        name={iconName}
        className={`${getVerifyColorOutcome(status.status)} mr-1`}
        size="sm"
      />
      <div className={`mr-3 ${getVerifyColorOutcome(status.status)}`}>
        {status.text}
      </div>
    </div>
  )
}

type Props = {
  applicantData: ApplicantResponse
}

export function ApplicantRulesetsTable({ applicantData }: Props) {
  const t = useI18n(APPLICANT_PERSONAL_INFO_KEYS, {
    keys: applicantPersonalInfoEn,
  })

  /**
   * @todo to be removed
   * dummy data
   */

  const data: ApplicantResponse = useMemo(
    () => ({
      ...applicantData,
      checkSummary: {
        ...applicantData.checkSummary,
        // rulesetsMatches: dummy,
      },
    }),
    [applicantData],
  )

  const priorityMatchedData = useMemo(() => {
    const getSortedRulesets = () =>
      data.checkSummary.rulesetsMatches.sort(
        (a: RulesetsMatches, b: RulesetsMatches) => {
          if (a.order === null) {
            return -1
          }

          if (b.order === null) {
            return 1
          }

          if (a.order === b.order) {
            return 0
          }

          return a.order < b.order ? -1 : 1
        },
      )
    const priorityMatched = getSortedRulesets().find(
      ruleset => ruleset.result === 'PASS',
    )
    return priorityMatched ? [priorityMatched] : []
  }, [data.checkSummary.rulesetsMatches])

  const rowData = useMemo(() => {
    const rulesets = priorityMatchedData.length
      ? priorityMatchedData
      : data.checkSummary.rulesetsMatches
    if (!rulesets.length) return []

    return rulesets.map(ruleset => ({
      ruleset: ruleset.name ? ruleset.name : '',
      name: `${getRowValue(ruleset.rulesetMatchFields.name)}`,
      dob: `${getRowValue(ruleset.rulesetMatchFields.dob)}`,
      address: `${getRowValue(ruleset.rulesetMatchFields.address)}`,
      govId: `${getRowValue(ruleset.rulesetMatchFields.govId)}`,
      result: ruleset.result,
      rulesetMatchField: ruleset.rulesetMatchFields,
    }))
  }, [data.checkSummary.rulesetsMatches, priorityMatchedData])

  const TABLE_MIN_WIDTH = 600

  const columns: GridColDef<RulesetsRowData>[] = [
    {
      field: 'ruleset',
      headerName: t('rulesets.priorityMatched.rulesets'),
      maxWidth: 200,
      flex: 200 / TABLE_MIN_WIDTH,
    },
    {
      field: 'name',
      headerName: t('rulesets.priorityMatched.name'),
      maxWidth: 120,
      flex: 120 / TABLE_MIN_WIDTH,
      renderCell: RulesetMatchedCell,
    },
    {
      field: 'dob',
      headerName: t('rulesets.priorityMatched.dob'),
      maxWidth: 120,
      flex: 120 / TABLE_MIN_WIDTH,
      renderCell: RulesetMatchedCell,
    },
    {
      field: 'address',
      headerName: t('rulesets.priorityMatched.address'),
      maxWidth: 120,
      flex: 120 / TABLE_MIN_WIDTH,
      renderCell: RulesetMatchedCell,
    },
    {
      field: 'govId',
      headerName: t('rulesets.priorityMatched.govId'),
      maxWidth: 120,
      flex: 120 / TABLE_MIN_WIDTH,
      renderCell: RulesetMatchedCell,
    },
    {
      field: 'outcome',
      headerName: t('rulesets.priorityMatched.outcome'),
      maxWidth: 120,
      flex: 120 / TABLE_MIN_WIDTH,
      renderCell: RulesetOutcomeCell,
    },
  ]

  return (
    <DataGridPro
      rows={rowData}
      slots={{
        detailPanelExpandIcon: GridKeyboardArrowRight,
        detailPanelCollapseIcon: GridExpandMoreIcon,
      }}
      getRowId={row => row.ruleset}
      hideFooter
      columnHeaderHeight={40}
      getDetailPanelHeight={() => 'auto'}
      columns={columns.map(item => ({
        hideSortIcons: true,
        resizable: false,
        disableColumnMenu: true,
        disableReorder: true,
        headerClassName:
          'text-xs !p-0 !pl-2 text-xs bg-tertiary-grey-50 font-medium text-tertiary-grey-500 !outline-none ',
        ...item,
      }))}
      getDetailPanelContent={RowDetailView}
    />
  )
}
