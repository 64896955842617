import { useI18n } from 'shared/i18n'

import { ANALYTICS_DASHBOARD_KEY } from '../analytics-dashboard.key'

export const analyticsDashboardEn = {
  title: 'Analytics (Standard dashboard)',
  description:
    'This dashboard provides two key metrics: <0>Pass Rate Straight Through Processing (STP),</0> which shows the percentage of unique active customers (by entity ID), and Pass Rate Including Manual, which includes both automatic and manual passes by operators. Data for both metrics refreshes daily at 02:00 am AESDT.',
  noAccess: 'No access',
  initializing: 'Initialising...',
}

export const useAnalyticsDashboardTranslation = () => {
  const t = useI18n([ANALYTICS_DASHBOARD_KEY], { keys: analyticsDashboardEn })

  return { t }
}
