import { useMemo } from 'react'

import { PermissionTypes } from 'entities/role'

import { useHasFeatureFlag } from '../../hooks/has-feature-flag/has-feature-flag.hook'
import { useHasPermission } from '../../hooks/has-permission/has-permission.hook'
import { useSessionQuery } from '../session/session.query'

type TransactionMonitoring = {
  hasAmlTransaction: boolean
  hasFraudTransaction: boolean
  hasDeviceCustomer: boolean
}

/**
 * Hook to check if the user has transaction monitoring enabled
 * transactionMonitoring can either be boolean or an object with the following properties: aml, fraud, device
 * if boolean and true, all transaction monitoring features are enabled
 * Along with com
 */
export function useHasTransactionMonitoring() {
  const { data: sessionData } = useSessionQuery()

  const txnPermission = useHasPermission({
    hasTxnPermissionAML: PermissionTypes.AmlTransactionData,
    hasTxnPermissionFraud: PermissionTypes.FraudTransactionData,
    hasTxnPermissionCustomer: PermissionTypes.DeviceTransactionData,
  })

  const transactionMonitoring = useHasFeatureFlag({
    hasAmlTransaction: ['transactionMonitoring', 'aml'],
    hasFraudTransaction: ['transactionMonitoring', 'fraud'],
    hasDeviceCustomer: ['transactionMonitoring', 'device'],
  })

  const tm = useMemo(() => {
    const tmFeatureFlag = sessionData?.features.transactionMonitoring

    const result: TransactionMonitoring = {
      hasAmlTransaction: !!tmFeatureFlag && txnPermission.hasTxnPermissionAML,
      hasFraudTransaction:
        !!tmFeatureFlag && txnPermission.hasTxnPermissionFraud,
      hasDeviceCustomer:
        !!tmFeatureFlag && txnPermission.hasTxnPermissionCustomer,
    }

    if (tmFeatureFlag && typeof tmFeatureFlag !== 'boolean') {
      return {
        hasAmlTransaction:
          transactionMonitoring.hasAmlTransaction &&
          txnPermission.hasTxnPermissionAML,
        hasFraudTransaction:
          transactionMonitoring.hasFraudTransaction &&
          txnPermission.hasTxnPermissionFraud,
        hasDeviceCustomer:
          transactionMonitoring.hasDeviceCustomer &&
          txnPermission.hasTxnPermissionCustomer,
      }
    }

    return result
  }, [sessionData?.features.transactionMonitoring, transactionMonitoring])

  return {
    ...tm,
    enableTransactionPage: tm.hasAmlTransaction || tm.hasFraudTransaction,
    enableDeviceCharacteristicsPage: tm.hasDeviceCustomer,
  }
}
