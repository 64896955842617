import React, { useMemo } from 'react'

import { useI18n } from 'shared/i18n'

import {
  NOTIFICATIONS_MANAGEMENT_KEY,
  notificationsManagementEn,
} from '../../locale/notifications-management.en'
import {
  INotificationChannel,
  INotificationSetting,
  INotificationSettingsDictionary,
} from '../../model/notifications-management.model'
import {
  NotificationsConfigTableRow,
  NotificationsConfigTableRowHeader,
} from '../notifications-config-table-row'

type DictionaryWithSettings = INotificationSettingsDictionary & {
  settings: INotificationSetting[]
}

type Props = {
  notificationChannels: INotificationChannel[]
  notificationSettingsDictionary: INotificationSettingsDictionary[]
  notificationSettings: INotificationSetting[]
  canSeeNonPersonalNotifications: boolean
  onChange: (setting: INotificationSetting, newChecked: boolean) => void
}

export function NotificationsConfigTable({
  notificationChannels,
  notificationSettings,
  notificationSettingsDictionary,
  canSeeNonPersonalNotifications,
  onChange,
}: Props) {
  const t = useI18n([NOTIFICATIONS_MANAGEMENT_KEY], {
    keys: notificationsManagementEn,
  })

  const orderedSettings = useMemo<INotificationSetting[]>(
    () =>
      notificationChannels
        .map<INotificationSetting[]>(chan =>
          notificationSettings.filter(
            setting => setting.channel === chan.value,
          ),
        )
        .flat(),
    [notificationSettings, notificationChannels],
  )

  const dictionaryWithSettingsAndI18n = useMemo<DictionaryWithSettings[]>(
    () =>
      notificationSettingsDictionary.map<DictionaryWithSettings>(dict => {
        let { name, description } = dict
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (notificationsManagementEn.type.types[dict.value]) {
          name = t(`type.types.${dict.value}.name`)
          description = t(`type.types.${dict.value}.description`)
        }

        return {
          ...dict,
          name,
          description,
          settings: orderedSettings.filter(
            setting => setting.type === dict.value,
          ),
        }
      }),
    [notificationSettingsDictionary, orderedSettings, t],
  )

  const filteredDictionaryWithSettingsAndI18n = useMemo<
    DictionaryWithSettings[]
  >(() => {
    if (canSeeNonPersonalNotifications) {
      return dictionaryWithSettingsAndI18n
    }
    return dictionaryWithSettingsAndI18n.filter(
      dict => dict.isPersonalNotification,
    )
  }, [dictionaryWithSettingsAndI18n, canSeeNonPersonalNotifications])

  const channelsWithI18n = useMemo<INotificationChannel[]>(
    () =>
      notificationChannels.map<INotificationChannel>(channel => ({
        ...channel,
        name: notificationsManagementEn.channel[channel.value]
          ? t(`channel.${channel.value}`)
          : channel.name,
      })),
    [notificationChannels, t],
  )

  return (
    <div>
      <div className="pr-8">
        <NotificationsConfigTableRowHeader channels={channelsWithI18n} />
      </div>
      <div className="h-[calc(100vh-364px)] pb-[116px] pr-8">
        {filteredDictionaryWithSettingsAndI18n.map(dict => (
          <NotificationsConfigTableRow
            onChange={onChange}
            notificationTypeInfo={dict}
            settings={dict.settings}
          />
        ))}
      </div>
    </div>
  )
}
