import React, { ReactNode } from 'react'

import { Control, UseFormRegister } from 'react-hook-form'

import { trustAnalyserEditFormQa } from 'features/applicant-supporting-documents/qa/applicant-support-documents.qa'

import { TextAreaFormField } from 'shared/form'
import { useI18n } from 'shared/i18n'

import { APPLICANT_SUPPORTING_DOCUMENTS_KEY } from '../../../applicant-supporting-documents.key'
import { applicantSupportingDocumentsEn } from '../../../locale/applicant-supporting-documents.en'
import {
  ITrustDeedForm,
  TrustFormTypes,
} from '../../../model/applicant-supporting-trust-deed.model'
import {
  FormHeader,
  FormFooter,
} from '../form-header-footer/form-header-footer'

export type TrustGeneralBeneficiaryFormProps = {
  title: string
  formKey: TrustFormTypes
  isLastItem: boolean
  index: number
  register: UseFormRegister<ITrustDeedForm>
  control: Control<ITrustDeedForm, unknown>
  onAdd: () => void
  onRemove: () => void
  tag?: ReactNode
}

export function TrustGeneralBeneficiaryForm({
  title,
  formKey,
  isLastItem,
  index,
  register,
  control,
  onAdd,
  onRemove,
  tag,
}: TrustGeneralBeneficiaryFormProps) {
  const t = useI18n([APPLICANT_SUPPORTING_DOCUMENTS_KEY], {
    keys: applicantSupportingDocumentsEn,
  })

  const firstItem = index === 0

  return (
    <>
      {firstItem && <div className="font-semibold text-lg mb-3">{title}</div>}
      <div className="bg-tertiary-grey-50 px-4 py-1">
        {firstItem && (
          <div className="font-bold text-md my-4">
            {t('generalBeneficiaries')}
          </div>
        )}
        <FormHeader
          number={index + 1}
          showRemove={!(isLastItem && index === 0)}
          onRemove={onRemove}
          text={t('group')}
          className="text-sm font-medium mb-2"
          removeText={`${t('remove')} ${t('group')}`}
          tag={tag}
        />
        <TextAreaFormField
          {...register(`beneficiary.generalBeneficiary.${index}.value`)}
          control={control}
          testId={{
            input: trustAnalyserEditFormQa.generalBeneficiaries(
              formKey,
              index + 1,
            ),
          }}
          data-hj-suppress
        />
        <FormFooter
          onAdd={onAdd}
          showFooter={isLastItem}
          text={t('group')}
          showDivider={false}
        />
      </div>
    </>
  )
}
