import React, { useMemo, useState } from 'react'

import { Outlet, useParams, generatePath, useLocation } from 'react-router-dom'

import { FrankieButton, FrankieIcon, FrankieTooltip } from 'frankify/src'

import {
  ApplicantReportGeneratedNotification,
  normalizeRiskData,
  useApplicantCheckSummaryState,
} from 'features/applicant-general-information'
import { getContentFromApplicantByBlocklistInfo } from 'features/blocklist-table'

import {
  ApplicantTabTypes,
  SelectApplicantAssignee,
  ApplicantRouteConfig,
  AddApplicantComment,
  useApplicantDataQuery,
  ApplicantIssueActionBadge,
  useApplicantInternationalKYB,
} from 'entities/applicant'
import { useHandleBack } from 'entities/routing'
import { useUpdatePreviousApplicantPath } from 'entities/routing/state/applicant-location/applicant-location'
import {
  TPath,
  useGetPathValue,
  useHasFeatureFlag,
  useHasTransactionMonitoring,
  useSessionQuery,
} from 'entities/session'

import { useI18n } from 'shared/i18n'
import { splitAndCompareLast } from 'shared/string'
import { modifyRecord } from 'shared/typescript'

import { LayoutApplicantProfileF2 } from './layout-applicant-profile-f2/layout-applicant-profile-f2'
import { TextView } from './text-view/text-view'
import { ROUTING_KEY, routingEn } from '../../locale/routing.en'
import { routingVueMigratedQa } from '../../qa/routing.qa'
import { NavTabs, NavTabOption } from '../nav-tabs/nav-tabs'

type NavData = {
  label: string
  value: ApplicantTabTypes
  types: ('individual' | 'organisation' | 'international')[]
  hidden?: boolean
}

type Props = ApplicantRouteConfig<TPath>

// eslint-disable-next-line complexity
export function LayoutApplicantProfileF1({
  applicantIdParamKey,
  paths,
}: Props) {
  const t = useI18n(ROUTING_KEY, { keys: routingEn })
  const [showTextCopied, setShowTextCopied] = useState(false)

  const params = useParams()
  const { pathname } = useLocation()

  useUpdatePreviousApplicantPath({ applicantIdParamKey })
  const { handleBack } = useHandleBack()

  const { getPathValue, generatePathValue } = useGetPathValue()

  /**
   * Final applicant paths using getPathValue
   */
  const applicantPaths = useMemo(
    () => modifyRecord(paths, getPathValue),
    [getPathValue, paths],
  )

  const { enableTransactionPage, enableDeviceCharacteristicsPage } =
    useHasTransactionMonitoring()

  const { hasConSardine } = useHasFeatureFlag({
    hasInternationalKYB: 'internationalKYB',
    hasConSardine: ['connectors', 'con_sardine'],
  })

  const applicantId = params[applicantIdParamKey]

  const {
    isBlocklistEntity,
    isDuplicateEntity,
    isManualBlocklist,
    profileStatusType,
  } = useApplicantCheckSummaryState({ applicantId })

  const { data: applicantData, isLoading } = useApplicantDataQuery({
    applicantId,
  })

  const { isInternationalKYB, isInternationalKybAmlEnabled } =
    useApplicantInternationalKYB({ applicantId })

  const riskLevel = useMemo(() => {
    const riskValue = applicantData?.checkSummary.risk.class
    /**
     * Removing this condition
     * {@link https://frankieone.atlassian.net/browse/FDEL-19553?focusedCommentId=135604}
     */
    // if (
    //   hasInternationalKYB &&
    //   applicantData?.businessInfo &&
    //   !applicantData.businessInfo.ABNNumber &&
    //   !applicantData.businessInfo.ACNNumber
    // ) {
    //   riskValue = 'risk_unknown'
    // }
    return normalizeRiskData(riskValue)
  }, [applicantData])

  const {
    name,
    isProfileOrganisationType,
    customerId,
    isBlocklistAttributeATTR,
    isInternationalBusiness,
  } = useMemo(() => {
    const isBlocklistAttributeATTR =
      applicantData?.applicantDetails.blocklistAttributes === 'ATTR'

    const applicantNameData = applicantData?.applicantDetails.name
    const applicationName: string = isBlocklistAttributeATTR
      ? getContentFromApplicantByBlocklistInfo(applicantData)
      : applicantNameData?.displayName ??
        [
          applicantNameData?.givenName,
          applicantNameData?.middleName,
          applicantNameData?.familyName,
        ]
          .filter(Boolean)
          .join(' ')

    const profileStatus = applicantData?.checkSummary.status.key
    const hasBusinessInfo = !!applicantData?.businessInfo

    return {
      name:
        applicationName.length > 0
          ? applicationName
          : applicantData?.businessInfo?.businessName,
      isProfileOrganisationType: hasBusinessInfo,
      customerId: applicantData?.applicantDetails.customerReference,

      isBlocklistAttributeATTR,

      isInternationalBusiness: isInternationalKYB,
      // this.applicantData?.profile?.country !== 'aus' <--this was in vue code
      hasBusinessInfo,

      profileStatus,
    }
  }, [applicantData, isInternationalKYB])

  const navData: NavData[] = useMemo(
    () => [
      {
        label: t('applicant.nav.overview'),
        value: 'generalInformation',
        types: ['individual', 'organisation', 'international'],
      },
      {
        label: t('applicant.nav.personalInfo'),
        value: 'personalInfoKyc',
        types: ['individual'],
      },
      {
        label: t('applicant.nav.blocklistedInfo'),
        value: 'blocklistedInfo',
        types: ['individual'],
        hidden: !(isManualBlocklist && isBlocklistAttributeATTR),
      },
      {
        label: t('applicant.nav.potentialMatches'),
        value: 'potentialMatches',
        types: ['individual'],
        hidden: !isManualBlocklist,
      },
      {
        label: t('applicant.nav.blocklist'),
        value: 'blocklistMatches',
        types: ['individual'],
        hidden: !(isBlocklistEntity && !isManualBlocklist),
      },
      {
        label: t('applicant.nav.duplicates'),
        value: 'duplicateMatches',
        types: ['individual'],
        hidden: !isDuplicateEntity,
      },
      {
        label: t('applicant.nav.amlScreening'),
        value: 'pepSanctions',
        types: ['individual', 'organisation'],
      },
      {
        label: t('applicant.nav.amlScreening'),
        value: 'iKybAmlScreening',
        types: ['international'],
        hidden: !isInternationalKybAmlEnabled,
      },
      {
        label: t('applicant.nav.ownersOfficeholders'),
        value: 'businessOwnership',
        types: ['organisation', 'international'],
      },
      {
        label: t('applicant.nav.riskScore'),
        value: 'riskIndicators',
        types: ['individual', 'organisation'],
      },
      {
        label: t('applicant.nav.biometricsOcr'),
        value: 'biometryOcr',
        types: ['individual'],
      },
      {
        label: isProfileOrganisationType
          ? t('applicant.nav.kybFraudCheck')
          : t('applicant.nav.kycFraudCheck'),
        value: 'fraudCheck',
        types: ['individual', 'organisation'],
      },
      {
        label: t('applicant.nav.transactions'),
        value: 'transactions',
        types: ['individual'],
        hidden: !enableTransactionPage,
      },
      {
        label: t('applicant.nav.characteristics'),
        value: 'characteristics',
        types: ['individual'],
        hidden: !enableDeviceCharacteristicsPage,
      },
      {
        label: t('applicant.nav.auditReport'),
        value: 'auditReport',
        types: ['individual', 'organisation', 'international'],
      },
      {
        label: isProfileOrganisationType
          ? t('applicant.nav.documentUpload')
          : t('applicant.nav.documents'),
        value: 'supportingDocuments',
        types: ['individual', 'organisation', 'international'],
      },
    ],

    [
      enableTransactionPage,
      enableDeviceCharacteristicsPage,
      isInternationalKybAmlEnabled,
      isBlocklistEntity,
      isDuplicateEntity,
      isManualBlocklist,
      isBlocklistAttributeATTR,
      isProfileOrganisationType,
      t,
    ],
  )

  const navOptions: NavTabOption[] = useMemo(
    () =>
      (() => {
        // If international business
        if (isInternationalBusiness) {
          return navData.filter(
            item => !item.hidden && item.types.includes('international'),
          )
        }

        // If Profile type is Organisation
        if (isProfileOrganisationType) {
          return navData.filter(
            item => !item.hidden && item.types.includes('organisation'),
          )
        }

        // Rest of the condition are for when Profile type is individual

        if (isManualBlocklist && isBlocklistAttributeATTR) {
          return navData.filter(item =>
            (
              [
                'generalInformation',
                'blocklistedInfo',
                'potentialMatches',
                'auditReport',
              ] as ApplicantTabTypes[]
            ).includes(item.value),
          )
        }

        return navData.filter(
          item =>
            !item.hidden &&
            item.types.includes('individual') &&
            (hasConSardine ? item.value !== 'fraudCheck' : !hasConSardine),
        )
      })().map(item => ({
        label: item.label,
        path: generatePath(applicantPaths[item.value], {
          [applicantIdParamKey]: applicantId,
        }),
        testId: routingVueMigratedQa.applicants.nav[item.value],
      })),
    [
      applicantIdParamKey,
      applicantId,
      isBlocklistAttributeATTR,
      hasConSardine,
      isInternationalBusiness,
      isManualBlocklist,
      isProfileOrganisationType,
      navData,
      applicantPaths,
    ],
  )

  const hideTabs = useMemo(() => {
    const manualIdvPath = generatePathValue(applicantPaths.manualIdv, {
      [applicantIdParamKey]: applicantId,
    })

    const editBusinessPath = generatePathValue(applicantPaths.editBusiness, {
      [applicantIdParamKey]: applicantId,
    })

    return (
      splitAndCompareLast(manualIdvPath, pathname, '/') ||
      splitAndCompareLast(editBusinessPath, pathname, '/') ||
      isLoading
    )
  }, [
    applicantId,
    applicantIdParamKey,
    applicantPaths,
    generatePathValue,
    isLoading,
    pathname,
  ])

  return (
    <>
      <div className="flex items-center mx-6 my-3 gap-2 whitespace-nowrap text-tertiary-grey-800">
        <FrankieButton
          noStyles
          singleIcon={{
            name: 'mdiChevronLeft',
          }}
          onClick={() => handleBack()}
        />
        <div className="flex-grow">
          <TextView
            dummyText="Company PVT Limited"
            showSkeleton={isLoading}
            className="text-xl font-bold"
          >
            {name}
          </TextView>

          <div className="flex gap-2 text-xs my-1">
            <FrankieIcon
              name={
                isProfileOrganisationType
                  ? 'mdiOfficeBuildingOutline'
                  : 'mdiAccountOutline'
              }
              className="text-neutral-60"
              size="xs"
            />
            <TextView
              showSkeleton={isLoading}
              dummyText="organisation"
              className="border-e border-tertiary-grey-300 pe-2 font-semibold"
            >
              {isProfileOrganisationType
                ? t('applicant.profileType.organisation')
                : t('applicant.profileType.individual')}
            </TextView>
            <div className="text-secondary-900 font-medium opacity-60">
              {t('applicant.customerId')}
            </div>
            {customerId && (
              <div className="flex items-center gap-1">
                <span className="font-semibold">{customerId}</span>
                <FrankieTooltip
                  position="top"
                  hidden={!showTextCopied}
                  title={t('applicant.copied')}
                >
                  <FrankieButton
                    noStyles
                    singleIcon={{
                      name: 'mdiContentCopy',
                      size: '2xs',
                      className: 'text-neutral-60 inline',
                    }}
                    onClick={() => {
                      void navigator.clipboard.writeText(customerId)
                      setShowTextCopied(true)
                      setTimeout(() => setShowTextCopied(false), 3000)
                    }}
                  />
                </FrankieTooltip>
              </div>
            )}
          </div>
        </div>
        {applicantId && (
          <div className="flex gap-1 items-center flex-grow-0">
            {!isManualBlocklist && (
              <>
                <div className="text-neutral-70 text-xs">
                  {t('applicant.riskLevel')}:
                </div>
                <ApplicantIssueActionBadge
                  showSkeleton={isLoading}
                  type={riskLevel.type}
                  masterLabel={riskLevel.label}
                  className="min-w-[50px] !px-4"
                />
              </>
            )}
            <div className="ms-2 text-neutral-70 text-xs">
              {t('applicant.status')}:
            </div>

            {isManualBlocklist ? (
              <div className="flex items-center gap-2 px-3 py-1 me-2 text-mono-white bg-tertiary-grey-800 rounded-sm">
                <FrankieIcon name="mdiCancel" size="xs" />
                <div className="font-bold">{t('applicant.blocklisted')}</div>
              </div>
            ) : (
              <ApplicantIssueActionBadge
                type={profileStatusType}
                className="min-w-[150px] me-2"
                showSkeleton={isLoading || !profileStatusType}
              />
            )}
            <SelectApplicantAssignee
              applicantId={applicantId}
              testId={{ input: routingVueMigratedQa.applicants.assignToInput }}
            />
            <AddApplicantComment
              applicantId={applicantId}
              location="Applicant Details"
              className="mx-2"
              testId={{
                toggleBtn: routingVueMigratedQa.applicants.toggleComment,
              }}
            />
          </div>
        )}
      </div>

      {!hideTabs && <NavTabs options={navOptions} className="bg-neutral-20" />}
      <ApplicantReportGeneratedNotification />
      <Outlet context={{ paths: applicantPaths, applicantIdParamKey }} />
    </>
  )
}

export function LayoutApplicantProfile({ applicantIdParamKey, paths }: Props) {
  const { isFrankie2 } = useHasFeatureFlag({ isFrankie2: 'frankie2customer' })
  const { isLoading } = useSessionQuery()
  if (isLoading) return null
  if (isFrankie2) {
    return (
      <LayoutApplicantProfileF2
        applicantIdParamKey={applicantIdParamKey}
        paths={paths}
      />
    )
  }
  return (
    <LayoutApplicantProfileF1
      applicantIdParamKey={applicantIdParamKey}
      paths={paths}
    />
  )
}
