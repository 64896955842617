import { useMutation } from '@tanstack/react-query'

import { amlResultApi } from 'entities/entity/api/aml.api'
import { AmlStatusUpdateProps } from 'entities/entity/model/aml.model'
import { useEntityAmlData } from 'entities/entity/state/entity-aml/entity-aml.state'

import { TrackingEventsTypes, trackingManager } from 'shared/tracking'

type Args = {
  entityId: string
}

export const useUpdateAmlStatus = ({ entityId }: Args) => {
  const { refetch } = useEntityAmlData({ entityId })
  return useMutation({
    mutationKey: ['updateAmlStatus'],
    mutationFn: async (data: AmlStatusUpdateProps) => {
      const res = await amlResultApi.updateAmlStatus(entityId, data)
      return res.data
    },
    onSuccess: data => {
      void refetch()
      // updateAmlAlert(data.processResults)
      trackingManager.track(TrackingEventsTypes.AmlConfirmResolveIndividual)
    },
  })
}
