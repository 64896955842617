import React, { useMemo } from 'react'

import { ApplicantId, useApplicantState } from 'entities/applicant'

import { ToggleSwitch } from 'shared/form'
import { useI18n } from 'shared/i18n'

import { IKYB_AML_SCREENING_KEY } from '../../ikyb-aml-screening.key'
import { iKybAmlScreeningEn } from '../../locale/ikyb-aml-screening.en'
import { getAmlScreeningStatusMapping } from '../../model/ikyb-aml-screening.model'
import { iKybAmlQa } from '../../qa/ikyb-aml-screening.qa'
import { useIKybAmlScreeningQuery } from '../../state/ikyb-aml-screening-query/ikyb-aml-screening.query'
import { useApplicantToggleOngoingAmlState } from '../../state/ikyb-toggle-ongoing-aml/ikyb-toggle-ongoing-aml.state'
import { IKybAmlScreeningDataGrid } from '../ikyb-aml-screening-data-grid/ikyb-aml-screening-data-grid'
import { IKybAmlInfoHeader } from '../ikyb-aml-screening-info-listing/ikyb-aml-screening-info-listing'
import { IKybAmlMatchStatusChange } from '../ikyb-aml-screening-match-status-change/ikyb-aml-screening-match-status-change'

type Props = {
  applicantId: ApplicantId
}

export function IKybAmlScreening({ applicantId }: Props) {
  const t = useI18n(IKYB_AML_SCREENING_KEY, {
    keys: iKybAmlScreeningEn,
  })

  const { inactiveProfile } = useApplicantState({ applicantId })

  const { amlStatus, showOngoingAmlToggle, toggleAmlStatus } =
    useApplicantToggleOngoingAmlState({
      applicantId,
    })

  const { data } = useIKybAmlScreeningQuery({ applicantId })

  const { needsAmlStatusAction, groupIssuesIds } = useMemo(() => {
    const matches = data?.matches ?? []

    const needsAmlStatusAction =
      matches.some(({ matchStatus }) => {
        const statusMap = getAmlScreeningStatusMapping(matchStatus.status)
        return statusMap.needsAction
      }) &&
      !matches.some(({ matchStatus }) => {
        const statusMap = getAmlScreeningStatusMapping(matchStatus.status)
        return statusMap.type === 'non-final' && !statusMap.needsAction
      })

    const groupIssuesIds = matches
      .filter(
        ({ matchStatus }) =>
          getAmlScreeningStatusMapping(matchStatus.status).term ===
          'true_positive',
      )
      .map(({ groupId }) => groupId)

    return {
      needsAmlStatusAction: needsAmlStatusAction && !inactiveProfile,
      groupIssuesIds,
    }
  }, [data?.matches, inactiveProfile])

  return (
    <div className="flex flex-col gap-8 px-6">
      {needsAmlStatusAction && (
        <div className="flex gap-3 bg-tertiary-yellow-50 text-tertiary-yellow-700 py-3 px-6 items-center rounded-sm">
          <span className="flex-grow text-md font-semibold">
            {t('hasTruePositiveMatch')}
          </span>
          <IKybAmlMatchStatusChange
            applicantId={applicantId}
            groupIds={groupIssuesIds}
            approve
          />
          <IKybAmlMatchStatusChange
            applicantId={applicantId}
            groupIds={groupIssuesIds}
            reject
          />
        </div>
      )}

      <div>
        <div
          className="flex items-center justify-between"
          data-qa={iKybAmlQa.toggleSwitchContainer}
        >
          <div className="flex-grow text-xl font-bold">
            {t('searchResults')}
          </div>
          {showOngoingAmlToggle && (
            <div className="flex-grow-0 flex gap-3 items-center">
              <span className="text-tertiary-grey-500">{t('ongoingAML')}</span>

              <ToggleSwitch
                className="scale-75"
                variant="basic"
                onChange={toggleAmlStatus}
                value={amlStatus}
              />
            </div>
          )}
        </div>

        <div className="text-md font-semibold tertiary-grey-700 py-4">
          {t('amlSearch')}
        </div>

        <IKybAmlInfoHeader
          applicantId={applicantId}
          testId={{
            container: iKybAmlQa.infoHeader,
          }}
        />
      </div>

      <div>
        <div className="text-md font-semibold tertiary-grey-700 pb-4">
          {t('amlMatch')}
        </div>

        <IKybAmlScreeningDataGrid
          applicantId={applicantId}
          testId={{
            container: iKybAmlQa.dataGrid,
          }}
        />
      </div>
    </div>
  )
}
