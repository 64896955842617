import { useMemo } from 'react'

import { useEntityDataQuery, useEntityLabelDataState } from 'entities/entity'
import { useSessionQuery } from 'entities/session'

import { flowIdOptions } from '../model/individual-send-verification-link.model'
import { useIndividualSendLinkContentQuery } from '../query/individual-send-link-content/individual-send-link-content.query'

type Args = {
  entityId: string
}

export const useSendLinkState = ({ entityId }: Args) => {
  const { data } = useEntityDataQuery(entityId)
  const { personalInfoWithLabelData } = useEntityLabelDataState({ entityId })
  const { data: smsData } = useIndividualSendLinkContentQuery()
  const name = personalInfoWithLabelData.at(0)?.value

  const { data: sessionData } = useSessionQuery()
  const phoneNumber = data?.individual?.phoneNumbers?.[0]
  const number = phoneNumber?.number
  const country = phoneNumber?.country || 'AUS'

  const entityData = useMemo(
    () => ({
      name,
      number,
      country,
      flowIdOptions: flowIdOptions.filter(i =>
        smsData?.flowIds.includes(i.value),
      ),
      smsContent: smsData?.smsContent,
      kycSmsContent: sessionData?.features.sendKycLink?.kycSMSContent,
    }),

    [country, name, number, smsData?.flowIds, sessionData, smsData?.smsContent],
  )

  if (!data || !smsData) return undefined

  return entityData
}
