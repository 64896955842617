import React from 'react'

import { FrankieButton } from 'frankify/src'

import { INDIVIDUAL_PROFILE_INSIGHT_KEY } from 'features/individual-profile-insight/individual-profile-insight.key'
import { individualProfileInsightEn } from 'features/individual-profile-insight/locale/individual-profile-insight.en'
import { gallery } from 'features/individual-profile-insight/model/mock-data.model'

import { useI18n } from 'shared/i18n'
import { useOverlay } from 'shared/overlay'

import { IndividualOcrOverlay } from './individual-ocr-overlay/individual-ocr-overlay'

type Props = {
  entityId: string
}

export function IndividualProfileSelfie({ entityId }: Props) {
  const t = useI18n([INDIVIDUAL_PROFILE_INSIGHT_KEY], {
    keys: individualProfileInsightEn,
  })
  const [createOverlay] = useOverlay()

  const handleExpand = (activeIndex: number) => () => {
    createOverlay(
      <IndividualOcrOverlay
        entityId={entityId}
        defaultIndex={activeIndex}
        gallery={gallery}
      />,
      { className: '!p-6', closeButtonClassName: '!top-6 !right-6' },
    )
  }

  const handleDownload = (activeIndex: number) => async () => {
    const blob = await fetch(gallery[activeIndex]).then(r => r.blob())
    const imageUrl = URL.createObjectURL(blob)
    const link = document.createElement('a')
    const isUrlNameCorrect =
      (gallery[activeIndex].split('.').at(-1)?.length ?? 0) <= 3
    link.href = imageUrl
    link.download = isUrlNameCorrect ? gallery[activeIndex] : 'download.jpg'
    link.target = '_blank'
    link.click()
  }

  return (
    <div className="flex flex-col  gap-y-3">
      <div className="font-bold text-tertiary-grey-800 text-xl justify-between flex items-center gap-1">
        {t('tab.selfies')}
        <div className="text-xs font-normal text-tertiary-grey-500">
          {t('modal.recentUploadedOn')}
        </div>
      </div>
      <div className="w-full pb-4 flex gap-3 scrollbar overflow-x-auto ">
        {gallery.map((media, index) => {
          const isVideo = ['MP4'].includes(
            media.split('.').at(-1)?.toUpperCase() || '',
          )
          return (
            <div className="min-w-[240px] flex gap-2 flex-col bg-tertiary-grey-200 rounded-sm p-2">
              <div className="">
                {!isVideo ? (
                  <img
                    src={media}
                    alt="selfie"
                    className="w-[225px] aspect-square mx-auto"
                  />
                ) : (
                  <video
                    loop
                    controls
                    key={media}
                    className="w-[225px] aspect-square mx-auto"
                  >
                    <track kind="captions" />
                    <source src={media} type="video/mp4" />
                  </video>
                )}
              </div>
              <div className="flex justify-between">
                <FrankieButton
                  className="p-1.5"
                  noStyles
                  onClick={handleExpand(index)}
                  singleIcon={{ name: 'mdiArrowExpand', size: 'sm' }}
                />
                <FrankieButton
                  className="p-1.5"
                  noStyles
                  onClick={handleDownload(index)}
                  singleIcon={{ name: 'mdiDownload', size: 'sm' }}
                />
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
