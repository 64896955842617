import { ProcessResultObject } from 'entities/entity'

type Args = {
  entityId: string
  processResultId?: string
}
export const useAmlData = ({ entityId }: Args) => {
  // const { data } = useGetWorkflowEventsData({ entityId })
  // const workflow = data?.workflowSummaries?.at(0)?.workflowResultData

  // const amlResolveData = workflow?.workflowStepResults
  //   ?.find(step => step.stepName === 'AML')
  //   ?.processResults?.find(
  //     process => process.processResultId === processResultId,
  //   )
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const amlData = dummyData
  return { amlData }
}

const dummyData = {
  class: 'AML',
  createdAt: '2024-06-06T08:06:46.261666Z',
  entityId: 'a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
  notes: {
    'aml.address.1': {
      type: 'general.string',
      value: 'Bentiu Unity SOUTH SUDAN',
    },
    'aml.address.2': {
      type: 'general.string',
      value: 'Juba Central Equatoria SOUTH SUDAN',
    },
    'aml.case_id': {
      type: 'result.code',
      value: 'a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
    },
    'aml.datasource.1': {
      type: 'general.string',
      value: 'PEP - National Government',
    },
    'aml.is_monitored_case': {
      type: 'general.bool',
      value: 'false',
    },
    'aml.search_countries': {
      type: 'general.string',
      value: 'AUS',
    },
    'aml.search_countries.matched': {
      type: 'general.string',
      value: 'SSD',
    },
    'aml.search_date_of_birth': {
      type: 'general.datetime',
      value: '1990-05-15',
    },
    'aml.search_entity_aka': {
      type: 'general.string',
      value: 'جلال الدين جوك',
    },
    'aml.search_entity_associate.affiliated_company': {
      type: 'general.string',
      value: 'NATIONAL CONGRESS PARTY',
    },
    'aml.search_entity_id': {
      type: 'result.code',
      value: '5jb8fie788ex1iqhc97xkfmpm',
    },
    'aml.search_match_details': {
      type: 'general.string',
      value: 'MEDIUM',
    },
    'aml.search_match_types': {
      type: 'general.string',
      value: 'name',
    },
    'aml.search_name': {
      type: 'general.string',
      value: 'Jo',
    },
    'aml.search_name.matched': {
      type: 'general.string',
      value: 'Jalal Eldin JOK',
    },
    'aml.search_reference_id': {
      type: 'result.code',
      value: '5jb82kqu3y5k1iqhc9219jj50',
    },
    'aml.search_result.score': {
      type: 'general.float',
      value: '84.10',
    },
    'bcro.grouping_id': {
      type: 'result.code',
      value:
        '5jb82kqu3y5k1iqhc9219jj50::5jb8fie788ex1iqhc97xkfmpm::a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
    },
    category: {
      type: 'general.string',
      value: 'POLITICAL INDIVIDUAL',
    },
    'reference.doc.0': {
      type: 'general.string',
      value: 'http://sslagoss.org/members_party.php?pid=8',
    },
    'reference.doc.1': {
      type: 'general.string',
      value:
        'http://www.gurtong.org/resourcecenter/gov/RegionalAssembly_list.asp',
    },
    'reference.doc.2': {
      type: 'general.string',
      value: 'http://www.state.gov/g/drl/rls/hrrpt/2005/61594.htm',
    },
    'reference.doc.3': {
      type: 'general.string',
      value:
        'https://paanluelwel.com/2011/07/11/2010-election-parliament-of-south-sudan/',
    },
  },
  objectType: 'INDIVIDUAL',
  processResultId: '01HZP9C1QNQWK74HP8GTP8VC0V',
  providerResult: {
    confidence: '50',
    name: 'Refinitiv',
    reference: '',
    source: 'World-Check Risk Intelligence',
    sourceNormalized: 'world-check risk intelligence',
  },
  result: 'HIT',
  schemaVersion: 2,
  state: 'COMPLETED',
  stepName: 'AML',
  stepType: 'pep',
  supplementaryData: {
    fuzziness: {},
    groupingId:
      '5jb82kqu3y5k1iqhc9219jj50::5jb8fie788ex1iqhc97xkfmpm::a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
    matchData: {
      name: 'Jalal Eldin JOK',
      spReferenceId: '',
      strength: 84.1,
    },
    mediaData: [],
    pepData: [
      {
        additionalData: [
          {
            key: 'aml.is_monitored_case',
            value: 'false',
          },
          {
            key: 'aml.case_id',
            value: 'a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
          },
          {
            key: 'aml.search_reference_id',
            value: '5jb82kqu3y5k1iqhc9219jj50',
          },
          {
            key: 'bcro.grouping_id',
            value:
              '5jb82kqu3y5k1iqhc9219jj50::5jb8fie788ex1iqhc97xkfmpm::a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
          },
          {
            key: 'aml.search_entity_id',
            value: '5jb8fie788ex1iqhc97xkfmpm',
          },
          {
            key: 'aml.search_result.score',
            value: '84.10',
          },
          {
            key: 'PepStatusUpdated',
            value: '2019-09-19',
          },
          {
            key: 'PepRolesUpdated',
            value: '2023-08-21',
          },
          {
            key: 'SOURCE.name',
            value: 'Member of Parliament for Unity State',
          },
          {
            key: 'SOURCE.aml_types',
            value: 'pep',
          },
          {
            key: 'Active Start Date',
            value: '2010',
          },
          {
            key: 'Active End Date',
            value: '2015',
          },
          {
            key: 'Title',
            value: 'Member of Parliament',
          },
          {
            key: 'Political Position',
            value: 'Member of Legislature',
          },
          {
            key: 'Status',
            value: 'FORMER',
          },
          {
            key: 'Level',
            value: 'PEP - National Government',
          },
        ],
        referenceDocs: [],
        sourceName: 'Member of Parliament for Unity State',
      },
      {
        additionalData: [
          {
            key: 'aml.is_monitored_case',
            value: 'false',
          },
          {
            key: 'aml.case_id',
            value: 'a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
          },
          {
            key: 'aml.search_reference_id',
            value: '5jb82kqu3y5k1iqhc9219jj50',
          },
          {
            key: 'bcro.grouping_id',
            value:
              '5jb82kqu3y5k1iqhc9219jj50::5jb8fie788ex1iqhc97xkfmpm::a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
          },
          {
            key: 'aml.search_entity_id',
            value: '5jb8fie788ex1iqhc97xkfmpm',
          },
          {
            key: 'aml.search_result.score',
            value: '84.10',
          },
          {
            key: 'PepStatusUpdated',
            value: '2019-09-19',
          },
          {
            key: 'PepRolesUpdated',
            value: '2023-08-21',
          },
          {
            key: 'SOURCE.name',
            value: 'Member of Parliament for Unity State',
          },
          {
            key: 'SOURCE.aml_types',
            value: 'pep',
          },
          {
            key: 'Active Start Date',
            value: '2005-09',
          },
          {
            key: 'Active End Date',
            value: '2010',
          },
          {
            key: 'Title',
            value: 'Member of Parliament',
          },
          {
            key: 'Political Position',
            value: 'Member of Legislature',
          },
          {
            key: 'Status',
            value: 'FORMER',
          },
          {
            key: 'Level',
            value: 'PEP - National Government',
          },
        ],
        referenceDocs: [],
        sourceName: 'Member of Parliament for Unity State',
      },
    ],
    sanctionData: [],
    searchId: '08fd4036-2fa2-443e-b7e9-11a574c0784c',
    serviceProvider: 'refinitiv',
    type: 'AML',
    watchlistData: [],
  },
  systemStatus: 'VALID',
  updatedAt: '2024-06-06T08:06:46.261667Z',
} as ProcessResultObject
