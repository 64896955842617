import { useMutation } from '@tanstack/react-query'

import { organisationApi } from 'entities/organisation'

import { applicantSupportingDocumentApi } from '../../api/applicant-supporting-document.api'
import { useApplicantSupportingDocumentQuery } from '../applicant-supporting-document-query/applicant-supporting-document.query'

type Args = {
  applicantId: string
  disableProfileDocCheckUpdate?: boolean
}

export const useSupportingDocumentDelete = ({
  applicantId,
  disableProfileDocCheckUpdate = false,
}: Args) => {
  const { refetch: refetchDocument } = useApplicantSupportingDocumentQuery({
    applicantId,
  })

  const handleSuccess = () => {
    void refetchDocument()

    /**
     * triggering profile check if document is deleted successfully
     */
    if (!disableProfileDocCheckUpdate) {
      void organisationApi.checkAmlPep(applicantId, {
        forceCheck: false,
        uploadDocs: true,
        noInvalidate: false,
      })
    }
  }

  const mutation = useMutation({
    mutationFn: (documentId: string) =>
      applicantSupportingDocumentApi.deleteApplicantSupportDocument(
        applicantId,
        documentId,
      ),
    onSuccess: handleSuccess,
  })

  return mutation
}
