import React, { useState } from 'react'

import { AxiosError } from 'axios'

import { FrankieButton } from 'frankify/src'

import { MfaTypes } from 'entities/mfa'
import { useSessionMutation, useSessionQuery } from 'entities/session'

import { ErrorCodeTypes } from 'shared/error'
import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'

import { MFA_KEY } from '../../mfa.key'
import { getIsMfaResendBlocked } from '../../model/mfa.model'
import { useEnableMfaMutation } from '../../mutation/enable-mfa.mutation'
import { useGenerateTokenMutation } from '../../mutation/generate-token.mutation'
import {
  SendTokenSuccessResponse,
  useSendTokenMutation,
} from '../../mutation/send-token.mutation'
import { mfaQa } from '../../qa/mfa.qa'
import { EnableMfaEmailCodeForm } from '../mfa-code-form'
import { MfaIsEnabled } from '../mfa-is-enabled/mfa-is-enabled'

enum CurrentScreenTypes {
  SendEmail = 'sendEmail',
  VerifyEmail = 'verifyEmail',
  MfaIsEnabled = 'mfaIsEnabled',
}

type Props = {
  onClose: () => void
  onBackToMfaTypeSelect: () => void
}

export function EnableMfaEmail({ onClose, onBackToMfaTypeSelect }: Props) {
  const t = useI18n(MFA_KEY)
  const [currentScreen, setCurrentScreen] = useState<CurrentScreenTypes>(
    CurrentScreenTypes.SendEmail,
  )

  const { mutateAsync: generateToken, isLoading: generatingToken } =
    useGenerateTokenMutation()
  const { mutateAsync: resendToken, isLoading: isResendingCode } =
    useSendTokenMutation()
  const { mutateAsync: updateSession } = useSessionMutation()
  const { mutateAsync: enableMfa, isLoading: isVerifying } =
    useEnableMfaMutation()
  const { data: session } = useSessionQuery()

  const isMfaResendBlocked = getIsMfaResendBlocked(session)

  const handleBackToMfaTypeSelect = () => {
    onBackToMfaTypeSelect()
  }

  const lockMfa = async () =>
    updateSession({ sessionData: { mfaBlockTS: Date.now() } })

  const handleResendCode = async (): Promise<SendTokenSuccessResponse> => {
    try {
      const { data } = await resendToken({
        email: session?.user.email || '',
      })
      if (data.attempts_count >= data.attempts_max) {
        await lockMfa()
      }
      notification.success(t('form.notification.resendCodeSuccess'))
      return data
    } catch (error) {
      if ((error as AxiosError).response?.status === ErrorCodeTypes.E403) {
        await lockMfa()
      }
      throw error
    }
  }

  const handleSendEmail = async () => {
    await generateToken(MfaTypes.Email)
    setCurrentScreen(CurrentScreenTypes.VerifyEmail)
  }

  const handleBackToSendEmail = () => {
    setCurrentScreen(CurrentScreenTypes.SendEmail)
  }

  const handleVerifyCode = async ({ code }: { code: string }) => {
    await enableMfa({ token: code, mfaType: MfaTypes.Email })
    setCurrentScreen(CurrentScreenTypes.MfaIsEnabled)
  }

  const handleClose = () => {
    onClose()
  }

  if (currentScreen === CurrentScreenTypes.SendEmail) {
    return (
      <div className="min-h-[213px] flex flex-initial flex-col justify-between">
        <div>
          <div
            className="text-2xl font-bold mb-4 leading-8 text-tertiary-grey-800"
            data-qa={mfaQa.mfaEnableEmail_sendEmail_header}
          >
            {t('enableMfaEmail.sendEmail.heading')}
          </div>
          <div
            className="text-sm text-tertiary-grey-700"
            data-qa={mfaQa.mfaEnableEmail_sendEmail_subheader}
          >
            {t('enableMfaEmail.sendEmail.subheading', {
              email: session?.user.email,
            })}
          </div>
        </div>
        <div className="mt-6 flex flex-initial justify-end items-center gap-4">
          <FrankieButton
            intent="subtle"
            size="sm"
            onClick={handleBackToMfaTypeSelect}
            testId={{ button: mfaQa.mfaEnableEmail_sendEmail_ctaCancel }}
          >
            {t('enableMfaEmail.sendEmail.cta.back')}
          </FrankieButton>
          <FrankieButton
            size="sm"
            onClick={handleSendEmail}
            testId={{ button: mfaQa.mfaEnableEmail_sendEmail_ctaSubmit }}
            disabled={generatingToken}
          >
            {t('enableMfaEmail.sendEmail.cta.next')}
          </FrankieButton>
        </div>
      </div>
    )
  }

  if (currentScreen === CurrentScreenTypes.VerifyEmail) {
    return (
      <EnableMfaEmailCodeForm
        onResendCode={handleResendCode}
        isMfaResendBlocked={isMfaResendBlocked}
        onBackToSendEmail={handleBackToSendEmail}
        onSubmit={handleVerifyCode}
        isVerifying={isVerifying}
        isResendingCode={isResendingCode}
      />
    )
  }

  return (
    <MfaIsEnabled
      className="min-h-[213px] flex flex-initial flex-col justify-between"
      onClose={handleClose}
    />
  )
}
