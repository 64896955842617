export const externalLinks = {
  support: 'https://www.frankieone.com/contact-us',
  smartUiDemo: 'https://widget.latest.frankiefinancial.io/',
  documentation: 'https://apidocs.frankiefinancial.com/docs',
  documentationV2:
    'https://apidocs.frankiefinancial.com/v2.0.0/docs/welcome-to-frankie',
  mfaDocs:
    'https://apidocs.frankiefinancial.com/docs/multi-factor-authentication',
  globalSearchDocs:
    'https://apidocs.frankiefinancial.com/docs/portal-global-search',
  systemMonitoring: process.env.VUE_APP_MONITORING_PAGE_URL || '',
  updateLegacyProfile:
    'https://apidocs.frankiefinancial.com/docs/updating-legacy-profile-to-recipe',
  whatIsRecipe: 'https://apidocs.frankiefinancial.com/docs/what-is-a-recipe',
  supportingDocs:
    'https://apidocs.frankiefinancial.com/docs/working-with-support-docs',
} satisfies Record<string, string>
