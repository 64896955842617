import React, { useState } from 'react'

import { useMutation } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'

import {
  FrankieButton,
  FrankieIcon,
  FrankieIconName,
  FrankieLoader,
  FrankiePopover,
} from 'frankify/src'

import { applicantApi } from 'entities/applicant/api/applicant.api'

import { TextAreaFormField } from 'shared/form'
import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'
import { SuggestionString } from 'shared/typescript'

import { APPLICANT_KEY, applicantEn } from '../../locale/applicant.en'

type Props = {
  className?: string
  applicantId: string
  iconName?: FrankieIconName
  location: SuggestionString<'Applicant Details'>
  testId?: { toggleBtn?: string }
}

type FormData = { comment: string }

export function AddApplicantComment({
  applicantId,
  location,
  className = '',
  iconName = 'mdiCommentTextOutline',
  testId,
}: Props) {
  const t = useI18n([APPLICANT_KEY], { keys: applicantEn })
  const [open, setOpen] = useState(false)

  const { mutate, isLoading, isSuccess, reset } = useMutation({
    mutationFn: ({ comment }: { comment: string }) =>
      applicantApi.addComments(applicantId, { comment, location }),
    onSuccess: () => {
      setTimeout(() => setOpen(false), 1500)
    },
    onError: () => {
      notification.error(t('error.addingComment'))
    },
  })

  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset: resetForm,
  } = useForm<FormData>()

  const onSubmit = (data: FormData) => {
    const { comment } = data
    mutate({ comment })
    resetForm({ comment: '' })
  }

  const isSendDisabled = !isValid || isLoading

  return (
    <FrankiePopover
      onOpenChange={setOpen}
      open={open}
      popoverRest={{ placement: 'bottom-end' }}
      trigger={
        <FrankieButton
          className={className}
          noStyles
          singleIcon={{
            name: iconName,
            className: 'text-tertiary-grey-700',
          }}
          onClick={() => {
            reset()
            setOpen(prev => !prev)
          }}
          testId={{ button: testId?.toggleBtn }}
        />
      }
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="bg-mono-white shadow-md rounded p-3"
      >
        <TextAreaFormField
          className="w-[250px]"
          fieldClassName="h-[150px] focus-visible:!border-tertiary-grey-300"
          control={control}
          name="comment"
          label={t('addComment')}
          rules={{ required: true }}
        />

        {(isSuccess || isLoading) && (
          <div className="absolute inset-x-[15px] top-[40px] bottom-[65px] flex flex-col justify-center items-center bg-mono-white bg-opacity-70">
            {isLoading ? (
              <FrankieLoader label={t('sending')} loading />
            ) : (
              <>
                <FrankieIcon
                  name="mdiCheckCircle"
                  className="bg-mono-white text-tertiary-green-400"
                  size="lg"
                />
                <div>{t('sent')}</div>
              </>
            )}
          </div>
        )}
        <FrankieButton
          className={`!outline-mono-50 ms-auto me-0 mt-3 ${
            isSendDisabled ? '' : '!bg-mono-100'
          }`}
          startIcon={{ name: 'mdiSend', size: 'xs', className: 'pr-2' }}
          type="submit"
          size="xs"
          disabled={isSendDisabled}
        >
          {t('send')}
        </FrankieButton>
      </form>
    </FrankiePopover>
  )
}
