import { DocumentType } from 'entities/entity'

import { I18nKeys } from 'shared/i18n'

import { documentF2En } from '../locale/document-f2.en'

export const documentIdTypesOption: Array<{
  tKey: I18nKeys<typeof documentF2En>
  value: DocumentType
}> = [
  {
    tKey: 'documentTypeName.driversLicense',
    value: DocumentType.DRIVERS_LICENSE,
  },
  {
    tKey: 'documentTypeName.passport',
    value: DocumentType.PASSPORT,
  },
  {
    tKey: 'documentTypeName.medicare',
    value: DocumentType.NATIONAL_HEALTH_ID,
  },
]
