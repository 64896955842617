export const SUPRSEND_KEY = 'suprsend'

export const suprsendEn = {
  message: {
    userAssigned: '<0>{{applicantName}}</0> was assigned to you',
    entityStatusUpdated:
      '<0>{{applicantName}}</0> has a new status: <strong>{{status}}</strong>',
    assignedEntityStatusUpdated:
      '<0>{{applicantName}}</0> assigned to you has a new status: <strong>{{status}}</strong>',
    entityRiskUpdated:
      '<0>{{applicantName}}</0> has an updated risk score: <strong>from {{riskFrom}} to {{riskTo}}</strong>',
    assignedEntityRiskUpdated:
      '<0>{{applicantName}}</0> assigned to you has an updated risk score: <strong>from {{riskFrom}} to {{riskTo}}</strong>',
    trustAnalysisComplete:
      'Trust deed analysis for <0>{{documentName}}</0> is complete and ready for review',
  },
  format: {
    ago: '{{date}} ago',
  },
}
