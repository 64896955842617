export const matchesTypeEn = {
  blocklist: {
    confirm: 'Confirmed Blocklist Match',
    match: 'Possible blocklist match, check the details and confirm',
    incoming:
      'Check the incoming applicants details against the blocklist profile',
    personalInfoTitle: 'Blocklisted Info',
  },
  duplicates: {
    confirm: 'Confirmed Duplicates Match',
    match: 'Possible duplicate match, check the details and confirm',
    incoming:
      'Check the incoming applicants details against the duplicates profile',
    personalInfoTitle: 'Duplicate Info',
  },
  watchlist: {
    confirm: 'Confirmed Potential Match',
    match: 'Possible potential match, check the details and confirm',
    incoming:
      'Check the incoming applicants details against the blocklisted profile',
    personalInfoTitle: 'Blocklisted Info',
  },
}

export const applicantPotentialMatchesEn = {
  title: 'this is title',
  incomingApplicant: 'INCOMING APPLICANT',
  blocklistedEntireProfile: 'BLOCKLISTED ENTIRE PROFILE',
  duplicateProfile: 'DUPLICATE PROFILE',
  potentialMatches: 'Existing Profile',
  modal: {
    title: 'Add to Watchlist',
    heading: 'Would you like to add this profile to the watchlist?',
    description:
      'A new risk factor will be added to the entity and further checks may need to be completed in order for the applicant to progress.',
    cancel: 'Do not add to watchlist',
    add: 'Yes, add to watchlist',
  },
  checkIncoming:
    'Check the incoming applicants details against the blocklist profile',
  personalInfo: {
    title: 'Personal Info',
    customerId: 'Customer ID',
    frankieId: 'Frankie ID',
    name: 'Name',
    dob: 'Date of Birth',
    currentAddress: 'Current Address',
    previousAddress: 'Previous Address',
    mailingAddress: 'Mailing Address',
    phoneNumber: 'Phone Number',
    emailAddress: 'Email Address',
    matchStrength: 'Match Strength',
  },
  documentInfo: {
    title: 'Document Info',
    idProvided: "ID's provided",
    documentNumber: 'Document Number',
    view: 'View',
  },
  scanError: 'Scan not available',
  isMatch: 'Is this a match?',
  comment: 'Comment',
  commentPlaceholder: 'This is a new comment',
  cancel: 'Cancel',
  saveMatchStatus: 'Save match status',
  confirmBlocklistMatch: 'Confirmed Blocklist Match',
  falsePositiveMatch: 'False Positive Match',
  existingProfile: 'Existing Profile',
  addedToBlocklist: 'Added to Blocklist by',
  dateAddedToBlocklist: 'Date Added to Blocklist',
  blocklisted: 'BLOCKLISTED',
  entireProfile: 'Entire Profile',
  idDocument: 'ID Document',
  nameDob: 'Name and Date Of Birth',
  noPotentialMatches: 'No potential matches to compare',
  noBlocklistMatches: 'No blocklist matches to compare',
  noDuplicateMatches: 'No duplicate matches to compare',
  noDocs: 'No documents listed',
  match: {
    true: 'True positive',
    false: 'False positive',
    skip: 'Skip',
  },
  resolvedSuccessfully:
    '{{type}} was resolved successfully and checks were run again',
  resolvedSuccessfullyError:
    '{{type}} were resolved successfully, but there was an issue triggering checks again. Please contact Frankie Financial support',
  ...matchesTypeEn,
}
