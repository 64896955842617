import { useMemo } from 'react'

import {
  AddressStatusEnum,
  AddressTypeEnum,
  IDocsToBeDisplayedType,
  useGetWorkflowEventsData,
  ILabelAndSources,
  WorkflowStepResultEnum,
  extractLongForm,
  useEntityDataQuery,
  useEntityLabelDataState,
  WorkflowStatusKeysTypes,
  getWorkflowStatusKey,
  ServiceProfileState,
  ProcessResultManualStatusEnumAML,
  ProcessResultManualStatusEnumKYC,
} from 'entities/entity'

import { useI18n } from 'shared/i18n'

import { workflowEventsEn } from '../locale/workflow-events.en'
import {
  formatDate,
  getSourceMatch,
} from '../model/applicant-workflow-events.model'
import {
  getIconConfigAml,
  IconConfig,
} from '../ui/entity-verification-helper/entity-verification-helper'
import { WORKFLOW_EVENTS_KEY } from '../workflow-events.key'

export type Args = {
  entityId: string
}

export const useGetWorkFlowDataWithSources = ({ entityId }: Args) => {
  const { data, isLoading } = useGetWorkflowEventsData({ entityId })
  const { data: entity, isFetching } = useEntityDataQuery(entityId, 'base64')
  const { documentWithLabelData } = useEntityLabelDataState({ entityId })
  const t = useI18n(WORKFLOW_EVENTS_KEY, { keys: workflowEventsEn })

  if (!data || !entity) {
    return {
      data: undefined,
      isLoading: isLoading || isFetching,
    }
  }

  const workflowEventResult = data.workflowSummaries?.at(0)

  const isArchived =
    entity.serviceProfiles?.at(0)?.state === ServiceProfileState.ARCHIVED

  const kycStep =
    workflowEventResult?.workflowResultData?.workflowStepResults?.find(
      i => i.stepName === 'KYC',
    )

  const isManuallyVerified =
    kycStep?.result === WorkflowStepResultEnum.MATCH &&
    kycStep.processResults?.every(
      i => i.manualStatus === ProcessResultManualStatusEnumKYC.PASS,
    )

  const isNotUnchecked =
    workflowEventResult?.status &&
    getWorkflowStatusKey(workflowEventResult) !==
      WorkflowStatusKeysTypes.UNCHECKED

  function fullName() {
    const { displayName, givenName, middleName, familyName } =
      entity?.individual?.name ?? {}

    return (
      displayName ??
      `${givenName ?? ''} ${middleName ?? ''} ${familyName ?? ''}`.trim()
    )
  }

  const profileInfo: ILabelAndSources[] = [
    {
      label: t('personalInfo.name'),
      value: fullName(),
      sources: getSourceMatch({
        objectType: 'NAME',
        workflowSummaries: data.workflowSummaries,
      }),
    },
    {
      label: t('personalInfo.dob'),
      value:
        entity.individual?.dateOfBirth?.normalized &&
        formatDate({
          dateString: entity.individual.dateOfBirth.normalized,
        }),
      sources: getSourceMatch({
        objectType: 'DATE_OF_BIRTH',
        workflowSummaries: data.workflowSummaries,
      }),
    },
    {
      label: t('personalInfo.address'),
      value: extractLongForm(
        entity.individual?.addresses?.find(
          address =>
            address.type === AddressTypeEnum.RESIDENTIAL &&
            address.status === AddressStatusEnum.CURRENT,
        ),
      ),
      sources: getSourceMatch({
        objectType: 'ADDRESS',
        workflowSummaries: data.workflowSummaries,
      }),
    },
  ]

  const docsToBeDisplayed: IDocsToBeDisplayedType[] =
    documentWithLabelData?.map(doc => ({
      ...doc,
      label: [
        {
          ...doc.label[0],
          sources: getSourceMatch({
            objectType: 'DOCUMENT',
            idType: doc.type,
            workflowSummaries: data.workflowSummaries,
          }),
        },
        ...doc.label.slice(1),
      ],
    })) || []

  return {
    docsToBeDisplayed,
    isLoading,
    profileInfo,
    isNotUnchecked,
    isManuallyVerified,
    isArchived,
  }
}

export const useOrderWithFailedAndPassed = ({ entityId }: Args) => {
  const { data } = useGetWorkflowEventsData({ entityId })

  const t = useI18n(WORKFLOW_EVENTS_KEY, { keys: workflowEventsEn })

  const workflow = data?.workflowSummaries?.at(0)

  const order = workflow?.steps?.order

  const failed = workflow?.steps?.failed

  const passed = workflow?.steps?.passed

  const filterEvents = useMemo(
    () =>
      order?.filter(
        event =>
          event !== 'START' &&
          event !== 'FINISH' &&
          event !== 'RISK' &&
          event !== 'DECISION',
      ) ?? [],
    [order],
  ) as ('KYC' | 'AML')[]

  const eventStepper = useMemo(
    () =>
      filterEvents.map(eventName => {
        if (eventName === 'AML') {
          return {
            config: getIconConfigAml(
              workflow?.workflowResultData?.workflowStepResults
                ?.find(step => step.stepName === 'AML')
                ?.processResults?.map(
                  result => result.result === 'HIT' && result.manualStatus,
                )
                .filter(Boolean) as
                | ProcessResultManualStatusEnumAML[]
                | undefined,
            ),
            text: t(`entityStatusText.${eventName}`),
            eventName,
          }
        }
        if (failed?.find(item => item === eventName)) {
          return {
            config: IconConfig({ type: WorkflowStepResultEnum.FAIL }),
            text: t(`entityStatusText.${eventName}`),
            eventName,
          }
        }
        if (passed?.find(item => item === eventName)) {
          return {
            config: IconConfig({ type: WorkflowStepResultEnum.PASS }),
            text: t(`entityStatusText.${eventName}`),
            eventName,
          }
        }
        return {
          config: IconConfig({ type: WorkflowStepResultEnum.UNCHECKED }),
          text: t(`entityStatusText.${eventName}`),
          eventName,
        }
      }),
    [t, failed, passed, filterEvents, workflow],
  )

  return {
    order,
    failed,
    passed,
    filterEvents,
    eventStepper,
  }
}
