import { useMutation } from '@tanstack/react-query'

import { useApplicantDataQuery } from 'entities/applicant'

import { individualProfileApi } from '../api/individual-profile.api'
import { IndividualProfilePayloadTypes } from '../model/individual-profile-payload.model'

type MutateArgs = {
  saveOnly?: boolean
  profile: IndividualProfilePayloadTypes
}

type Args = {
  applicantId: string
}

export const useUpdateIndividualProfile = ({ applicantId }: Args) => {
  const { remove } = useApplicantDataQuery({ applicantId })

  return useMutation({
    mutationFn: ({ profile, saveOnly = false }: MutateArgs) =>
      individualProfileApi.updateIndividualProfile(
        profile,
        applicantId,
        saveOnly,
      ),
    onSuccess: () => {
      remove()
    },
  })
}
