import React, { useEffect, useMemo } from 'react'

import { FrankieTextField } from 'frankify/src'

import { AustralianStateCodeTypes, stateList } from 'entities/country'
import { KycDocumentCategoryTypes } from 'entities/document'
import { useSessionQuery } from 'entities/session'

import { SelectFormField, getError } from 'shared/form'
import { useI18n } from 'shared/i18n'

import { INDIVIDUAL_PROFILE_KEY } from '../../../../individual-profile.keys'
import { individualProfileEn } from '../../../../locale/new-profile.en'
import { IndividualProfileInputTypes } from '../../../../model/form.model'
import { individualProfileVueMigratedQa } from '../../../../qa/individual-profile.qa'
import { DocumentCategory } from '../document-category/document-category'
import { DocumentsSubFormProps } from '../document-sub-forms.types'

export function DriverLicenseForm({
  form,
  idx,
  wrapperClasses,
}: DocumentsSubFormProps) {
  const {
    register,
    watch,
    control,
    setValue,
    formState: { errors },
    unregister,
  } = form
  const t = useI18n([INDIVIDUAL_PROFILE_KEY], { keys: individualProfileEn })

  const { data } = useSessionQuery()

  const documentNumberFieldName = useMemo(
    () =>
      `${IndividualProfileInputTypes.Document}.${idx}.${IndividualProfileInputTypes.DocumentNumber}` as IndividualProfileInputTypes.Document,

    [idx],
  )

  const selectedCountry: string = watch(
    `${IndividualProfileInputTypes.Document}.${idx}.${IndividualProfileInputTypes.Country}`,
  )

  const statesOptions = useMemo(
    () =>
      Object.hasOwn(stateList, selectedCountry)
        ? stateList[selectedCountry]
        : [],
    [selectedCountry],
  )

  useEffect(() => {
    setValue(
      `${IndividualProfileInputTypes.Document}.${idx}.${IndividualProfileInputTypes.DocumentCategory}`,
      KycDocumentCategoryTypes.PRIMARY_PHOTOGRAPHIC as never,
    )
  }, [idx, setValue])

  const idNumberFieldName = useMemo(
    () =>
      `${IndividualProfileInputTypes.Document}.${idx}.${IndividualProfileInputTypes.IdNumber}` as IndividualProfileInputTypes.Document,
    [idx],
  )

  const licenceNumberFieldName = useMemo(
    () =>
      `${IndividualProfileInputTypes.Document}.${idx}.${IndividualProfileInputTypes.LicenceVersion}` as IndividualProfileInputTypes.Document,
    [idx],
  )

  const homeCountryFieldName = useMemo(
    () =>
      `${IndividualProfileInputTypes.Document}.${idx}.${IndividualProfileInputTypes.HomeCountryFullName}` as IndividualProfileInputTypes.Document,
    [idx],
  )

  // unregister id number because used in multiple components
  useEffect(
    () => () => {
      unregister(idNumberFieldName)
    },
    [idx, unregister, idNumberFieldName],
  )

  const watchState = watch(
    `${IndividualProfileInputTypes.Document}.${idx}.${IndividualProfileInputTypes.State}`,
  )
  const { qldDlCardNumberRequired, vicDlCardNumberRequired } =
    data?.features || {
      vicDlCardNumberRequired: false,
      qldDlCardNumberRequired: false,
    }

  let documentNumberRequired: boolean | undefined = true
  if (watchState === AustralianStateCodeTypes.QLD) {
    documentNumberRequired = qldDlCardNumberRequired
  } else if (watchState === AustralianStateCodeTypes.VIC) {
    documentNumberRequired = vicDlCardNumberRequired
  }

  const showDocumentNumber = selectedCountry === 'AUS' && documentNumberRequired
  return (
    <div className={wrapperClasses}>
      {selectedCountry === 'AUS' && (
        <SelectFormField
          options={statesOptions}
          className="basis-[32%]"
          label={t('documentForm.state')}
          placeholder={t('documentForm.statePlaceholder')}
          control={control}
          rules={{
            required: true,
          }}
          error={
            !!getError(
              `${IndividualProfileInputTypes.Document}.${idx}.${IndividualProfileInputTypes.State}`,
              errors,
            )
          }
          name={`${IndividualProfileInputTypes.Document}.${idx}.${IndividualProfileInputTypes.State}`}
          testId={{ input: individualProfileVueMigratedQa.document.region }}
        />
      )}

      <FrankieTextField
        className="basis-[32%]"
        label={t('documentForm.licenseNumber')}
        placeholder={t('documentForm.licenseNumberPlaceholder')}
        testId={{
          input: individualProfileVueMigratedQa.document.idNumberField,
        }}
        error={!!getError(idNumberFieldName, errors)}
        {...register(idNumberFieldName, {
          required: true,
        })}
      />

      {selectedCountry === 'CHN' && (
        <FrankieTextField
          className="basis-[32%]"
          label={t('documentForm.homeCountryFullName')}
          placeholder={t('documentForm.homeCountryFullName')}
          testId={{
            input: individualProfileVueMigratedQa.document.homeCountryFullName,
          }}
          error={!!getError(homeCountryFieldName, errors)}
          {...register(homeCountryFieldName, {
            required: true,
            shouldUnregister: true,
          })}
        />
      )}

      {selectedCountry === 'NZL' && (
        <FrankieTextField
          className="basis-[32%]"
          label={t('documentForm.licenceVersion')}
          placeholder={t('documentForm.licenceVersion')}
          testId={{
            input: individualProfileVueMigratedQa.document.licenceVersion,
          }}
          error={!!getError(licenceNumberFieldName, errors)}
          {...register(licenceNumberFieldName, {
            required: true,
            shouldUnregister: true,
          })}
        />
      )}

      <div className="basis-[32%]" />
      {showDocumentNumber && (
        <FrankieTextField
          className="basis-[32%]"
          label={t('documentForm.documentNumber')}
          placeholder={t('documentForm.documentNumberPlaceholder')}
          error={!!getError(documentNumberFieldName, errors)}
          testId={{
            input: individualProfileVueMigratedQa.document.documentNumber,
          }}
          {...register(documentNumberFieldName, {
            required: true,
            shouldUnregister: true,
          })}
        />
      )}

      <DocumentCategory
        disabled
        className="basis-[32%]"
        form={form}
        idx={idx}
      />
    </div>
  )
}
