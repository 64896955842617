const params = {
  roleId: 'roleId',
  applicantId: 'applicantId',
  viewId: 'viewId',
}
export const newPaths = {
  dashboardNew: '/new/dashboard',
  systemMonitoring: '/new/dashboard/system-monitoring',
  blocklist: '/new/blocklist',
  individualProfile: '/new/new-profile/individual',
  businessProfile: '/new/new-profile/business', // not in vue code so not to be used in navigation (original path is to be used)
  applicant: '/new/entities', // not to be used in navigation, used to highlight entity tab
  applicantProfile: `/new/entities/profile/:${params.applicantId}`,
  applicantProfileGeneralInformation: `/new/entities/profile/:${params.applicantId}/general-information`,
  applicantProfileSupportingDocuments: `/new/entities/profile/:${params.applicantId}/supporting-documents`,
  applicantProfileDuplicateMatches: `/new/entities/profile/:${params.applicantId}/duplicate-matches`,
  applicantProfileAuditReport: `/new/entities/profile/:${params.applicantId}/audit-report`,
  applicantProfileBlocklistMatches: `/new/entities/profile/:${params.applicantId}/blocklist-matches`,
  applicantProfilePersonalInfo: `/new/entities/profile/:${params.applicantId}/personal-info-kyc`,
  applicantProfileRiskIndicators: `/new/entities/profile/:${params.applicantId}/risk-indicators`,
  applicantProfilePotentialMatches: `/new/entities/profile/:${params.applicantId}/potential-matches`,
  applicantProfileTransactions: `/new/entities/profile/:${params.applicantId}/transactions`,
  applicantProfileCharacteristics: `/new/entities/profile/:${params.applicantId}/characteristics`,
  applicantProfileOwnersOfficeholders: `/new/entities/profile/:${params.applicantId}/business-ownership`,
  applicantNewBlocklist: '/new/blocklist/new',
  applicantProfileBlocklistedInfo: `/new/entities/profile/:${params.applicantId}/blocklisted-info`,
  applicantProfileBiometricsOcr: `/new/entities/profile/:${params.applicantId}/biometry-ocr`,
  applicantProfileFraudCheck: `/new/entities/profile/:${params.applicantId}/fraud-check`,
  applicantProfilePepSanctions: `/new/entities/profile/:${params.applicantId}/pep-sanctions`,
  applicantManualIdv: `/new/entities/profile/:${params.applicantId}/manual-idv`,
  applicantEditBusiness: `/new/entities/profile/:${params.applicantId}/edit-business`,
  applicantProfileWorkflowEvent: `/new/entities/profile/:${params.applicantId}/workflow-event`,
  individualProfileInsight: `/new/entities/profile/:${params.applicantId}/profile-insight`,
  customView: `/custom-views/:${params.viewId}`,
}

export const f2Paths = {
  individualAmlHistory: `/f2/individual/:${params.applicantId}/aml-history`,
  editEntity: `/f2/individual/:${params.applicantId}/edit`,
  resolveEntity: `/f2/individual/:${params.applicantId}/resolve`,
  resolveAmlIssue: `/f2/individual/:${params.applicantId}/resolve-aml-issue`,
  amlScreening: `/f2/individual/:${params.applicantId}/aml-screening`,
}
export const paths = {
  root: '/',
  login: '/login',
  reset_password: '/reset-password', // page after login
  reset_password_sent: '/reset-password/:email', // password link sent
  new_password: '/new-password',
  settings_notifications: '/admin/notifications',
  settings_personal: '/admin/personal',
  settings_organisation: '/admin/settings',
  settings_role_list: '/admin/roles',
  settings_role_new: '/admin/roles/new',
  settings_role_edit: `/admin/roles/:${params.roleId}/edit`,
  settings_role_view: `/admin/roles/:${params.roleId}/view`,
  dashboard: '/dashboard',
  blocklist: '/blocklist',
  new_blocklist: '/blocklist/new',
  settings_user_list: '/admin/users',
  new_profile: '/new-profile',
  new_profile_individual: '/new-profile/individual',
  new_profile_business: '/new-profile/business',
  applicants: '/entities',
  applicantsTest: '/entities-test',
  needs_review: '/needs-review',
  needs_review_general_information: `/needs-review/profile/:${params.applicantId}/general-information`,
  search_results: '/search-results',
  // TODO: @ggrigorev NAV - remove this when we have a dashboard v2
  onboarding: '/onboarding',
  // end TODO
  monitoring_id: '/monitoring/profile/:entityId',
  onboarding_id: '/onboarding/profile/:entityId',
  monitoring_id_general_information: `/monitoring/profile/:${params.applicantId}/general-information`,
  onboarding_id_general_information: `/onboarding/profile/:${params.applicantId}/general-information`,
  applicant_profile: `/entities/profile/:${params.applicantId}`,
  applicant_profile_general_information: `/entities/profile/:${params.applicantId}/general-information`,

  applicantProfileSupportingDocuments: `/entities/profile/:${params.applicantId}/supporting-documents`,
  applicantProfileDuplicateMatches: `/entities/profile/:${params.applicantId}/duplicate-matches`,
  applicantProfileAuditReport: `/entities/profile/:${params.applicantId}/audit-report`,
  applicantProfileBlocklistMatches: `/entities/profile/:${params.applicantId}/blocklist-matches`,
  applicantProfileRiskIndicators: `/entities/profile/:${params.applicantId}/risk-indicators`,
  applicantProfilePotentialMatches: `/entities/profile/:${params.applicantId}/potential-matches`,

  // Rest of the applicant profile that will be converted to react
  applicantProfilePersonalInfo: `/entities/profile/:${params.applicantId}/personal-info-kyc`,
  applicantProfileBlocklistedInfo: `/entities/profile/:${params.applicantId}/blocklisted-info`,
  applicantProfilePepSanctions: `/entities/profile/:${params.applicantId}/pep-sanctions`,
  applicantProfileOwnersOfficeholders: `/entities/profile/:${params.applicantId}/business-ownership`,
  applicantProfileBiometricsOcr: `/entities/profile/:${params.applicantId}/biometry-ocr`,
  applicantProfileFraudCheck: `/entities/profile/:${params.applicantId}/fraud-check`,
  applicantProfileTransactions: `/entities/profile/:${params.applicantId}/transactions`,
  applicantProfileCharacteristics: `/entities/profile/:${params.applicantId}/characteristics`,
  applicantManualIdv: `/entities/profile/:${params.applicantId}/manual-idv`,
  applicantEditBusiness: `/entities/profile/:${params.applicantId}/edit-business`,
}
