import React from 'react'

import { FrankieIcon } from 'frankify/src'

import { amlDetailCardQa } from '../../../qa/individual-aml-result.qa'

type Args = {
  entityId: string
}
export function IndividualAmlEntityAdverseMediaCard({ entityId }: Args) {
  const review = [
    'by Elizabeth Harris',
    'Australia',
    'Published on 31 May 2019',
    'Last updated on 10 Jul 2024',
  ]

  return (
    <div data-qa={amlDetailCardQa.container} className="flex flex-col gap-4">
      <div className="flex flex-col gap-4 pb-4 border-b-[1px] border-b-tertiary-grey-200">
        <div>
          <div className="text-primary-500 font-semibold text-sm leading-5">
            <a
              data-qa={amlDetailCardQa.title}
              className="flex gap-1 items-center justify-between"
              href="https://www.google.com"
              target="_blank"
              rel="noreferrer"
            >
              Horrible Histories: Dreadful Deaf review at Bristol Old Vic
            </a>
          </div>

          <ul className="mt-1" data-qa={amlDetailCardQa.descInfo}>
            <div className="text-tertiary-grey-500 text-xs leading-3">
              {review.map((detail, i) => (
                <span key={detail}>
                  {detail}
                  {i < review.length - 1 && ' • '}
                </span>
              ))}
            </div>
          </ul>
        </div>
        <div
          data-qa={amlDetailCardQa.description}
          className="text-tertiary-grey-700 text-sm leading-5"
        >
          {`But the show also celebrates the remarkable stories of D/deaf people
          that most of the audience will likely never have come across. We meet
          James Burke, heavyweight boxing champion and First Last, the
          suffragist who was jailed for refusing to pay taxes without the right
          to vote. Important allies feature, too, such as the founder of
          Britain's first deaf school, Thomas Braidwood.`}
        </div>
      </div>
      <div className="flex flex-col gap-4 pb-4 border-b-[1px] border-b-tertiary-grey-200">
        <div>
          <div className="text-primary-500 font-semibold text-sm leading-5">
            <a
              data-qa={amlDetailCardQa.title}
              className="flex gap-1 items-center justify-between"
              href="https://www.google.com"
              target="_blank"
              rel="noreferrer"
            >
              Horrible Histories: Dreadful Deaf review at Bristol Old Vic
            </a>
          </div>

          <ul className="mt-1" data-qa={amlDetailCardQa.descInfo}>
            <div className="text-tertiary-grey-500 text-xs leading-3">
              {review.map((detail, i) => (
                <span key={detail}>
                  {detail}
                  {i < review.length - 1 && ' • '}
                </span>
              ))}
            </div>
          </ul>
        </div>
        <div
          data-qa={amlDetailCardQa.description}
          className="text-tertiary-grey-700 text-sm leading-5"
        >
          {`But the show also celebrates the remarkable stories of D/deaf people
          that most of the audience will likely never have come across. We meet
          James Burke, heavyweight boxing champion and First Last, the
          suffragist who was jailed for refusing to pay taxes without the right
          to vote. Important allies feature, too, such as the founder of
          Britain's first deaf school, Thomas Braidwood.`}
        </div>
      </div>
    </div>
  )
}

export function IndividualAmlEntityOtherDataSrcCard({ entityId }: Args) {
  return (
    <div
      data-qa={amlDetailCardQa.container}
      className="flex flex-col gap-4 grow-0"
    >
      <div className="flex flex-col gap-4 pb-4 border-b-[1px] border-b-tertiary-grey-200">
        <div>
          <div className="text-primary-500 font-semibold text-sm leading-5">
            <a
              data-qa={amlDetailCardQa.title}
              className="flex gap-1 items-center justify-between"
              href="https://www.google.com"
              target="_blank"
              rel="noreferrer"
            >
              Horrible Histories: Dreadful Deaf review at Bristol Old Vic
              <span>
                <FrankieIcon
                  name="mdiOpenInNew"
                  className="text-tertiary-grey-400"
                  size="xs"
                  testId={{ icon: amlDetailCardQa.icon }}
                />
              </span>
            </a>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-4 pb-4 border-b-[1px] border-b-tertiary-grey-200">
        <div>
          <div className="text-primary-500 font-semibold text-sm leading-5">
            <a
              data-qa={amlDetailCardQa.title}
              className="flex gap-1 items-center justify-between"
              href="https://www.google.com"
              target="_blank"
              rel="noreferrer"
            >
              Horrible Histories: Dreadful Deaf review at Bristol Old Vic
              <span>
                <FrankieIcon
                  name="mdiOpenInNew"
                  className="text-tertiary-grey-400"
                  size="xs"
                  testId={{ icon: amlDetailCardQa.icon }}
                />
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
