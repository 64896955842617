export const PROFILES_FILTER_KEY = 'profilesFilter'

export const profilesFilterEn = {
  form: {
    title: 'Filters',
    customTitle: 'Select filters to create a new custom view',
    headers: {
      entityType: 'Entity type',
      state: 'Entity status',
      issue: 'Issue',
      riskLevel: 'Risk level',
      createdDate: 'Created date',
      updatedDate: 'Last updated',
      workflowNames: 'Workflow',
      workflowStatuses: 'Event status',
      assignee: 'Assignee',
    },
    cta: {
      showLess: 'Show less',
      viewAll: 'View all',
      applyAll: 'Apply filters',
      clearAll: 'Clear all',
      apply: 'Apply',
      clear: 'Clear',
    },
    date: {
      custom: 'Custom',
      all: 'All',
      today: 'Today',
      yesterday: 'Yesterday',
      past7Days: 'Past 7 days',
      past30Days: 'Past 30 days',
      pastYear: 'Past year',
      from: 'From',
      to: 'To',
      startValidation: 'Start date can not be empty',
      endValidation: 'End date can not be empty',
      startBeforeEndValidation: 'Start date can not be after end date',
    },
    assignee: {
      placeholder: 'Search user',
    },
  },
  applicantTypes: {
    individual: 'Individual',
    organisation: 'Organisation',
  },
  profileStates: {
    init: 'Init',
    unchecked: 'Unchecked',
    auto: 'Auto',
    active: 'Active',
    archived: 'Archived',
    blocklisted: 'Blocklisted',
    inactive: 'Inactive',
    deleted: 'Deleted',
  },
  workflowStatuses: {
    passed: 'Passed',
    failed: 'Failed',
    needsAttention: 'Needs attention',
    manuallyPassed: 'Manually passed',
    manuallyFailed: 'Manually failed',
    unchecked: 'Unchecked',
  },
  profileIssueCategories: {
    verify: 'Verify',
    kyc: 'KYC',
    badData: 'Bad data',
    deceased: 'Deceased',
    expiry: 'Expiry',
    informational: 'Informational',
    aml: 'AML',
    fraud: 'Fraud',
    idv: 'IDV',
    device: 'Device',
    transaction: 'Transaction',
    watchlist: 'Watchlist',
    duplicate: 'Duplicate',
    blocklisted: 'Blocklisted',
    system: 'System',
  },
  applicantRisks: {
    low: 'Low',
    medium: 'Medium',
    high: 'High',
    unacceptable: 'Unacceptable',
    unknown: 'Unknown',
  },
  plusMore: '+ more',
}
