import React, { useEffect, useState } from 'react'

import classNames from 'classnames'
import { useInView } from 'react-intersection-observer'

import { FrankieIcon } from 'frankify/src'

import { IndividualProfileDocumentQa } from 'features/individual-profile-insight/qa/individual-profile-insight.qa'

import { getCountryNameByAlpha3Code } from 'entities/country'
import {
  DOCUMENT_F2_KEY,
  DocumentViewF2,
  documentF2En,
  documentIdTypesOption,
} from 'entities/document-f2'
import {
  DocumentType,
  ILabelAndSources,
  useEntityLabelDataState,
} from 'entities/entity'

import { useI18n } from 'shared/i18n'
import { TrackingEventsTypes, trackingManager } from 'shared/tracking'

import { INDIVIDUAL_PROFILE_INSIGHT_KEY } from '../../individual-profile-insight.key'
import { individualProfileInsightEn } from '../../locale/individual-profile-insight.en'

type Props = {
  entityId: string
  isInvalidated?: boolean
}

export function IndividualProfileDocument({ entityId, isInvalidated }: Props) {
  const t = useI18n(INDIVIDUAL_PROFILE_INSIGHT_KEY, {
    keys: individualProfileInsightEn,
  })
  const tDocument = useI18n(DOCUMENT_F2_KEY, {
    keys: documentF2En,
  })
  const { documentWithLabelData } = useEntityLabelDataState({ entityId })
  const [isEventEmitted, setIsEventEmitted] = useState(false)

  const { ref, inView } = useInView()

  useEffect(() => {
    if (!isEventEmitted && inView) {
      trackingManager.track(
        TrackingEventsTypes.ProfileInsightsIdentityDocumentsViewIndividual,
      )
      setIsEventEmitted(true)
    }
  }, [])

  return (
    <div
      className="flex flex-col gap-y-3"
      data-qa={IndividualProfileDocumentQa.container}
      ref={ref}
    >
      <div className="font-bold text-tertiary-grey-800 text-xl flex items-center gap-1">
        {isInvalidated && (
          <FrankieIcon
            size="md"
            name="mdiAlertCircle"
            className="text-tertiary-yellow-300"
            testId={{ icon: IndividualProfileDocumentQa.tabDocumentIcon }}
          />
        )}
        {t('tab.documents')}
      </div>
      <div className="flex flex-col gap-y-6">
        {documentWithLabelData?.map(document => {
          const documentName = documentIdTypesOption.find(
            ({ value }) => value === document.type,
          )?.tKey

          const commonLabelData: ILabelAndSources[] = [
            {
              label: t('label.idType'),
              value: documentName && tDocument(documentName),
            },
            {
              label: t('label.country'),
              value: getCountryNameByAlpha3Code(document.country),
            },
          ]
          if (document.type === DocumentType.PASSPORT) {
            commonLabelData.pop()
          }

          const hasAttachment = (document.attachments?.length ?? 0) > 0

          return (
            <div className="w-full flex gap-6 items-start flex-wrap">
              {hasAttachment && (
                <div className="min-w-[calc(33%-24px)]">
                  <div
                    className="w-[200px] desktop:w-[250px]"
                    data-qa={IndividualProfileDocumentQa.documentViewContainer}
                  >
                    <DocumentViewF2
                      document={document}
                      className="aspect-video"
                      extraData={[...document.extraData, ...document.label]}
                    />
                  </div>
                </div>
              )}
              <div
                className="flex basis-[60%] flex-wrap gap-y-3"
                data-qa={IndividualProfileDocumentQa.srcLabelContainer}
              >
                {[
                  ...commonLabelData,
                  // sort if placing is different from workflow page i.e medicare card
                  ...document.label.sort(
                    (a, b) => (a.sortPos || 0) - (b.sortPos || 0),
                  ),
                ].map(({ label, value }) => (
                  <div
                    className={classNames(
                      hasAttachment ? 'basis-1/2' : 'basis-1/3',
                      'min-w-[200px]',
                    )}
                  >
                    <div className="font-medium text-tertiary-grey-800">
                      {label}
                    </div>
                    <div
                      data-hj-suppress
                      className="font-normal text-tertiary-grey-700"
                    >
                      {value || '-'}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
