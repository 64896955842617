import React, { forwardRef } from 'react'

import { IFrankieTextareaProps } from './text-area.types'

export const FrankieTextarea = forwardRef(
  // eslint-disable-next-line complexity, prefer-arrow-callback
  function FrankieTextField(
    {
      className,
      value,
      onChange,
      placeholder,
      testId,
      disabled,
    }: IFrankieTextareaProps,
    ref: React.ForwardedRef<HTMLInputElement>,
  ): JSX.Element {
    return (
      <textarea
        placeholder={placeholder}
        className={className}
        onChange={onChange}
        value={value}
        data-qa={testId?.input}
        disabled={disabled}
      />
    )
  },
)
