import React, { forwardRef, useState } from 'react'

import { BannerStyle } from './banner.theme'
import { BannerProps } from './banner.types'
import { FrankieButton } from '../button'
import { FrankieIcon } from '../icon'

export const Banner = forwardRef<HTMLElement, BannerProps>(
  (
    {
      button,
      description,
      heading,
      theme,
      className,
      children,
      onClose,
      hideClose = false,
    }: BannerProps,
    ref,
  ) => {
    const [showBanner, setShowBanner] = useState(true)
    const handleOnClose = () => {
      setShowBanner(false)
      if (onClose) {
        onClose()
      }
    }

    if (!showBanner) {
      return null
    }
    return (
      <div
        className={BannerStyle({
          theme,
          className,
        })}
      >
        <div className="flex justify-between items-start">
          <div className="flex justify-start items-start">
            <FrankieIcon
              name="mdiInformation"
              size="md"
              className={BannerStyle({
                iconTheme: theme,
                // className,
              })}
            />
            <div className="text-wrap mx-2 font-semibold text-[16px]">
              {heading}
            </div>
          </div>
          {!hideClose && (
            <FrankieButton
              singleIcon={{
                name: 'mdiClose',
                size: 'md',
              }}
              noStyles
              onClick={handleOnClose}
              className="cursor-pointer text-tertiary-grey-400"
            />
          )}
        </div>
        <div className="">{children ?? description}</div>
        {button?.label && (
          <FrankieButton
            size="xs"
            {...button}
            className={BannerStyle({
              btnTheme: theme,
              className: button.className,
            })}
          >
            {button.label}
          </FrankieButton>
        )}
      </div>
    )
  },
)
