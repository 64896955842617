import { useEntityDataQuery } from 'entities/entity'

import { I18nFunction, useI18n } from 'shared/i18n'

import { useAmlData } from './individual-aml-result.state'
import { INDIVIDUAL_AML_RESULT_KEY } from '../individual-aml-result.key'
import { individualAmlResultEn } from '../locale/individual-aml-result.en'

type LabelAndSrc = {
  t: I18nFunction<typeof individualAmlResultEn>
  entityId: string
}

type SrcLabel = { label: string; value: string; isUrl?: boolean }

export const useLabelAndSrc = ({ t }: LabelAndSrc) => {
  const pepSrcLabel: SrcLabel[] = [
    {
      label: t('tabMatchCard.countryCodes'),
      value: 'AU',
    },
    {
      label: t('tabMatchCard.country'),
      value: 'Australia',
    },
    {
      label: t('tabMatchCard.level'),
      value: 'PEP - National Government',
    },
    {
      label: t('tabMatchCard.srcUrl'),
      value: 'https://www.pmc.gov.au/reference',
      isUrl: true,
    },
    {
      label: t('tabMatchCard.politicalPosition'),
      value: 'Member of Legislature',
    },
    {
      label: t('tabMatchCard.startData'),
      value: '15 Jan 2018',
    },
    {
      label: t('tabMatchCard.endDate'),
      value: '28 May 2022',
    },
    {
      label: t('tabMatchCard.imageUrl'),
      value: 'https://www.google.com',
      isUrl: true,
    },
    {
      label: t('tabMatchCard.referenceDocs'),
      value: 'https://www.pmc.gov.au/reference',
      isUrl: true,
    },
  ]

  const sanctionSrcLabel: SrcLabel[] = [
    {
      label: t('tabMatchCard.countryCodes'),
      value: 'AU, UK, US, IN',
    },
    {
      label: t('tabMatchCard.country'),
      value: 'Australia',
    },
    {
      label: t('tabMatchCard.srcUrl'),
      value: 'https://www.pmc.gov.au/reference',
      isUrl: true,
    },
    {
      label: t('tabMatchCard.srcReason'),
      value: 'Minor discrepancies in financial documentation',
    },
    {
      label: t('tabMatchCard.startData'),
      value: '15 Jan 2018',
    },
    {
      label: t('tabMatchCard.endDate'),
      value: '28 May 2022',
    },

    {
      label: t('tabMatchCard.imageUrl'),
      value: 'https://www.google.com',
      isUrl: true,
    },
    {
      label: t('tabMatchCard.referenceDocs'),
      value: 'https://www.pmc.gov.au/reference',
      isUrl: true,
    },
  ]

  const watchlistSrcLabel: SrcLabel[] = [
    {
      label: t('tabMatchCard.countryCodes'),
      value: 'AU, UK, US, IN',
    },
    {
      label: t('tabMatchCard.country'),
      value: 'Australia',
    },
    {
      label: t('tabMatchCard.srcUrl'),
      value: 'https://www.pmc.gov.au/reference',
      isUrl: true,
    },

    {
      label: t('tabMatchCard.startData'),
      value: '15 Jan 2018',
    },
    {
      label: t('tabMatchCard.endDate'),
      value: '28 May 2022',
    },

    {
      label: t('tabMatchCard.imageUrl'),
      value: 'https://www.google.com',
      isUrl: true,
    },
    {
      label: t('tabMatchCard.referenceDocs'),
      value: 'https://www.pmc.gov.au/reference',
      isUrl: true,
    },
  ]
  return { pepSrcLabel, sanctionSrcLabel, watchlistSrcLabel }
}

type SummaryLabelProps = {
  entityId: string
  processResultId?: string
}

export const useLabelAndSrcForSummary = ({
  entityId,
  processResultId,
}: SummaryLabelProps) => {
  const { amlData } = useAmlData({ entityId, processResultId })
  const { data: entity } = useEntityDataQuery(entityId, 'base64')
  const t = useI18n([INDIVIDUAL_AML_RESULT_KEY], {
    keys: individualAmlResultEn,
  })
  return [
    {
      label: t('individualAmlResolveSingleSummary.fullName'),
      value: entity?.individual?.name?.displayName,
    },
    {
      label: t('individualAmlResolveSingleSummary.yearOfBirth'),
      value: entity?.individual?.dateOfBirth?.normalized,
    },

    {
      label: t('individualAmlResolveSingleSummary.residentialAddress'),
      value: amlData.notes?.['aml.address.1']?.value,
    },
    {
      label: t('individualAmlResolveSingleSummary.previousAddresses'),
      value: '121 King St, Greenway ACT 2900, Australia',
    },
    {
      label: t('individualAmlResolveSingleSummary.countryOfResidence'),
      value: amlData.notes?.['aml.search_countries']?.value,
    },
    {
      label: t('individualAmlResolveSingleSummary.fuzziness'),
      value: '10%',
    },
    {
      label: t('individualAmlResolveSingleSummary.vendorId'),
      value: 'becbeceb-9c55-0371-a63c-985c4a79729f',
    },
  ]
}
