import React, { useCallback, useMemo } from 'react'

import { FrankieCheckbox } from 'frankify/src'

import {
  NotificationChannelCell,
  NotificationsConfigTableRowLayout,
} from './notifications-config-table-row-layout/notifications-config-table-row-layout'
import {
  INotificationSetting,
  INotificationSettingsDictionary,
} from '../../model/notifications-management.model'

type Props = {
  notificationTypeInfo: INotificationSettingsDictionary
  settings: INotificationSetting[]
  onChange: (setting: INotificationSetting, newChecked: boolean) => void
}

export function NotificationsConfigTableRow({
  settings,
  notificationTypeInfo,
  onChange,
}: Props) {
  const handleChange = useCallback(
    (setting: INotificationSetting, newChecked: boolean) => () => {
      onChange(setting, newChecked)
    },
    [onChange],
  )

  const channels = useMemo<NotificationChannelCell[]>(
    () =>
      settings.map<NotificationChannelCell>(setting => ({
        cell: (
          <div key={setting.channel} className="py-3 px-4">
            <FrankieCheckbox
              checked={setting.value}
              onChange={handleChange(setting, !setting.value)}
              disabled={!setting.canDisable}
            />
          </div>
        ),
        key: setting.channel,
      })),
    [settings, handleChange],
  )

  return (
    <div className="w-full text-sm leading-5 hover:bg-tertiary-grey-100">
      <NotificationsConfigTableRowLayout
        notificationTypeCell={
          <div className="px-4 py-3">
            <div className="text-sm font-semibold text-tertiary-grey-700">
              {notificationTypeInfo.name}
            </div>
            <div className="text-sm font-normal text-tertiary-grey-700">
              {notificationTypeInfo.description}
            </div>
          </div>
        }
        channelCells={channels}
      />
    </div>
  )
}
