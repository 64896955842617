import React, { useMemo, useState } from 'react'

import { SubmitHandler, useForm } from 'react-hook-form'
import { Trans } from 'react-i18next'

import { FrankieAlert, FrankieButton, FrankieTextField } from 'frankify/src'

import { useI18n } from 'shared/i18n'

import { MFA_KEY } from '../../../mfa.key'
import {
  getMfaCodeRegisterOptions,
  getResendCodeAttemptsAlert,
  IAlert,
  IMfaCodeInputs,
  MFA_CODE_LENGTH,
  MfaCodeInputTypes,
} from '../../../model/mfa-code-form.model'
import { SendTokenSuccessResponse } from '../../../mutation/send-token.mutation'
import { mfaQa } from '../../../qa/mfa.qa'

type Props = {
  onResendCode: () => Promise<SendTokenSuccessResponse>
  onBackToSendEmail: () => void
  onSubmit: ({ code }: { code: string }) => Promise<void>
  isVerifying: boolean
  isResendingCode: boolean
  isMfaResendBlocked: boolean
}

export function EnableMfaEmailCodeForm({
  onResendCode,
  onBackToSendEmail,
  onSubmit,
  isVerifying,
  isResendingCode,
  isMfaResendBlocked,
}: Props) {
  const t = useI18n([MFA_KEY])
  const [alert, setAlert] = useState<IAlert>(null)

  const {
    register,
    handleSubmit,
    formState: { isDirty, isValid, errors },
  } = useForm<IMfaCodeInputs>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  })

  const isFormDisabled = !isDirty || !isValid

  const codeRegister = useMemo(
    () => register(MfaCodeInputTypes.Code, getMfaCodeRegisterOptions(t)),
    [register, t],
  )

  const handleBackToSendEmail = () => {
    onBackToSendEmail()
  }

  const handleResendCode = async () => {
    try {
      const { attempts_count, attempts_max } = await onResendCode()
      const attemptsLeft = attempts_max - attempts_count

      setAlert(getResendCodeAttemptsAlert(attemptsLeft, t))
    } catch (error) {
      setAlert(getResendCodeAttemptsAlert(0, t))
    }
  }

  const handleFormSubmit: SubmitHandler<IMfaCodeInputs> = async ({ code }) => {
    try {
      setAlert(null)
      await onSubmit({ code })
    } catch (error) {
      setAlert({ type: 'error', children: t('form.errors.403') })
    }
  }

  return (
    <form
      onSubmit={handleSubmit(handleFormSubmit)}
      className="min-h-[213px] flex flex-initial flex-col justify-between"
    >
      <div>
        <div
          data-qa={mfaQa.mfaEnableEmail_verifyCode_header}
          className="text-2xl leading-8 font-bold mb-4 text-tertiary-grey-800"
        >
          {t('enableMfaEmail.verifyEmail.heading')}
        </div>
        <FrankieTextField
          {...codeRegister}
          maxLength={MFA_CODE_LENGTH}
          label={t('enableMfaEmail.verifyEmail.codeLabel')}
          error={!!errors[MfaCodeInputTypes.Code]}
          errorText={errors[MfaCodeInputTypes.Code]?.message}
          testId={{ input: mfaQa.codeInput }}
        />
        <FrankieButton
          noStyles
          className="mt-2 text-primary-800 font-normal text-sm leading-4 disabled:text-tertiary-grey-300"
          onClick={handleResendCode}
          testId={{ button: mfaQa.resendCta }}
          disabled={isResendingCode || isMfaResendBlocked}
        >
          {t('enableMfaEmail.verifyEmail.cta.resend')}
        </FrankieButton>
        {alert ? (
          <FrankieAlert
            className="mt-4"
            type={alert.type}
            testId={{ alert: mfaQa.alert }}
          >
            <Trans className="text-tertiary-grey-700">{alert.children}</Trans>
          </FrankieAlert>
        ) : null}
      </div>
      <div className="mt-6 flex flex-initial justify-end items-center gap-4">
        <FrankieButton
          intent="subtle"
          size="sm"
          onClick={handleBackToSendEmail}
          testId={{ button: mfaQa.mfaEnableEmail_verifyCode_ctaCancel }}
        >
          {t('enableMfaEmail.verifyEmail.cta.back')}
        </FrankieButton>
        <FrankieButton
          disabled={isFormDisabled || isVerifying}
          size="sm"
          type="submit"
          testId={{ button: mfaQa.mfaEnableEmail_verifyCode_ctaSubmit }}
        >
          {t('enableMfaEmail.verifyEmail.cta.verify')}
        </FrankieButton>
      </div>
    </form>
  )
}
