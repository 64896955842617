import React, { useMemo } from 'react'

import {
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
} from '@mui/x-data-grid-pro'
import { UseQueryResult } from '@tanstack/react-query'

import { FrankieButton } from 'frankify/src'

import { useMultiDocumentScansQuery } from 'features/applicant-supporting-documents'

import {
  ApplicantResponse,
  GenerateIndividualVerificationReport,
} from 'entities/applicant'
import { Scan } from 'entities/document'
import { useHasFeatureFlag } from 'entities/session'

import { DateFormatTypes, formatDate } from 'shared/date-time'
import { convertDataURItoBlob, makeDataURL } from 'shared/file'
import { WithProps } from 'shared/hoc'
import { useI18n } from 'shared/i18n'
import { useOverlay } from 'shared/overlay'

import { APPLICANT_PERSONAL_INFO_KEYS } from '../../applicant-persona-info.keys'
import { applicantPersonalInfoEn } from '../../locale/applicant-personal-info.en'
import { Reports } from '../../model/individual-verification-reports.model'
import { individualVerificationQa } from '../../qa/applicant-personal-info.qa'

type Props = {
  applicantData: ApplicantResponse
  isLegacyProfile: boolean
}

type ReportCellProps = GridRenderCellParams<Reports> & {
  scans: UseQueryResult<Scan[], unknown>[]
}

function NoRowsOverlay() {
  const t = useI18n(APPLICANT_PERSONAL_INFO_KEYS, {
    keys: applicantPersonalInfoEn,
  })

  return (
    <div className="flex justify-center items-center text-tertiary-grey-500 font-medium">
      {t('individualVerificationReport.noRowsOverlayText')}
    </div>
  )
}

function RenderActions({ row, scans }: ReportCellProps) {
  const t = useI18n(APPLICANT_PERSONAL_INFO_KEYS, {
    keys: applicantPersonalInfoEn,
  })

  const handleView = (scanData: Scan | null, download = false) => {
    if (scanData && scanData.file) {
      const base64 = makeDataURL(scanData.mimeType, scanData.file)
      const blob = convertDataURItoBlob(base64)
      const temp_url = window.URL.createObjectURL(blob)

      if (download) {
        const link = document.createElement('a')
        link.href = temp_url
        link.download = scanData.scanName
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        URL.revokeObjectURL(temp_url)
        return
      }

      window.open(temp_url, '_blank')
    }
  }

  const getScanData = useMemo(
    () =>
      scans.find(scan => scan.data && scan.data[0]?.scanId === row.scanId)
        ?.data?.[0] || null,
    [row.scanId, scans],
  )

  return (
    <div className="flex justify-start items-center font-bold text-tertiary-grey-700">
      <FrankieButton
        className=" mx-2 "
        noStyles
        onClick={() => handleView(getScanData)}
      >
        {t('individualVerificationReport.view')}
      </FrankieButton>
      <FrankieButton
        className="mx-2"
        noStyles
        onClick={() => handleView(getScanData, true)}
      >
        {t('individualVerificationReport.download')}
      </FrankieButton>
    </div>
  )
}

function RenderCellDateGenerated({ row, scans }: ReportCellProps) {
  const getCreationData = useMemo(() => {
    const createdAt =
      scans.find(scan => scan.data && scan.data[0]?.scanId === row.scanId)
        ?.data?.[0]?.scanCreated || null

    if (createdAt) {
      return formatDate(createdAt, DateFormatTypes.FullMonthDate)
    }

    return createdAt
  }, [row.scanId, scans])

  return <div>{getCreationData}</div>
}

export function ApplicantIndividualVerificationReport({
  applicantData,
  isLegacyProfile,
}: Props) {
  const { isAllowGenerateKYCReport } = useHasFeatureFlag({
    isAllowGenerateKYCReport: 'KYCIndividualReports',
  })

  const t = useI18n(APPLICANT_PERSONAL_INFO_KEYS, {
    keys: applicantPersonalInfoEn,
  })

  const [createOverlay, closeOverlay] = useOverlay()

  const handleGenerateReport = () => {
    createOverlay(
      <GenerateIndividualVerificationReport
        applicantId={applicantData.applicantDetails.entityId}
        handleClose={closeOverlay}
      />,
      {
        className: 'p-0',
      },
    )
  }

  const reports: Reports[] | [] = useMemo(() => {
    if (isLegacyProfile) return []
    const { documents } = applicantData

    if (!documents.length) return []

    return documents
      .filter(d => d.idType === 'REPORT' && d.idSubType === 'kyc-report')
      .map(document => ({
        name: t('individualVerificationReport.individualEntityReport'),
        documentId: document.documentId,
        scanId: document.scans[0]?.scanId || '',
      }))
  }, [applicantData, isLegacyProfile, t])

  const scans = useMultiDocumentScansQuery([
    ...reports.map(doc => doc.documentId),
  ])

  const isLoading = scans.some(query => query.isLoading)

  const TABLE_MIN_WIDTH = 600

  const columns: GridColDef<Reports>[] = useMemo(
    () => [
      {
        field: 'name',
        headerName: t('individualVerificationReport.name'),
        maxWidth: 300,
        flex: 300 / TABLE_MIN_WIDTH,
      },
      {
        field: 'dateGenerated',
        headerName: t('individualVerificationReport.dateGenerated'),
        minWidth: 300,
        flex: 300 / TABLE_MIN_WIDTH,
        renderCell: WithProps(RenderCellDateGenerated, { scans }),
      },
      {
        field: 'actions',
        headerName: t('individualVerificationReport.actions'),
        maxWidth: 200,
        flex: 200 / TABLE_MIN_WIDTH,
        renderCell: WithProps(RenderActions, { scans }),
      },
    ],
    [scans, t],
  )

  if (!isAllowGenerateKYCReport) return null

  return (
    <div className=" my-4 pb-8 px-10 border-[1px] border-tertiary-grey-200 border-solid rounded-t-md">
      <div className="flex justify-between items-center">
        <div className="text-xl font-[700] mt-8 mb-6">
          {t('individualVerificationReport.title')}
        </div>
        {!isLegacyProfile && (
          <div>
            <FrankieButton
              testId={{ button: individualVerificationQa.button }}
              size="sm"
              className="!bg-mono-100 focus:border-0 focus:bg-mono-100 !text-mono-white hover:bg-mono-100 "
              intent="secondary"
              onClick={handleGenerateReport}
            >
              {t('individualVerificationReport.generateReport')}
            </FrankieButton>
          </div>
        )}
      </div>
      <div>
        <div className="wi-full border-[1px] border-tertiary-grey-200 border-solid rounded border-b-0 p-3 font-bold flex justify-end items-center h-[55px] rounded-t-sm">
          {`${isLegacyProfile ? 0 : scans.length} ${
            scans.length > 0
              ? t('individualVerificationReport.reports')
              : t('individualVerificationReport.report')
          }`}
        </div>

        <DataGridPro
          rows={reports}
          getRowId={row => row.documentId}
          slots={{
            noRowsOverlay: NoRowsOverlay,
          }}
          loading={isLoading}
          columnHeaderHeight={40}
          columns={columns.map(item => ({
            hideSortIcons: true,
            resizable: false,
            disableColumnMenu: true,
            disableReorder: true,
            headerClassName:
              'text-xs !p-0 text-xs bg-tertiary-grey-50 font-semibold text-tertiary-grey-500 !outline-none',
            ...item,
          }))}
          className="!rounded-t-[0px] !rounded-b-sm"
          data-qa={individualVerificationQa.table}
          isCellEditable={() => false}
          disableMultipleRowSelection
          disableColumnSelector
          disableColumnFilter
          disableColumnMenu
          disableDensitySelector
          showCellVerticalBorder={false}
          showColumnVerticalBorder={false}
          hideFooterPagination
          hideFooter
          hideFooterSelectedRowCount
          getCellClassName={() => '!outline-none !border-neutral-30'}
          getRowClassName={() => ' hover:!bg-transparent'}
          sx={{
            '& .MuiDataGrid-columnSeparator': {
              display: 'none',
            },
            '& .MuiDataGrid-virtualScroller': {
              minHeight: '61px',
            },
            '& .MuiDataGrid-columnHeaderTitleContainerContent': {
              height: '100%',
            },
            '& .MuiDataGrid-columnHeaders': {
              lineHeight: 'unset !important',
              borderRadius: '0 !important',
            },
            '& .MuiDataGrid-row--lastVisible': {
              '& .MuiDataGrid-cell': { border: '0px !important' },
            },

            '& .MuiDataGrid-columnHeaderTitleContainer': {
              padding: '0 14px',
            },
            '& .MuiDataGrid-columnHeaderTitle': {
              fontWeight: 'inherit',
              whiteSpace: 'pre-line',
            },
            '& .MuiDataGrid-row': {
              lineHeight: 'unset !important',
            },
            '& .MuiDataGrid-cell': {
              maxHeight: 'unset !important',
              p: '20px 14px',
            },
            '& .MuiDataGrid-cellContent': {
              whiteSpace: 'normal',
              color: 'inherit',
            },
            '& .MuiDataGrid-overlayWrapperInner': {
              p: '20px',
            },
          }}
        />
      </div>
    </div>
  )
}
