import { bffClient, IClient } from 'shared/client'

import { CustomViewPayload, CustomViewData } from '../model/custom-views.model'

export type CustomViewDataSingle = {
  customViews: {
    id: number
    query: string
    name: string
  }
}

export class CustomViewApi {
  client: IClient

  constructor(client: IClient) {
    this.client = client
  }

  async createCustomView(data: CustomViewPayload) {
    return this.client.post<CustomViewDataSingle, CustomViewPayload>(
      '/data/v1/custom-views',
      data,
    )
  }

  async getCustomViews() {
    return this.client.get<CustomViewData>('/data/v1/custom-views')
  }

  async updateCustomView(viewId: string, data: CustomViewPayload) {
    return this.client.put(`/data/v1/custom-views/${viewId}`, data)
  }

  async deleteView(viewId: string) {
    return this.client.delete(`/data/v1/custom-views/${viewId}`)
  }
}

export const customViewApi = new CustomViewApi(bffClient)
