import React, { useMemo } from 'react'

import { ApplicantResponse } from 'entities/applicant'

import { useI18n } from 'shared/i18n'
import { Nullable } from 'shared/typescript'

import { APPLICANT_PERSONAL_INFO_KEYS } from '../../applicant-persona-info.keys'
import { applicantPersonalInfoEn } from '../../locale/applicant-personal-info.en'
import { DetailViewTypes } from '../../model/applicant-detail-view.model'
import { RulesetMatchResultTypes } from '../../model/applicant-matched-rulesets.model'
import { applicantMatchedRuleSetQa } from '../../qa/applicant-personal-info.qa'
import { ApplicantDetailView } from '../applicant-detail-view/applicant-detail-view'
import { ApplicantRulesetsTable } from '../applicant-matched-rulesets-table-view/applicant-matched-rulesets-table'

type Props = {
  applicantData: ApplicantResponse
  isLegacyProfile: boolean
  testId?: { container?: string }
}

type Rulesets = {
  checkCount: number
  isVerified: Nullable<boolean>
  countSource?: Nullable<unknown[]>
  isManualKYC: boolean
} | null

export function ApplicantMatchedRulesets({
  applicantData,
  isLegacyProfile,
  testId,
}: Props) {
  const t = useI18n(APPLICANT_PERSONAL_INFO_KEYS, {
    keys: applicantPersonalInfoEn,
  })

  const rulesets: Rulesets = useMemo(() => {
    const rulesetsData = applicantData.checkSummary.rulesetsMatches.length
      ? applicantData.checkSummary.rulesetsMatches
      : [] // dummy

    const matchResult = (checkSourceCount: number) => {
      const uncheckedRulesets = rulesetsData.filter(
        row => row.result === RulesetMatchResultTypes.UNCHECKED,
      )
      if (uncheckedRulesets.length === rulesetsData.length) return null
      return checkSourceCount > 0
    }

    if (!rulesetsData.length) return null

    const checkSource = rulesetsData.filter(row => row.result === 'PASS')

    const checkSourceCount = checkSource.length

    const isVerified = matchResult(checkSourceCount)

    const isManualKYC = applicantData.checkSummary.kycMethod === 'manual'

    // mdiSourceBranch
    return {
      checkCount: checkSourceCount,
      countSource: checkSource,
      isVerified,
      isManualKYC,
    }
  }, [
    applicantData.checkSummary.kycMethod,
    applicantData.checkSummary.rulesetsMatches,
  ])

  if (!rulesets) return null

  return (
    <div data-qa={testId?.container} className="text-tertiary-grey-700">
      <div>
        <div className="text-xl font-[700] mt-8 mb-6">
          {t('rulesets.title')}
        </div>
        <ApplicantDetailView
          isLast
          label={t('rulesets.title')}
          value={
            isLegacyProfile
              ? t('rulesets.sectionNotSupported')
              : t('rulesets.matches')
          }
          iconName="mdiAccount"
          checkCount={rulesets.checkCount}
          isVerified={rulesets.isVerified}
          isManualKYCPass={null}
          isEntityPassed={null}
          isManualKYC={rulesets.isManualKYC}
          isLegacyProfile={isLegacyProfile}
          type={DetailViewTypes.RULESET_MATCHES}
          renderAdditionalInfo={
            <ApplicantRulesetsTable applicantData={applicantData} />
          }
          testId={{ container: applicantMatchedRuleSetQa.applicantDetailView }}
        />
      </div>
    </div>
  )
}
