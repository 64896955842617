import React, { useEffect, useMemo, useState } from 'react'

import { useForm } from 'react-hook-form'

import {
  FrankieButton,
  FrankieIcon,
  FrankieLoader,
  FrankieTextField,
} from 'frankify/src'

import { ApplicantId, useApplicantState } from 'entities/applicant'
import { countryPhoneCodeList } from 'entities/country'

import {
  ConfirmationFormField,
  SelectFormField,
  TextAreaFormField,
} from 'shared/form'
import { Show } from 'shared/hoc'
import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'
import { Noop } from 'shared/typescript'

import { APPLICANT_BIOMETRICS_OCR_KEY } from '../../applicant-biometrics-ocr.key'
import { applicantBiometricsOcrEn } from '../../locale/applicant-biometrics-ocr.en'
import { applicantSendBiometricLinkQa } from '../../qa/applicant-biometric-ocr.qa'
import { useApplicantIdvLinkMutation } from '../../state/applicant-idv-link/applicant-idv-link.mutation'

type ModalState = 'send-biometric-link' | 'copy-link' | 'text-message'

const LIVENESS_TEXT = {
  active: 'useLiveness=true',
  inactive: 'useLiveness=false',
}

const supportedCountry = ['AUS']
const australiaCountryCode = '61'

type FormData = {
  country: string
  mobileNumber: string
  comment: string
  isCopied: boolean
  useLiveness: boolean
}

type Props = {
  applicantId: ApplicantId
  onClose: Noop
  isRestart?: boolean
}

export function ApplicantSendBiometricLinkModal({
  applicantId,
  onClose,
  isRestart = false,
}: Props) {
  const t = useI18n([APPLICANT_BIOMETRICS_OCR_KEY], {
    keys: applicantBiometricsOcrEn,
  })

  const [state, setState] = useState<ModalState>('send-biometric-link')
  const [idvLinkUrl, setIdvLinkUrl] = useState<string>()

  const { watch, control, register, setValue, getValues } = useForm<FormData>({
    defaultValues: {
      country: australiaCountryCode,
    },
  })

  const { applicantName } = useApplicantState({ applicantId })

  const { mutate, isSuccess, isLoading, data, variables } =
    useApplicantIdvLinkMutation({
      applicantId,
    })

  const countryOption = useMemo(
    () =>
      countryPhoneCodeList
        .filter(item => supportedCountry.includes(item.code))
        .map(item => ({
          label: item.label,
          value: item.value,
        })),
    [],
  )

  /**
   * Modal heading
   */
  const heading = useMemo(() => {
    const record: Record<ModalState, string> = {
      'copy-link': t('process.copyLink'),
      'text-message': isRestart ? t('process.restart') : t('process.sendLink'),
      'send-biometric-link': t('process.start'),
    }
    return record[state]
  }, [isRestart, state, t])

  // Watch form values
  const watchIsCopied = watch('isCopied')
  const watchPhoneNumber = watch('mobileNumber')
  const watchLiveness = watch('useLiveness')

  useEffect(() => {
    setIdvLinkUrl(
      watchLiveness
        ? data?.idvLink.replace(LIVENESS_TEXT.inactive, LIVENESS_TEXT.active)
        : data?.idvLink.replace(LIVENESS_TEXT.active, LIVENESS_TEXT.inactive),
    )

    setValue('isCopied', false)
  }, [watchLiveness, data?.idvLink, setValue])

  useEffect(() => {
    if (isSuccess && variables?.payload.pushToSms) {
      onClose()
    }
  }, [isSuccess, onClose, variables?.payload.pushToSms])

  /**
   * Send link via text message
   */
  const sendLinkTextMessage = () => {
    const { mobileNumber, country, comment, useLiveness } = getValues()

    mutate({
      payload: {
        pushToSms: true,
        useLiveness,
        comment,
        smsPhoneNumber: `+${country}${mobileNumber}`,
      },
    })
  }
  const handleCopyLink = () => {
    if (!idvLinkUrl) {
      notification.error(t('error.noLinkCopy'))
    } else {
      navigator.clipboard
        .writeText(idvLinkUrl)
        .then(() => setValue('isCopied', true))
        .catch(() => notification.error(t('error.copy')))
    }
  }

  const handleStateChange = (newState: ModalState) => {
    if (newState === 'send-biometric-link') {
      setValue('isCopied', false)
    }

    setState(newState)
  }

  const containerClass = 'p-6 flex flex-col items-start'

  return (
    <div className="text-tertiary-grey-700 w-[510px]">
      <div className="py-4 px-6 flex gap-2 border-b border-tertiary-grey-300 text-md font-bold text-tertiary-grey-800">
        <FrankieIcon
          testId={{ icon: applicantSendBiometricLinkQa.icon }}
          className="text-tertiary-grey-300"
          name={isRestart ? 'mdiRestart' : 'mdiFaceRecognition'}
        />
        <span>{heading}</span>
      </div>

      <Show>
        <Show.When isTrue={state === 'send-biometric-link'}>
          <div className={`${containerClass} gap-4`}>
            <FrankieLoader
              loading={isLoading}
              className="flex flex-col items-start gap-4"
              label={
                <div className="text-tertiary-grey-800 font-bold">
                  {t('loading.link')}
                </div>
              }
            >
              <div className="text-tertiary-grey-800 font-bold">
                {t(isRestart ? 'resendLink' : 'sendLink', { applicantName })}
              </div>

              <div className="font-medium">{t('processCompleted')}</div>
            </FrankieLoader>

            <FrankieButton
              intent="subtle"
              className="!ps-0 !pe-2"
              startIcon={{
                size: 'lg',
                name: 'mdiCellphoneText',
                className: `pr-2 ${isLoading ? '' : 'text-primary-500'}`,
              }}
              onClick={() => handleStateChange('text-message')}
              disabled={isLoading}
              testId={{ button: applicantSendBiometricLinkQa.button }}
            >
              {t('action.sendLinkText')}
            </FrankieButton>

            <FrankieButton
              intent="subtle"
              className="!ps-0 !pe-2"
              startIcon={{
                size: 'lg',
                name: 'mdiLinkVariant',
                className: `pr-2 ${isLoading ? '' : 'text-primary-500'}`,
              }}
              onClick={() => handleStateChange('copy-link')}
              disabled={isLoading}
            >
              {t('action.copyLink')}
            </FrankieButton>

            <FrankieButton className="mt-2" onClick={onClose}>
              {t('action.cancel')}
            </FrankieButton>
          </div>
        </Show.When>

        <Show.When isTrue={state === 'copy-link'}>
          <div className={`${containerClass} gap-4`}>
            <div className="font-bold">{t('copyLinkAndSend')}</div>

            <div className="flex items-end ">
              <FrankieTextField
                label={t('secureLink')}
                value={idvLinkUrl}
                className="!w-[300px]"
              />

              <FrankieButton
                className={`${
                  watchIsCopied ? '!bg-tertiary-green-400 !text-mono-white' : ''
                }`}
                onClick={handleCopyLink}
              >
                {watchIsCopied ? t('copied') : t('copy')}
              </FrankieButton>
            </div>

            <ConfirmationFormField
              label={t('enableLivenessDetection')}
              control={control}
              name="useLiveness"
            />

            <div className="flex justify-between w-full mt-4">
              <FrankieButton onClick={onClose}>{t('done')}</FrankieButton>

              <FrankieButton
                intent="secondary"
                onClick={() => handleStateChange('send-biometric-link')}
              >
                {t('back')}
              </FrankieButton>
            </div>
          </div>
        </Show.When>

        <Show.When isTrue={state === 'text-message'}>
          <FrankieLoader
            loading={isLoading}
            label={
              <div className="text-tertiary-grey-800 font-bold">
                {t('loading.sendingLink')}
              </div>
            }
          >
            <div className={`${containerClass} gap-6`}>
              <div className="font-bold">{t('sendLinkToApplicant')}</div>

              <SelectFormField
                key={state}
                className="!w-[75%]"
                label={t('country')}
                options={countryOption}
                control={control}
                name="country"
              />

              <FrankieTextField
                className="!w-[75%]"
                label={t('mobileNumber')}
                {...register('mobileNumber', { required: true })}
              />

              <ConfirmationFormField
                label={t('enableLivenessDetection')}
                control={control}
                name="useLiveness"
              />

              <div>
                <div className="text-tertiary-grey-800 text-sm font-medium">
                  {t('comment')}
                </div>
                <TextAreaFormField
                  label={t('auditPurposeOnly')}
                  control={control}
                  name="comment"
                  placeholder={t('typeComment')}
                />
              </div>

              <div className="flex justify-between w-full">
                <FrankieButton
                  disabled={!watchPhoneNumber}
                  onClick={sendLinkTextMessage}
                >
                  {t('action.sendLink')}
                </FrankieButton>

                <FrankieButton
                  intent="secondary"
                  onClick={() => setState('send-biometric-link')}
                >
                  {t('back')}
                </FrankieButton>
              </div>
            </div>
          </FrankieLoader>
        </Show.When>
      </Show>
    </div>
  )
}
