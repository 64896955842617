import React, { useMemo } from 'react'

import { DataGridPro } from '@mui/x-data-grid-pro'

import { ApplicantId } from 'entities/applicant'

import { WithProps } from 'shared/hoc'
import { useI18n } from 'shared/i18n'

import { APPLICANT_BUSINESS_OWNERSHIP_KEY } from '../../applicant-business-ownership.key'
import { applicantBusinessOwnershipEn } from '../../locale/applicant-business-ownership.en'
import { ApplicantOwnershipHolderTypes } from '../../model/applicant-business-ownership.model'
import { businessOwnerShipDataGridQa } from '../../qa/applicant-business-ownership.qa'
import { useApplicantBusinessOwnershipState } from '../../state/applicant-business-ownership-query/applicant-business-ownership.query'
import {
  BusinessOwnershipDataGridCell,
  BusinessOwnershipDataGridColumn,
  BusinessOwnershipDataGridLoadingOverlay,
  BusinessOwnershipDataGridRow,
  businessOwnershipDataGridCellWidth,
  businessOwnershipDataGridSx,
  businessOwnershipGridName,
} from '../business-ownership-data-grid-helper/business-ownership-data-grid-helper'

type Props = {
  applicantId: ApplicantId
  className?: string
  ownerShipHolderType: ApplicantOwnershipHolderTypes
  emptyText?: string
}

export function BusinessOwnershipDataGrid({
  applicantId,
  className = '',
  ownerShipHolderType,
  emptyText,
}: Props) {
  const t = useI18n([APPLICANT_BUSINESS_OWNERSHIP_KEY], {
    keys: applicantBusinessOwnershipEn,
  })

  const columns: BusinessOwnershipDataGridColumn = [
    {
      field: 'type',
      headerName: t('dataGridFields.type'),
      ...businessOwnershipDataGridCellWidth(60, true),
      showInGrid: ['BO_1', 'BO_2', 'BO_3', 'BO_4', 'BO_5A', 'BO_6', 'BO_7'],
    },
    {
      field: 'classTitle',
      headerName: t('dataGridFields.classTitle'),
      ...businessOwnershipDataGridCellWidth(200),
      showInGrid: ['BO_5B'],
    },
    {
      field: 'name',
      headerName: t('dataGridFields.name'),
      ...businessOwnershipDataGridCellWidth(180),
      showInGrid: ['BO_1', 'BO_2', 'BO_3', 'BO_4', 'BO_5A', 'BO_6', 'BO_7'],
    },
    {
      field: 'entityType',
      headerName: t('dataGridFields.entityType'),
      ...businessOwnershipDataGridCellWidth(100),
      showInGrid: ['BO_6'],
    },
    {
      field: 'role',
      headerName: t('dataGridFields.role'),
      ...businessOwnershipDataGridCellWidth(100),
      showInGrid: ['BO_4'],
    },
    {
      field: 'beneficiallyHeldOwnership',
      headerName: t('dataGridFields.beneficiallyHeldOwnership'),
      ...businessOwnershipDataGridCellWidth(100),
      showInGrid: ['BO_1', 'BO_2', 'BO_3', 'BO_7'],
    },
    {
      field: 'nonBeneficiallyHeldOwnership',
      headerName: t('dataGridFields.nonBeneficiallyHeldOwnership'),
      ...businessOwnershipDataGridCellWidth(120),
      showInGrid: ['BO_1', 'BO_2', 'BO_3', 'BO_7'],
    },
    {
      field: 'jointHolding',
      headerName: t('dataGridFields.jointHolding'),
      ...businessOwnershipDataGridCellWidth(100),
      showInGrid: ['BO_5A'],
    },
    {
      field: 'beneficiallyHeld',
      headerName: t('dataGridFields.beneficiallyHeld'),
      ...businessOwnershipDataGridCellWidth(100),
      showInGrid: ['BO_5A'],
    },
    {
      field: 'currentPrevious',
      headerName: t('dataGridFields.currentPrevious'),
      ...businessOwnershipDataGridCellWidth(100),
      showInGrid: ['BO_4'],
    },
    {
      field: 'total',
      headerName: t('dataGridFields.total'),
      ...businessOwnershipDataGridCellWidth(60),
      showInGrid: ['BO_1', 'BO_2', 'BO_3', 'BO_7'],
    },
    {
      field: 'profileStatus',
      headerName: t('dataGridFields.profileStatus'),
      ...businessOwnershipDataGridCellWidth(100),
      showInGrid: ['BO_1', 'BO_2', 'BO_3', 'BO_4', 'BO_5A', 'BO_7'],
    },
    {
      field: 'classCode',
      headerName: t('dataGridFields.classCode'),
      ...businessOwnershipDataGridCellWidth(80),
      showInGrid: ['BO_5A', 'BO_5B'],
    },
    {
      field: 'sharesHeld',
      headerName: t('dataGridFields.sharesHeld'),
      ...businessOwnershipDataGridCellWidth(80),
      showInGrid: ['BO_5A'],
    },
    {
      field: 'sharesIssued',
      headerName: t('dataGridFields.sharesIssued'),
      ...businessOwnershipDataGridCellWidth(100),
      showInGrid: ['BO_5B'],
    },
    {
      field: 'totalAmountPaid',
      headerName: t('dataGridFields.totalAmountPaid'),
      ...businessOwnershipDataGridCellWidth(100),
      showInGrid: ['BO_5B'],
    },
    {
      field: 'totalAmountDueAndPayable',
      headerName: t('dataGridFields.totalAmountDueAndPayable'),
      ...businessOwnershipDataGridCellWidth(100),
      showInGrid: ['BO_5B'],
    },
    {
      field: 'issues',
      headerName: t('dataGridFields.issues'),
      ...businessOwnershipDataGridCellWidth(100),
      showInGrid: ['BO_1', 'BO_2', 'BO_3', 'BO_4'],
    },
    {
      field: 'roleType',
      headerName: t('dataGridFields.roleType'),
      ...businessOwnershipDataGridCellWidth(100),
      showInGrid: ['BO_7'],
    },
    {
      field: 'recipe',
      headerName: t('dataGridFields.recipe'),
      ...businessOwnershipDataGridCellWidth(100),
      showInGrid: ['BO_1', 'BO_2', 'BO_3', 'BO_4', 'BO_7'],
    },
    {
      field: 'riskLevel',
      headerName: t('dataGridFields.riskLevel'),
      ...businessOwnershipDataGridCellWidth(80),
      showInGrid: ['BO_1', 'BO_2', 'BO_3', 'BO_4', 'BO_5A'],
    },
    {
      field: 'actions',
      headerName: '',
      ...businessOwnershipDataGridCellWidth(60, true),
      showInGrid: ['BO_1', 'BO_2', 'BO_3', 'BO_4', 'BO_5A', 'BO_6', 'BO_7'],
    },
  ]

  const { getOwnershipData, getTotalSubtitle, isLoading } =
    useApplicantBusinessOwnershipState({
      applicantId,
    })

  const { totalSubtitle, rows } = useMemo(
    () => ({
      totalSubtitle: getTotalSubtitle(ownerShipHolderType),
      rows: getOwnershipData(ownerShipHolderType),
    }),
    [getOwnershipData, getTotalSubtitle, ownerShipHolderType],
  )

  return (
    <div
      className={`${className}`}
      data-qa={businessOwnerShipDataGridQa.container}
    >
      {totalSubtitle && (
        <div className="text-right text-md mb-3 mt-[-30px]">
          <span className="mr-2 font-medium">{totalSubtitle.label}:</span>
          <span className={totalSubtitle.value ? 'font-bold' : 'font-medium'}>
            {totalSubtitle.value ?? '-'}
          </span>
        </div>
      )}
      <DataGridPro
        data-qa={businessOwnerShipDataGridQa.dataGrid}
        rows={rows}
        className={
          !rows.length && !isLoading
            ? '[&_.MuiDataGrid-virtualScroller]:h-[60px]'
            : ''
        }
        slots={{
          row: BusinessOwnershipDataGridRow,
          loadingOverlay: BusinessOwnershipDataGridLoadingOverlay,
          noRowsOverlay: () => emptyText ?? t('noResults'),
        }}
        columns={columns
          .filter(item =>
            item.showInGrid.includes(
              businessOwnershipGridName[ownerShipHolderType],
            ),
          )
          .map(item => ({
            // Adding default properties for every column
            renderCell: WithProps(BusinessOwnershipDataGridCell, {
              applicantId,
            }),
            headerClassName:
              '!p-0 text-xs !border-tertiary-grey-200 font-semibold bg-neutral-30 text-tertiary-grey-500 !outline-none',
            cellClassName:
              '!outline-none !border-tertiary-grey-200 text-tertiary-grey-700 text-sm break-words',
            sortable: false,
            hideSortIcons: true,
            resizable: false,
            disableColumnMenu: true,
            disableReorder: true,
            ...item,
          }))}
        loading={isLoading}
        isRowSelectable={() => false}
        isCellEditable={() => false}
        getRowHeight={() => 'auto'}
        columnHeaderHeight={45}
        disableRowSelectionOnClick
        disableColumnSelector
        disableColumnFilter
        disableColumnMenu
        disableDensitySelector
        showCellVerticalBorder={false}
        showColumnVerticalBorder={false}
        hideFooterPagination
        hideFooter
        hideFooterSelectedRowCount
        autoHeight
        autoPageSize
        sx={businessOwnershipDataGridSx}
      />
    </div>
  )
}
