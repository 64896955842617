import React, { useEffect, useMemo, useState } from 'react'

import { useLocation } from 'react-router-dom'

import { useHasFeatureFlag } from 'entities/session'

import { Menu, MenuOptionType } from 'shared/components'
import { useI18n } from 'shared/i18n'
import { useOverlay } from 'shared/overlay'

import { CUSTOM_VIEWS_KEYS } from '../../custom-view.key'
import { customViewEn } from '../../locale/custom-view.en'
import { customViewEditCtaQa } from '../../qa/custom-view.qa'
import { useCustomViewData } from '../../states/custom-view-state/custom-view.state'
import { CustomViewSaveModal } from '../custom-view-save-modal/custom-view-save-modal'

export function CustomViewEditCta() {
  const { currentView, customViewLimitReached } = useCustomViewData()
  const t = useI18n([CUSTOM_VIEWS_KEYS], { keys: customViewEn })
  const location = useLocation()
  const [showCta, setShowCta] = useState<boolean | '' | undefined>(false)
  const hideBtn = location.state?.hideBtn
  const { hasCustomViews } = useHasFeatureFlag({
    hasCustomViews: 'customViews',
  })

  const showEditBtn = useMemo(
    () =>
      currentView?.query !== location.search.slice(1) &&
      currentView?.query &&
      !hideBtn,
    [currentView, location, hideBtn],
  )

  useEffect(() => {
    setShowCta(showEditBtn)
  }, [showEditBtn])

  const [createOverlay, closeOverlay] = useOverlay()

  const handleChange = (value: 'save' | 'new') => {
    const defaultName = value === 'save' ? currentView?.name : undefined

    createOverlay(
      <CustomViewSaveModal
        onCloseOverlay={closeOverlay}
        mode={value}
        defaultName={defaultName}
        customViewStateChange={() => setShowCta(false)}
      />,
      {
        closeButtonClassName: '!top-[27px] !right-[24px]',
        className: '!p-6',
      },
    )
  }

  const maxReachedOptions: MenuOptionType<'save' | 'new'>[] = [
    {
      label: t('saveChanges'),
      value: 'save',
      testId: customViewEditCtaQa.saveChanges,
    },
    {
      label: t('saveNewChange'),
      value: 'new',
      disable: true,
      className: '!text-tertiary-grey-400 hover:!bg-transparent',
      description: t('limitReachedTitle'),
      descriptionClassName: 'text-tertiary-grey-400 hover:bg-transparent',
      testId: customViewEditCtaQa.saveNewChange,
    },
  ]
  const options: MenuOptionType<'save' | 'new'>[] = [
    {
      label: t('saveChanges'),
      value: 'save',
      testId: customViewEditCtaQa.saveChanges,
    },

    {
      label: t('saveNewChange'),
      value: 'new',
      testId: customViewEditCtaQa.saveNewChange,
    },
  ]

  const option = customViewLimitReached ? maxReachedOptions : options

  if (!showEditBtn || !showCta || !hasCustomViews) return null

  return (
    <Menu
      options={option}
      onSelect={value => {
        handleChange(value)
      }}
      onFocusClassName="!outline-2 !outline-solid !outline-primary-200 !bg-primary-container"
      buttonClassName="!min-w-[103px] !max-w-[103px] !h-[34] !rounded-sm !py-2 !px-3  justify-between   !text-tertiary-grey-700 !font-semibold !text-xs !border !border-solid !border-tertiary-grey-200 !mr-[14px]"
      icons={{ open: 'mdiChevronDown' }}
      optionsClassName="max-w-[171px]"
      testId={{ button: customViewEditCtaQa.btn }}
    >
      {t('saveView')}
    </Menu>
  )
}
