import React, { useCallback, useEffect, useState } from 'react'

import { FrankieTabs } from 'frankify/src'

import { useI18n } from 'shared/i18n'
import { TrackingEventsTypes, trackingManager } from 'shared/tracking'

import { INDIVIDUAL_AML_RESULT_KEY } from '../../../individual-aml-result.key'
import { individualAmlResultEn } from '../../../locale/individual-aml-result.en'
import { TabItemTypes, tabItems } from '../../../model/individual-aml-result'
import { amlMatchTabsQa } from '../../../qa/individual-aml-result.qa'
import {
  IndividualAmlEntityAdverseMediaCard,
  IndividualAmlEntityOtherDataSrcCard,
} from '../../individual-aml-entity-helper/individual-aml-entity-detail-card/individual-aml-entity-detail-card'
import { IndividualAmlPepCard } from '../../individual-aml-entity-helper/individual-aml-pep-card/individual-aml-entity-pep-card'
import { IndividualAmlSanctionCard } from '../../individual-aml-entity-helper/individual-aml-sanctions-card/individual-aml-sanctions-card'
import { IndividualAmlWatchlistCard } from '../../individual-aml-entity-helper/individual-aml-watchlist-card/individual-aml-watchlist-card'

type Props = {
  entityId: string
}

export function IndividualAmlMatchTabs({ entityId }: Props) {
  const t = useI18n(INDIVIDUAL_AML_RESULT_KEY, {
    keys: individualAmlResultEn,
  })

  const [currentTab, setCurrentTab] = useState<TabItemTypes>(tabItems[0].value)
  useEffect(() => {
    trackingManager.track(TrackingEventsTypes.AmlTabViewIndividual, {
      tab: currentTab,
    })
  }, [currentTab])

  const CardsMapper = useCallback(
    (currentTab: TabItemTypes) => {
      switch (currentTab) {
        case TabItemTypes.PEP:
          return <IndividualAmlPepCard entityId={entityId} />
        case TabItemTypes.SANCTIONS:
          return <IndividualAmlSanctionCard entityId={entityId} />
        case TabItemTypes.WATCHLIST:
          return <IndividualAmlWatchlistCard entityId={entityId} />
        case TabItemTypes.OTHER_DATA_SOURCES:
          return <IndividualAmlEntityOtherDataSrcCard entityId={entityId} />
        default:
          return <IndividualAmlEntityAdverseMediaCard entityId={entityId} />
      }
    },
    [currentTab, entityId],
  )

  return (
    <div className="flex flex-col gap-6">
      <div data-qa={amlMatchTabsQa.container}>
        <FrankieTabs
          selectedTab={currentTab}
          onTabSelect={setCurrentTab}
          options={tabItems.map(item => ({
            value: item.value,
            label: `${t(item.tKey, { count: 2 })}`,
          }))}
          testId={{ tabsContainer: amlMatchTabsQa.amlTabs }}
          className="!border-tertiary-grey-200"
        />
      </div>

      {CardsMapper(currentTab)}
    </div>
  )
}
