import React, { forwardRef } from 'react'

import { CheckboxStyle, ContainerStyle } from './checkbox.theme'
import { IFrankieCheckboxProps } from './checkbox.types'

export const FrankieCheckbox = forwardRef(
  // eslint-disable-next-line prefer-arrow-callback
  function FrankieCheckbox(
    {
      className = '',
      size = 'md',
      name,
      disabled,
      checked,
      partial = false,
      onBlur,
      onChange,
      value,
      testId = {
        input: '',
      },
    }: IFrankieCheckboxProps,
    ref: React.ForwardedRef<HTMLInputElement>,
  ) {
    return (
      <div className={ContainerStyle({ size, className })}>
        <input
          id={name}
          name={name}
          aria-label={name}
          ref={ref}
          onChange={onChange}
          onBlur={onBlur}
          className={CheckboxStyle({
            size,
            partial,
          })}
          disabled={disabled}
          type="checkbox"
          checked={checked}
          data-qa={testId.input}
          value={value}
        />
      </div>
    )
  },
)
