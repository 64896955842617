import React, { ReactNode } from 'react'

import { NavLink } from 'react-router-dom'

import { FrankieIcon, FrankieIconName, FrankieTooltip } from 'frankify/src'

import { useSetOriginatedPath } from 'entities/routing'

type Props = {
  children: ReactNode

  to: string
  icon?: FrankieIconName
  open: boolean

  className?: string
  tooltip?: string
  state?: Record<string, unknown>
  viewNameFirstCharacter?: string
  iconClassName?: string
  hideToolTip?: boolean
  testId?: {
    toolTip?: string
    iconLogo?: string
    navLink?: string
    customLogo?: string
  }
}

type CustomViewLogNameProps = {
  character: string
  isActive: boolean
  testId?: {
    container?: string
  }
}

function CustomViewLogName({
  character,
  isActive,
  testId,
}: CustomViewLogNameProps) {
  return (
    <div
      data-qa={testId?.container}
      className={`flex items-center justify-center h-[20px] w-[20px] border rounded-sm ${
        isActive
          ? 'bg-primary-800 text-mono-white border-transparent'
          : 'text-mono-black border-mono-black'
      }`}
    >
      <span className="text-xs font-medium capitalize">{character}</span>
    </div>
  )
}

export function CustomNavLink({
  children,
  to,
  open,
  tooltip,
  className,
  state,
  viewNameFirstCharacter,
  icon,
  iconClassName,
  hideToolTip,
  testId,
}: Props) {
  const { setOriginatedPath } = useSetOriginatedPath()

  return (
    <FrankieTooltip
      hidden={hideToolTip}
      position="right"
      title={tooltip ?? children}
      className="!z-50"
      testId={{ tooltip: testId?.toolTip }}
    >
      <NavLink
        state={state}
        className={({ isActive }) =>
          `flex h-[36px] justify-between p-2 items-center whitespace-nowrap text-sm font-medium rounded-sm ${
            isActive
              ? 'text-primary-800'
              : 'text-tertiary-grey-600 hover:text-tertiary-grey-800 hover:bg-tertiary-grey-100 ' +
                'active:text-tertiary-grey-800 focus-visible:text-tertiary-grey-800 active:bg-primary-50 focus-visible:bg-primary-50 ' +
                'active:outline focus-visible:outline outline-tertiary-grey-100 focus-visible:outline-3 active:outline-3'
          } ${className ?? ''}`
        }
        to={to}
        onClick={() => setOriginatedPath(to)}
        data-qa={testId?.navLink}
      >
        {({ isActive }) => (
          <>
            {!open && viewNameFirstCharacter && (
              <CustomViewLogName
                character={viewNameFirstCharacter}
                isActive={isActive}
                testId={{ container: testId?.customLogo }}
              />
            )}
            {open && children}
            {icon && (
              <FrankieIcon
                name={icon}
                size="xs"
                className={iconClassName || ''}
                testId={{ icon: testId?.iconLogo }}
              />
            )}
          </>
        )}
      </NavLink>
    </FrankieTooltip>
  )
}
