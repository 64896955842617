import React from 'react'

import { FrankieTableHeaderCell } from 'frankify/src'

import { useI18n } from 'shared/i18n'

import {
  NOTIFICATIONS_MANAGEMENT_KEY,
  notificationsManagementEn,
} from '../../../locale/notifications-management.en'
import { INotificationChannel } from '../../../model/notifications-management.model'
import { NotificationsConfigTableRowLayout } from '../notifications-config-table-row-layout/notifications-config-table-row-layout'

type Props = {
  channels: INotificationChannel[]
}

export function NotificationsConfigTableRowHeader({ channels }: Props) {
  const t = useI18n([NOTIFICATIONS_MANAGEMENT_KEY], {
    keys: notificationsManagementEn,
  })

  return (
    <div className="w-full text-xs font-medium text-tertiary-grey-500 leading-4 bg-tertiary-grey-50">
      <NotificationsConfigTableRowLayout
        notificationTypeCell={
          <FrankieTableHeaderCell>{t('type.header')}</FrankieTableHeaderCell>
        }
        channelCells={channels.map(channel => ({
          cell: <div className="py-2 px-4">{channel.value}</div>,
          key: channel.value,
        }))}
      />
    </div>
  )
}
