import {
  useAmlResultData,
  // AML_RESULT_F2_QUERY_KEY,
} from './entity-aml-pro/entity-aml-pro.query'
import {
  EntityID,
  // ProcessResultObject,
  // ServiceProfile,
} from '../../model/entity.model'
import {
  useGetWorkflowEventsData,
  // EntityWorkflowDataQueryKey,
} from '../entity-workflow/entity-workflow.query'

type Args = {
  entityId: EntityID
  workflowExecutionId?: string
  mock?: boolean
}

export const useEntityAmlData = ({
  entityId,
  workflowExecutionId,
  mock,
}: Args) => {
  // const queryClient = useQueryClient()
  const { data, refetch: refetchWorkflow } = useGetWorkflowEventsData({
    entityId,
    disabled: !workflowExecutionId || mock,
  })

  const workflow = data?.workflowSummaries?.at(-1)?.workflowResultData
  const workflowAmlStep = workflow?.workflowStepResults?.find(
    step => step.stepName === 'AML',
  )

  const { data: amlData, refetch: refetchPRO } = useAmlResultData({
    entityId,
    disabled: !!workflowExecutionId && !mock,
    mock,
  })

  const refetch = () => {
    void refetchWorkflow()
    void refetchPRO()
  }

  // const updateAmlAlert = (processResults: ProcessResultObject[]) => {
  //   const proObject = processResults.reduce((acc, curr) => {
  //     acc[curr.processResultId] = curr
  //     return acc
  //   }, {} as Partial<Record<string, ProcessResultObject>>)
  //   console.log(proObject, processResults, 'hello')
  //   queryClient.setQueriesData(
  //     [AML_RESULT_F2_QUERY_KEY],
  //     (oldData?: { processResults?: ProcessResultObject[] }) => {
  //       if (oldData?.processResults) {
  //         for(let i in oldData.processResults =processResult => {
  //           const updatedPro = proObject[processResult.processResultId]
  //           if (updatedPro) {
  //             console.log(updatedPro, processResult.processResultId, 'hello2')

  //           }
  //         })
  //       }
  //       return JSON.parse(JSON.stringify(oldData)) as {
  //         processResults?: ProcessResultObject[]
  //       }
  //     },
  //   )

  //   queryClient.setQueriesData(
  //     [EntityWorkflowDataQueryKey, { entityId }],
  //     (oldData?: ServiceProfile) => {
  //       console.log('deep', oldData)

  //       if (oldData?.workflowSummaries) {
  //         console.log('deep1', oldData.workflowSummaries)
  //         for (const workflow of oldData.workflowSummaries) {
  //           for (const step of workflow.workflowResultData
  //             ?.workflowStepResults || []) {
  //             console.log('deep2', step)
  //             if (step.stepName === 'AML') {
  //               for (let processResult of step.processResults || []) {
  //                 console.log('deep3', processResult.processResultId, proObject)

  //                 const updatedPro = proObject[processResult.processResultId]
  //                 if (updatedPro) {
  //                   console.log(
  //                     updatedPro,
  //                     processResult.processResultId,
  //                     'hello3',
  //                   )

  //                   processResult = {...processResult,...updatedPro}
  //                 }
  //               }
  //             }
  //           }
  //         }
  //       }
  //       return JSON.parse(JSON.stringify(oldData)) as ServiceProfile
  //     },
  //   )
  // }

  return {
    data: {
      processResults: workflowExecutionId
        ? workflowAmlStep?.processResults?.filter(i => i.result === 'HIT')
        : amlData?.processResults.filter(i => i.result === 'HIT'),
    },
    refetch,
    // updateAmlAlert,
  }
}
