import React, { useEffect } from 'react'

import { Outlet } from 'react-router-dom'

import { FrankieDivider } from 'frankify/src'

import { PermissionTypes } from 'entities/role'
import { useHasPermission } from 'entities/session'

import { useI18n } from 'shared/i18n'
import { TrackingEventsTypes, trackingManager } from 'shared/tracking'

import { SettingsNavLink } from './settings-nav-link/settings-nav-link'
import { ROUTING_KEY, routingEn } from '../../locale/routing.en'
import { routingQa } from '../../qa/routing.qa'

type Props = {
  settingsNotificationsPath: string
  settingsPersonalPath: string
  settingsAccountPath: string
  settingsRolesPath: string
  settingsUsersPath: string
}

export function LayoutSettings({
  settingsNotificationsPath,
  settingsRolesPath,
  settingsAccountPath,
  settingsPersonalPath,
  settingsUsersPath,
}: Props) {
  const t = useI18n(ROUTING_KEY, { keys: routingEn })
  const { canSeeRolesList, canSeeUsersList, canSeeOrganisationSettings } =
    useHasPermission({
      canSeeRolesList: PermissionTypes.RoleIndexPage,
      canSeeUsersList: PermissionTypes.UserIndex,
      canSeeOrganisationSettings: PermissionTypes.OrganisationSettingsFetch,
    })

  useEffect(() => {
    trackingManager.track(TrackingEventsTypes.Settings)
  }, [])

  return (
    <div>
      <div className="relative px-8 mt-4">
        <div className="mb-6 text-tertiary-grey-800 text-2xl font-bold">
          {t('settingsNav.title')}
        </div>
        <nav className="flex flex-initial gap-8 items-center">
          <SettingsNavLink
            to={settingsPersonalPath}
            testId={routingQa.settings.personal}
          >
            {t('settingsNav.personal')}
          </SettingsNavLink>
          {canSeeOrganisationSettings && (
            <SettingsNavLink
              to={settingsAccountPath}
              testId={routingQa.settings.account}
            >
              {t('settingsNav.account')}
            </SettingsNavLink>
          )}
          <SettingsNavLink
            to={settingsNotificationsPath}
            testId={routingQa.settings.notifications}
          >
            {t('settingsNav.notifications')}
          </SettingsNavLink>
          {canSeeRolesList && (
            <SettingsNavLink
              to={settingsRolesPath}
              testId={routingQa.settings.roles}
            >
              {t('settingsNav.roles')}
            </SettingsNavLink>
          )}
          {canSeeUsersList && (
            <SettingsNavLink
              to={settingsUsersPath}
              testId={routingQa.settings.users}
            >
              {t('settingsNav.users')}
            </SettingsNavLink>
          )}
        </nav>
        <FrankieDivider />
      </div>
      <Outlet />
    </div>
  )
}
