import React, { useEffect, useMemo, useState } from 'react'

import { FrankieLoader } from 'frankify/src'

import {
  NotificationsConfigTable,
  useChangeNotificationSettingsMutation,
  useNotificationSettingsQuery,
  INotificationSetting,
} from 'features/notifications-management'

import { replaceAll } from 'shared/array'
import { debounce } from 'shared/function'
import { useI18n } from 'shared/i18n'
import { TrackingEventsTypes, trackingManager } from 'shared/tracking'

import {
  SETTINGS_NOTIFICATIONS_PAGE_KEY,
  settingsNotificationsPageEn,
} from '../../locale/settings-notifications.en'

export function SettingsNotificationsPage() {
  const t = useI18n([SETTINGS_NOTIFICATIONS_PAGE_KEY], {
    keys: settingsNotificationsPageEn,
  })
  const { data: settingsConfig, isLoading } = useNotificationSettingsQuery({
    canFetch: true,
  })
  const { mutateAsync: changeSettings } =
    useChangeNotificationSettingsMutation()
  const [settingsState, setSettingsState] = useState<INotificationSetting[]>([])
  const [changedSettings, setChangedSettings] = useState<
    INotificationSetting[]
  >([])

  useEffect(() => {
    trackingManager.track(TrackingEventsTypes.NotificationSettings)
  }, [])

  useEffect(() => {
    setSettingsState(settingsConfig?.notificationSettings || [])
    setChangedSettings([])
  }, [settingsConfig])

  const debouncedChangeSettings = useMemo(
    () => debounce(changeSettings, 400),
    [changeSettings],
  )
  const handleChangeSetting = (
    newChangedSetting: INotificationSetting,
    newChecked: boolean,
  ) => {
    const newChangedSettings = [
      ...changedSettings.filter(
        item =>
          item.channel !== newChangedSetting.channel ||
          item.type !== newChangedSetting.type,
      ),
      { ...newChangedSetting, value: newChecked },
    ]
    setChangedSettings(newChangedSettings)
    setSettingsState(prev =>
      replaceAll(
        prev,
        newChangedSettings,
        setting => `${setting.type}${setting.channel}`,
      ),
    )
    debouncedChangeSettings({
      newSettings: newChangedSettings,
    })
  }

  if (isLoading) {
    return <FrankieLoader loading fullscreen />
  }

  return (
    <div className="p-8 pr-0">
      <p className="mb-4 text-sm font-normal text-tertiary-grey-700">
        {t('description')}
      </p>
      <div className="w-fit">
        <NotificationsConfigTable
          onChange={handleChangeSetting}
          canSeeNonPersonalNotifications
          notificationChannels={settingsConfig?.notificationChannels || []}
          notificationSettings={settingsState}
          notificationSettingsDictionary={
            settingsConfig?.notificationSettingsDictionary || []
          }
        />
      </div>
    </div>
  )
}
