import React from 'react'

import { useI18n } from 'shared/i18n'

import { CUSTOM_VIEWS_KEYS } from '../../custom-view.key'
import { customViewEn } from '../../locale/custom-view.en'
import { customViewNavQa } from '../../qa/custom-view.qa'
import { useCustomViewData } from '../../states/custom-view-state/custom-view.state'
import { CustomNavLink } from '../custom-view-nav-link/custom-nav-link'

type props = {
  open: boolean
  applicantsPath: string
}

export function CustomViewNav({ open, applicantsPath }: props) {
  const {
    customViewLimitReached,
    hasCustomViews,
    isFetchingCustomView,
    routeForView,
    sortedViewData,
  } = useCustomViewData()

  const t = useI18n([CUSTOM_VIEWS_KEYS], { keys: customViewEn })

  if (!hasCustomViews) return null

  return (
    <>
      <CustomNavLink
        open={open}
        to={applicantsPath}
        state={{ openModal: !customViewLimitReached && !isFetchingCustomView }}
        className="mt-4"
        tooltip={
          customViewLimitReached
            ? t('tooltip.limitReached.customViewTitle')
            : t('tooltip.title')
        }
        hideToolTip={false}
        icon="mdiPlus"
        iconClassName={
          isFetchingCustomView || customViewLimitReached
            ? 'text-tertiary-grey-300'
            : 'text-tertiary-grey-500'
        }
        testId={{
          toolTip: customViewNavQa.toolTip,
          customLogo: customViewNavQa.customLogo,
          iconLogo: customViewNavQa.logo,
          navLink: customViewNavQa.navLink,
        }}
      >
        <div className="text-tertiary-grey-500 font-semibold text-xs leading-4 flex justify-between items-center">
          {t('customView')}
        </div>
      </CustomNavLink>

      {sortedViewData?.map(item => (
        <CustomNavLink
          hideToolTip={false}
          key={item.id}
          to={routeForView(item.name)}
          viewNameFirstCharacter={item.name[0]}
          open={open}
          tooltip={item.name}
          className={open ? 'pl-[-2px]' : ''}
          testId={{
            toolTip: customViewNavQa.toolTip,
            customLogo: customViewNavQa.customLogo,
            iconLogo: customViewNavQa.logo,
            navLink: customViewNavQa.navLink,
          }}
        >
          <div className="font-medium text-sm leading-5">{item.name}</div>
        </CustomNavLink>
      ))}
    </>
  )
}
