import React, { ReactNode } from 'react'

export type NotificationChannelCell = { cell: ReactNode; key: string }

type Props = {
  notificationTypeCell: ReactNode
  channelCells: NotificationChannelCell[]
}

export function NotificationsConfigTableRowLayout({
  notificationTypeCell,
  channelCells,
}: Props) {
  return (
    <div className="w-full flex flex-initial flex-row border-b border-solid border-tertiary-grey-200">
      <div className="basis-full max-w-[580px] min-w-[100px] shrink grow-0">
        {notificationTypeCell}
      </div>
      {channelCells.map(({ cell, key }) => (
        <div
          key={key}
          className="basis-[97px] shrink-0 grow-0 flex flex-row justify-center"
        >
          {cell}
        </div>
      ))}
    </div>
  )
}
