import { useQuery } from '@tanstack/react-query'

import { amlResultApi } from '../../../api/aml.api'
import {
  ProcessResultResponse,
  ProcessResultManualStatusEnumAML,
} from '../../../model/entity.model'

export const AML_RESULT_F2_QUERY_KEY = 'aml-result-f2'

export type Args = {
  entityId: string
  mock?: boolean
  disabled?: boolean
}

export const useAmlResultData = ({ entityId, disabled, mock }: Args) =>
  useQuery({
    enabled: !!entityId && !disabled,
    queryKey: [AML_RESULT_F2_QUERY_KEY, { entityId, mock }],
    queryFn: async () => {
      /* eslint-disable @typescript-eslint/no-use-before-define */
      if (mock) return dummyData
      const res = await amlResultApi.getAmlResult(entityId, {
        limit: 1000,
        page: 1,
      })

      return res.data
    },
    keepPreviousData: true,
  })
const dummyData = {
  meta: {
    count: 12,
    limit: 12,
    page: 1,
    total: 12,
  },

  processResults: [
    {
      class: 'AML',
      createdAt: '2024-06-06T08:06:46.261666Z',
      entityId: 'a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
      manualStatus: ProcessResultManualStatusEnumAML.TRUE_POSITIVE,
      notes: {
        'aml.address.1': {
          type: 'general.string',
          value: 'Bentiu Unity SOUTH SUDAN',
        },
        'aml.address.2': {
          type: 'general.string',
          value: 'Juba Central Equatoria SOUTH SUDAN',
        },
        'aml.case_id': {
          type: 'result.code',
          value: 'a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
        },
        'aml.datasource.1': {
          type: 'general.string',
          value: 'PEP - National Government',
        },
        'aml.is_monitored_case': {
          type: 'general.bool',
          value: 'false',
        },
        'aml.search_countries': {
          type: 'general.string',
          value: 'AUS',
        },
        'aml.search_countries.matched': {
          type: 'general.string',
          value: 'SSD',
        },
        'aml.search_date_of_birth': {
          type: 'general.datetime',
          value: '1990-05-15',
        },
        'aml.search_entity_aka': {
          type: 'general.string',
          value: 'جلال الدين جوك',
        },
        'aml.search_entity_associate.affiliated_company': {
          type: 'general.string',
          value: 'NATIONAL CONGRESS PARTY',
        },
        'aml.search_entity_id': {
          type: 'result.code',
          value: '5jb8fie788ex1iqhc97xkfmpm',
        },
        'aml.search_match_details': {
          type: 'general.string',
          value: 'MEDIUM',
        },
        'aml.search_match_types': {
          type: 'general.string',
          value: 'name',
        },
        'aml.search_name': {
          type: 'general.string',
          value: 'Jo',
        },
        'aml.search_name.matched': {
          type: 'general.string',
          value: 'Jalal Eldin JOK',
        },
        'aml.search_reference_id': {
          type: 'result.code',
          value: '5jb82kqu3y5k1iqhc9219jj50',
        },
        'aml.search_result.score': {
          type: 'general.float',
          value: '84.10',
        },
        'bcro.grouping_id': {
          type: 'result.code',
          value:
            '5jb82kqu3y5k1iqhc9219jj50::5jb8fie788ex1iqhc97xkfmpm::a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
        },
        category: {
          type: 'general.string',
          value: 'POLITICAL INDIVIDUAL',
        },
        'reference.doc.0': {
          type: 'general.string',
          value: 'http://sslagoss.org/members_party.php?pid=8',
        },
        'reference.doc.1': {
          type: 'general.string',
          value:
            'http://www.gurtong.org/resourcecenter/gov/RegionalAssembly_list.asp',
        },
        'reference.doc.2': {
          type: 'general.string',
          value: 'http://www.state.gov/g/drl/rls/hrrpt/2005/61594.htm',
        },
        'reference.doc.3': {
          type: 'general.string',
          value:
            'https://paanluelwel.com/2011/07/11/2010-election-parliament-of-south-sudan/',
        },
      },
      objectType: 'INDIVIDUAL',
      processResultId: '01HZP9C1QNQWK74HP8GTP8VC0V',
      providerResult: {
        confidence: '50',
        name: 'Refinitiv',
        reference: '',
        source: 'World-Check Risk Intelligence',
        sourceNormalized: 'world-check risk intelligence',
      },
      result: 'HIT',
      schemaVersion: 2,
      state: 'COMPLETED',
      stepName: 'AML',
      stepType: 'pep',

      supplementaryData: {
        fuzziness: {},
        groupingId:
          '5jb82kqu3y5k1iqhc9219jj50::5jb8fie788ex1iqhc97xkfmpm::a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
        matchData: {
          name: 'Jalal Eldin JOK',
          spReferenceId: '',
          strength: 84.1,
        },
        mediaData: [],
        pepData: [
          {
            additionalData: [
              {
                key: 'aml.is_monitored_case',
                value: 'false',
              },
              {
                key: 'aml.case_id',
                value: 'a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
              },
              {
                key: 'aml.search_reference_id',
                value: '5jb82kqu3y5k1iqhc9219jj50',
              },
              {
                key: 'bcro.grouping_id',
                value:
                  '5jb82kqu3y5k1iqhc9219jj50::5jb8fie788ex1iqhc97xkfmpm::a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
              },
              {
                key: 'aml.search_entity_id',
                value: '5jb8fie788ex1iqhc97xkfmpm',
              },
              {
                key: 'aml.search_result.score',
                value: '84.10',
              },
              {
                key: 'PepStatusUpdated',
                value: '2019-09-19',
              },
              {
                key: 'PepRolesUpdated',
                value: '2023-08-21',
              },
              {
                key: 'SOURCE.name',
                value: 'Member of Parliament for Unity State',
              },
              {
                key: 'SOURCE.aml_types',
                value: 'pep',
              },
              {
                key: 'Active Start Date',
                value: '2010',
              },
              {
                key: 'Active End Date',
                value: '2015',
              },
              {
                key: 'Title',
                value: 'Member of Parliament',
              },
              {
                key: 'Political Position',
                value: 'Member of Legislature',
              },
              {
                key: 'Status',
                value: 'FORMER',
              },
              {
                key: 'Level',
                value: 'PEP - National Government',
              },
            ],
            referenceDocs: [],
            sourceName: 'Member of Parliament for Unity State',
          },
          {
            additionalData: [
              {
                key: 'aml.is_monitored_case',
                value: 'false',
              },
              {
                key: 'aml.case_id',
                value: 'a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
              },
              {
                key: 'aml.search_reference_id',
                value: '5jb82kqu3y5k1iqhc9219jj50',
              },
              {
                key: 'bcro.grouping_id',
                value:
                  '5jb82kqu3y5k1iqhc9219jj50::5jb8fie788ex1iqhc97xkfmpm::a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
              },
              {
                key: 'aml.search_entity_id',
                value: '5jb8fie788ex1iqhc97xkfmpm',
              },
              {
                key: 'aml.search_result.score',
                value: '84.10',
              },
              {
                key: 'PepStatusUpdated',
                value: '2019-09-19',
              },
              {
                key: 'PepRolesUpdated',
                value: '2023-08-21',
              },
              {
                key: 'SOURCE.name',
                value: 'Member of Parliament for Unity State',
              },
              {
                key: 'SOURCE.aml_types',
                value: 'pep',
              },
              {
                key: 'Active Start Date',
                value: '2005-09',
              },
              {
                key: 'Active End Date',
                value: '2010',
              },
              {
                key: 'Title',
                value: 'Member of Parliament',
              },
              {
                key: 'Political Position',
                value: 'Member of Legislature',
              },
              {
                key: 'Status',
                value: 'FORMER',
              },
              {
                key: 'Level',
                value: 'PEP - National Government',
              },
            ],
            referenceDocs: [],
            sourceName: 'Member of Parliament for Unity State',
          },
        ],
        sanctionData: [],
        searchId: '08fd4036-2fa2-443e-b7e9-11a574c0784c',
        serviceProvider: 'refinitiv',
        type: 'AML',
        watchlistData: [],
      },
      systemStatus: 'VALID',
      updatedAt: '2024-06-06T08:06:46.261667Z',
    },
    {
      class: 'AML',
      createdAt: '2024-06-06T08:06:46.220474Z',
      entityId: 'a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
      manualStatus: ProcessResultManualStatusEnumAML.FALSE_POSITIVE,
      notes: {
        'aml.address.1': {
          type: 'general.string',
          value: 'Loikaw Kayah MYANMAR',
        },
        'aml.case_id': {
          type: 'result.code',
          value: 'a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
        },
        'aml.datasource.1': {
          type: 'general.string',
          value: 'World-Check Data',
        },
        'aml.datasource.2': {
          type: 'general.string',
          value: 'Narcotics Trafficking',
        },
        'aml.datasource.3': {
          type: 'general.string',
          value: 'Illegal Possession or Sale',
        },
        'aml.is_monitored_case': {
          type: 'general.bool',
          value: 'false',
        },
        'aml.search_countries': {
          type: 'general.string',
          value: 'AUS',
        },
        'aml.search_countries.matched': {
          type: 'general.string',
          value: 'MMR',
        },
        'aml.search_date_of_birth': {
          type: 'general.datetime',
          value: '1990-05-15',
        },
        'aml.search_entity_aka': {
          type: 'general.string',
          value: 'Ah Moe,ဂျိုဝါးနာ,အမိုး',
        },
        'aml.search_entity_id': {
          type: 'result.code',
          value: '5jb8fie788ex1iqhc97xkfmvs',
        },
        'aml.search_match_details': {
          type: 'general.string',
          value: 'MEDIUM',
        },
        'aml.search_match_types': {
          type: 'general.string',
          value: 'name',
        },
        'aml.search_name': {
          type: 'general.string',
          value: 'Jo',
        },
        'aml.search_name.matched': {
          type: 'general.string',
          value: '- JO WAH NA',
        },
        'aml.search_reference_id': {
          type: 'result.code',
          value: '5jb82kqu3y5k1iqhc9219jj50',
        },
        'aml.search_result.score': {
          type: 'general.float',
          value: '85.04',
        },
        'bcro.grouping_id': {
          type: 'result.code',
          value:
            '5jb82kqu3y5k1iqhc9219jj50::5jb8fie788ex1iqhc97xkfmvs::a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
        },
        category: {
          type: 'general.string',
          value: 'INDIVIDUAL',
        },
        'reference.doc.0': {
          type: 'general.string',
          value:
            'https://moha.gov.mm/index.php?option=com_content&view=article&id=2951:2018-05-15-07-04-30&catid=79&Itemid=555&lang=en',
        },
        'reference.doc.1': {
          type: 'general.string',
          value:
            'https://www.burmalibrary.org/sites/burmalibrary.org/files/obl/docs24/mal%2016.5.18.pdf',
        },
        'reference.doc.2': {
          type: 'general.string',
          value: 'https://www.tachileik.net/mm/2018/05/dclkw.html',
        },
        'status.comment': {
          type: 'general.string',
          value: 'Marked as false positive due to secondary field matches',
        },
        'status.current': {
          type: 'general.string',
          value: 'false_positive',
        },
        'status.false_positive.ignore': {
          type: 'result.code',
          value: '01HZP9BEGMQQZBB17SPBAYTMXX',
        },
        'status.manual_update': {
          type: 'result.code',
          value: '01HZP9BEGMQQZBB17SPBAYTMXX',
        },
        'status.set_by': {
          type: 'general.string',
          value: 'Refinitiv',
        },
        'status.set_date': {
          type: 'general.string',
          value: '2024-06-06T08:06:43Z',
        },
      },
      objectType: 'INDIVIDUAL',
      processResultId: '01HZP9C1PC2AC568RY77D45S55',
      providerResult: {
        confidence: '50',
        name: 'Refinitiv',
        reference: '',
        source: 'World-Check Risk Intelligence',
        sourceNormalized: 'world-check risk intelligence',
      },
      result: 'HIT',
      schemaVersion: 2,
      state: 'COMPLETED',
      stepName: 'AML',
      stepType: 'pep',
      supplementaryData: {
        fuzziness: {},
        groupingId:
          '5jb82kqu3y5k1iqhc9219jj50::5jb8fie788ex1iqhc97xkfmvs::a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
        matchData: {
          name: '- JO WAH NA',
          spReferenceId: '',
          strength: 85.04,
        },
        mediaData: [],
        pepData: [],
        sanctionData: [],
        searchId: '08fd4036-2fa2-443e-b7e9-11a574c0784c',
        serviceProvider: 'refinitiv',
        type: 'AML',
        watchlistData: [
          {
            additionalData: [
              {
                key: 'aml.is_monitored_case',
                value: 'false',
              },
              {
                key: 'aml.case_id',
                value: 'a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
              },
              {
                key: 'aml.search_reference_id',
                value: '5jb82kqu3y5k1iqhc9219jj50',
              },
              {
                key: 'status.current',
                value: 'false_positive',
              },
              {
                key: 'status.comment',
                value:
                  'Marked as false positive due to secondary field matches',
              },
              {
                key: 'status.manual_update',
                value: '01HZP9BEGMQQZBB17SPBAYTMXX',
              },
              {
                key: 'status.false_positive.ignore',
                value: '01HZP9BEGMQQZBB17SPBAYTMXX',
              },
              {
                key: 'status.set_by',
                value: 'Refinitiv',
              },
              {
                key: 'status.set_date',
                value: '2024-06-06T08:06:43Z',
              },
              {
                key: 'bcro.grouping_id',
                value:
                  '5jb82kqu3y5k1iqhc9219jj50::5jb8fie788ex1iqhc97xkfmvs::a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
              },
              {
                key: 'aml.search_entity_id',
                value: '5jb8fie788ex1iqhc97xkfmvs',
              },
              {
                key: 'aml.search_result.score',
                value: '85.04',
              },
              {
                key: 'SOURCE.name',
                value: 'World-Check Data',
              },
              {
                key: 'SOURCE.aml_types',
                value: 'Other Bodies',
              },
              {
                key: 'SOURCE.is_current',
                value: 'true',
              },
              {
                key: 'SOURCE.description',
                value:
                  'These are World-Check specific keywords. Keywords within OTHER BODIES do not represent listing in another keyword type but are provided to assist clients in identifying specific areas within the Data File.',
              },
              {
                key: 'SOURCE.country_names',
              },
            ],
            referenceDocs: [],
            sourceName: 'World-Check Data',
          },
        ],
      },
      systemStatus: 'VALID',
      updatedAt: '2024-06-06T08:06:46.220475Z',
    },
    {
      class: 'AML',
      createdAt: '2024-06-06T08:06:46.182913Z',
      entityId: 'a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
      manualStatus: ProcessResultManualStatusEnumAML.TRUE_POSITIVE,
      notes: {
        'aml.address.1': {
          type: 'general.string',
          value: 'Maungdaw Rakhine MYANMAR',
        },
        'aml.case_id': {
          type: 'result.code',
          value: 'a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
        },
        'aml.datasource.1': {
          type: 'general.string',
          value: 'World-Check Data',
        },
        'aml.datasource.2': {
          type: 'general.string',
          value: 'Terror Related',
        },
        'aml.datasource.3': {
          type: 'general.string',
          value: 'Violent Crime',
        },
        'aml.is_monitored_case': {
          type: 'general.bool',
          value: 'false',
        },
        'aml.search_countries': {
          type: 'general.string',
          value: 'AUS',
        },
        'aml.search_countries.matched': {
          type: 'general.string',
          value: 'MMR',
        },
        'aml.search_date_of_birth': {
          type: 'general.datetime',
          value: '1990-05-15',
        },
        'aml.search_entity_aka': {
          type: 'general.string',
          value: 'ဂျိုလာခါ',
        },
        'aml.search_entity_associate.affiliated_company': {
          type: 'general.string',
          value: 'AQA MUL MUJAHIDIN',
        },
        'aml.search_entity_id': {
          type: 'result.code',
          value: '5jb8fie788ex1iqhc97xkfmmk',
        },
        'aml.search_match_details': {
          type: 'general.string',
          value: 'MEDIUM',
        },
        'aml.search_match_types': {
          type: 'general.string',
          value: 'name',
        },
        'aml.search_name': {
          type: 'general.string',
          value: 'Jo',
        },
        'aml.search_name.matched': {
          type: 'general.string',
          value: '- JO LAR KHAR',
        },
        'aml.search_reference_id': {
          type: 'result.code',
          value: '5jb82kqu3y5k1iqhc9219jj50',
        },
        'aml.search_result.score': {
          type: 'general.float',
          value: '85.04',
        },
        'bcro.grouping_id': {
          type: 'result.code',
          value:
            '5jb82kqu3y5k1iqhc9219jj50::5jb8fie788ex1iqhc97xkfmmk::a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
        },
        category: {
          type: 'general.string',
          value: 'INDIVIDUAL',
        },
        'reference.doc.0': {
          type: 'general.string',
          value:
            'http://www.moi.gov.mm/npe/sites/default/files/newspaper-journal/2018/01/22/mal%2022.1.18.pdf',
        },
        'reference.doc.1': {
          type: 'general.string',
          value:
            'https://www.burmalibrary.org/sites/burmalibrary.org/files/obl/docs24/km%2022.1.18.pdf',
        },
        'status.comment': {
          type: 'general.string',
          value: 'Marked as false positive due to secondary field matches',
        },
        'status.current': {
          type: 'general.string',
          value: 'false_positive',
        },
        'status.false_positive.ignore': {
          type: 'result.code',
          value: '01HZP9BEGMQQZBB17SPBAYTMXX',
        },
        'status.manual_update': {
          type: 'result.code',
          value: '01HZP9BEGMQQZBB17SPBAYTMXX',
        },
        'status.set_by': {
          type: 'general.string',
          value: 'Refinitiv',
        },
        'status.set_date': {
          type: 'general.string',
          value: '2024-06-06T08:06:43Z',
        },
      },
      objectType: 'INDIVIDUAL',
      processResultId: '01HZP9C1N693D1F54YDD3FGVQC',
      providerResult: {
        confidence: '50',
        name: 'Refinitiv',
        reference: '',
        source: 'World-Check Risk Intelligence',
        sourceNormalized: 'world-check risk intelligence',
      },
      result: 'HIT',
      schemaVersion: 2,
      state: 'COMPLETED',
      stepName: 'AML',
      stepType: 'pep',
      supplementaryData: {
        fuzziness: {},
        groupingId:
          '5jb82kqu3y5k1iqhc9219jj50::5jb8fie788ex1iqhc97xkfmmk::a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
        matchData: {
          name: '- JO LAR KHAR',
          spReferenceId: '',
          strength: 85.04,
        },
        mediaData: [],
        pepData: [],
        sanctionData: [],
        searchId: '08fd4036-2fa2-443e-b7e9-11a574c0784c',
        serviceProvider: 'refinitiv',
        type: 'AML',
        watchlistData: [
          {
            additionalData: [
              {
                key: 'aml.is_monitored_case',
                value: 'false',
              },
              {
                key: 'aml.case_id',
                value: 'a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
              },
              {
                key: 'aml.search_reference_id',
                value: '5jb82kqu3y5k1iqhc9219jj50',
              },
              {
                key: 'status.current',
                value: 'false_positive',
              },
              {
                key: 'status.comment',
                value:
                  'Marked as false positive due to secondary field matches',
              },
              {
                key: 'status.manual_update',
                value: '01HZP9BEGMQQZBB17SPBAYTMXX',
              },
              {
                key: 'status.false_positive.ignore',
                value: '01HZP9BEGMQQZBB17SPBAYTMXX',
              },
              {
                key: 'status.set_by',
                value: 'Refinitiv',
              },
              {
                key: 'status.set_date',
                value: '2024-06-06T08:06:43Z',
              },
              {
                key: 'bcro.grouping_id',
                value:
                  '5jb82kqu3y5k1iqhc9219jj50::5jb8fie788ex1iqhc97xkfmmk::a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
              },
              {
                key: 'aml.search_entity_id',
                value: '5jb8fie788ex1iqhc97xkfmmk',
              },
              {
                key: 'aml.search_result.score',
                value: '85.04',
              },
              {
                key: 'SOURCE.name',
                value: 'World-Check Data',
              },
              {
                key: 'SOURCE.aml_types',
                value: 'Other Bodies',
              },
              {
                key: 'SOURCE.is_current',
                value: 'true',
              },
              {
                key: 'SOURCE.description',
                value:
                  'These are World-Check specific keywords. Keywords within OTHER BODIES do not represent listing in another keyword type but are provided to assist clients in identifying specific areas within the Data File.',
              },
              {
                key: 'SOURCE.country_names',
              },
            ],
            referenceDocs: [],
            sourceName: 'World-Check Data',
          },
        ],
      },
      systemStatus: 'VALID',
      updatedAt: '2024-06-06T08:06:46.182913Z',
    },
    {
      class: 'AML',
      createdAt: '2024-06-06T08:06:46.141989Z',
      entityId: 'a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
      manualStatus: ProcessResultManualStatusEnumAML.UNKNOWN,
      notes: {
        'aml.address.1': {
          type: 'general.string',
          value: 'Sderot HaDarom ISRAEL',
        },
        'aml.case_id': {
          type: 'result.code',
          value: 'a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
        },
        'aml.datasource.1': {
          type: 'general.string',
          value: 'PEP - National Government - Immediate Relative',
        },
        'aml.is_monitored_case': {
          type: 'general.bool',
          value: 'false',
        },
        'aml.search_countries': {
          type: 'general.string',
          value: 'AUS',
        },
        'aml.search_countries.matched': {
          type: 'general.string',
          value: 'ISR',
        },
        'aml.search_date_of_birth': {
          type: 'general.datetime',
          value: '1990-05-15',
        },
        'aml.search_entity_aka': {
          type: 'general.string',
          value: "ג'ו קריב",
        },
        'aml.search_entity_associate.associate': {
          type: 'general.string',
          value: 'Ahlama PERETZ',
        },
        'aml.search_entity_id': {
          type: 'result.code',
          value: '5jb8fie788ex1iqhc97xkfmso',
        },
        'aml.search_match_details': {
          type: 'general.string',
          value: 'MEDIUM',
        },
        'aml.search_match_types': {
          type: 'general.string',
          value: 'name',
        },
        'aml.search_name': {
          type: 'general.string',
          value: 'Jo',
        },
        'aml.search_name.matched': {
          type: 'general.string',
          value: 'Joe KARIB',
        },
        'aml.search_reference_id': {
          type: 'result.code',
          value: '5jb82kqu3y5k1iqhc9219jj50',
        },
        'aml.search_result.score': {
          type: 'general.float',
          value: '85.91',
        },
        'bcro.grouping_id': {
          type: 'result.code',
          value:
            '5jb82kqu3y5k1iqhc9219jj50::5jb8fie788ex1iqhc97xkfmso::a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
        },
        category: {
          type: 'general.string',
          value: 'INDIVIDUAL',
        },
        'reference.doc.0': {
          type: 'general.string',
          value:
            'https://www.mako.co.il/tv-morning-news/articles/Article-bbe185b358ccc61027.htm',
        },
        'reference.doc.1': {
          type: 'general.string',
          value: 'https://www.makorrishon.co.il/nrg/online/1/ART2/286/326.html',
        },
        'reference.doc.2': {
          type: 'general.string',
          value:
            'https://ashdodnet.com/%D7%A8%D7%9B%D7%99%D7%9C%D7%95%D7%AA-%D7%95%D7%9C%D7%99%D7%9C%D7%94/60169',
        },
      },
      objectType: 'INDIVIDUAL',
      processResultId: '01HZP9C1KXQAZJNAKANKWP2WFC',
      providerResult: {
        confidence: '50',
        name: 'Refinitiv',
        reference: '',
        source: 'World-Check Risk Intelligence',
        sourceNormalized: 'world-check risk intelligence',
      },
      result: 'HIT',
      schemaVersion: 2,
      state: 'COMPLETED',
      stepName: 'AML',
      stepType: 'pep',
      supplementaryData: {
        fuzziness: {},
        groupingId:
          '5jb82kqu3y5k1iqhc9219jj50::5jb8fie788ex1iqhc97xkfmso::a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
        matchData: {
          name: 'Joe KARIB',
          spReferenceId: '',
          strength: 85.91,
        },
        mediaData: [],
        pepData: [
          {
            additionalData: [
              {
                key: 'aml.is_monitored_case',
                value: 'false',
              },
              {
                key: 'aml.case_id',
                value: 'a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
              },
              {
                key: 'aml.search_reference_id',
                value: '5jb82kqu3y5k1iqhc9219jj50',
              },
              {
                key: 'bcro.grouping_id',
                value:
                  '5jb82kqu3y5k1iqhc9219jj50::5jb8fie788ex1iqhc97xkfmso::a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
              },
              {
                key: 'aml.search_entity_id',
                value: '5jb8fie788ex1iqhc97xkfmso',
              },
              {
                key: 'aml.search_result.score',
                value: '85.91',
              },
              {
                key: 'SOURCE.name',
                value: 'PEP - National Government - Immediate Relative',
              },
              {
                key: 'SOURCE.aml_types',
                value: 'pep',
              },
              {
                key: 'Level',
                value: 'PEP - National Government - Immediate Relative',
              },
            ],
            referenceDocs: [],
            sourceName: 'PEP - National Government - Immediate Relative',
          },
        ],
        sanctionData: [],
        searchId: '08fd4036-2fa2-443e-b7e9-11a574c0784c',
        serviceProvider: 'refinitiv',
        type: 'AML',
        watchlistData: [],
      },
      systemStatus: 'VALID',
      updatedAt: '2024-06-06T08:06:46.141989Z',
    },
    {
      class: 'AML',
      createdAt: '2024-06-06T08:06:46.107149Z',
      entityId: 'a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
      manualStatus: ProcessResultManualStatusEnumAML.UNKNOWN_ACCEPT,
      notes: {
        'aml.address.1': {
          type: 'general.string',
          value: 'Haifa Haifa District ISRAEL',
        },
        'aml.address.2': {
          type: 'general.string',
          value: 'Jericho Jericho - Al Aghwar PALESTINIAN TERRITORY, OCCUPIED',
        },
        'aml.address.3': {
          type: 'general.string',
          value: 'Tel Adashim Jezreel Valley ISRAEL',
        },
        'aml.case_id': {
          type: 'result.code',
          value: 'a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
        },
        'aml.datasource.1': {
          type: 'general.string',
          value: 'World-Check Data',
        },
        'aml.datasource.2': {
          type: 'general.string',
          value: 'Forgery and Uttering',
        },
        'aml.datasource.3': {
          type: 'general.string',
          value: 'Fraud',
        },
        'aml.is_monitored_case': {
          type: 'general.bool',
          value: 'false',
        },
        'aml.search_countries': {
          type: 'general.string',
          value: 'AUS',
        },
        'aml.search_countries.matched': {
          type: 'general.string',
          value: 'ISR,PSE',
        },
        'aml.search_date_of_birth': {
          type: 'general.datetime',
          value: '1990-05-15',
        },
        'aml.search_entity_aka': {
          type: 'general.string',
          value:
            "THOMAS Joe,UMAR Judith Judat,ג'ו תומס,גודת עבדאללה עומר,עבדאללה עומר",
        },
        'aml.search_entity_id': {
          type: 'result.code',
          value: '5jb8fie788ex1iqhc97xkfmr0',
        },
        'aml.search_match_details': {
          type: 'general.string',
          value: 'MEDIUM',
        },
        'aml.search_match_types': {
          type: 'general.string',
          value: 'name',
        },
        'aml.search_name': {
          type: 'general.string',
          value: 'Jo',
        },
        'aml.search_name.matched': {
          type: 'general.string',
          value: 'Abdallah UMAR',
        },
        'aml.search_reference_id': {
          type: 'result.code',
          value: '5jb82kqu3y5k1iqhc9219jj50',
        },
        'aml.search_result.score': {
          type: 'general.float',
          value: '87.25',
        },
        'bcro.grouping_id': {
          type: 'result.code',
          value:
            '5jb82kqu3y5k1iqhc9219jj50::5jb8fie788ex1iqhc97xkfmr0::a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
        },
        category: {
          type: 'general.string',
          value: 'INDIVIDUAL',
        },
        'reference.doc.0': {
          type: 'general.string',
          value: 'https://www.ynet.co.il/articles/0,7340,L-5342896,00.html',
        },
        'reference.doc.1': {
          type: 'general.string',
          value:
            'https://www.hakolhayehudi.co.il/item/%D7%91%D7%90%D7%A8%D7%A5_%D7%95%D7%91%D7%A2%D7%95%D7%9C%D7%9D/%D7%A2%D7%A8%D7%91%D7%99_%D7%9E%D7%99%D7%A8%D7%99%D7%97%D7%95_%D7%94%D7%AA%D7%97%D7%96%D7%94_%D7%9C%D7%90%D7%9E%D7%A8%D7%99%D7%A7%D7%90%D7%99_%D7%95%D7%92%D7%A8_15_%D7%A9%D7%A0%D7%94_%D7%91%D7%9E%D7%95%D7%A9%D7%91_%D7%91%D7%92%D7%9C%D7%99%D7%9C',
        },
        'reference.doc.2': {
          type: 'general.string',
          value: 'https://www.ynetnews.com/articles/0,7340,L-5347966,00.html',
        },
      },
      objectType: 'INDIVIDUAL',
      processResultId: '01HZP9C1JV24QQNNGAAVW968FS',
      providerResult: {
        confidence: '50',
        name: 'Refinitiv',
        reference: '',
        source: 'World-Check Risk Intelligence',
        sourceNormalized: 'world-check risk intelligence',
      },
      result: 'HIT',
      schemaVersion: 2,
      state: 'COMPLETED',
      stepName: 'AML',
      stepType: 'pep',
      supplementaryData: {
        fuzziness: {},
        groupingId:
          '5jb82kqu3y5k1iqhc9219jj50::5jb8fie788ex1iqhc97xkfmr0::a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
        matchData: {
          name: 'Abdallah UMAR',
          spReferenceId: '',
          strength: 87.25,
        },
        mediaData: [],
        pepData: [],
        sanctionData: [],
        searchId: '08fd4036-2fa2-443e-b7e9-11a574c0784c',
        serviceProvider: 'refinitiv',
        type: 'AML',
        watchlistData: [
          {
            additionalData: [
              {
                key: 'aml.is_monitored_case',
                value: 'false',
              },
              {
                key: 'aml.case_id',
                value: 'a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
              },
              {
                key: 'aml.search_reference_id',
                value: '5jb82kqu3y5k1iqhc9219jj50',
              },
              {
                key: 'bcro.grouping_id',
                value:
                  '5jb82kqu3y5k1iqhc9219jj50::5jb8fie788ex1iqhc97xkfmr0::a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
              },
              {
                key: 'aml.search_entity_id',
                value: '5jb8fie788ex1iqhc97xkfmr0',
              },
              {
                key: 'aml.search_result.score',
                value: '87.25',
              },
              {
                key: 'SOURCE.name',
                value: 'World-Check Data',
              },
              {
                key: 'SOURCE.aml_types',
                value: 'Other Bodies',
              },
              {
                key: 'SOURCE.is_current',
                value: 'true',
              },
              {
                key: 'SOURCE.description',
                value:
                  'These are World-Check specific keywords. Keywords within OTHER BODIES do not represent listing in another keyword type but are provided to assist clients in identifying specific areas within the Data File.',
              },
              {
                key: 'SOURCE.country_names',
              },
            ],
            referenceDocs: [],
            sourceName: 'World-Check Data',
          },
        ],
      },
      systemStatus: 'VALID',
      updatedAt: '2024-06-06T08:06:46.107149Z',
    },
    {
      class: 'AML',
      createdAt: '2024-06-06T08:06:46.056464Z',
      entityId: 'a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
      manualStatus: ProcessResultManualStatusEnumAML.UNKNOWN_ACCEPT,
      notes: {
        'aml.address.1': {
          type: 'general.string',
          value: 'Mandalay Mandalay MYANMAR',
        },
        'aml.case_id': {
          type: 'result.code',
          value: 'a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
        },
        'aml.datasource.1': {
          type: 'general.string',
          value: 'MYANMAR - MMPF-MW - Myanmar Police Force - Most Wanted',
        },
        'aml.datasource.2': {
          type: 'general.string',
          value: 'Absconder or Fugitive',
        },
        'aml.datasource.3': {
          type: 'general.string',
          value: 'Violent Crime',
        },
        'aml.is_monitored_case': {
          type: 'general.bool',
          value: 'false',
        },
        'aml.search_countries': {
          type: 'general.string',
          value: 'AUS',
        },
        'aml.search_countries.matched': {
          type: 'general.string',
          value: 'MMR',
        },
        'aml.search_date_of_birth': {
          type: 'general.datetime',
          value: '1990-05-15',
        },
        'aml.search_entity_aka': {
          type: 'general.string',
          value: 'Nga Jo,ငဂျိုး,ပြည့်စုံအောင်',
        },
        'aml.search_entity_associate.associate': {
          type: 'general.string',
          value: '- AYE MOE',
        },
        'aml.search_entity_id': {
          type: 'result.code',
          value: '5jb8fie788ex1iqhc97xkfmpw',
        },
        'aml.search_match_details': {
          type: 'general.string',
          value: 'MEDIUM',
        },
        'aml.search_match_types': {
          type: 'general.string',
          value: 'name',
        },
        'aml.search_name': {
          type: 'general.string',
          value: 'Jo',
        },
        'aml.search_name.matched': {
          type: 'general.string',
          value: '- PYAE SONE AUNG',
        },
        'aml.search_reference_id': {
          type: 'result.code',
          value: '5jb82kqu3y5k1iqhc9219jj50',
        },
        'aml.search_result.score': {
          type: 'general.float',
          value: '88.35',
        },
        'bcro.grouping_id': {
          type: 'result.code',
          value:
            '5jb82kqu3y5k1iqhc9219jj50::5jb8fie788ex1iqhc97xkfmpw::a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
        },
        category: {
          type: 'general.string',
          value: 'INDIVIDUAL',
        },
        'reference.doc.0': {
          type: 'general.string',
          value:
            'https://www.policeforce.gov.mm/index.php?option=com_mtree&task=viewlink&link_id=2439&Itemid=847',
        },
        'reference.doc.1': {
          type: 'general.string',
          value:
            'https://policeforce.gov.mm/index.php?option=com_mtree&task=viewlink&link_id=2439&Itemid=847',
        },
      },
      objectType: 'INDIVIDUAL',
      processResultId: '01HZP9C1H8XXEQGCCVEGZFDAKG',
      providerResult: {
        confidence: '50',
        name: 'Refinitiv',
        reference: '',
        source: 'World-Check Risk Intelligence',
        sourceNormalized: 'world-check risk intelligence',
      },
      result: 'HIT',
      schemaVersion: 2,
      state: 'COMPLETED',
      stepName: 'AML',
      stepType: 'pep',
      supplementaryData: {
        fuzziness: {},
        groupingId:
          '5jb82kqu3y5k1iqhc9219jj50::5jb8fie788ex1iqhc97xkfmpw::a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
        matchData: {
          name: '- PYAE SONE AUNG',
          spReferenceId: '',
          strength: 88.35,
        },
        mediaData: [],
        pepData: [],
        sanctionData: [],
        searchId: '08fd4036-2fa2-443e-b7e9-11a574c0784c',
        serviceProvider: 'refinitiv',
        type: 'AML',
        watchlistData: [
          {
            additionalData: [
              {
                key: 'aml.is_monitored_case',
                value: 'false',
              },
              {
                key: 'aml.case_id',
                value: 'a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
              },
              {
                key: 'aml.search_reference_id',
                value: '5jb82kqu3y5k1iqhc9219jj50',
              },
              {
                key: 'bcro.grouping_id',
                value:
                  '5jb82kqu3y5k1iqhc9219jj50::5jb8fie788ex1iqhc97xkfmpw::a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
              },
              {
                key: 'aml.search_entity_id',
                value: '5jb8fie788ex1iqhc97xkfmpw',
              },
              {
                key: 'aml.search_result.score',
                value: '88.35',
              },
              {
                key: 'SOURCE.name',
                value: 'MYANMAR - MMPF-MW - Myanmar Police Force - Most Wanted',
              },
              {
                key: 'SOURCE.aml_types',
                value: 'Law Enforcement',
              },
              {
                key: 'SOURCE.is_current',
                value: 'true',
              },
              {
                key: 'SOURCE.description',
                value:
                  'This lists details of persons appearing on an official law enforcement public domain site as either wanted, investigated, or arrested by an official law enforcement body or the police; or individuals or entities charged, prosecuted, convicted and/or sentenced by a competent criminal court in connection with a World-Check crime that constitutes a criminal act.',
              },
              {
                key: 'SOURCE.country_names',
                value: 'MYANMAR',
              },
              {
                key: 'SOURCE.country_codes',
                value: 'MMR',
              },
            ],
            countryCode: 'MMR',
            countryName: 'MYANMAR',
            referenceDocs: [],
            sourceName:
              'MYANMAR - MMPF-MW - Myanmar Police Force - Most Wanted',
          },
        ],
      },
      systemStatus: 'VALID',
      updatedAt: '2024-06-06T08:06:46.056464Z',
    },
    {
      class: 'AML',
      createdAt: '2024-06-06T08:06:46.004479Z',
      entityId: 'a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
      manualStatus: ProcessResultManualStatusEnumAML.TRUE_POSITIVE_ACCEPT,
      notes: {
        'aml.address.1': {
          type: 'general.string',
          value: 'Tachileik Shan MYANMAR',
        },
        'aml.case_id': {
          type: 'result.code',
          value: 'a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
        },
        'aml.datasource.1': {
          type: 'general.string',
          value: 'MYANMAR - MMPF-MW - Myanmar Police Force - Most Wanted',
        },
        'aml.datasource.2': {
          type: 'general.string',
          value: 'Absconder or Fugitive',
        },
        'aml.datasource.3': {
          type: 'general.string',
          value: 'Illegal Possession or Sale',
        },
        'aml.datasource.4': {
          type: 'general.string',
          value: 'Narcotics Trafficking',
        },
        'aml.is_monitored_case': {
          type: 'general.bool',
          value: 'false',
        },
        'aml.search_countries': {
          type: 'general.string',
          value: 'AUS',
        },
        'aml.search_countries.matched': {
          type: 'general.string',
          value: 'MMR',
        },
        'aml.search_date_of_birth': {
          type: 'general.datetime',
          value: '1990-05-15',
        },
        'aml.search_entity_aka': {
          type: 'general.string',
          value: 'Kaeba,ကဲဘာ,ဂျိုးဇက်',
        },
        'aml.search_entity_associate.associate': {
          type: 'general.string',
          value: '- KYAW SEIN',
        },
        'aml.search_entity_id': {
          type: 'result.code',
          value: '5jb8fie788ex1iqhc97xkfmqg',
        },
        'aml.search_match_details': {
          type: 'general.string',
          value: 'MEDIUM',
        },
        'aml.search_match_types': {
          type: 'general.string',
          value: 'name',
        },
        'aml.search_name': {
          type: 'general.string',
          value: 'Jo',
        },
        'aml.search_name.matched': {
          type: 'general.string',
          value: '- JOSEPH',
        },
        'aml.search_reference_id': {
          type: 'result.code',
          value: '5jb82kqu3y5k1iqhc9219jj50',
        },
        'aml.search_result.score': {
          type: 'general.float',
          value: '88.35',
        },
        'bcro.grouping_id': {
          type: 'result.code',
          value:
            '5jb82kqu3y5k1iqhc9219jj50::5jb8fie788ex1iqhc97xkfmqg::a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
        },
        category: {
          type: 'general.string',
          value: 'INDIVIDUAL',
        },
        'reference.doc.0': {
          type: 'general.string',
          value: 'https://news-eleven.com/article/240941',
        },
        'reference.doc.1': {
          type: 'general.string',
          value:
            'https://policeforce.gov.mm/index.php?option=com_mtree&task=viewlink&link_id=3654&Itemid=847',
        },
        'reference.doc.2': {
          type: 'general.string',
          value:
            'https://www.moi.gov.mm/nlm/sites/default/files/newspaper-pdf/2022-11/24_Nov_22_gnlm.pdf',
        },
      },
      objectType: 'INDIVIDUAL',
      processResultId: '01HZP9C1FMWE8H12H8XSFS5G4E',
      providerResult: {
        confidence: '50',
        name: 'Refinitiv',
        reference: '',
        source: 'World-Check Risk Intelligence',
        sourceNormalized: 'world-check risk intelligence',
      },
      result: 'HIT',
      schemaVersion: 2,
      state: 'COMPLETED',
      stepName: 'AML',
      stepType: 'pep',
      supplementaryData: {
        fuzziness: {},
        groupingId:
          '5jb82kqu3y5k1iqhc9219jj50::5jb8fie788ex1iqhc97xkfmqg::a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
        matchData: {
          name: '- JOSEPH',
          spReferenceId: '',
          strength: 88.35,
        },
        mediaData: [],
        pepData: [],
        sanctionData: [],
        searchId: '08fd4036-2fa2-443e-b7e9-11a574c0784c',
        serviceProvider: 'refinitiv',
        type: 'AML',
        watchlistData: [
          {
            additionalData: [
              {
                key: 'aml.is_monitored_case',
                value: 'false',
              },
              {
                key: 'aml.case_id',
                value: 'a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
              },
              {
                key: 'aml.search_reference_id',
                value: '5jb82kqu3y5k1iqhc9219jj50',
              },
              {
                key: 'bcro.grouping_id',
                value:
                  '5jb82kqu3y5k1iqhc9219jj50::5jb8fie788ex1iqhc97xkfmqg::a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
              },
              {
                key: 'aml.search_entity_id',
                value: '5jb8fie788ex1iqhc97xkfmqg',
              },
              {
                key: 'aml.search_result.score',
                value: '88.35',
              },
              {
                key: 'SOURCE.name',
                value: 'MYANMAR - MMPF-MW - Myanmar Police Force - Most Wanted',
              },
              {
                key: 'SOURCE.aml_types',
                value: 'Law Enforcement',
              },
              {
                key: 'SOURCE.is_current',
                value: 'true',
              },
              {
                key: 'SOURCE.description',
                value:
                  'This lists details of persons appearing on an official law enforcement public domain site as either wanted, investigated, or arrested by an official law enforcement body or the police; or individuals or entities charged, prosecuted, convicted and/or sentenced by a competent criminal court in connection with a World-Check crime that constitutes a criminal act.',
              },
              {
                key: 'SOURCE.country_names',
                value: 'MYANMAR',
              },
              {
                key: 'SOURCE.country_codes',
                value: 'MMR',
              },
            ],
            countryCode: 'MMR',
            countryName: 'MYANMAR',
            referenceDocs: [],
            sourceName:
              'MYANMAR - MMPF-MW - Myanmar Police Force - Most Wanted',
          },
        ],
      },
      systemStatus: 'VALID',
      updatedAt: '2024-06-06T08:06:46.004479Z',
    },
    {
      class: 'AML',
      createdAt: '2024-06-06T08:06:45.964008Z',
      entityId: 'a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
      manualStatus: ProcessResultManualStatusEnumAML.TRUE_POSITIVE_REJECT,
      notes: {
        'aml.address.1': {
          type: 'general.string',
          value: 'Khin-U Sagaing MYANMAR',
        },
        'aml.case_id': {
          type: 'result.code',
          value: 'a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
        },
        'aml.datasource.1': {
          type: 'general.string',
          value: 'MYANMAR - MMPF-MW - Myanmar Police Force - Most Wanted',
        },
        'aml.datasource.2': {
          type: 'general.string',
          value: 'Absconder or Fugitive',
        },
        'aml.datasource.3': {
          type: 'general.string',
          value: 'Theft and Embezzlement',
        },
        'aml.is_monitored_case': {
          type: 'general.bool',
          value: 'false',
        },
        'aml.search_countries': {
          type: 'general.string',
          value: 'AUS',
        },
        'aml.search_countries.matched': {
          type: 'general.string',
          value: 'MMR',
        },
        'aml.search_date_of_birth': {
          type: 'general.datetime',
          value: '1990-05-15',
        },
        'aml.search_entity_aka': {
          type: 'general.string',
          value: 'ငဂျိုး',
        },
        'aml.search_entity_id': {
          type: 'result.code',
          value: '5jb8fie788ex1iqhc97xkfmoi',
        },
        'aml.search_match_details': {
          type: 'general.string',
          value: 'MEDIUM',
        },
        'aml.search_match_types': {
          type: 'general.string',
          value: 'name',
        },
        'aml.search_name': {
          type: 'general.string',
          value: 'Jo',
        },
        'aml.search_name.matched': {
          type: 'general.string',
          value: '- NA GYO',
        },
        'aml.search_reference_id': {
          type: 'result.code',
          value: '5jb82kqu3y5k1iqhc9219jj50',
        },
        'aml.search_result.score': {
          type: 'general.float',
          value: '88.35',
        },
        'bcro.grouping_id': {
          type: 'result.code',
          value:
            '5jb82kqu3y5k1iqhc9219jj50::5jb8fie788ex1iqhc97xkfmoi::a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
        },
        category: {
          type: 'general.string',
          value: 'INDIVIDUAL',
        },
        'reference.doc.0': {
          type: 'general.string',
          value:
            'https://policeforce.gov.mm/index.php?option=com_mtree&task=viewlink&link_id=2553&Itemid=847',
        },
      },
      objectType: 'INDIVIDUAL',
      processResultId: '01HZP9C1ECA8Q8STKBEVGS974Q',
      providerResult: {
        confidence: '50',
        name: 'Refinitiv',
        reference: '',
        source: 'World-Check Risk Intelligence',
        sourceNormalized: 'world-check risk intelligence',
      },
      result: 'HIT',
      schemaVersion: 2,
      state: 'COMPLETED',
      stepName: 'AML',
      stepType: 'pep',
      supplementaryData: {
        fuzziness: {},
        groupingId:
          '5jb82kqu3y5k1iqhc9219jj50::5jb8fie788ex1iqhc97xkfmoi::a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
        matchData: {
          name: '- NA GYO',
          spReferenceId: '',
          strength: 88.35,
        },
        mediaData: [],
        pepData: [],
        sanctionData: [],
        searchId: '08fd4036-2fa2-443e-b7e9-11a574c0784c',
        serviceProvider: 'refinitiv',
        type: 'AML',
        watchlistData: [
          {
            additionalData: [
              {
                key: 'aml.is_monitored_case',
                value: 'false',
              },
              {
                key: 'aml.case_id',
                value: 'a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
              },
              {
                key: 'aml.search_reference_id',
                value: '5jb82kqu3y5k1iqhc9219jj50',
              },
              {
                key: 'bcro.grouping_id',
                value:
                  '5jb82kqu3y5k1iqhc9219jj50::5jb8fie788ex1iqhc97xkfmoi::a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
              },
              {
                key: 'aml.search_entity_id',
                value: '5jb8fie788ex1iqhc97xkfmoi',
              },
              {
                key: 'aml.search_result.score',
                value: '88.35',
              },
              {
                key: 'SOURCE.name',
                value: 'MYANMAR - MMPF-MW - Myanmar Police Force - Most Wanted',
              },
              {
                key: 'SOURCE.aml_types',
                value: 'Law Enforcement',
              },
              {
                key: 'SOURCE.is_current',
                value: 'true',
              },
              {
                key: 'SOURCE.description',
                value:
                  'This lists details of persons appearing on an official law enforcement public domain site as either wanted, investigated, or arrested by an official law enforcement body or the police; or individuals or entities charged, prosecuted, convicted and/or sentenced by a competent criminal court in connection with a World-Check crime that constitutes a criminal act.',
              },
              {
                key: 'SOURCE.country_names',
                value: 'MYANMAR',
              },
              {
                key: 'SOURCE.country_codes',
                value: 'MMR',
              },
            ],
            countryCode: 'MMR',
            countryName: 'MYANMAR',
            referenceDocs: [],
            sourceName:
              'MYANMAR - MMPF-MW - Myanmar Police Force - Most Wanted',
          },
        ],
      },
      systemStatus: 'VALID',
      updatedAt: '2024-06-06T08:06:45.964008Z',
    },
    {
      class: 'AML',
      createdAt: '2024-06-06T08:06:45.918582Z',
      entityId: 'a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
      manualStatus: ProcessResultManualStatusEnumAML.UNKNOWN_REJECT,
      notes: {
        'aml.address.1': {
          type: 'general.string',
          value: 'Pekon Shan MYANMAR',
        },
        'aml.case_id': {
          type: 'result.code',
          value: 'a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
        },
        'aml.datasource.1': {
          type: 'general.string',
          value: 'World-Check Data',
        },
        'aml.datasource.2': {
          type: 'general.string',
          value: 'Narcotics Trafficking',
        },
        'aml.datasource.3': {
          type: 'general.string',
          value: 'Illegal Possession or Sale',
        },
        'aml.is_monitored_case': {
          type: 'general.bool',
          value: 'false',
        },
        'aml.search_countries': {
          type: 'general.string',
          value: 'AUS',
        },
        'aml.search_countries.matched': {
          type: 'general.string',
          value: 'MMR',
        },
        'aml.search_date_of_birth': {
          type: 'general.datetime',
          value: '1990-05-15',
        },
        'aml.search_entity_aka': {
          type: 'general.string',
          value: 'ဂျိုလေး',
        },
        'aml.search_entity_associate.associate': {
          type: 'general.string',
          value: '- SHIT SHAR AUNG',
        },
        'aml.search_entity_id': {
          type: 'result.code',
          value: '5jb8fie788ex1iqhc97xkfmu4',
        },
        'aml.search_match_details': {
          type: 'general.string',
          value: 'MEDIUM',
        },
        'aml.search_match_types': {
          type: 'general.string',
          value: 'name',
        },
        'aml.search_name': {
          type: 'general.string',
          value: 'Jo',
        },
        'aml.search_name.matched': {
          type: 'general.string',
          value: '- JO LAY',
        },
        'aml.search_reference_id': {
          type: 'result.code',
          value: '5jb82kqu3y5k1iqhc9219jj50',
        },
        'aml.search_result.score': {
          type: 'general.float',
          value: '88.35',
        },
        'bcro.grouping_id': {
          type: 'result.code',
          value:
            '5jb82kqu3y5k1iqhc9219jj50::5jb8fie788ex1iqhc97xkfmu4::a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
        },
        category: {
          type: 'general.string',
          value: 'INDIVIDUAL',
        },
        'reference.doc.0': {
          type: 'general.string',
          value:
            'https://moha.gov.mm/index.php?option=com_content&view=article&id=5327:2019-04-27-09-36-29&catid=79&Itemid=555&lang=ar',
        },
        'reference.doc.1': {
          type: 'general.string',
          value:
            'https://www.burmalibrary.org/sites/burmalibrary.org/files/obl/mal_28.4.19.pdf',
        },
      },
      objectType: 'INDIVIDUAL',
      processResultId: '01HZP9C1CYGZ0NBAKGN8XNB4RJ',
      providerResult: {
        confidence: '50',
        name: 'Refinitiv',
        reference: '',
        source: 'World-Check Risk Intelligence',
        sourceNormalized: 'world-check risk intelligence',
      },
      result: 'HIT',
      schemaVersion: 2,
      state: 'COMPLETED',
      stepName: 'AML',
      stepType: 'pep',
      supplementaryData: {
        fuzziness: {},
        groupingId:
          '5jb82kqu3y5k1iqhc9219jj50::5jb8fie788ex1iqhc97xkfmu4::a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
        matchData: {
          name: '- JO LAY',
          spReferenceId: '',
          strength: 88.35,
        },
        mediaData: [],
        pepData: [],
        sanctionData: [],
        searchId: '08fd4036-2fa2-443e-b7e9-11a574c0784c',
        serviceProvider: 'refinitiv',
        type: 'AML',
        watchlistData: [
          {
            additionalData: [
              {
                key: 'aml.is_monitored_case',
                value: 'false',
              },
              {
                key: 'aml.case_id',
                value: 'a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
              },
              {
                key: 'aml.search_reference_id',
                value: '5jb82kqu3y5k1iqhc9219jj50',
              },
              {
                key: 'bcro.grouping_id',
                value:
                  '5jb82kqu3y5k1iqhc9219jj50::5jb8fie788ex1iqhc97xkfmu4::a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
              },
              {
                key: 'aml.search_entity_id',
                value: '5jb8fie788ex1iqhc97xkfmu4',
              },
              {
                key: 'aml.search_result.score',
                value: '88.35',
              },
              {
                key: 'SOURCE.name',
                value: 'World-Check Data',
              },
              {
                key: 'SOURCE.aml_types',
                value: 'Other Bodies',
              },
              {
                key: 'SOURCE.is_current',
                value: 'true',
              },
              {
                key: 'SOURCE.description',
                value:
                  'These are World-Check specific keywords. Keywords within OTHER BODIES do not represent listing in another keyword type but are provided to assist clients in identifying specific areas within the Data File.',
              },
              {
                key: 'SOURCE.country_names',
              },
            ],
            referenceDocs: [],
            sourceName: 'World-Check Data',
          },
        ],
      },
      systemStatus: 'VALID',
      updatedAt: '2024-06-06T08:06:45.918582Z',
    },
    {
      class: 'AML',
      createdAt: '2024-06-06T08:06:45.872309Z',
      entityId: 'a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
      manualStatus: ProcessResultManualStatusEnumAML.TRUE_POSITIVE_ACCEPT,

      notes: {
        'aml.address.1': {
          type: 'general.string',
          value: 'Khin-U Sagaing MYANMAR',
        },
        'aml.case_id': {
          type: 'result.code',
          value: 'a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
        },
        'aml.datasource.1': {
          type: 'general.string',
          value: 'MYANMAR - MMPF-MW - Myanmar Police Force - Most Wanted',
        },
        'aml.datasource.2': {
          type: 'general.string',
          value: 'Absconder or Fugitive',
        },
        'aml.datasource.3': {
          type: 'general.string',
          value: 'Theft and Embezzlement',
        },
        'aml.datasource.4': {
          type: 'general.string',
          value: 'Violent Crime',
        },
        'aml.is_monitored_case': {
          type: 'general.bool',
          value: 'false',
        },
        'aml.search_countries': {
          type: 'general.string',
          value: 'AUS',
        },
        'aml.search_countries.matched': {
          type: 'general.string',
          value: 'MMR',
        },
        'aml.search_date_of_birth': {
          type: 'general.datetime',
          value: '1990-05-15',
        },
        'aml.search_entity_aka': {
          type: 'general.string',
          value: 'ငဂျိုး',
        },
        'aml.search_entity_id': {
          type: 'result.code',
          value: '5jb8fie788ex1iqhc97xkfmt8',
        },
        'aml.search_match_details': {
          type: 'general.string',
          value: 'MEDIUM',
        },
        'aml.search_match_types': {
          type: 'general.string',
          value: 'name',
        },
        'aml.search_name': {
          type: 'general.string',
          value: 'Jo',
        },
        'aml.search_name.matched': {
          type: 'general.string',
          value: '- NGA JOE',
        },
        'aml.search_reference_id': {
          type: 'result.code',
          value: '5jb82kqu3y5k1iqhc9219jj50',
        },
        'aml.search_result.score': {
          type: 'general.float',
          value: '88.35',
        },
        'bcro.grouping_id': {
          type: 'result.code',
          value:
            '5jb82kqu3y5k1iqhc9219jj50::5jb8fie788ex1iqhc97xkfmt8::a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
        },
        category: {
          type: 'general.string',
          value: 'INDIVIDUAL',
        },
        'reference.doc.0': {
          type: 'general.string',
          value:
            'https://policeforce.gov.mm/index.php?option=com_mtree&task=viewlink&link_id=2248&Itemid=847',
        },
      },
      objectType: 'INDIVIDUAL',
      processResultId: '01HZP9C1BGNX20WTC2GGNABSXQ',
      providerResult: {
        confidence: '50',
        name: 'Refinitiv',
        reference: '',
        source: 'World-Check Risk Intelligence',
        sourceNormalized: 'world-check risk intelligence',
      },
      result: 'HIT',
      schemaVersion: 2,
      state: 'COMPLETED',
      stepName: 'AML',
      stepType: 'pep',
      supplementaryData: {
        fuzziness: {},
        groupingId:
          '5jb82kqu3y5k1iqhc9219jj50::5jb8fie788ex1iqhc97xkfmt8::a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
        matchData: {
          name: '- NGA JOE',
          spReferenceId: '',
          strength: 88.35,
        },
        mediaData: [],
        pepData: [],
        sanctionData: [],
        searchId: '08fd4036-2fa2-443e-b7e9-11a574c0784c',
        serviceProvider: 'refinitiv',
        type: 'AML',
        watchlistData: [
          {
            additionalData: [
              {
                key: 'aml.is_monitored_case',
                value: 'false',
              },
              {
                key: 'aml.case_id',
                value: 'a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
              },
              {
                key: 'aml.search_reference_id',
                value: '5jb82kqu3y5k1iqhc9219jj50',
              },
              {
                key: 'bcro.grouping_id',
                value:
                  '5jb82kqu3y5k1iqhc9219jj50::5jb8fie788ex1iqhc97xkfmt8::a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
              },
              {
                key: 'aml.search_entity_id',
                value: '5jb8fie788ex1iqhc97xkfmt8',
              },
              {
                key: 'aml.search_result.score',
                value: '88.35',
              },
              {
                key: 'SOURCE.name',
                value: 'MYANMAR - MMPF-MW - Myanmar Police Force - Most Wanted',
              },
              {
                key: 'SOURCE.aml_types',
                value: 'Law Enforcement',
              },
              {
                key: 'SOURCE.is_current',
                value: 'true',
              },
              {
                key: 'SOURCE.description',
                value:
                  'This lists details of persons appearing on an official law enforcement public domain site as either wanted, investigated, or arrested by an official law enforcement body or the police; or individuals or entities charged, prosecuted, convicted and/or sentenced by a competent criminal court in connection with a World-Check crime that constitutes a criminal act.',
              },
              {
                key: 'SOURCE.country_names',
                value: 'MYANMAR',
              },
              {
                key: 'SOURCE.country_codes',
                value: 'MMR',
              },
            ],
            countryCode: 'MMR',
            countryName: 'MYANMAR',
            referenceDocs: [],
            sourceName:
              'MYANMAR - MMPF-MW - Myanmar Police Force - Most Wanted',
          },
        ],
      },
      systemStatus: 'VALID',
      updatedAt: '2024-06-06T08:06:45.872309Z',
    },
    {
      class: 'AML',
      createdAt: '2024-06-06T08:06:45.82732Z',
      entityId: 'a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
      manualStatus: ProcessResultManualStatusEnumAML.UNKNOWN,

      notes: {
        'aml.address.1': {
          type: 'general.string',
          value: 'Kalaw Shan MYANMAR',
        },
        'aml.case_id': {
          type: 'result.code',
          value: 'a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
        },
        'aml.datasource.1': {
          type: 'general.string',
          value: 'MYANMAR - MMPF-MW - Myanmar Police Force - Most Wanted',
        },
        'aml.datasource.2': {
          type: 'general.string',
          value: 'Absconder or Fugitive',
        },
        'aml.datasource.3': {
          type: 'general.string',
          value: 'Terror Related',
        },
        'aml.is_monitored_case': {
          type: 'general.bool',
          value: 'false',
        },
        'aml.search_countries': {
          type: 'general.string',
          value: 'AUS',
        },
        'aml.search_countries.matched': {
          type: 'general.string',
          value: 'MMR',
        },
        'aml.search_date_of_birth': {
          type: 'general.datetime',
          value: '1990-05-15',
        },
        'aml.search_entity_aka': {
          type: 'general.string',
          value: 'ဂျိုးဇက်',
        },
        'aml.search_entity_id': {
          type: 'result.code',
          value: '5jb8fie788ex1iqhc97xkfmwc',
        },
        'aml.search_match_details': {
          type: 'general.string',
          value: 'MEDIUM',
        },
        'aml.search_match_types': {
          type: 'general.string',
          value: 'name',
        },
        'aml.search_name': {
          type: 'general.string',
          value: 'Jo',
        },
        'aml.search_name.matched': {
          type: 'general.string',
          value: '- JOSEPH',
        },
        'aml.search_reference_id': {
          type: 'result.code',
          value: '5jb82kqu3y5k1iqhc9219jj50',
        },
        'aml.search_result.score': {
          type: 'general.float',
          value: '88.35',
        },
        'bcro.grouping_id': {
          type: 'result.code',
          value:
            '5jb82kqu3y5k1iqhc9219jj50::5jb8fie788ex1iqhc97xkfmwc::a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
        },
        category: {
          type: 'general.string',
          value: 'INDIVIDUAL',
        },
        'reference.doc.0': {
          type: 'general.string',
          value:
            'https://policeforce.gov.mm/index.php?option=com_mtree&task=viewlink&link_id=4618&Itemid=847',
        },
      },
      objectType: 'INDIVIDUAL',
      processResultId: '01HZP9C1A3AQPBJKFQX4TFE0W4',
      providerResult: {
        confidence: '50',
        name: 'Refinitiv',
        reference: '',
        source: 'World-Check Risk Intelligence',
        sourceNormalized: 'world-check risk intelligence',
      },
      result: 'HIT',
      schemaVersion: 2,
      state: 'COMPLETED',
      stepName: 'AML',
      stepType: 'pep',
      supplementaryData: {
        fuzziness: {},
        groupingId:
          '5jb82kqu3y5k1iqhc9219jj50::5jb8fie788ex1iqhc97xkfmwc::a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
        matchData: {
          name: '- JOSEPH',
          spReferenceId: '',
          strength: 88.35,
        },
        mediaData: [],
        pepData: [],
        sanctionData: [],
        searchId: '08fd4036-2fa2-443e-b7e9-11a574c0784c',
        serviceProvider: 'refinitiv',
        type: 'AML',
        watchlistData: [
          {
            additionalData: [
              {
                key: 'aml.is_monitored_case',
                value: 'false',
              },
              {
                key: 'aml.case_id',
                value: 'a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
              },
              {
                key: 'aml.search_reference_id',
                value: '5jb82kqu3y5k1iqhc9219jj50',
              },
              {
                key: 'bcro.grouping_id',
                value:
                  '5jb82kqu3y5k1iqhc9219jj50::5jb8fie788ex1iqhc97xkfmwc::a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
              },
              {
                key: 'aml.search_entity_id',
                value: '5jb8fie788ex1iqhc97xkfmwc',
              },
              {
                key: 'aml.search_result.score',
                value: '88.35',
              },
              {
                key: 'SOURCE.name',
                value: 'MYANMAR - MMPF-MW - Myanmar Police Force - Most Wanted',
              },
              {
                key: 'SOURCE.aml_types',
                value: 'Law Enforcement',
              },
              {
                key: 'SOURCE.is_current',
                value: 'true',
              },
              {
                key: 'SOURCE.description',
                value:
                  'This lists details of persons appearing on an official law enforcement public domain site as either wanted, investigated, or arrested by an official law enforcement body or the police; or individuals or entities charged, prosecuted, convicted and/or sentenced by a competent criminal court in connection with a World-Check crime that constitutes a criminal act.',
              },
              {
                key: 'SOURCE.country_names',
                value: 'MYANMAR',
              },
              {
                key: 'SOURCE.country_codes',
                value: 'MMR',
              },
            ],
            countryCode: 'MMR',
            countryName: 'MYANMAR',
            referenceDocs: [],
            sourceName:
              'MYANMAR - MMPF-MW - Myanmar Police Force - Most Wanted',
          },
        ],
      },
      systemStatus: 'VALID',
      updatedAt: '2024-06-06T08:06:45.82732Z',
    },
    {
      class: 'AML',
      createdAt: '2024-06-06T08:06:45.789325Z',
      entityId: 'a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
      manualStatus: ProcessResultManualStatusEnumAML.FALSE_POSITIVE,

      notes: {
        'aml.address.1': {
          type: 'general.string',
          value: 'North Dagon Yangon MYANMAR',
        },
        'aml.case_id': {
          type: 'result.code',
          value: 'a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
        },
        'aml.datasource.1': {
          type: 'general.string',
          value: 'World-Check Data',
        },
        'aml.datasource.2': {
          type: 'general.string',
          value: 'Narcotics Trafficking',
        },
        'aml.datasource.3': {
          type: 'general.string',
          value: 'Illegal Possession or Sale',
        },
        'aml.is_monitored_case': {
          type: 'general.bool',
          value: 'false',
        },
        'aml.search_countries': {
          type: 'general.string',
          value: 'AUS',
        },
        'aml.search_countries.matched': {
          type: 'general.string',
          value: 'MMR',
        },
        'aml.search_date_of_birth': {
          type: 'general.datetime',
          value: '1990-05-15',
        },
        'aml.search_date_of_birth.matched': {
          type: 'general.datetime',
          value: '1990',
        },
        'aml.search_entity_aka': {
          type: 'general.string',
          value: 'Joseph,ဂျိုးဇက်,မာန်ချင့်ပေါင်',
        },
        'aml.search_entity_id': {
          type: 'result.code',
          value: '5jb8fie788ex1iqhc97xkfmts',
        },
        'aml.search_match_details': {
          type: 'general.string',
          value: 'MEDIUM',
        },
        'aml.search_match_types': {
          type: 'general.string',
          value: 'name',
        },
        'aml.search_name': {
          type: 'general.string',
          value: 'Jo',
        },
        'aml.search_name.matched': {
          type: 'general.string',
          value: '- MAN CHIN PAUNG',
        },
        'aml.search_reference_id': {
          type: 'result.code',
          value: '5jb82kqu3y5k1iqhc9219jj50',
        },
        'aml.search_result.score': {
          type: 'general.float',
          value: '88.35',
        },
        'bcro.grouping_id': {
          type: 'result.code',
          value:
            '5jb82kqu3y5k1iqhc9219jj50::5jb8fie788ex1iqhc97xkfmts::a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
        },
        category: {
          type: 'general.string',
          value: 'INDIVIDUAL',
        },
        'reference.doc.0': {
          type: 'general.string',
          value:
            'https://www.moi.gov.mm/npe/sites/default/files/newspaper-journal/2019/12/16/16_Dec_19_gnlm.pdf',
        },
        'reference.doc.1': {
          type: 'general.string',
          value:
            'https://www.moi.gov.mm/npe/sites/default/files/newspaper-journal/2019/12/16/mal%2016.12.19.pdf',
        },
        'reference.doc.2': {
          type: 'general.string',
          value:
            'https://moha.gov.mm/index.php?option=com_content&view=article&id=6908:2019-12-15-07-32-47&catid=79&Itemid=555&lang=ar',
        },
      },
      objectType: 'INDIVIDUAL',
      processResultId: '01HZP9C18XR7KMGECRRYTBWPHP',
      providerResult: {
        confidence: '50',
        name: 'Refinitiv',
        reference: '',
        source: 'World-Check Risk Intelligence',
        sourceNormalized: 'world-check risk intelligence',
      },
      result: 'HIT',
      schemaVersion: 2,
      state: 'COMPLETED',
      stepName: 'AML',
      stepType: 'pep',
      supplementaryData: {
        fuzziness: {},
        groupingId:
          '5jb82kqu3y5k1iqhc9219jj50::5jb8fie788ex1iqhc97xkfmts::a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
        matchData: {
          date: '1990',
          name: '- MAN CHIN PAUNG',
          spReferenceId: '',
          strength: 88.35,
        },
        mediaData: [],
        pepData: [],
        sanctionData: [],
        searchId: '08fd4036-2fa2-443e-b7e9-11a574c0784c',
        serviceProvider: 'refinitiv',
        type: 'AML',
        watchlistData: [
          {
            additionalData: [
              {
                key: 'aml.is_monitored_case',
                value: 'false',
              },
              {
                key: 'aml.case_id',
                value: 'a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
              },
              {
                key: 'aml.search_reference_id',
                value: '5jb82kqu3y5k1iqhc9219jj50',
              },
              {
                key: 'bcro.grouping_id',
                value:
                  '5jb82kqu3y5k1iqhc9219jj50::5jb8fie788ex1iqhc97xkfmts::a5203c91-c6c3-4fd7-9b5c-d02cee353d3a',
              },
              {
                key: 'aml.search_entity_id',
                value: '5jb8fie788ex1iqhc97xkfmts',
              },
              {
                key: 'aml.search_result.score',
                value: '88.35',
              },
              {
                key: 'SOURCE.name',
                value: 'World-Check Data',
              },
              {
                key: 'SOURCE.aml_types',
                value: 'Other Bodies',
              },
              {
                key: 'SOURCE.is_current',
                value: 'true',
              },
              {
                key: 'SOURCE.description',
                value:
                  'These are World-Check specific keywords. Keywords within OTHER BODIES do not represent listing in another keyword type but are provided to assist clients in identifying specific areas within the Data File.',
              },
              {
                key: 'SOURCE.country_names',
              },
            ],
            referenceDocs: [],
            sourceName: 'World-Check Data',
          },
        ],
      },
      systemStatus: 'VALID',
      updatedAt: '2024-06-06T08:06:45.789325Z',
    },
  ],
  requestId: '01J28PSRWP43F3EMQ5W5ADJTJC',
} as ProcessResultResponse
