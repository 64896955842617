export const individualProfileF2En = {
  frankieId: 'Frankie Id: ',
  title: 'Create New Profile',
  profileLoading: 'Loading profile details',
  hasBeenUpdated: '{{fullName}} profile has been updated.',
  hasBeenCreated: 'New Profile {{fullName}} has been created.',
  runningChecks: 'Running checks...',
  savingChanges: 'Saving changes...',
  profileCheckError:
    'Checks could not be completed at this time. Please contact support.',
  saveOnlyError:
    'Entity could not be created at this time. Please contact support.',
  saveOnlyErrorUpdate:
    'Entity could not be updated at this time. Please contact support.',
  plusIconTestId: 'create-icon-test-id',
  individual: 'Individual',
  business: 'Business',
  personalInfo: 'Personal Info',
  residentialAddress: 'Residential Address',
  RESIDENTIAL1: 'Residential address',
  RESIDENTIAL2: 'Previous Residential Address',
  POSTAL: 'Mailing Address',
  currentAddress: 'Current Residential Address',
  cannotBePOBox: 'Cannot be a PO Box',
  workflow: 'Workflow',
  addAddressManually: 'Add address manually',
  suggestions: 'Suggestions',
  noSearchResults: 'No results found',
  previousResidentialAddress: 'Previous Residential Address',
  mailingAddress: 'Mailing Address',
  removeAddress: 'Remove Address',
  saveVerify: 'Save & verify',
  saveChanges: 'Save changes',
  saveApprove: 'Save & Approve',
  optional: '(optional)',
  recipeToolTip:
    'A recipe is a set of rules and checks that will be run on this individual.',

  profileForm: {
    customerId: 'Customer ID',
    name: 'Name',
    optional: '(optional)',
    firstName: 'Given Name',
    lastName: 'Family Name',
    middleName: 'Middle Name',
    dob: 'Date of Birth',
    dobPlaceholder: 'DD-MM-YYYY',
    phoneNumber: 'Phone Number',
    phoneNumberPlaceholder: 'eg: 0400 000 000',
    email: 'Email Address',
    emailPlaceholder: 'eg: example@email.com',
    country: 'Country',
    searchAddress: 'Type in an address',
    errors: {
      firstName: 'Please enter the entity’s given name',
      lastName: 'Please enter the entity’s family name',
      dob: 'Please enter the entity’s date of birth',
      email: 'Please enter a valid email address',
      workflow:
        'A workflow is a set of checks that will be run on this entity.',
      validDob: 'Please enter a valid date of birth',
      validDate: 'Please enter a valid date of expiry',
    },
  },

  addressForm: {
    RESIDENTIAL1: 'residential address',
    RESIDENTIAL2: 'previous residential address',
    POSTAL: 'mailing address',
    addAddressManually: 'Add address manually',
    addAnotherAddress: 'Add another address',
    optional: '(optional)', // Remove
    addressId: 'Address ID',
    propertyName: 'Property Name',
    propertyNamePlaceholder: 'Property Name',
    unitNumberPlaceholder: 'eg. 2',
    streetNumberPlaceholder: 'eg. 13-42',
    streetName: 'Street Name',
    streetNamePlaceholder: 'eg. Hastings Street',
    streetType: 'Street Type',
    search: 'Search',
    streetTypePlaceholder: 'Street Type',
    suburb: 'Suburb',
    suburbPlaceholder: 'Suburb',
    townCity: 'Town/City',
    town: 'Town',
    city: 'City',
    suburbLocality: 'Suburb/Locality',
    state: 'State',
    provinceTerritory: 'Province/Territory',
    stateTerritory: 'State or territory',
    zipCode: 'ZipCode',
    stateOrTerritory: 'State or Territory',
    stateOrTerritoryPlaceholder: 'State or Territory',
    postalCodePlaceholder: 'Postal Code',
    detailAddressHeading: {
      empty: 'Add address manually',
      filled: 'Check the address has been input into the appropriate fields',
    },
    sameAsCurrent: 'Same as current residential address',
    suburbError: 'Please enter the address suburb or town',
    streetNumberError: 'Please enter a street number',
    streetNameError:
      'Please enter the entity’s street name, excluding street number',
    stateError: 'Please select State or Territory',
    useAddressSearch: 'Use address search',
    enterAddressManually: 'Enter address manually',
    sourceId: 'sourceId',
    type: 'type',
    typeDescription: 'typeDescription',
    format: 'format',
    validFrom: 'validFrom',
    validTo: 'validTo',
    longForm: 'longForm',
    unstructuredLongForm: '',
    unitNumber: 'Unit No. (optional)',
    buildingName: 'Property Name (optional)',
    streetNumber: 'Street Number',
    neighborhood: 'neighborhood',
    locality: 'Suburb / Town',
    district: 'District',
    subdivision: 'State / Territory',
    country: 'Country',
    postalCode: 'Postcode',
    careOf: 'careOf',
    status: 'status',
  },

  manualKyc: {
    mKycRequirement:
      'Please provide one of the following combinations of documents to fulfill <0>manual KYC requirements:</0>',
    docTypeReq1: 'At least <0>1 Primary Photographic</0> ID document',
    docTypeReq2: 'At least <0>2 Primary Non-photographic</0> ID documents',
    docTypeReq3:
      '<0>1 Primary Non-photographic</0> ID Document & <0>1 Secondary</0> ID Document',
    category: {
      primary: 'Primary Photographic',
      nonPrimary: 'Primary Non Photographic',
      secondary: 'Secondary',
    },
  },

  documentForm: {
    title: 'Identity documents',
    heading: 'ID Document',
    remove: 'Remove',
    deleteDocument: 'Delete Document',
    delete: 'Delete',
    cancel: 'Cancel',
    confirmRemove: 'Are you sure you want to delete this {{idType}} document?',
    mKyc: 'mKYC',
    eKyc: 'eKYC',
    idTypeError: 'Please select an identity document type',
    sizeError:
      'File size exceeds the maximum limit. Please upload a file less than {{sizeInMb}}MB.',
    noUpload: 'Please upload a supporting file',
    myKyc:
      'Please provide at least one Primary Photographic document to fulfill <strong> manual KYC requirement <strong>',
    idType: 'ID Type',
    cardNumber: 'Card Number',
    medicareCardNumber: 'Medicare card number',
    cardNumberPlaceholder: "driver's Licence card Number",
    idTypePlaceholder: 'Select ID Type',
    country: 'Country',
    addAnother: 'Add another identity document',
    state: 'State / Territory',
    statePlaceholder: 'State',
    passportNumber: 'Passport Number',
    passportNumberPlaceholder: 'e.g. A1234567890',
    docCardNumber: 'Document/Card number',
    licenseNumber: 'Licence Number',
    licenseNumberPlaceholder: "driver's Licence Number",
    number: 'Card Number',
    numberPlaceholder: 'e.g. 1234 56789 1',
    marriageDate: 'Marriage Date',
    stateOfRegistration: 'State or territory of registration',
    stateOfRegistrationPlaceholder: 'State or territory',
    registrationNumber: 'Registration Number',
    registrationDate: 'Registration Date',
    registrationDatePlaceholder: 'YYYY',
    registeredName: 'Registered Name',
    firstGiveName: 'First Given Name',
    otherGivenName: 'Other Given Name ',
    familyName: 'Family Name',
    description: 'Description',
    positionOnCard: 'Position on Card',
    positionOnCardPlaceholder: 'e.g. 1',
    cardColor: 'Card Colour',
    dateOfExpiry: 'Date Of Expiry',
    medicareDateOfExpiry: 'medicare Date Of Expiry',
    dateOfExpiryPlaceholder: 'MM-YYYY',
    displayMiddleName: 'How is middle name displayed on the card?',
    nameOnCard: 'Display Name on Card',
    nameAsDisplay: 'Name as displayed on card',
    fullMiddleName: 'Full middle name',
    noMiddleName: 'No middle name',
    middleNameInitial: 'Initial only',
    stockNumber: 'Stock Number',
    stockNumberPlaceholder: 'Number',
    acquisitionDate: 'Date Of Acquisition',
    datePlaceholder: 'DD-MM-YYYY',
    primaryCountry: 'Primary Country',
    secondaryCountry: 'Secondary Country',
    partnerOne: 'Partner One (Brides) Name',
    partnerTwo: 'Partner Two (Grooms) Name',
    idNumber: 'ID Number',
    idSubType: 'ID SubType',
    documentCategory: 'Document Category',
    documentNumber: 'Document/Card Number',
    documentNumberPlaceholder: 'Document/Card Number',
    registrationDistrict: 'Registration District',
    certificateNumber: 'Certificate Number',
    dateOfPrint: 'Date of Print',
    placeOfBirth: 'Place of Birth',
    multipleLineLabel:
      'Does name appear across more than one line on the card?',
    yes: 'Yes',
    no: 'No',
    medicare: {
      skipError: 'Name line must not be skipped',
    },
  },

  idType: {
    passport: 'Passport',
    driverLicense: "Driver's Licence",
    visa: 'Visa',
    immigration: 'Immigration',
    birthCertificate: 'Birth Certificate',
    medicareCard: 'Medicare Card',
    citizenshipCertificate: 'Citizenship Certificate',
    changeOfNameCert: 'Change Of Name Certificate',
    marriageCertificate: 'Marriage Certificate',
    motorVehicleRegistration: 'Motor Vehicle Registration',
    nationalID: 'National Id',
    taxId: 'Tax ID',
    voterId: 'Voter ID',
    panCard: 'Pan Card',
    other: 'Other',
    bankStatement: 'Bank Card',
  },

  additionalText: {
    dni: '(DNI)',
    cpf: '(CPF)',
    curp: '(CURP/RFC)',
    smartId: '(Smart ID)',
    hongKongId: 'Hong Kong ID',
    epic: '/EPIC',
  },

  consentForm: {
    title: 'Consent',
    eKyc: 'You have obtained consent from your customer to the collection, use and disclosure of their personal information in accordance with your privacy policy, and for the purposes of verifying their identity, they consent to: (a) the verification of their personal information with a credit bureau header files (for verification only); (b) against records held by official document issuers or official record holders via third party systems; and c) your verification agent(s) acting as a nominated intermediary in accordance with Australian Privacy Principles. They consent to the use by third parties of the results of any verification checks on their identity for the purposes of monitoring and improving the verification services.',
    mKyc: 'You attest that you have sighted the customer identity documents and you have uploaded true copies of the sighted documents for manual KYC verification.',
    general: {
      mKyc: 'You have obtained consent from your customer to the collection, use and disclosure of their personal information being checked in connection with a request to verify their identity.',
      eKyc: 'You attest that you have sighted the customer identity documents and you have uploaded true copies of the sighted documents for manual KYC ',
      title: ' General Consent.',
    },
    docs: {
      mKyc: 'You have obtained consent from your customer to the collection, use and disclosure of their personal information being checked with the document issuer or official record holder via third-party systems; in connection with a request to verify their identity.',
      title: ' Government ID.',
    },
    creditHeader: {
      mKyc: 'You have obtained consent from your customer to the collection, use and disclosure of their personal information being disclosed to a credit reporting agency in connection with a request to verify their identity.',
      title: ' Credit Header.',
    },
    comment: 'Comments',
    addYourComment: 'Add your comments here',
  },

  forceCheck: 'Force all checks to be re-run regardless of previous results',
  errors: {
    minLength: '{{label}} should be at least {{minimum}} characters',
    maxLength: '{{label}} should be less than {{maximum}} characters',
    minLengthMust: '{{label}} must be at least {{minimum}} characters',
    maxLengthMust: '{{label}} must not exceed {{maximum}} characters',
    minLengthMustNum: '{{label}} must be at least {{minimum}} digits',
    maxLengthMustNum: '{{label}} must not exceed {{maximum}} digits',
    maxCharacter: 'Max {{maximum}} characters',
    required: 'Please enter the {{label}}',
    requiredEntity: "Please enter the entity's {{label}}",
    select: 'Please select the {{label}}',
  },
}
