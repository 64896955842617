import { Nullable, PartialRecord, aliasKeys } from 'shared/typescript'

const STATE_FAILED = 'failed'
const STATE_PASSED = 'passed'
const STATE_MANUAL_FAILED = 'fail_manual'
const STATE_MANUAL_PASSED = 'pass_manual'
const STATE_NEEDS_ATTENTION = 'refer'
const STATE_WAITING_ON_CUSTOMER = 'wait'
const STATE_UNCHECKED = 'unchecked'

type StateBackend = {
  type: Nullable<string>
  label: Nullable<string>
  key: Nullable<string>
}

export function mappedBackendState(state: string | object): StateBackend {
  if (typeof state !== 'string') {
    return state as StateBackend
  }
  if (state) {
    const frontend: PartialRecord<string, { type: string; label: string }> = {
      ...aliasKeys(
        ['pass', 'passed', 'checked_success', 'checked_success_clear'],
        {
          type: STATE_PASSED,
          label: 'Passed',
        },
      ),
      ...aliasKeys(
        [
          'review',
          'refer',
          'checked_partial_success',
          'checked_success_with_notes',
        ],
        {
          type: STATE_NEEDS_ATTENTION,
          label: 'Refer',
        },
      ),
      ...aliasKeys(
        [
          'no_match',
          'fail',
          'failed',
          'check_stopped',
          'checked_failed',
          'not_supported',
          'unprocessable',
          'checking',
        ],
        { type: STATE_FAILED, label: 'Failed' },
      ),
      ...aliasKeys(['wait', 'waiting'], {
        type: STATE_WAITING_ON_CUSTOMER,
        label: 'Waiting',
      }),
      ...aliasKeys(['fail_manual', 'manual_fail'], {
        type: STATE_MANUAL_FAILED,
        label: 'Manually Failed',
      }),
      ...aliasKeys(['pass_manual', 'manual_pass'], {
        type: STATE_MANUAL_PASSED,
        label: 'Manually Failed',
      }),
      unchecked: { type: STATE_UNCHECKED, label: 'Unchecked' },
    }
    return {
      ...(frontend[state.toLowerCase()] ?? { type: state, label: state }),
      key: state.toLowerCase(),
    }
  }
  return { type: null, label: null, key: null }
}
