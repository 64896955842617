import { useCallback } from 'react'

import { useQuery, useQueryClient } from '@tanstack/react-query'

export const AppStateQueryKey = ['app-state-query-key']

export type AppState = {
  isSidebarOpen: boolean
}
export const useGlobalAppState = () => {
  const queryClient = useQueryClient()
  const { data } = useQuery({
    initialData: { isSidebarOpen: false },
    queryKey: AppStateQueryKey,
    queryFn: () => queryClient.getQueryData<AppState>(AppStateQueryKey),
  })

  const updateAppState = useCallback(
    (newState: Partial<AppState>) => {
      // update the app state
      queryClient.setQueryData<AppState>(
        AppStateQueryKey,
        (oldData?: AppState) =>
          oldData
            ? {
                ...oldData,
                ...newState,
              }
            : oldData,
      )
    },
    [queryClient],
  )

  return { data, updateAppState }
}
