import { useCallback, useMemo } from 'react'

import { useMutation } from '@tanstack/react-query'

import { ApplicantId } from 'entities/applicant'

import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'

import { applicantBusinessOwnershipApi } from '../../api/applicant-business-ownership.api'
import { APPLICANT_BUSINESS_OWNERSHIP_KEY } from '../../applicant-business-ownership.key'
import { applicantBusinessOwnershipEn } from '../../locale/applicant-business-ownership.en'
import {
  AssociateNewPartiesPayload,
  AssociatePartiesPayload,
  HidePartyFromSearch,
} from '../../model/associate-party.model'
import { useApplicantBusinessOwnershipQuery } from '../applicant-business-ownership-query/applicant-business-ownership.query'

export type AssociationType = 'new' | 'existing' | 'updating' | 'remove'

type Args<TType extends AssociationType> = {
  applicantId: ApplicantId
  associationType: TType
  onSuccessCallback?: () => void
}

type MainData = {
  mainData: AssociatePartiesPayload
}

type PartyData = {
  partyData:
    | AssociateNewPartiesPayload<'BUSINESS'>
    | AssociateNewPartiesPayload<'INDIVIDUAL'>
}

type EntityIdArg = {
  entityId: ApplicantId
}

export const useApplicantAssociateBusinessMutation = <
  TType extends AssociationType,
>({
  applicantId,
  associationType,
  onSuccessCallback,
}: Args<TType>) => {
  const t = useI18n([APPLICANT_BUSINESS_OWNERSHIP_KEY], {
    keys: applicantBusinessOwnershipEn,
  })

  const { refetch: refetchBusinessOwnership } =
    useApplicantBusinessOwnershipQuery({ applicantId })

  const [successMsg, errorMsg] = useMemo(() => {
    const successMsgMap = {
      new: t('success.createAssociatedParty'),
      existing: t('success.addAssociatedParty'),
      updating: t('success.editAssociatedParty'),
      remove: t('success.removeAssociatedParty'),
    }

    const errorMsgMap = {
      new: t('error.createAssociatedParty'),
      existing: t('error.addAssociatedParty'),
      updating: t('error.editAssociatedParty'),
      remove: t('error.removeAssociatedParty'),
    }

    return [successMsgMap[associationType], errorMsgMap[associationType]]
  }, [associationType, t])

  const onSuccess = useCallback(() => {
    if (!onSuccessCallback) {
      void refetchBusinessOwnership()
      notification.success(successMsg)
    } else {
      void onSuccessCallback()
    }
  }, [refetchBusinessOwnership, successMsg, onSuccessCallback])

  const onError = useCallback(() => {
    notification.error(errorMsg)
  }, [errorMsg])

  const removeAssociatedPartyMutation = useMutation({
    mutationFn: ({ entityId }: EntityIdArg) =>
      applicantBusinessOwnershipApi.removeAssociatedParty(
        applicantId,
        entityId,
      ),
    onSuccess,
    onError,
  })

  const updateAssociatedPartyMutation = useMutation({
    mutationFn: ({ entityId, mainData }: MainData & EntityIdArg) =>
      applicantBusinessOwnershipApi.updateAssociatedParty(
        applicantId,
        mainData,
        entityId,
      ),
    onSuccess,
    onError,
  })

  const associateExistingPartyMutation = useMutation({
    mutationFn: ({
      entityId,
      mainData,
      hidePartyFromSearch,
    }: MainData & EntityIdArg & HidePartyFromSearch) =>
      applicantBusinessOwnershipApi.associateExistingParty(
        applicantId,
        { ...mainData, hidePartyFromSearch },
        entityId,
      ),
    onSuccess,
    onError,
  })

  const associateNewPartyMutation = useMutation({
    mutationFn: ({
      mainData,
      partyData,
      hidePartyFromSearch,
    }: PartyData & MainData & HidePartyFromSearch) =>
      applicantBusinessOwnershipApi.associateNewParty(applicantId, {
        hidePartyFromSearch,
        ...mainData,
        ...partyData,
      }),
    onSuccess,
    onError,
  })

  const mutate = useMemo(() => {
    const mutationMap = {
      new: associateNewPartyMutation,
      existing: associateExistingPartyMutation,
      updating: updateAssociatedPartyMutation,
      remove: removeAssociatedPartyMutation,
    }

    return mutationMap[associationType]
  }, [
    associateExistingPartyMutation,
    associateNewPartyMutation,
    removeAssociatedPartyMutation,
    updateAssociatedPartyMutation,
    associationType,
  ])

  return mutate
}
