import React from 'react'

import { useI18n } from 'shared/i18n'

import { INDIVIDUAL_AML_SCREENING_KEY } from '../../individual-aml-screening.key'
import { individualAmlScreeningEn } from '../../locale/individual-aml-screening.en'

export function IndividualAmlScreeningSummary() {
  const t = useI18n(INDIVIDUAL_AML_SCREENING_KEY, {
    keys: individualAmlScreeningEn,
  })

  return (
    <>
      <div className="flex flex-col gap-2">
        <div className="font-semibold  text-md text-tertiary-grey-800 leading-[24px] text-left">
          {t('amlSearch')}
        </div>
        <div className="flex gap-y-2 flex-wrap">
          <div className="grow min-w-[150px] basis-1/4">
            <div className="text-sm text-tertiary-grey-800 font-medium">
              {t('name')}
            </div>
            <div className="text-sm text-tertiary-grey-700 font-normal">
              Jane Citizen
            </div>
          </div>
          <div className="grow min-w-[150px] basis-1/4">
            <div className="text-sm text-tertiary-grey-800 font-medium">
              {t('yearOfBirth')}
            </div>
            <div className="text-sm text-tertiary-grey-700 font-normal">
              1990
            </div>
          </div>
          <div className="grow min-w-[150px] basis-1/4">
            <div className="text-sm text-tertiary-grey-800 font-medium">
              {t('residence')}
            </div>
            <div className="text-sm text-tertiary-grey-700 font-normal">AU</div>
          </div>
          <div className="grow min-w-[150px] basis-1/4">
            <div className="text-sm text-tertiary-grey-800 font-medium">
              {t('fuzziness')}
            </div>
            <div className="text-sm text-tertiary-grey-700 font-normal">
              10%
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <div className="font-semibold text-md text-tertiary-grey-800 leading-[24px] text-left">
          {t('matchSummary')}
        </div>
        <div className="flex flex-wrap">
          <div className="grow min-w-[150px] basis-1/4">
            <div className="text-sm text-tertiary-grey-800 font-medium">
              {t('potentialMatch')}
            </div>
            <div className="text-xl text-tertiary-grey-700 font-semibold">
              0
            </div>
          </div>
          <div className="grow min-w-[150px] basis-1/4">
            <div className="text-sm text-tertiary-grey-800 font-medium">
              {t('unknown')}
            </div>
            <div className="text-xl text-tertiary-grey-700 font-semibold">
              0
            </div>
          </div>
          <div className="grow min-w-[150px] basis-1/4">
            <div className="text-sm text-tertiary-grey-800 font-medium">
              {t('truePositive')}
            </div>
            <div className="text-xl text-tertiary-grey-700 font-semibold">
              0
            </div>
          </div>
          <div className="grow min-w-[150px] basis-1/4">
            <div className="text-sm text-tertiary-grey-800 font-medium">
              {t('falsePositive')}
            </div>
            <div className="text-xl text-tertiary-grey-700 font-semibold">
              1
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
