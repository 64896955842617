import { useEffect, useState } from 'react'

import { AuthType, AuthStatus, init } from '@thoughtspot/visual-embed-sdk'

import { useSessionQuery } from 'entities/session'

import { bffClient } from 'shared/client'

/**
 * ThoughtSpot SDK initialization hook.
 * resetCachedAuthToken() together with the init() function can be used to clear the cached auth token.
 */
export function useThoughSpot() {
  const [status, setStatus] = useState<'success' | 'failure' | 'idle'>('idle')

  const { data } = useSessionQuery()
  const thoughtSpotConfig = data?.features.embeddableDashboard?.thoughtSpot

  const thoughtSpotHost = thoughtSpotConfig?.hostUrl ?? ''
  const username = thoughtSpotConfig?.username ?? data?.user.username

  const initTs = () => {
    const authStatus = init({
      thoughtSpotHost,
      authType: AuthType.TrustedAuthToken,
      getAuthToken: () =>
        bffClient
          .get<{ token: string }>('/data/v1/ts-token')
          .then(data => data.data.token),
      username,
      autoLogin: true,
    })

    authStatus.on(AuthStatus.SUCCESS, () => {
      console.log('TS::SUCCESS')
      setStatus('success')
    })

    authStatus.on(AuthStatus.SDK_SUCCESS, () => {
      console.log('TS::SDK_SUCCESS')
      setStatus('success')
    })

    authStatus.on(AuthStatus.FAILURE, () => {
      console.log('TS::FAILURE')
      setStatus('failure')
    })
  }

  useEffect(() => {
    initTs()
  }, [])

  return {
    isSuccess: status === 'success',
    isError: status === 'failure',
    isLoading: status === 'idle',
    status,
  }
}
