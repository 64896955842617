import React, { useEffect, useMemo } from 'react'

import { useForm } from 'react-hook-form'
import { Trans } from 'react-i18next'

import { FrankieButton, FrankieStepper } from 'frankify/src'

import { countryPhoneCodeList } from 'entities/country'
import { FlowIdTypes } from 'entities/entity'

import { externalLinks } from 'shared/external-links'
import {
  ConfirmationFormField,
  PhoneFormField,
  SelectFormField,
  TextAreaFormField,
} from 'shared/form'
import { useI18n } from 'shared/i18n'
import { useOverlay } from 'shared/overlay'

import { IndividualSendVerificationLinkActions } from './individual-send-verification-link-actions/individual-send-verification-link-actions'
import { INDIVIDUAL_SEND_VERIFICATION_LINK_KEY } from '../../individual-send-verification-link.key'
import { individualSendVerificationLinkEn } from '../../locale/individual-send-verification-link.en'
import { SendLinkFormType } from '../../model/individual-send-verification-link.model'
import { useSendVerificationLinkMutation } from '../../mutation/individual-send-verification-link.mutation'
import { useSendLinkState } from '../../state/individual-send-verification-link.state'

type Props = {
  entityId: string
  defaultFlowId?: FlowIdTypes
}

export function IndividualSendVerificationLinkForm({
  entityId,
  defaultFlowId,
}: Props) {
  const [activeStep, setActiveStep] = React.useState(0)
  const t = useI18n([INDIVIDUAL_SEND_VERIFICATION_LINK_KEY], {
    keys: individualSendVerificationLinkEn,
  })

  const { mutate } = useSendVerificationLinkMutation({ entityId })

  const [, closeOverlay] = useOverlay()

  const entityData = useSendLinkState({ entityId })

  const {
    control,
    formState: { isValid },
    handleSubmit,
    watch,
    reset,
  } = useForm<SendLinkFormType>({
    mode: 'onBlur',
    defaultValues: { flowId: defaultFlowId },
  })

  useEffect(() => {
    if (entityData) {
      reset(entityData)
    }
  }, [entityData, reset])

  const flowId = watch('flowId')

  const onSubmit = (data: SendLinkFormType) => {
    mutate({ ...data, entityId })
    closeOverlay()
  }

  const messageData = useMemo(() => {
    if (flowId === FlowIdTypes.MANUAL_KYC) {
      return entityData?.kycSmsContent
    }
    return entityData?.smsContent
  }, [flowId, entityData])

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col w-[400px] gap-4 "
    >
      <FrankieStepper
        currentStep={activeStep}
        steps={[{ label: t('step.1.label') }, { label: t('step.2.label') }]}
      />
      {activeStep === 0 ? (
        <>
          <div className="flex flex-col gap-2">
            <div className="leading-tight text-xl font-bold text-tertiary-grey-700">
              {t('step.1.title')}
            </div>
            <div className="text-tertiary-grey-700">
              {t('step.1.description', {
                name: entityData?.name,
              })}
            </div>
          </div>
          <SelectFormField
            options={
              entityData?.flowIdOptions.map(option => ({
                label: t(option.label),
                value: option.value,
              })) ?? []
            }
            label={`${t('step.1.flowLabel')}`}
            placeholder={t('step.1.flowPlaceholder')}
            name="flowId"
            rules={{ required: true }}
            control={control}
          />

          {flowId === FlowIdTypes.IDV && (
            <ConfirmationFormField
              size="sm"
              className="items-center"
              name="livenessDetection"
              control={control}
              label={t('step.1.livenessDetection')}
            />
          )}

          <PhoneFormField
            label={`${t('step.1.phoneLabel')}`}
            control={control}
            name="number"
            countryList={countryPhoneCodeList.map(country => ({
              label: `${country.alpha2} +${country.value}`,
              value: country.code,
            }))}
            countryName="country"
            supportingText={
              entityData?.number ? undefined : t('step.1.phoneHelperText')
            }
            // rules={numericRule}
            rules={{ required: true }}
            disabled={!!entityData?.number}
          />

          <TextAreaFormField
            label={`${t('step.1.commentLabel')}`}
            placeholder={t('step.1.commentPlaceholder')}
            name="comment"
            rules={{ required: true }}
            control={control}
          />
          <div className="text-tertiary-grey-500 -mt-2 mb-2 text-xs font-normal">
            {t('step.1.commentHelperText')}
          </div>
          <div className="flex justify-between">
            <FrankieButton onClick={closeOverlay} intent="subtle">
              {t('step.cancel')}
            </FrankieButton>
            <FrankieButton
              disabled={!isValid}
              onClick={() => setActiveStep(1)}
              intent="secondary"
            >
              {t('step.next')}
            </FrankieButton>
          </div>
        </>
      ) : (
        <>
          <div className="flex flex-col gap-2">
            <div className="leading-tight text-xl font-bold text-tertiary-grey-700">
              {t('step.2.title')}
            </div>
            <div className="text-tertiary-grey-700 ">
              <Trans
                components={{
                  support: (
                    // eslint-disable-next-line jsx-a11y/control-has-associated-label, jsx-a11y/anchor-has-content
                    <a
                      target="_blank"
                      href={externalLinks.support}
                      className="text-primary-700 "
                      rel="noreferrer"
                    />
                  ),
                }}
              >
                {t('step.2.description')}
              </Trans>
            </div>
          </div>

          <TextAreaFormField
            label={`${t('step.2.messageLabel')}`}
            overrideValue={messageData}
            control={control}
            name="smsContent"
            disabled
          />

          <div className="flex justify-between">
            <FrankieButton onClick={() => setActiveStep(0)} intent="subtle">
              {t('step.back')}
            </FrankieButton>
            <div className="flex gap-4 shrink">
              <IndividualSendVerificationLinkActions
                entityId={entityId}
                flowId={flowId}
                handleSubmit={closeOverlay}
              />
            </div>
          </div>
        </>
      )}
    </form>
  )
}
