import React from 'react'

import cv from 'classnames'

import { FrankieBadge, FrankieButton, FrankieDivider } from 'frankify/src'

import { WorkflowStatusKeysTypes } from 'entities/entity'
import { useGlobalAppState } from 'entities/routing'

import { useI18n } from 'shared/i18n'
import { useOverlay } from 'shared/overlay'
import { TrackingEventsTypes, useTrackingShowEvents } from 'shared/tracking'

import { INDIVIDUAL_OCR_CHECK_KEY } from '../../individual-ocr-check.key'
import { individualOcrCheckEn } from '../../locale/individual-ocr-check.en'
import {
  borderStyleConfig,
  IconConfig,
} from '../../model/individual-idv-check.model'
import { useIndividualOcrCheckState } from '../../state/individual-ocr-check/individual-ocr-check.state'
import { OcrDocumentRow } from '../individual-ocr-check-document-row/individual-ocr-check-document-row'
import { ManualStatusChangeForm } from '../individual-ocr-form/individual-ocr-form'
import { IndividualOcrGallery } from '../individual-ocr-gallery/individual-orc-gallery'
import './individual-ocr-check.css'

type Props = {
  entityId: string
  idx: number
}

export function IndividualOcrCheck({ entityId, idx }: Props) {
  const {
    hadOcrChecks,
    status,
    ocrCheckResults,
    isManualFail,
    isManualPass,
    requireResolve,
    gallery,
  } = useIndividualOcrCheckState({
    entityId,
    idx,
  })

  const [createOverlay, closeOverlay] = useOverlay()

  const { data } = useGlobalAppState()
  const t = useI18n([INDIVIDUAL_OCR_CHECK_KEY], { keys: individualOcrCheckEn })

  useTrackingShowEvents({
    data: hadOcrChecks,
    eventsType: TrackingEventsTypes.WorkflowEventsDocumentShow,
  })

  if (!hadOcrChecks) return null

  const handleClick = () => {
    createOverlay(
      <ManualStatusChangeForm
        options={[
          {
            label: t('manualPassStatus'),
            value: WorkflowStatusKeysTypes.MANUALLY_PASSED,
          },
          {
            label: t('manualFailStatus'),
            value: WorkflowStatusKeysTypes.MANUALLY_FAILED,
          },
        ]}
        closeOverlay={closeOverlay}
        updateWorkflowStatus={() => {}}
      />,
      {
        closeButtonClassName: '!top-6 !right-6',
        className: '!p-6',
      },
    )
  }
  return (
    <div
      id="OCR"
      className={`border rounded-1.5 flex flex-col gap-4 pt-5 px-6 pb-6 ${borderStyleConfig(
        status,
      )}`}
    >
      <div className="flex items-center justify-between">
        <div className="text-xl text-tertiary-grey-800 gap-2 font-bold flex items-center">
          <IconConfig type={status} />
          {t('title')}
          {isManualFail && <FrankieBadge theme="red" text={t('manualFail')} />}
          {isManualPass && (
            <FrankieBadge theme="green" text={t('manualPass')} />
          )}
          {requireResolve && (
            <FrankieButton
              noStyles
              onClick={handleClick}
              className="ml-auto !mt-1 text-sm text-primary-800"
            >
              {t('resolve')}
            </FrankieButton>
          )}
        </div>
      </div>
      <div
        className={cv(
          'grid grid-container auto-rows-min gap-6',
          data?.isSidebarOpen && 'open',
        )}
      >
        <IndividualOcrGallery entityId={entityId} gallery={gallery} />
        {ocrCheckResults.map((ocrResult, idx) => (
          <>
            {idx !== 0 && (
              <FrankieDivider className="my-4 h-min col-span-2 col-start-2" />
            )}

            <OcrDocumentRow ocrResult={ocrResult} />
          </>
        ))}
      </div>
    </div>
  )
}
