export const individualSendVerificationLinkEn = {
  step: {
    1: {
      label: 'Select link type',
      title: 'Send link via SMS',
      description: 'You are sending a link to {{name}}.',
      flowLabel: 'Workflow to send',
      flowPlaceholder: 'Select a workflow',
      phoneLabel: 'Mobile number',
      phoneHelperText:
        'This will update the recorded mobile number for this entity.',
      commentLabel: 'Comment',
      commentPlaceholder: 'Type your comment here',
      commentHelperText:
        'For audit report purposes only, comment will not be sent to the applicant',
      livenessDetection: 'Enable liveness detection',
    },
    2: {
      label: 'Edit message & send',
      title: 'Edit SMS message',
      description:
        'This is the message they will receive. Please contact <support>support</support> to make changes to this template.',
      messageLabel: 'Message',
    },
    next: 'Next',
    cancel: 'Cancel',
    back: 'Back',
    copy: 'Copy link',
    send: 'Send link',
  },
  flows: {
    kyc: 'Manual KYC',
    idv: 'Biometrics/OCR',
    ocr: 'OCR only',
  },
  copySuccess: 'Link copied to clipboard',
  success: 'Workflow sent to {{displayName}}',
  error: 'Failed to send workflow',
}
