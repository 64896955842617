import React, { PropsWithChildren } from 'react'

import { useInView } from 'react-intersection-observer'

import { FrankieBadge, FrankieIcon } from 'frankify/src'

import { useI18n } from 'shared/i18n'
import { TrackingEventsTypes, useTrackingShowEvents } from 'shared/tracking'

import { INDIVIDUAL_AUDIT_REPORT_F2_KEY } from '../../individual-audit-report-f2.key'
import { individualAuditReportF2En } from '../../locale/individual-audit-report-f2.en'
import { StatusTypes } from '../../model/individual-audit-report-f2.model'
import {
  checkStatusMapperQa,
  emptyViewQa,
  tagMapperQa,
  workflowStatusMapperQa,
} from '../../qa/individual-audit-report-f2.qa'

type MapperProps = {
  type?: StatusTypes
}

export function TagMapper({ type }: MapperProps) {
  const t = useI18n([INDIVIDUAL_AUDIT_REPORT_F2_KEY], {
    keys: individualAuditReportF2En,
  })

  if (!type) return null

  const tagWordConverter = {
    [StatusTypes.ARCHIVED]: t('status.Archived'),
    [StatusTypes.PASS]: t('status.Passed'),
    [StatusTypes.FAIL]: t('status.Failed'),
    [StatusTypes.REVIEW]: t('status.review'),
  }

  switch (type) {
    case StatusTypes.ARCHIVED:
      return (
        <FrankieBadge
          theme="grey"
          className="outline-none rounded-2 !bg-tertiary-grey-100 text-center font-semibold text-tertiary-grey-700 capitalize"
          text={tagWordConverter[StatusTypes.ARCHIVED]}
          testId={{ badge: tagMapperQa.archived }}
        />
      )
    case StatusTypes.PASS:
      return (
        <FrankieBadge
          theme="green"
          className="outline-none rounded-2 !bg-tertiary-green-100 text-center font-semibold text-tertiary-green-700 capitalize"
          text={tagWordConverter[StatusTypes.PASS]}
          testId={{ badge: tagMapperQa.pass }}
        />
      )
    case StatusTypes.FAIL:
      return (
        <FrankieBadge
          theme="red"
          className="outline-none rounded-2 !bg-tertiary-red-100 text-center font-semibold text-tertiary-red-700 capitalize"
          text={tagWordConverter[StatusTypes.FAIL]}
          testId={{ badge: tagMapperQa.fail }}
        />
      )
    case StatusTypes.REVIEW:
      return (
        <FrankieBadge
          theme="yellow"
          className="outline-none rounded-2 !bg-tertiary-yellow-100 text-center font-semibold text-yellow-red-700 capitalize"
          text={tagWordConverter[StatusTypes.REVIEW]}
          testId={{ badge: tagMapperQa.review }}
        />
      )
    default:
      return null
  }
}

export function CheckStatusMapper({ type }: MapperProps) {
  switch (type) {
    case StatusTypes.PASS:
      return (
        <div
          className="absolute top-[25px] left-[24px] bg-mono-white p-[6px] pb-3"
          data-qa={checkStatusMapperQa.pass}
        >
          <div className="rounded-full bg-tertiary-green-100 w-8 h-8 flex justify-center items-center">
            <FrankieIcon
              name="mdiCheck"
              className="text-tertiary-green-800"
              size="sm"
            />
          </div>
        </div>
      )

    case StatusTypes.FAIL:
      return (
        <div
          className="absolute top-[25px] left-[24px] bg-mono-white p-[6px] pb-3"
          data-qa={checkStatusMapperQa.fail}
        >
          <div className="rounded-full bg-tertiary-red-100 w-8 h-8 flex justify-center items-center">
            <FrankieIcon
              name="mdiClose"
              className="text-tertiary-red-800"
              size="sm"
            />
          </div>
        </div>
      )

    default:
      return (
        <div
          className="absolute top-[25px] left-8 bg-mono-white p-[5px] pb-2"
          data-qa={checkStatusMapperQa.default}
        >
          <div className="rounded-full bg-tertiary-grey-300 w-4 h-4 " />
        </div>
      )
  }
}

export function WorkflowStatusMapper({ type }: MapperProps) {
  switch (type) {
    case StatusTypes.PASS:
      return (
        <div
          className="absolute top-[25px] left-[27px] bg-mono-white p-[5px] pb-3"
          data-qa={workflowStatusMapperQa.pass}
        >
          <FrankieIcon
            name="mdiSitemapOutline"
            className="text-tertiary-grey-500"
          />
          <div className="rounded-full bg-tertiary-green-100 w-5 h-5  flex justify-center items-center absolute right-[-9px] top-[-10px]">
            <FrankieIcon
              name="mdiCheck"
              size="xs"
              className="text-tertiary-green-800"
            />
          </div>
        </div>
      )

    case StatusTypes.FAIL:
      return (
        <div
          className="absolute top-[25px] left-[27px] bg-mono-white p-[5px] pb-3"
          data-qa={workflowStatusMapperQa.fail}
        >
          <FrankieIcon
            name="mdiSitemapOutline"
            className="text-tertiary-grey-500"
          />
          <div className="rounded-full bg-tertiary-red-100 w-5 h-5   flex justify-center items-center absolute right-[-9px] top-[-10px]">
            <FrankieIcon
              name="mdiClose"
              size="xs"
              className="text-tertiary-red-800"
            />
          </div>
        </div>
      )
    case StatusTypes.REVIEW:
      return (
        <div
          className="absolute top-[25px] left-[27px] bg-mono-white p-[5px] pb-3"
          data-qa={workflowStatusMapperQa.review}
        >
          <FrankieIcon
            name="mdiSitemapOutline"
            className="text-tertiary-grey-500"
          />
          <div className="rounded-full text-tertiary-yellow-700 bg-tertiary-yellow-100 w-5 h-5   flex justify-center items-center absolute right-[-9px] top-[-10px]">
            !
          </div>
        </div>
      )

    default:
      return (
        <div
          className="absolute top-[25px] left-[27px] bg-mono-white p-[5px]"
          data-qa={workflowStatusMapperQa.default}
        >
          <FrankieIcon
            name="mdiSitemapOutline"
            className="text-tertiary-grey-500"
          />
        </div>
      )
  }
}

export function EmptyView() {
  const t = useI18n([INDIVIDUAL_AUDIT_REPORT_F2_KEY], {
    keys: individualAuditReportF2En,
  })

  return (
    <div
      className="mt-6 h-[calc(100vh-540px)] w-full flex flex-col justify-center items-center "
      data-qa={emptyViewQa.emptyScreenHeader}
    >
      <div>
        <FrankieIcon name="mdiMagnify" className="text-primary-300" size="xl" />
      </div>

      <div className="font-bold text-lg  text-center mb-3">
        {t('emptyScreen.title')}
      </div>
      <div className="text-sm w-[350px] text-center">
        {t('emptyScreen.subTitle1')}
      </div>
      <div className="text-sm w-[350px] text-center">
        {t('emptyScreen.subTitle2')}
      </div>
    </div>
  )
}

export function IndividualReportSectionPage({
  children,
  currentPage,
}: PropsWithChildren<{ currentPage: number }>) {
  const [ref, inView] = useInView()
  useTrackingShowEvents({
    data: currentPage && currentPage - 1 && inView,
    eventsType: TrackingEventsTypes.IndividualAuditReportScroll,
    options: {
      page: `${currentPage}`,
    },
  })
  return <div ref={ref}>{children}</div>
}
