import { Nullable } from 'shared/typescript'

import { Address } from './individual-profile-f2-form.model'

export type AddressSuggestionResponse = {
  label: string
  value: string
}

export type DetailAddressResponse = {
  buildingName: Nullable<string>
  postalCode: Nullable<string>
  state: Nullable<string>
  streetNumber: Nullable<string>
  streetType: Nullable<string>
  suburb: Nullable<string>
  town: Nullable<string>
  unitNumber: Nullable<string>
  addressType: Nullable<string>
  country: Nullable<string>
  addressId: Nullable<string>
  district: Nullable<string>
  city: Nullable<string>
  streetName: Nullable<string>
  longForm: Nullable<string>
}

export const defaultAddressFields: Partial<Address> = {
  unstructuredLongForm: '',
  search: '',
  buildingName: '',
  streetName: '',
  district: '',
  postalCode: '',
  locality: '',
  subdivision: '',
  unitNumber: '',
  streetNumber: '',
  streetType: '',
}

export type Places = {
  label: string
  value: string
}
