import React from 'react'

import 'react-toastify/dist/ReactToastify.css'
import { cssTransition, ToastContainer, TypeOptions } from 'react-toastify'

import { enableTailwindClass, FrankieIcon } from 'frankify/src'

import './notification-container.styles.css'
import { notificationQa } from '../../qa/notification.qa'

const transition = cssTransition({
  enter: 'Toastify--animate Toastify--custom-enter',
  exit: 'Toastify--animate Toastify--custom-out',
})

function NotificationIcon({ type }: { type: TypeOptions }) {
  if (type === 'info') {
    return (
      <div
        className="w-8 h-8 flex flex-initial justify-center items-center bg-primary-500 text-mono-white text-lg font-bold rounded-full"
        data-qa={notificationQa.infoIcon}
      >
        i
      </div>
    )
  }

  if (type === 'error') {
    return (
      <FrankieIcon
        size="sm"
        className="w-8 h-8 flex flex-initial justify-center items-center bg-tertiary-red-600 rounded-full"
        name="mdiClose"
        testId={{
          icon: notificationQa.errorIcon,
        }}
      />
    )
  }
  return (
    <FrankieIcon
      size="sm"
      className="w-8 h-8 flex flex-initial justify-center items-center bg-tertiary-green-600 rounded-full"
      name="mdiCheck"
      testId={{
        icon: notificationQa.successIcon,
      }}
    />
  )
}

export function NotificationContainer() {
  return (
    <ToastContainer
      hideProgressBar
      position="bottom-right"
      transition={transition}
      draggable={false}
      closeOnClick={false}
      autoClose={3000}
      toastClassName={() =>
        `${enableTailwindClass} flex flex-initial w-80 min-h-10 bg-tertiary-grey-800 relative p-3.5 pr-3 min-h-10 rounded-md justify-between overflow-hidden cursor-pointer`
      }
      bodyClassName={() =>
        'Notification-container__toast-body flex flex-initial min-w-0 break-words max-w-full items-center text-sm text-tertiary-grey-300'
      }
      icon={NotificationIcon}
    />
  )
}
