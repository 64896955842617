import { useMutation } from '@tanstack/react-query'

import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'

import { customViewApi } from '../../../api/custom-view.api'
import { CUSTOM_VIEWS_KEYS } from '../../../custom-view.key'
import { customViewEn } from '../../../locale/custom-view.en'
import { CustomViewPayload } from '../../../model/custom-views.model'
import { useCustomView } from '../../custom-view-state/custom-view.state'

export const useUpdateCustomView = () => {
  const { refetch, isFetching: isCustomViewLoading } = useCustomView()
  const t = useI18n([CUSTOM_VIEWS_KEYS], { keys: customViewEn })

  const { mutate, isLoading, isSuccess } = useMutation({
    mutationKey: ['updateCustomView'],
    mutationFn: async ({
      viewId,
      data,
    }: {
      viewId: string
      data: CustomViewPayload
    }) => {
      const res = await customViewApi.updateCustomView(viewId, data)
      return res.data
    },
    onSuccess: () => {
      void refetch()
      notification.success(t('notification.viewUpdated'))
    },
    onError: () => {
      notification.error(t('notification.error'))
    },
  })

  return {
    mutate,
    isLoading: isLoading || isCustomViewLoading,
    isSuccess,
  }
}
