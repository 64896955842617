import { useEntityAmlData } from 'entities/entity'

type Args = {
  entityId: string
}

export const useAmlPagination = ({ entityId }: Args) => {
  const searchParams = new URLSearchParams(window.location.search)
  const processResultId = searchParams.get('processResultId')
  const { data } = useEntityAmlData({ entityId })

  const currentIndex =
    data.processResults?.findIndex(
      process => process.processResultId === processResultId,
    ) ?? 0
  const totalData = data.processResults?.length ?? 0

  function next() {
    if (!data.processResults) return null
    if (currentIndex + 1 >= data.processResults.length) return null
    return data.processResults[currentIndex + 1].processResultId
  }

  function prev() {
    if (!data.processResults) return null
    if (currentIndex - 1 < 0) return null
    return data.processResults[currentIndex - 1].processResultId
  }

  return { currentIndex, totalData, data, next, prev }
}
