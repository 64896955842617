import { IClient, bffClient } from 'shared/client'

import {
  IndividualsEntityResponse,
  ServiceProfile,
} from '../model/entity.model'

export class EntityAPI {
  client: IClient

  constructor(client: IClient) {
    this.client = client
  }

  async getEntity(entityId: string, level?: string) {
    return this.client.get<IndividualsEntityResponse>(
      `/f2/v1/individual-entity/${entityId}`,
      {
        params: {
          level,
        },
      },
    )
  }

  async assignEntity(entityId: string, assignee: string) {
    return this.client.patch<{ serviceProfile: ServiceProfile }, unknown>(
      `/f2/v1/individual/${entityId}/assign`,
      { assignee },
    )
  }

  async archiveEntity(entityId: string, state: string, serviceName?: string) {
    return this.client.patch<{ serviceProfile: ServiceProfile }, unknown>(
      `/f2/v1/individual/${entityId}/state`,
      { state, serviceName },
    )
  }

  async getWorkflowEvents(entityId: string) {
    return this.client.get<{ serviceProfile: ServiceProfile }>(
      `f2/v1/individual/${entityId}/workflows`,
    )
  }
}

export const entityApi = new EntityAPI(bffClient)
