import React, { useEffect, useMemo } from 'react'

import { Link } from 'react-router-dom'

import { IndividualAmlScreening } from 'features/individual-aml-screening'

import {
  ProcessResultManualStatusEnumAML,
  useFrankie2R2Customer,
  useGetWorkflowEventsData,
} from 'entities/entity'
import { useApplicantRoute } from 'entities/routing'

import { useI18n } from 'shared/i18n'
import { TrackingEventsTypes, trackingManager } from 'shared/tracking'

import { workflowEventsEn } from '../../locale/workflow-events.en'
import { amlScreeningEventsQa } from '../../qa/applicant-workflow-events.qa'
import { useOrderWithFailedAndPassed } from '../../state/get-workflow-events.query'
import { WORKFLOW_EVENTS_KEY } from '../../workflow-events.key'
import {
  getIconConfigAml,
  borderStyleConfigAml,
} from '../entity-verification-helper/entity-verification-helper'

type Props = {
  entityId: string
}

export function AmlScreeningEvents({ entityId }: Props) {
  const { data } = useGetWorkflowEventsData({ entityId })

  const t = useI18n([WORKFLOW_EVENTS_KEY], {
    keys: workflowEventsEn,
  })

  const { generateRoute } = useApplicantRoute()

  const isFrankie2 = useFrankie2R2Customer()

  const status = data?.workflowSummaries
    ?.at(0)
    ?.workflowResultData?.workflowStepResults?.find(
      step => step.stepName === 'AML',
    )
    ?.processResults?.map(
      result => result.result === 'HIT' && result.manualStatus,
    )
    .filter(Boolean) as ProcessResultManualStatusEnumAML[] | undefined

  const { eventStepper } = useOrderWithFailedAndPassed({ entityId })

  const isAml = useMemo(
    () => eventStepper.some(stepper => stepper.eventName === 'AML'),
    [eventStepper],
  )

  useEffect(() => {
    if (isAml) {
      trackingManager.track(TrackingEventsTypes.WorkflowAmlShowIndividual)
    }
  }, [])

  if (!isAml) {
    return null
  }
  if (!isFrankie2) return null

  return (
    <div
      data-qa={amlScreeningEventsQa.container}
      className={`border min-w-[708px] rounded-1.5 flex flex-col gap-4 pt-5 px-6 pb-6 ${borderStyleConfigAml(
        status,
      )}`}
    >
      <div className="flex items-center justify-between">
        <div
          data-qa={amlScreeningEventsQa.title}
          className="text-xl text-tertiary-grey-800 gap-2 font-bold flex items-center"
        >
          {getIconConfigAml(status)}
          {t('amlScreeningTitle')}
        </div>
        <div>
          <Link
            to={generateRoute({
              routeKey: 'individualAmlHistory',
              overrideParams: { entityId },
            })}
            className="text-primary-800 text-sm"
            onClick={() => {
              trackingManager.track(
                TrackingEventsTypes.AmlHistoryClickIndividual,
              )
            }}
            data-qa={amlScreeningEventsQa.historyRouteBtn}
          >
            {t('amlViewHistoryBtn')}
          </Link>
        </div>
      </div>
      {data && (
        <IndividualAmlScreening
          workflowExecutionId={
            data.workflowSummaries?.at(0)?.workflowExecutionId
          }
          entityId={entityId}
        />
      )}
    </div>
  )
}
