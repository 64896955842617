import { useCallback, useState } from 'react'

import { useQuery, useQueryClient } from '@tanstack/react-query'

import { applicantApi } from '../../api/applicant.api'
import { ApplicantId } from '../../model/applicant.model'

type Arg = {
  applicantId?: ApplicantId
  isEnabled?: boolean
}

export type RequestedReportData = {
  applicantId?: string
}

export const GenerateIndividualReportQueryKey = [
  'applicant-generate-individual-report',
]

export const IndividualReportGenerationNotificationSessionKey = [
  'individual-report-generation-notification',
]

export const useGenerateIndividualReportQuery = ({
  applicantId,
  isEnabled = false,
}: Arg) => {
  const queryClient = useQueryClient()

  const [isGenerateReport, setIsGenerateReport] = useState(isEnabled)

  const { data, isError, isSuccess, refetch, isFetching } = useQuery({
    enabled: isGenerateReport && !!applicantId,
    queryKey: [GenerateIndividualReportQueryKey, applicantId],
    queryFn: async () => {
      const { data } = await applicantApi.generateIndividualReport(applicantId!)
      return data
    },
    retry: false,
    staleTime: 0,
    cacheTime: 0,
  })

  const generateReport = useCallback(() => {
    setIsGenerateReport(true)
  }, [])

  /** *
   * Setting notification session for report generation
   */
  const notifyWhenGenerated = useCallback(() => {
    queryClient.setQueryData<RequestedReportData>(
      IndividualReportGenerationNotificationSessionKey,
      { applicantId },
    )
  }, [queryClient, applicantId])

  return {
    needRetry: isError,
    reportUrl: data?.fileURL,
    reportGenerated: isSuccess,
    fetchingReport: isFetching,
    isGenerateReport,
    generateReport,
    retryGenerateReport: () => refetch(),
    notifyWhenGenerated,
  }
}
