import { useMutation } from '@tanstack/react-query'

import { useGetWorkflowEventsData } from 'entities/entity'

import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'

import { workflowEventsAPI } from '../api/workflow-events.api'
import { workflowEventsEn } from '../locale/workflow-events.en'
import { WORKFLOW_EVENTS_KEY } from '../workflow-events.key'

type Args = {
  entityId: string
}

export const useExecuteWorkflowMutation = ({ entityId }: Args) => {
  const { refetch } = useGetWorkflowEventsData({ entityId })
  const t = useI18n([WORKFLOW_EVENTS_KEY], { keys: workflowEventsEn })
  return useMutation({
    mutationFn: async () => {
      await workflowEventsAPI.executeWorkflow(entityId)
    },
    onSuccess: () => {
      void refetch()
      notification.success(t('executeWorkflow.success'))
    },
    onError: () => {
      notification.error(t('executeWorkflow.error'))
    },
  })
}
