import React from 'react'

import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { FrankieButton } from 'frankify/src'

import { SelectFormField, TextAreaFormField } from 'shared/form'
import { useI18n } from 'shared/i18n'

import { INDIVIDUAL_OCR_CHECK_KEY } from '../../individual-ocr-check.key'
import { individualOcrCheckEn } from '../../locale/individual-ocr-check.en'

type ManualStatusChangeFormProps = {
  options: { label: string; value: string }[]

  closeOverlay: () => void
  updateWorkflowStatus: () => void
}

export type UpdateStatusType = {
  manualStatus: string
  comment: string
}

export function ManualStatusChangeForm({
  options,
  closeOverlay,
  updateWorkflowStatus,
}: ManualStatusChangeFormProps) {
  const t = useI18n([INDIVIDUAL_OCR_CHECK_KEY], {
    keys: individualOcrCheckEn,
  })

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<UpdateStatusType>({
    defaultValues: {
      manualStatus: '',
      comment: '',
    },
    mode: 'onTouched',
  })

  const onSubmitHandler = (data: UpdateStatusType) => {
    if (Math.random() > 0.5) {
      toast.success(t('form.successMessage'))
    } else {
      toast.error(t('form.errorMessage'))
    }
    closeOverlay()
  }

  return (
    <div className="w-[440px]">
      <div className="flex flex-col">
        <div className="flex flex-col gap-4">
          <div className="flex justify-between flex-grow-0">
            <div className="text-tertiary-grey-800 font-bold text-xl leading-7">
              {t('form.title')}
            </div>
          </div>

          <div className="leading-5">{t('form.subTitle')}</div>

          <form
            onSubmit={handleSubmit(onSubmitHandler)}
            className="flex flex-col gap-4 justify-between"
          >
            <div className="flex flex-col gap-4">
              <SelectFormField
                name="manualStatus"
                control={control}
                options={options}
                label={t('form.selectLabel')}
                rules={{ required: true }}
              />

              <TextAreaFormField
                name="comment"
                shouldUnregister
                control={control}
                showError
                label={t('form.commentLabel')}
                rules={{ required: true }}
              />
            </div>
            <div className="flex flex-grow-0 justify-end">
              <FrankieButton
                className="mr-8"
                noStyles
                onClick={() => closeOverlay()}
              >
                {t('form.cancel')}
              </FrankieButton>
              <FrankieButton size="sm" disabled={!isValid} type="submit">
                {t('form.changeStatus')}
              </FrankieButton>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
