import { FrankieBadgeTheme } from 'frankify/src'

import { individualAmlScreeningEn } from 'features/individual-aml-screening/locale/individual-aml-screening.en'

import {
  ProcessResultManualStatusEnum,
  SupplementaryDataAML,
  ProcessResultManualStatusEnumAML,
} from 'entities/entity'

import { I18nFunction } from 'shared/i18n'

export function getMatchStatusBadge(
  t: I18nFunction<typeof individualAmlScreeningEn>,
  status?: ProcessResultManualStatusEnum,
): {
  theme: FrankieBadgeTheme
  text: string
} {
  if (status)
    switch (status) {
      case ProcessResultManualStatusEnumAML.FALSE_POSITIVE:
        return {
          theme: 'green',
          text: t('matchStatus.falsePositive'),
        }
      case ProcessResultManualStatusEnumAML.UNKNOWN_ACCEPT:
        return {
          theme: 'green',
          text: t('matchStatus.unknown'),
        }
      case ProcessResultManualStatusEnumAML.TRUE_POSITIVE_ACCEPT:
        return {
          theme: 'green',
          text: t('matchStatus.truePositive'),
        }
      case ProcessResultManualStatusEnumAML.UNKNOWN_REJECT:
        return {
          theme: 'red',
          text: t('matchStatus.unknown'),
        }
      case ProcessResultManualStatusEnumAML.TRUE_POSITIVE_REJECT:
        return {
          theme: 'red',
          text: t('matchStatus.truePositive'),
        }
      case ProcessResultManualStatusEnumAML.TRUE_POSITIVE:
        return {
          theme: 'yellow',
          text: t('matchStatus.truePositive'),
        }
      case ProcessResultManualStatusEnumAML.UNKNOWN:
      default:
        return {
          theme: 'yellow',
          text: t('matchStatus.unknown'),
        }
    }
  return {
    theme: 'yellow',
    text: t('matchStatus.potentialMatch'),
  }
}

export function getAmlIssues(
  supplementaryData: SupplementaryDataAML,
  t: I18nFunction<typeof individualAmlScreeningEn>,
) {
  const issues = []

  if ((supplementaryData.pepData?.length ?? 0) > 0) {
    issues.push(t('amlIssues.pep'))
  }

  if ((supplementaryData.mediaData?.length ?? 0) > 0) {
    issues.push(t('amlIssues.adverseMedia'))
  }
  if ((supplementaryData.sanctionData?.length ?? 0) > 0) {
    issues.push(t('amlIssues.sanction'))
  }
  if ((supplementaryData.watchlistData?.length ?? 0) > 0) {
    issues.push(t('amlIssues.watchlist'))
  }

  return issues
}

export type AmlFilters = {
  amlMatch: string[]
  matchStatus: string[]
  issues: string[]
  workflow: string[]
}
