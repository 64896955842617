import React, { ReactNode } from 'react'

import { FrankieDivider } from 'frankify/src'

import { trustAnalyserFormQa } from 'features/applicant-supporting-documents/qa/applicant-support-documents.qa'

import { useI18n } from 'shared/i18n'

import { APPLICANT_SUPPORTING_DOCUMENTS_KEY } from '../../../applicant-supporting-documents.key'
import { applicantSupportingDocumentsEn } from '../../../locale/applicant-supporting-documents.en'

export type FormHeaderProps = {
  number: number
  onRemove?: () => void
  showRemove: boolean
  text: string
  removeText?: string
  className?: string
  tag?: ReactNode
  testId?: {
    tag?: string
  }
}

export function FormHeader({
  showRemove,
  onRemove,
  number,
  text,
  className,
  removeText,
  tag,
  testId = {
    tag: '',
  },
}: FormHeaderProps) {
  const t = useI18n([APPLICANT_SUPPORTING_DOCUMENTS_KEY], {
    keys: applicantSupportingDocumentsEn,
  })

  const defaultClassName = 'text-md font-bold mb-4'

  return (
    <div className="flex justify-between">
      <div className={className || defaultClassName}>
        {text}
        <span className="px-1">{number}</span>
        {tag && (
          <div className="inline-block" data-qa={testId.tag}>
            {tag}
          </div>
        )}
      </div>
      {showRemove && (
        <div
          className="text-primary-800 h-5"
          onClick={onRemove}
          onKeyDown={onRemove}
          role="button"
          tabIndex={0}
          data-qa={trustAnalyserFormQa.removeEntity}
        >
          {removeText || t('remove')}
        </div>
      )}
    </div>
  )
}

export type FormFooterProps = {
  showFooter: boolean
  onAdd: () => void
  text: string
  showDivider?: boolean
}

export function FormFooter({
  showFooter,
  onAdd,
  text,
  showDivider = true,
}: FormFooterProps) {
  const t = useI18n([APPLICANT_SUPPORTING_DOCUMENTS_KEY], {
    keys: applicantSupportingDocumentsEn,
  })

  const addMarginY = !showDivider ? 'my-3' : ''

  if (showFooter) {
    return (
      <>
        <div
          className={`text-primary-800 inline-block ${addMarginY}`}
          onClick={onAdd}
          onKeyDown={onAdd}
          role="button"
          tabIndex={0}
          data-qa={trustAnalyserFormQa.addEntity}
        >
          {t('add')} {text}
        </div>
        {showDivider && <FrankieDivider className="my-4" />}
      </>
    )
  }

  return null
}
