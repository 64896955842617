export const NOTIFICATIONS_MANAGEMENT_KEY = 'notifications-management'

export const notificationsManagementEn = {
  type: {
    header: 'NOTIFICATION TYPE',
    types: {
      entityAssignment: {
        name: 'Entity assignment',
        description: 'Notify me when an entity is assigned to me',
      },
      entityStatusUpdatedToNeedsAttentionFailedManuallyFailed: {
        name: 'Workflow status updates on any entities',
        description:
          'Notify me when the workflow status of any entities is updated to Needs attention, Failed, or Manually failed',
      },
      assignedEntityStatusUpdatedToNeedsAttentionFailedManuallyFailed: {
        name: 'Workflow status updates on entities assigned to me',
        description:
          'Notify me when the workflow status of entities assigned to me is updated to Needs attention, Failed, or Manually failed ',
      },
      entityRiskUpdatedToHighUnacceptable: {
        name: 'Risk level updates on any entities',
        description:
          'Notify me when the risk level of any entities is updated to High or Unacceptable',
      },
      assignedEntityRiskUpdatedToHighUnacceptable: {
        name: 'Risk level updates on entities assigned to me',
        description:
          'Notify me when the risk level of entities assigned to me is updated to High or Unacceptable',
      },
    },
  },
  channel: {
    PORTAL: 'PORTAL',
    EMAIL: 'EMAIL',
  },
}
