import React from 'react'

import { TextFormField } from 'shared/form'
import { useI18n } from 'shared/i18n'
import { useValidationRules } from 'shared/validation'

import { INDIVIDUAL_PROFILE_F2_KEY } from '../../../../individual-profile-f2.key'
import { individualProfileF2En } from '../../../../locale/individual-profile-f2.en'
import { getDriverLicenceQa } from '../../../../qa/individual-profile-f2.qa'
import { DocumentsSubFormProps } from '../document-sub-forms.types'

export function PassportForm({
  form,
  idx,
  wrapperClasses,
}: DocumentsSubFormProps) {
  const t = useI18n([INDIVIDUAL_PROFILE_F2_KEY], {
    keys: individualProfileF2En,
  })

  const { control } = form

  const { alphaNumericRule } = useValidationRules()
  const getRequiredRule = (label: Parameters<typeof t>['0']) => ({
    required: t('errors.requiredEntity', {
      label: t(label).toLowerCase(),
    }),
  })

  const driverLicenceF2Qa = getDriverLicenceQa(idx)
  return (
    <div className={wrapperClasses} data-qa={driverLicenceF2Qa.wrapper}>
      <TextFormField
        control={control}
        name={`documents.${idx}.primaryIdentifier`}
        className="basis-[47.5%]"
        label={t('documentForm.passportNumber')}
        placeholder={t('documentForm.passportNumberPlaceholder')}
        rules={{
          ...getRequiredRule('documentForm.passportNumber'),
          ...alphaNumericRule,
        }}
        showErrorText
        shouldUnregister
        testId={{ input: driverLicenceF2Qa.passportNumber }}
      />
    </div>
  )
}
