import React from 'react'

import { FrankieButton } from 'frankify/src'

import { FlowIdTypes } from 'entities/entity'

import { copyToClipboard } from 'shared/clipboard'
import { notification } from 'shared/notification'

import { useSendLinkTranslation } from '../../../model/individual-send-verification-link.model'
import { useIndividualSendVerificationLinkQuery } from '../../../query/individual-send-verification-link/individual-send-verification-link.query'

type Props = {
  entityId: string
  flowId: FlowIdTypes
  handleSubmit: () => void
}
export function IndividualSendVerificationLinkActions({
  entityId,
  flowId,
  handleSubmit,
}: Props) {
  const { data } = useIndividualSendVerificationLinkQuery({ entityId, flowId })

  const t = useSendLinkTranslation()

  const handleCopy = () => {
    void copyToClipboard(data?.url || '')
    notification.success(t('copySuccess'))
  }

  return (
    <>
      <FrankieButton
        intent="secondary"
        type="button"
        onClick={handleCopy}
        startIcon={{
          name: 'mdiContentCopy',
          size: 'sm',
          className: 'mr-2',
        }}
      >
        {t('step.copy')}
      </FrankieButton>
      <FrankieButton type="submit">{t('step.send')}</FrankieButton>
    </>
  )
}
