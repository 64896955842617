import { useCallback, useEffect, useMemo, useState } from 'react'

import { useMutation } from '@tanstack/react-query'

import {
  ApplicantId,
  useApplicantDataQuery,
  useApplicantState,
} from 'entities/applicant'
import { useHasFeatureFlag } from 'entities/session'

import { ErrorResponse } from 'shared/http'
import { useI18n } from 'shared/i18n'
import { useNotification } from 'shared/notification'

import { ikybAmlScreeningApi } from '../../api/ikyb-aml-screening'
import { IKYB_AML_SCREENING_KEY } from '../../ikyb-aml-screening.key'
import { iKybAmlScreeningEn } from '../../locale/ikyb-aml-screening.en'

type Args = {
  applicantId: ApplicantId
}

type MutateArgs = {
  flag: boolean
}

export const useApplicantToggleOngoingAmlState = ({ applicantId }: Args) => {
  const t = useI18n(IKYB_AML_SCREENING_KEY, {
    keys: iKybAmlScreeningEn,
  })

  const { showNotification } = useNotification()

  const { data: applicantData } = useApplicantDataQuery({ applicantId })
  const { applicantName } = useApplicantState({ applicantId })
  const { hasAmlOngoingMonitoring } = useHasFeatureFlag({
    hasAmlOngoingMonitoring: 'amlOngoingMonitoring',
  })

  const [amlStatus, setAmlStatus] = useState(false)

  const { mutate } = useMutation({
    mutationFn: (data: MutateArgs) =>
      ikybAmlScreeningApi.toggleOngoingAml(applicantId, data),

    onError: (error: ErrorResponse, { flag }) => {
      const isPermissionDenied = error.response?.status === 403

      showNotification({
        message: `${t('error.ongoingAmlError', {
          errorState: amlStatus ? t('state.enabling') : t('state.disabling'),
        })} ${
          isPermissionDenied
            ? t('error.permissionDenied')
            : t('error.pleaseTryAgain')
        }`,
        type: 'error',
      })
      setAmlStatus(!flag) // Resetting it to original if error
    },
  })

  /**
   * Check to show ongoing toggle
   */
  const showOngoingAmlToggle = useMemo(() => {
    const isInMonitoring = ['pass', 'pass_manual'].includes(
      applicantData?.checkSummary.status.type ?? '',
    )

    const hasPepCheck = applicantData?.checkSummary.checkResults.some(
      check => check.type === 'pep_media' || check.type === 'pep',
    )

    return isInMonitoring && hasPepCheck && hasAmlOngoingMonitoring
  }, [applicantData, hasAmlOngoingMonitoring])

  /**
   * Ongoing aml value
   */
  useEffect(() => {
    const amlFlag = applicantData?.applicantDetails.flags?.find(
      flag => flag.flag === 'ongoing_aml_disabled',
    )

    if (!amlFlag) {
      setAmlStatus(false)
    } else {
      const flagValue = !amlFlag.value
      setAmlStatus(flagValue)
    }
  }, [applicantData?.applicantDetails.flags])

  /**
   * To toggle ongoing aml status
   */
  const toggleAmlStatus = useCallback(() => {
    mutate({ flag: !amlStatus })
    // As per Vue code implementation - changing the state and showing success message, if error then changing the state back and showing error message
    showNotification({
      message: t('success.ongoingAml', {
        state: amlStatus ? t('state.disabled') : t('state.enabled'),
        applicantName,
      }),
      type: 'success',
    })
    setAmlStatus(!amlStatus)
  }, [amlStatus, applicantName, mutate, showNotification, t])

  return {
    amlStatus,
    toggleAmlStatus,
    showOngoingAmlToggle,
  }
}
