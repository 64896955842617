import React from 'react'

import {
  LiveboardEmbed,
  useEmbedRef,
} from '@thoughtspot/visual-embed-sdk/lib/src/react'

import { FrankieLoader } from 'frankify/src'

import { useAnalyticsDashboardTranslation } from 'features/analytics-dashboard/locale/analytics-dashboard.en'

import { useSessionQuery } from 'entities/session'

import { useThoughSpot } from '../../state/thought-spot/thought-spot'

/**
 * ThoughtSpot Live board Integration
 * Clear cookies on logout see {@link src/features/logout/model/logout.model.ts}
 */
export function AnalyticsLiveboard() {
  const { t } = useAnalyticsDashboardTranslation()
  const embedRef = useEmbedRef()
  const { isSuccess, isLoading, isError } = useThoughSpot()

  const { data } = useSessionQuery()
  const dashboardId =
    data?.features.embeddableDashboard?.thoughtSpot.dashboardId

  return (
    <FrankieLoader
      loading={isLoading}
      label={
        <div className="font-semibold pt-2 text-tertiary-700">
          {t('initializing')}
        </div>
      }
      className="min-h-[120px]"
    >
      {/* ThoughtSpot liveboard Embed */}
      <div className="px-6">
        {isSuccess && (
          <LiveboardEmbed
            frameParams={{
              height: 'calc(100vh - 213px)',
            }}
            className="h-[calc(100vh-213px)]"
            ref={embedRef as unknown as undefined}
            liveboardId={dashboardId}
          />
        )}
        {isError && <div>{t('noAccess')}</div>}
      </div>
    </FrankieLoader>
  )
}
