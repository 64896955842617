import React, { useEffect } from 'react'

import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro'
import cx from 'classnames'

import { RiskLevel, useEntityDataQuery } from 'entities/entity'

import { useI18n } from 'shared/i18n'
import { TrackingEventsTypes, trackingManager } from 'shared/tracking'

import {
  RiskDataGridHeader,
  RiskFactorDataGridCell,
  RiskScoreDataGridCell,
  RiskValueDataGridCell,
  cellClassName,
  headerClassName,
  getWidth,
} from './entity-risk-data-grid-helper/entity-risk-data-grid-helper'
import { workflowEventsEn } from '../../locale/workflow-events.en'
import { getRiskLevelConfig } from '../../model/applicant-workflow-events-mapping.model'
import { RiskFactorRow } from '../../model/applicant-workflow-events.model'
import { entityRiskScoreQa } from '../../qa/applicant-workflow-events.qa'
import { useRiskScore } from '../../state/risk-score.state'
import { WORKFLOW_EVENTS_KEY } from '../../workflow-events.key'

type Props = {
  entityId: string
  disableVirtualization?: boolean
}

export function EntityRiskScore({
  entityId,
  disableVirtualization = false,
}: Props) {
  const { riskScore } = useRiskScore({ entityId })
  const { data } = useEntityDataQuery(entityId)

  const t = useI18n(WORKFLOW_EVENTS_KEY, {
    keys: workflowEventsEn,
  })

  const columns: GridColDef<RiskFactorRow>[] = [
    {
      field: 'riskFactor',
      headerName: t('riskScore.label.factors'),
      sortable: false,
      hideSortIcons: true,
      headerClassName: cx(headerClassName),
      cellClassName,
      renderHeader: RiskDataGridHeader,
      renderCell: RiskFactorDataGridCell,
      ...getWidth(200),
    },
    {
      field: 'riskValue',
      headerName: t('riskScore.label.value'),
      sortable: false,
      hideSortIcons: true,
      headerClassName: cx(headerClassName),
      cellClassName,
      renderHeader: RiskDataGridHeader,
      renderCell: RiskValueDataGridCell,
      ...getWidth(144),
    },
    {
      field: 'riskScore',
      headerName: t('riskScore.label.score'),
      sortable: false,
      hideSortIcons: true,
      headerClassName: cx(headerClassName),
      align: 'right',
      headerAlign: 'right',
      cellClassName,
      renderHeader: RiskDataGridHeader,
      renderCell: RiskScoreDataGridCell,
      ...getWidth(80),
    },
  ]

  useEffect(() => {
    if (data?.individual) {
      if (data.individual.entityType === 'INDIVIDUAL') {
        trackingManager.track(
          TrackingEventsTypes.WorkflowEventRiskShowIndividual,
        )
      } else {
        trackingManager.track(
          TrackingEventsTypes.WorkflowEventRiskShowOrganisation,
        )
      }
    }
  }, [data])

  return (
    <div
      className="min-w-[400px] flex flex-col gap-6"
      data-qa={entityRiskScoreQa.container}
    >
      <div
        className="text-tertiary-grey-700 font-bold text-xl"
        data-qa={entityRiskScoreQa.riskScore}
      >
        {t('header.risk.riskScore')}
      </div>
      <DataGridPro
        data-qa={entityRiskScoreQa.dataGrid}
        rows={riskScore}
        getRowId={row => row.id}
        columns={columns}
        rowHeight={45}
        columnHeaderHeight={32}
        columnBuffer={0}
        isRowSelectable={() => false}
        isCellEditable={() => false}
        getRowClassName={({ isLastVisible, row: { riskValue } }) =>
          isLastVisible
            ? getRiskLevelConfig(riskValue as RiskLevel, t).bgStyle
            : ''
        }
        disableRowSelectionOnClick
        disableColumnSelector
        disableColumnFilter
        disableColumnMenu
        disableDensitySelector
        showCellVerticalBorder={false}
        showColumnVerticalBorder={false}
        hideFooterPagination
        hideFooter
        hideFooterSelectedRowCount
        disableVirtualization={disableVirtualization}
        sx={{
          '.MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          '&.MuiDataGrid-root': {
            border: 'none',
          },
          '.MuiDataGrid-columnHeaders': {
            minHeight: 'unset !important',
            maxHeight: 'unset !important',
            lineHeight: 'unset !important',
            borderRadius: '0 !important',
          },
          '.MuiDataGrid-columnHeaderTitleContainer': {
            display: 'inline-block !important',
            flex: 'none !important',
          },
          '.MuiDataGrid-cell': {
            minHeight: 'unset !important',
            maxHeight: 'unset !important',
            lineHeight: 'unset !important',
            padding: '0 !important',
          },
        }}
      />
    </div>
  )
}
