import {
  AustralianStateCodeTypes,
  CountryAlpha3CodeTypes,
} from 'entities/country'
import { DocumentType } from 'entities/entity'

import { useI18n } from 'shared/i18n'
import { useValidationRules } from 'shared/validation'

import { INDIVIDUAL_PROFILE_F2_KEY } from '../individual-profile-f2.key'
import { individualProfileF2En } from '../locale/individual-profile-f2.en'

export const useValidationSchema = () => {
  const { numericRule, alphaNumericRule } = useValidationRules()
  const t = useI18n(INDIVIDUAL_PROFILE_F2_KEY, { keys: individualProfileF2En })
  const getMaxValidation = (
    max: number,
    tKey: Parameters<typeof t>[0],
    isNumeric = false,
  ) => ({
    maxLength: {
      value: max,
      message: t(
        isNumeric ? 'errors.maxLengthMustNum' : 'errors.maxLengthMust',
        {
          maximum: max,
          label: t(tKey),
        },
      ),
    },
  })

  const getMinValidation = (
    max: number,
    tKey: Parameters<typeof t>[0],
    isNumeric = false,
  ) => ({
    minLength: {
      value: max,
      message: t(
        isNumeric ? 'errors.minLengthMustNum' : 'errors.minLengthMust',
        {
          minimum: max,
          label: t(tKey),
        },
      ),
    },
  })

  return {
    documents: {
      [DocumentType.DRIVERS_LICENSE]: {
        [CountryAlpha3CodeTypes.Australia]: {
          [AustralianStateCodeTypes.ACT]: {
            primaryIdentifier: {
              ...getMaxValidation(10, 'documentForm.licenseNumber', true),
              pattern: numericRule.pattern,
            },
            secondaryIdentifier: {
              ...getMinValidation(10, 'documentForm.cardNumber'),
              ...getMaxValidation(10, 'documentForm.cardNumber'),
              pattern: alphaNumericRule.pattern,
            },
          },
          [AustralianStateCodeTypes.NSW]: {
            primaryIdentifier: {
              ...getMinValidation(6, 'documentForm.licenseNumber'),

              ...getMaxValidation(8, 'documentForm.licenseNumber'),
              pattern: alphaNumericRule.pattern,
            },
            secondaryIdentifier: {
              ...getMinValidation(10, 'documentForm.cardNumber', true),
              ...getMaxValidation(10, 'documentForm.cardNumber', true),
              pattern: numericRule.pattern,
            },
          },
          [AustralianStateCodeTypes.NT]: {
            primaryIdentifier: {
              ...getMaxValidation(10, 'documentForm.licenseNumber', true),
              pattern: numericRule.pattern,
            },
            secondaryIdentifier: {
              ...getMinValidation(6, 'documentForm.cardNumber', true),
              ...getMaxValidation(8, 'documentForm.cardNumber', true),
              pattern: numericRule.pattern,
            },
          },
          [AustralianStateCodeTypes.QLD]: {
            primaryIdentifier: {
              ...getMinValidation(8, 'documentForm.licenseNumber', true),
              ...getMaxValidation(9, 'documentForm.licenseNumber', true),
              pattern: numericRule.pattern,
            },
            secondaryIdentifier: {
              ...getMinValidation(10, 'documentForm.cardNumber'),
              ...getMaxValidation(10, 'documentForm.cardNumber'),
              pattern: alphaNumericRule.pattern,
            },
          },
          [AustralianStateCodeTypes.SA]: {
            primaryIdentifier: {
              ...getMinValidation(6, 'documentForm.licenseNumber'),
              ...getMaxValidation(6, 'documentForm.licenseNumber'),
              pattern: alphaNumericRule.pattern,
            },
            secondaryIdentifier: {
              ...getMinValidation(9, 'documentForm.cardNumber'),
              ...getMaxValidation(9, 'documentForm.cardNumber'),
              pattern: alphaNumericRule.pattern,
            },
          },
          [AustralianStateCodeTypes.TAS]: {
            primaryIdentifier: {
              ...getMinValidation(6, 'documentForm.licenseNumber'),
              ...getMaxValidation(8, 'documentForm.licenseNumber'),
              pattern: alphaNumericRule.pattern,
            },
            secondaryIdentifier: {
              ...getMinValidation(9, 'documentForm.cardNumber'),
              ...getMaxValidation(9, 'documentForm.cardNumber'),
              pattern: alphaNumericRule.pattern,
            },
          },
          [AustralianStateCodeTypes.VIC]: {
            primaryIdentifier: {
              ...getMaxValidation(10, 'documentForm.licenseNumber', true),
              pattern: numericRule.pattern,
            },
            secondaryIdentifier: {
              ...getMinValidation(8, 'documentForm.cardNumber'),
              ...getMaxValidation(8, 'documentForm.cardNumber'),
              pattern: alphaNumericRule.pattern,
            },
          },
          [AustralianStateCodeTypes.WA]: {
            primaryIdentifier: {
              ...getMinValidation(7, 'documentForm.licenseNumber', true),
              ...getMaxValidation(7, 'documentForm.licenseNumber', true),
              pattern: numericRule.pattern,
            },
            secondaryIdentifier: {
              ...getMinValidation(8, 'documentForm.cardNumber'),
              ...getMaxValidation(10, 'documentForm.cardNumber'),
              pattern: alphaNumericRule.pattern,
            },
          },
        },
      },
    },
  }
}
