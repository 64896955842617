export const IndividualAmlResultPageEn = {
  title: 'AML alert',
  back: 'Back',
  amlStatus: {
    potentialMatch: 'Potential Match',
    falsePositive: 'False Positive',
    truePositive: 'True Positive',
    unknown: 'Unknown',
  },
  amlStatusForm: {
    title: 'Resolve AML alert',
    subTitle:
      'This will assign a match status to the AML match, please review the data before proceeding.',
    selectLabel: 'AML status',
    commentLabel: 'Comment',
    cancel: 'Cancel',
    changeStatus: 'Resolve alert',
    approvalStatusSelectLabel: 'Approval status',
    approvalStatusPlaceholder: 'Select approval status',
  },
  amlMatchData: {
    title: 'Match data',
    matchStrength: '80% match strength',
  },
  amlManualStatus: {
    pass: 'Pass',
    fail: 'Fail',
  },
  alertAml: {
    title: 'You have identified a true positive match.',
    subTitle:
      'Do you want to update the rest of the AML matches to false  positives?',
    noKeep: 'No, keep as is',
    yesUpdate: 'Yes, update all',
  },
}
