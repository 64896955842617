import React, { useMemo, useRef, useState } from 'react'

import {
  FrankieBadge,
  FrankieCheckbox,
  FrankiePopover,
  FrankieTextField,
} from 'frankify/src'

type Option = { value: string; label: string }

type Props = {
  options: Option[]
  values: Option[]
  onChange: (values: Option[]) => void
  placeholder?: string
  testId?: {
    searchInput?: string
    checkbox?: string
    badge?: string
    closeButton?: string
    closeBadge?: string
  }
}

export function CheckboxesSearch({
  options,
  values,
  placeholder,
  onChange,
  testId,
}: Props) {
  const [open, setOpen] = useState<boolean>(false)
  const [search, setSearch] = useState<string>('')
  const inputRef = useRef<HTMLInputElement>(null)

  const focus = () =>
    setTimeout(() => {
      inputRef.current?.focus()
    }, 1)

  const displayOptions = useMemo<Option[]>(
    () =>
      options.filter(option =>
        option.label.toLowerCase().includes(search.toLowerCase()),
      ),
    [options, search],
  )

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value)
  }

  const handleChangeChecked = (option: Option, newChecked: boolean) => () => {
    if (newChecked) {
      onChange([...values, option])
    } else onChange(values.filter(value => value.value !== option.value))
  }

  const handleUnselect = (option: Option) => () => {
    handleChangeChecked(option, false)()
  }

  const handleOpen = () => {
    setOpen(true)
    focus()
  }

  const handleClearSearch = () => {
    setSearch('')
  }

  return (
    <div>
      <div className="max-w-[404px]">
        <FrankiePopover
          flip={false}
          open={open}
          popoverRest={{ placement: 'bottom-start' }}
          onOpenChange={setOpen}
          trigger={
            <FrankieTextField
              type="search"
              size="sm"
              isSearchIcon
              value={search}
              ref={inputRef}
              variant="light"
              placeholder={placeholder}
              onChange={handleChangeSearch}
              onClick={handleOpen}
              testId={{
                input: testId?.searchInput,
                closeCta: testId?.closeButton,
              }}
              closeButton={search ? { onClick: handleClearSearch } : undefined}
            />
          }
        >
          <div className="bg-mono-white shadow-md rounded-sm w-[404px]">
            <div className="flex flex-col py-2.5 max-h-[165px] overflow-y-auto scrollbar-sm mr-1">
              {displayOptions.map(option => {
                const checked = values.some(val => val.value === option.value)

                return (
                  <label
                    className="flex flex-row items-center py-1.5 px-4 cursor-pointer gap-2 leading-tight text-sm text-tertiary-grey-700 hover:bg-tertiary-grey-100"
                    key={option.value}
                  >
                    <FrankieCheckbox
                      size="sm"
                      checked={checked}
                      onChange={handleChangeChecked(option, !checked)}
                      testId={{ input: testId?.checkbox }}
                    />
                    {option.label}
                  </label>
                )
              })}
            </div>
          </div>
        </FrankiePopover>
      </div>

      <div className="flex flex-row flex-wrap gap-2 mt-3">
        {values.map(option => (
          <FrankieBadge
            key={option.value}
            text={option.label}
            size="lg"
            theme="blue"
            testId={{ badge: testId?.badge }}
            closeButton={{
              onClick: handleUnselect(option),
              testId: { button: testId?.closeBadge },
            }}
          />
        ))}
      </div>
    </div>
  )
}
