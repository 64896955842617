import React, { useMemo, useState } from 'react'

import classNames from 'classnames'

import { FrankieButton } from 'frankify/src'

import {
  DOCUMENT_F2_KEY,
  documentF2En,
} from 'entities/document-f2/locale/document-f2.en'
import { documentIdTypesOption } from 'entities/document-f2/model/document-id-types.model'
import { DocumentInformation } from 'entities/entity'

import { DateFormatTypes, formatDate } from 'shared/date-time'
import { convertDataURItoBlob, makeDataURL } from 'shared/file'
import { Show } from 'shared/hoc'
import { useI18n } from 'shared/i18n'
import { useOverlay } from 'shared/overlay'

type Props = {
  document: DocumentInformation
  className?: string
  extraData: { label: string; value?: string }[]
  zoom?: boolean
}
export function DocumentViewF2({
  document,
  extraData,
  className = '',
  zoom = true,
}: Props) {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [createOverlay] = useOverlay()
  const t = useI18n(DOCUMENT_F2_KEY, { keys: documentF2En })

  const { url, isImage, multipleAttachments } = useMemo(() => {
    const attachment = document.attachments?.[currentIndex]
    const multipleAttachments = (document.attachments?.length ?? 0) > 1
    const mimeType = attachment?.mimeType
    const fileUrl =
      attachment?.data && 'base64' in attachment.data
        ? attachment.data.base64
        : ''

    if (!fileUrl || !mimeType) {
      // Safety check
      return { url: '', isImage: false, isVideo: false }
    }
    const temp_url = makeDataURL(mimeType, fileUrl)
    const blob = convertDataURItoBlob(temp_url)
    const url = window.URL.createObjectURL(blob)
    const isImage = mimeType.split('/').includes('image')
    return { url, isImage, multipleAttachments }
  }, [currentIndex, document.attachments])

  if (!url) return null

  const handlePageChange = (type: boolean) => () => {
    if (type) {
      setCurrentIndex(prev => (prev + 1) % (document.attachments?.length ?? 0))
    } else {
      setCurrentIndex(
        prev =>
          (prev - 1 + (document.attachments?.length ?? 0)) %
          (document.attachments?.length ?? 0),
      )
    }
  }
  const handleClick = () => {
    const documentLabelTKey = documentIdTypesOption.find(
      ({ value }) => value === document.type,
    )?.tKey
    createOverlay(
      <div className="flex flex-wrap max-w-[850px] gap-x-6 gap-y-4 min-h-[450px]">
        <div className="text-xl basis-full font-semibold">
          {documentLabelTKey ? t(documentLabelTKey) : document.type}
        </div>
        <div className="flex justify-center items-center w-[610px] h-[360px]">
          <DocumentViewF2
            document={document}
            extraData={extraData}
            zoom={false}
          />
        </div>
        <div className="min-w-[200px] max-w-[200px]">
          {extraData.map(({ label, value }) => (
            <div key={label} className="mt-3">
              <div className="font-medium text-tertiary-grey-800">{label}</div>
              <div
                data-hj-suppress
                className="font-normal  text-tertiary-grey-700"
              >
                {value}
              </div>
            </div>
          ))}
        </div>
        <div className="text-sm mt-2 font-normal basis-full text-tertiary-grey-500">
          {t('uploadedAt', {
            date: formatDate(
              document.createdAt ?? new Date().toUTCString(),
              DateFormatTypes.DateNumbersSlash,
            ),
            time: formatDate(
              document.createdAt ?? new Date().toUTCString(),
              DateFormatTypes.Time24HoursWithSeconds,
            ),
          })}
        </div>
      </div>,
      {
        closeButtonClassName: '!top-6 !right-6',
        className: '!p-6',
      },
    )
  }

  return (
    <div
      className={`bg-tertiary-grey-500  w-full flex justify-center h-full border rounded-sm border-tertiary-grey-200 relative ${className}`}
    >
      <Show>
        <Show.When isTrue={isImage}>
          <img
            data-hj-suppress
            className="object-contain h-[100%]"
            src={url}
            alt="uploaded-file"
          />
        </Show.When>
        <Show.Else>
          <div
            className={classNames('w-full h-full absolute ', !zoom && 'hidden')}
          />
          <iframe
            data-hj-suppress
            className="h-full w-full"
            src={url}
            title="uploaded-file"
          />
        </Show.Else>
      </Show>

      {zoom && (
        <FrankieButton
          intent="subtle"
          size="xs"
          className="border border-solid border-tertiary-grey-200 !bg-mono-white rounded-sm absolute bottom-2 left-2"
          onClick={handleClick}
          singleIcon={{ name: 'mdiArrowExpand' }}
        />
      )}
      {multipleAttachments && (
        <div className=" border border-tertiary-grey-200 flex bg-mono-white rounded-sm absolute right-2 bottom-2">
          <FrankieButton
            intent="subtle"
            disabled={currentIndex === 0}
            size="xs"
            className="border-r border-solid border-tertiary-grey-200 rounded-none"
            onClick={handlePageChange(false)}
            singleIcon={{ name: 'mdiChevronLeft' }}
          />
          <FrankieButton
            intent="subtle"
            size="xs"
            disabled={currentIndex === (document.attachments?.length ?? 0) - 1}
            onClick={handlePageChange(true)}
            singleIcon={{ name: 'mdiChevronRight' }}
          />
        </div>
      )}
    </div>
  )
}
