export const individualAmlResultEn = {
  individualAmlResolveSingleSummary: {
    entitySummary: 'Entity summary',
    fullName: 'Full Name',
    aliases: 'Aliases',
    yearOfBirth: 'Year of Birth',
    countries: 'Countries',
    residentialAddress: 'Residential Address',
    previousAddresses: 'Previous Residential Address',
    fuzziness: 'Fuzziness',
    vendorId: 'Vendor Case ID',
    associates: 'Associates',
    address: 'Address',
    country: 'Country',
    countryOfResidence: 'Country of Residence',
  },
  individualAmlMatchData: {
    title: 'Match data',
    matchStrength: '{{matchStrength}}% match strength',
  },
  tabsItem: {
    pep: 'PEP ({{count}})',
    sanctions: 'Sanctions ({{count}})',
    watchlist: 'Watchlist ({{count}})',
    adverseMedia: 'Adverse Media ({{count}})',
    otherDataSources: 'Other data sources ({{count}})',
  },
  tabMatchCard: {
    countryCodes: 'Country Codes',
    country: 'Country',
    srcName: 'Source name',
    level: 'Level',
    srcUrl: 'Source URL',
    srcReason: 'Source Reason',
    politicianPosition: 'Political Position',
    srcPosition: 'Source Position',
    startData: 'Source Listing Started',
    endDate: 'Source Listing Ended',
    imageUrl: 'Image URL',
    referenceDocs: 'Reference Docs',
    politicalPosition: 'Political Position',
  },
}
