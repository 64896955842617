import React, { useMemo, useState } from 'react'

import { FrankieButton, FrankieIcon, FrankieIconName } from 'frankify/src'

import { useI18n } from 'shared/i18n'
import { Nullable } from 'shared/typescript'

import { APPLICANT_PERSONAL_INFO_KEYS } from '../../applicant-persona-info.keys'
import { applicantPersonalInfoEn } from '../../locale/applicant-personal-info.en'
import {
  DetailViewTypes,
  getStatusIcon,
  getVerifyBgColor,
  getVerifyColor,
} from '../../model/applicant-detail-view.model'
import { applicantDetailViewQa } from '../../qa/applicant-personal-info.qa'

type Props = {
  label: string
  secondaryLabel?: string | undefined
  secondaryValue?: string | undefined
  value?: string | null | undefined
  isLast: boolean
  renderAdditionalInfo?: React.ReactNode
  iconName: FrankieIconName
  checkCount?: number
  checkSource?: Nullable<unknown[]>
  isVerified?: Nullable<boolean>
  isManualKYCPass?: Nullable<boolean>
  isEntityPassed: Nullable<boolean>
  isManualKYC: boolean
  showMatchesText?: boolean
  isLegacyProfile?: boolean
  isAddedByCustomer?: boolean
  uboTitle?: Nullable<string>
  uboValue?: Nullable<string>
  type: DetailViewTypes
  testId?: { container?: string }
  openAdditionalInfo?: React.ReactNode
}

// eslint-disable-next-line complexity
export function ApplicantDetailView(props: Props) {
  const {
    label,
    secondaryLabel,
    secondaryValue,
    value,
    renderAdditionalInfo,
    iconName,
    checkCount = undefined,
    checkSource,
    isVerified,
    isManualKYCPass = false,
    isEntityPassed,
    isManualKYC,
    isAddedByCustomer = false,
    showMatchesText = false,
    isLegacyProfile = false,
    uboTitle,
    uboValue,
    type,
    testId,
    isLast,
    openAdditionalInfo,
  } = props

  const t = useI18n(APPLICANT_PERSONAL_INFO_KEYS, {
    keys: applicantPersonalInfoEn,
  })
  const [openDropdown, setOpenDropdown] = useState<boolean>(false)

  const verifyLabel = useMemo(() => {
    if (isVerified) return t('status.verified')
    if (isVerified === null) return t('status.unchecked')
    if (isVerified === false) return t('status.unVerified')
    return ''
  }, [isVerified, t])

  const statusIcon = useMemo(
    () => getStatusIcon(isVerified ?? null, isManualKYC),
    [isManualKYC, isVerified],
  )

  const getAdditionalInfo = useMemo(() => {
    let text = ''

    if (type === DetailViewTypes.RULESET_MATCHES) return t('rulesets.title')

    if (checkCount && checkCount > 0)
      return `${label} ${t('matchDataWithSource')}`

    switch (isVerified) {
      case true:
        text = `${label} ${t('matchDataWithSource')}`
        break
      case false:
        text = `${label} ${t('notMatchedWithSource')}`
        break
      case null:
        text = `${label} ${t('notChecked')}`
        break

      default:
        break
    }
    return text
  }, [checkCount, isVerified, label, t, type])

  return (
    <div
      className={`text-tertiary-grey-700 ${
        isLast ? 'border-[1px]' : 'border-t-[1px] border-x-[1px]'
      }  border-solid border-tertiary-grey-200 py-3 pl-8 pr-4`}
      data-qa={testId?.container}
    >
      <div className="flex justify-start items-start mt-2">
        <div className="basis-[20%] flex justify-start items-center relative">
          {(getAdditionalInfo || (value && renderAdditionalInfo)) &&
            !isLegacyProfile && (
              <FrankieButton
                testId={{ button: applicantDetailViewQa.expandBtn }}
                singleIcon={{
                  name: 'mdiChevronRight',
                  className: `mr-3 text-tertiary-grey-300 absolute top-[0px] left-[-22px] ${
                    openDropdown ? 'rotate-90' : 'rotate-0'
                  }`,
                  size: 'sm',
                }}
                noStyles
                onClick={() => setOpenDropdown(prev => !prev)}
              />
            )}
          <FrankieIcon
            name={iconName}
            size="sm"
            className="mr-3 text-tertiary-grey-300"
          />
          {secondaryLabel ? (
            <div className="text-tertiary-grey-500">
              <div>{label}</div>
              <div>{secondaryLabel} </div>
            </div>
          ) : (
            <div className="text-tertiary-grey-500">{label}</div>
          )}
        </div>
        <div className="basis-[8%] relative">
          {showMatchesText && (
            <div className="text-[13px] left-[15px] absolute top-[-50px]">
              {t('rulesets.matches')}
            </div>
          )}
          {checkCount !== undefined && !isLegacyProfile && (
            <div
              className={`flex justify-center items-center text-mono-white font-bold mx-auto text-center w-[18px] h-[18px] px-1 ${getVerifyBgColor(
                isVerified ?? null,
                isManualKYCPass,
                isEntityPassed,
              )}`}
            >
              {checkCount}
            </div>
          )}
        </div>
        {value && secondaryValue && (
          <div className="basis-[60%] text-mono-90 font-bold flex flex-wrap ">
            {value && <div className="basis-[100%]">{value}</div>}
            <div className="basis-[100%]">{secondaryValue}</div>
            <div />
          </div>
        )}
        {value && !secondaryValue && (
          <div className="basis-[60%] text-mono-90 font-bold  ">
            {value && <div className="">{value}</div>}
            {isAddedByCustomer && (
              <div className="w-fit px-1 mt-2 text-tertiary-grey-500 border borer-solid border-tertiary-grey-200 font-light">
                {t('noInRegistry')}
                <div />
              </div>
            )}
            {!isAddedByCustomer && uboValue && (
              <div className="w-fit px-1 mt-2 text-tertiary-grey-500 border borer-solid border-tertiary-grey-200 font-light">
                {t('diffToRegistry')}
                <div />
              </div>
            )}
            <div />
          </div>
        )}

        {!value && renderAdditionalInfo && (
          <div className="basis-[60%] text-mono-90 flex flex-wrap">
            {renderAdditionalInfo}
          </div>
        )}
        <div className="basis-[22%]">
          {isVerified !== undefined && (
            <div className="flex justify-end items-center font-bold">
              <div
                className={`mr-3 ${getVerifyColor(
                  isVerified,
                  isManualKYCPass,
                  isEntityPassed,
                )}`}
              >
                {isManualKYCPass ? 'Manually Verified' : verifyLabel}
              </div>
              <FrankieIcon
                name={statusIcon}
                className={`${getVerifyColor(
                  isVerified,
                  isManualKYCPass,
                  isEntityPassed,
                )}`}
                size="md"
              />
            </div>
          )}
        </div>
      </div>

      {openDropdown && getAdditionalInfo && (
        <div
          className={`basis-[100%] mt-4 ml-[26%] ${
            type === DetailViewTypes.RULESET_MATCHES ? 'font-bold' : ''
          }`}
        >
          {getAdditionalInfo}
          <div className="flex flex-wrap gap-3 my-3">
            {(checkSource as string[] | undefined)?.map(source => (
              <div
                key={source}
                className=" border border-tertiary-grey-300 text-tertiary-grey-500 p-1 leading-tight rounded-sm"
              >
                {source}
              </div>
            ))}
          </div>
        </div>
      )}
      {openDropdown && value && renderAdditionalInfo && (
        <div
          data-qa={applicantDetailViewQa.openAdditional}
          className="basis-[100%] mt-4 ml-[26%]"
        >
          {renderAdditionalInfo}
        </div>
      )}
      {openDropdown && uboValue && uboTitle && (
        <div
          data-qa={applicantDetailViewQa.openUboDetail}
          className="basis-[100%] mt-4 ml-[26%]"
        >
          <div className="text-[13px] text-tertiary-grey-400">{uboTitle}</div>
          <div className="text-sm font-bold">{uboValue}</div>
        </div>
      )}
      {openDropdown && openAdditionalInfo}
    </div>
  )
}
