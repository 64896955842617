import { getLocalStorageState, StorageKeyTypes } from '../../local-storage'
import { ITracker } from '../model/tracker.model'
import {
  TrackerNameTypes,
  TrackingEventsPerTracker,
  TrackingEventsTypeF2Common,
  TrackingEventsTypes,
  TrackingEventsTypesF1,
} from '../model/tracking-events.enum'

export class TrackingManagerService {
  private trackers: ITracker[] = []

  register(tracker: ITracker) {
    this.trackers.push(tracker)
  }

  isTrackerExists(name: TrackerNameTypes) {
    return this.trackers.some(item => item.name === name)
  }

  track(name: string, options?: Record<string, string>) {
    const [pageData] = getLocalStorageState(StorageKeyTypes.PageData)

    const isFrankie2Customer = !!(
      pageData as { features?: { frankie2customer?: boolean } } | undefined
    )?.features?.frankie2customer

    // not emit F1 events for Frankie2 customers and F2 common events for non-Frankie2 customers
    if (isFrankie2Customer) {
      if (TrackingEventsTypesF1.includes(name as TrackingEventsTypes)) {
        return
      }
    } else if (
      TrackingEventsTypeF2Common.includes(name as TrackingEventsTypes)
    ) {
      return
    }

    console.log('Tracking event:', name, options, 'command')

    this.trackers.forEach(item => {
      if (
        TrackingEventsPerTracker[item.name].includes(
          name as TrackingEventsTypes,
        )
      ) {
        // replace all the templated strings

        let templatedName = name

        for (const key in options) {
          if (templatedName.includes(`{${key}}`))
            templatedName = templatedName.replaceAll(`{${key}}`, options[key])
        }

        item.track(templatedName)
      }
    })
  }
}

export const trackingManager = new TrackingManagerService()
