import React, { useMemo } from 'react'

import { ApplicantId } from 'entities/applicant'

import { DateFormatTypes, formatDate } from 'shared/date-time'
import { useI18n } from 'shared/i18n'

import { APPLICANT_BUSINESS_OWNERSHIP_KEY } from '../../applicant-business-ownership.key'
import { applicantBusinessOwnershipEn } from '../../locale/applicant-business-ownership.en'
import { ApplicantOwnershipHolderTypes } from '../../model/applicant-business-ownership.model'
import { applicantBusinessOwnerShipQa } from '../../qa/applicant-business-ownership.qa'
import { useApplicantBusinessOwnershipQuery } from '../../state/applicant-business-ownership-query/applicant-business-ownership.query'
import { ApplicantAddBusinessEntity } from '../applicant-add-business-entity/applicant-add-business-entity'
import { BusinessOwnershipDataGrid } from '../business-ownership-data-grid/business-ownership-data-grid'

type ApplicantOwnershipRecordData = {
  ownershipType: {
    label?: string
    value: ApplicantOwnershipHolderTypes
    emptyText?: string
  }[]
  content: {
    heading: string
    subHeading: string
    description?: string
    emptyText?: string
  }
}[]

type Props = {
  applicantId: ApplicantId
  className?: string
}

export function ApplicantBusinessOwnership({
  applicantId,
  className = '',
}: Props) {
  const t = useI18n([APPLICANT_BUSINESS_OWNERSHIP_KEY], {
    keys: applicantBusinessOwnershipEn,
  })

  const { data: ownershipData } = useApplicantBusinessOwnershipQuery({
    applicantId,
  })

  const ownershipRecordData = useMemo(() => {
    const data: ApplicantOwnershipRecordData = [
      {
        ownershipType: [{ value: 'ubos' }],
        content: {
          heading: t('ubo.heading'),
          subHeading: t('ubo.subHeading'),
          description: t('ubo.description'),
          emptyText: t('ubo.emptyText'),
        },
      },
      {
        ownershipType: [{ value: 'beneficialOwners' }],
        content: {
          heading: t('otherOwners.heading'),
          subHeading: t('otherOwners.subHeading'),
          emptyText: t('otherOwners.emptyText'),
        },
      },
      {
        ownershipType: [{ value: 'nonIndividuals' }],
        content: {
          heading: t('nonIndividualOwners.heading'),
          subHeading: t('nonIndividualOwners.subHeading'),
          emptyText: t('nonIndividualOwners.emptyText'),
        },
      },
      {
        ownershipType: [{ value: 'linkedCompanies' }],
        content: {
          heading: t('linkedCompanies.heading'),
          subHeading: t('linkedCompanies.subHeading'),
          emptyText: t('linkedCompanies.emptyText'),
        },
      },
      {
        ownershipType: [{ value: 'officeHolders' }],
        content: {
          heading: t('officeHolders.heading'),
          subHeading: t('officeHolders.subHeading'),
          emptyText: t('officeHolders.emptyText'),
        },
      },
      {
        ownershipType: [
          {
            value: 'shareCapitalGroup',
            label: t('shareCapital.issued'),
            emptyText: t('shareCapital.emptyText.shareCapital'),
          },
          {
            value: 'shareHolders',
            label: t('shareCapital.shareHolders'),
            emptyText: t('shareCapital.emptyText.shareHolder'),
          },
        ],
        content: {
          heading: t('shareCapital.heading'),
          subHeading: t('shareCapital.subHeading'),
        },
      },
      {
        ownershipType: [{ value: 'associatedParties' }],
        content: {
          heading: t('associatedParties.heading'),
          subHeading: t('associatedParties.subHeading'),
          emptyText: t('associatedParties.emptyText'),
        },
      },
    ]

    return data
  }, [t])

  return (
    <div
      className={`text-tertiary-grey-700 ${className}`}
      data-qa={applicantBusinessOwnerShipQa.container}
    >
      {ownershipRecordData.map(({ content, ownershipType }) => (
        <div
          className="py-4"
          key={ownershipType.map(item => item.value).join('-')}
        >
          <div className="text-tertiary-grey-800 font-bold text-xl">
            {content.heading}
          </div>
          <div className="pt-1 pb-2 text-tertiary-grey-700 text-sm">
            <div>{content.subHeading}</div>
            {content.description && <div>{content.description}</div>}
          </div>

          {ownershipType.map(({ label, value, emptyText }) => (
            <div key={value}>
              {label && (
                <div className="pb-2 pt-4 text-tertiary-grey-800 font-bold text-md">
                  {label}
                </div>
              )}
              <BusinessOwnershipDataGrid
                key={value}
                applicantId={applicantId}
                ownerShipHolderType={value}
                emptyText={emptyText ?? content.emptyText ?? t('noEntityData')}
              />
            </div>
          ))}
        </div>
      ))}
      <ApplicantAddBusinessEntity className="mt-4" applicantId={applicantId} />

      <div className="my-8">
        <span className="text-tertiary-grey-500 font-medium pr-2">
          {t('lastUpdated')}
        </span>
        <span
          className="font-bold pr-4"
          data-qa={applicantBusinessOwnerShipQa.createdDate}
        >
          {ownershipData?.reportCreatedAt
            ? formatDate(
                ownershipData.reportCreatedAt,
                DateFormatTypes.MonthDateAndTime,
              )
            : '-'}
        </span>
        <span className="text-tertiary-grey-500 font-medium pr-2">
          {t('frankieId')}
        </span>
        <span className="font-bold">{applicantId}</span>
      </div>
    </div>
  )
}
