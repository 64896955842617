export const riskStatusOptionEn = {
  low: 'Low',
  medium: 'Medium',
  high: 'High',
  unknown: 'Unknown',
  unacceptable: 'Unacceptable',
}

export const applicantRiskScoreEn = {
  heading: {
    onboarding: 'Onboarding Risk Score',
    fraudMonitoring: 'Fraud Monitoring Risk Score',
    amlMonitoring: 'AML Monitoring Risk Score',
    manual: 'Manual Risk Score',
    overall: 'Overall Risk Score',
  },

  field: {
    factor: 'Factor',
    value: 'Value',
    policy: 'Policy',
    score: 'Score',
    level: 'Level',
  },

  subheading: 'The following risk assessment has been performed',
  manual: 'Manual',
  risk: 'Risk',
  manualOverride: 'Manual Override',
  loading: 'Loading...',
  refreshing: 'Refreshing...',
  updating: 'Updating...',
  comment: 'Comment',
  cancel: 'Cancel',
  noData: 'No risk indicators',
  changeMatch: 'Change match status to',
  changeStatus: 'Change status',
  changeStatusSuccess: 'Successfully overridden risk level to {{level}}',
  typeYourComment: 'Type your comment here',
  errorChangeLevel: 'Error occurred changing risk level',

  ...riskStatusOptionEn,
}
