import { FlowIdTypes } from 'entities/entity'

import { I18nKeys, useI18n } from 'shared/i18n'

import { INDIVIDUAL_SEND_VERIFICATION_LINK_KEY } from '../individual-send-verification-link.key'
import { individualSendVerificationLinkEn } from '../locale/individual-send-verification-link.en'

type FlowIdOption = {
  label: I18nKeys<typeof individualSendVerificationLinkEn>
  value: FlowIdTypes
}

export const flowIdOptions: FlowIdOption[] = [
  {
    label: 'flows.kyc',
    value: FlowIdTypes.MANUAL_KYC,
  },
  {
    label: 'flows.idv',
    value: FlowIdTypes.IDV,
  },
  {
    label: 'flows.ocr',
    value: FlowIdTypes.OCR_ONLY,
  },
]

export type SendLinkFormType = {
  flowId: FlowIdTypes
  comment: string
  number: string
  country: string
  livenessDetection?: boolean
  smsContent: string
}

export type SendLinkPayload = SendLinkFormType & { entityId: string }

export type SendLinkContentType = {
  smsContent: string
  flowIds: string[]
  expiryIn: number
}

export type OnboardingURLPayloadType = {
  flowId: FlowIdTypes
  entityId: string
}

export const useSendLinkTranslation = () => {
  const t = useI18n([INDIVIDUAL_SEND_VERIFICATION_LINK_KEY], {
    keys: individualSendVerificationLinkEn,
  })
  return t
}
