import React, { useMemo, useState } from 'react'

import { endOfDay } from 'date-fns'

import { FrankieTextField } from 'frankify/src'

import {
  endOfToday,
  endOfYesterday,
  startOfToday,
  startOfYesterday,
  subDays,
} from 'shared/date-time'
import { I18nKeys, useI18n } from 'shared/i18n'

import {
  FILTERS_COMMON_KEY,
  filtersCommonEn,
} from '../../locale/filters-common.en'
import { CheckboxAsButton } from '../checkbox-as-button/checkbox-as-button'

export interface IDateRange {
  startDate: Date | null
  endDate: Date | null
  dateOption?: DateOption
}

type DateOption =
  | 'custom'
  | 'today'
  | 'yesterday'
  | 'past7Days'
  | 'past30Days'
  | 'pastYear'
  | 'all'

enum ErrorTypes {
  StartDateRequired = 'startDateRequired',
  EndDateRequired = 'endDateRequired',
  StartDateBeforeEndDate = 'startDateBeforeEndDate',
}

const errorMessages: Record<ErrorTypes, I18nKeys<typeof filtersCommonEn>> = {
  [ErrorTypes.StartDateRequired]: 'form.date.startValidation',
  [ErrorTypes.EndDateRequired]: 'form.date.endValidation',
  [ErrorTypes.StartDateBeforeEndDate]: 'form.date.startBeforeEndValidation',
}

export const validateDateRange = (
  range?: IDateRange,
): ErrorTypes | undefined => {
  if (!range) {
    return undefined
  }
  const { startDate, endDate } = range
  if (startDate && !endDate) {
    return ErrorTypes.EndDateRequired
  }
  if (endDate && !startDate) {
    return ErrorTypes.StartDateRequired
  }
  if (endDate && startDate && startDate > endDate) {
    return ErrorTypes.StartDateBeforeEndDate
  }
  return undefined
}

export const validateDateRangeRequired = (
  range?: IDateRange,
): string | undefined => {
  const errors = []
  // validation only when date option is custom
  if (range && range.dateOption === 'custom') {
    const { startDate, endDate } = range
    if (!endDate) {
      errors.push(ErrorTypes.EndDateRequired)
    }
    if (!startDate) {
      errors.push(ErrorTypes.StartDateRequired)
    }
    if (startDate && endDate && startDate > endDate) {
      errors.push(ErrorTypes.StartDateBeforeEndDate)
    }
  }
  return errors.length ? errors.join(',') : undefined
}

function getDateOption(value: IDateRange): DateOption | null {
  const start = value.startDate?.toISOString()
  const end = value.endDate?.toISOString()
  if (!start && !end) {
    return null
  }
  if (
    start === startOfToday().toISOString() &&
    end === endOfToday().toISOString()
  ) {
    return 'today'
  }
  if (
    start === startOfYesterday().toISOString() &&
    end === endOfYesterday().toISOString()
  ) {
    return 'yesterday'
  }
  if (
    start === subDays(startOfToday(), 7).toISOString() &&
    end === endOfToday().toISOString()
  ) {
    return 'past7Days'
  }
  if (
    start === subDays(startOfToday(), 30).toISOString() &&
    end === endOfToday().toISOString()
  ) {
    return 'past30Days'
  }
  if (
    start === subDays(startOfToday(), 365).toISOString() &&
    end === endOfToday().toISOString()
  ) {
    return 'pastYear'
  }
  return 'custom'
}

type Props = {
  value: IDateRange
  onChange: (values: IDateRange) => void
  error?: string[]
  testId?: {
    inputFrom?: string
    inputTo?: string
    optionCustom?: string
    optionToday?: string
    optionYesterday?: string
    optionPast7Days?: string
    optionPast30Days?: string
    optionPastYear?: string
    optionAll?: string
  }
}

// eslint-disable-next-line complexity
export function DateSelector({ value, onChange, error, testId }: Props) {
  const t = useI18n([FILTERS_COMMON_KEY], { keys: filtersCommonEn })
  const [dateRange, setDateRange] = useState<DateOption | null>(
    getDateOption(value),
  )

  const showInputs = !!(
    dateRange === 'custom' ||
    (dateRange === null && (value.startDate || value.endDate))
  )

  const endDateError = useMemo(() => {
    const endDateError = error?.find(
      err => err === ErrorTypes.EndDateRequired,
    ) as ErrorTypes | undefined

    return endDateError ? t(errorMessages[endDateError]) : undefined
  }, [error, t])

  const startDateError = useMemo(() => {
    const endDateError = error?.find(err =>
      [
        ErrorTypes.StartDateRequired,
        ErrorTypes.StartDateBeforeEndDate,
      ].includes(err as ErrorTypes),
    ) as ErrorTypes | undefined

    return endDateError ? t(errorMessages[endDateError]) : undefined
  }, [error, t])

  const handleChangeFrom = (e: React.ChangeEvent<HTMLInputElement>) => {
    const startDate = e.target.valueAsDate
    if (startDate) {
      onChange({ ...value, startDate })
    }
  }
  const handleChangeTo = (e: React.ChangeEvent<HTMLInputElement>) => {
    const endDate = e.target.valueAsDate
    // use end of date for to
    if (endDate) {
      const date = endOfDay(endDate)
      onChange({ ...value, endDate: date })
    }
  }

  // eslint-disable-next-line complexity
  const handleSelectRange = (selectedRange: DateOption | null) => () => {
    const newRange = selectedRange === dateRange ? null : selectedRange
    setDateRange(newRange)
    switch (newRange) {
      case 'custom':
        onChange({ startDate: null, endDate: null, dateOption: 'custom' })
        break
      case 'all':
      case null:
        onChange({ startDate: null, endDate: null })
        break
      case 'today':
        onChange({ startDate: startOfToday(), endDate: endOfToday() })
        break
      case 'yesterday':
        onChange({ startDate: startOfYesterday(), endDate: endOfYesterday() })
        break
      case 'past7Days':
        onChange({
          startDate: subDays(startOfToday(), 7),
          endDate: endOfToday(),
        })
        break
      case 'past30Days':
        onChange({
          startDate: subDays(startOfToday(), 30),
          endDate: endOfToday(),
        })
        break
      case 'pastYear':
        onChange({
          startDate: subDays(startOfToday(), 365),
          endDate: endOfToday(),
        })
        break
      default:
        break
    }
  }

  return (
    <div>
      <div className="flex flex-row flex-wrap items-center gap-3">
        <CheckboxAsButton
          onChange={handleSelectRange(null)}
          checked={dateRange === null}
          testId={{ input: testId?.optionAll }}
        >
          {t('form.date.all')}
        </CheckboxAsButton>
        <CheckboxAsButton
          onChange={handleSelectRange('custom')}
          checked={dateRange === 'custom'}
          testId={{ input: testId?.optionCustom }}
        >
          {t('form.date.custom')}
        </CheckboxAsButton>
        <CheckboxAsButton
          onChange={handleSelectRange('today')}
          checked={dateRange === 'today'}
          testId={{ input: testId?.optionToday }}
        >
          {t('form.date.today')}
        </CheckboxAsButton>
        <CheckboxAsButton
          onChange={handleSelectRange('yesterday')}
          checked={dateRange === 'yesterday'}
          testId={{ input: testId?.optionYesterday }}
        >
          {t('form.date.yesterday')}
        </CheckboxAsButton>
        <CheckboxAsButton
          onChange={handleSelectRange('past7Days')}
          checked={dateRange === 'past7Days'}
          testId={{ input: testId?.optionPast7Days }}
        >
          {t('form.date.past7Days')}
        </CheckboxAsButton>
        <CheckboxAsButton
          onChange={handleSelectRange('past30Days')}
          checked={dateRange === 'past30Days'}
          testId={{ input: testId?.optionPast30Days }}
        >
          {t('form.date.past30Days')}
        </CheckboxAsButton>
        <CheckboxAsButton
          onChange={handleSelectRange('pastYear')}
          checked={dateRange === 'pastYear'}
          testId={{ input: testId?.optionPastYear }}
        >
          {t('form.date.pastYear')}
        </CheckboxAsButton>
      </div>
      {showInputs && (
        <div className="mt-4 flex flex-row gap-6">
          <FrankieTextField
            onChange={handleChangeFrom}
            className="max-w-[261px]"
            type="date"
            value={value.startDate?.toISOString().split('T')[0]}
            label={t('form.date.from')}
            error={!!startDateError}
            errorText={startDateError}
            testId={{ input: testId?.inputFrom }}
          />
          <FrankieTextField
            onChange={handleChangeTo}
            className="max-w-[261px]"
            type="date"
            value={value.endDate?.toISOString().split('T')[0]}
            label={t('form.date.to')}
            error={!!endDateError}
            errorText={endDateError}
            testId={{ input: testId?.inputTo }}
          />
        </div>
      )}
    </div>
  )
}
