import React, { useMemo, useState } from 'react'

import { FrankieButton } from 'frankify/src'

import { DOCUMENT_KEY, documentEn } from 'entities/document/locale/document.en'

import { DateFormatTypes, formatDate, isValidDate } from 'shared/date-time'
import { convertDataURItoBlob, makeDataURL } from 'shared/file'
import { Show } from 'shared/hoc'
import { useI18n } from 'shared/i18n'

type Data = {
  fileUrl: string
  fileName: string
  mimeType: string
  uploadedOn?: string
}

type Props = Data & {
  additionalData?: Data[]
  hideFileName?: boolean
  fullView?: boolean
  testId?: {
    container?: string
  }
}

/**
 * Document view
 * @description Supports only pdf and images upload
 */
export function DocumentView({
  fileUrl,
  fileName,
  mimeType,
  uploadedOn,
  additionalData,
  hideFileName = false,
  fullView = false,
  testId,
}: Props) {
  const [currentFile, setCurrentFile] = useState(-1)
  const { url, isImage, isVideo } = useMemo(() => {
    if (currentFile === -1 || !additionalData) {
      if (!fileUrl) {
        // Safety check
        return { url: '', isImage: false, isVideo: false }
      }
      const temp_url = makeDataURL(mimeType, fileUrl)
      const blob = convertDataURItoBlob(temp_url)
      const url = window.URL.createObjectURL(blob)
      const isVideo = mimeType.split('/').includes('video')
      const isImage = mimeType.split('/').includes('image')
      return { url, isVideo, isImage }
    }
    if (additionalData[currentFile]) {
      const { fileUrl, mimeType } = additionalData[currentFile]
      if (!fileUrl) {
        // Safety check
        return { url: '', isImage: false, isVideo: false }
      }
      const temp_url = makeDataURL(mimeType, fileUrl)
      const blob = convertDataURItoBlob(temp_url)
      const url = window.URL.createObjectURL(blob)
      const isVideo = mimeType.split('/').includes('video')
      const isImage = mimeType.split('/').includes('image')
      return { url, isVideo, isImage }
    }

    return { url: '', isImage: false, isVideo: false }
  }, [additionalData, currentFile, fileUrl, mimeType])

  const t = useI18n([DOCUMENT_KEY], { keys: documentEn })

  const handleFileNext = () => {
    if ((additionalData?.length ?? 0) - 1 > currentFile) {
      setCurrentFile(prev => prev + 1)
    }
  }

  const handleFilePrev = () => {
    if (currentFile >= 0) {
      setCurrentFile(prev => prev - 1)
    }
  }

  const showUploadedOn = uploadedOn && isValidDate(uploadedOn)

  return (
    <div
      data-qa={testId?.container}
      className="flex flex-col gap-2 h-full w-full"
    >
      {!hideFileName && (
        <div className="text-tertiary-grey-800 font-bold">{fileName}</div>
      )}

      <div
        className={`flex relative flex-grow-1 justify-center p-2${
          fileUrl ? '' : 'w-[500px] h-[350px]'
        } ${
          fullView
            ? 'h-full items-start'
            : 'items-center bg-tertiary-grey-200 border border-tertiary-grey-400'
        }`}
      >
        {!!additionalData?.length && (
          <div className="absolute flex  top-0 right-0">
            <FrankieButton
              disabled={currentFile === -1}
              onClick={handleFilePrev}
              singleIcon={{ name: 'mdiChevronLeft' }}
            />
            <FrankieButton
              onClick={handleFileNext}
              disabled={currentFile === additionalData.length - 1}
              singleIcon={{ name: 'mdiChevronRight' }}
            />
          </div>
        )}
        {fileUrl && (
          <Show>
            <Show.When isTrue={isImage}>
              <img
                className={`object-contain ${
                  fullView ? '' : 'w-full  max-h-[342px]'
                }`}
                src={url}
                alt="uploaded-file"
              />
            </Show.When>

            <Show.When isTrue={isVideo}>
              {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
              <video className="w-[500px] h-[300px]" controls>
                <source src={url} type={mimeType} />
              </video>
            </Show.When>

            <Show.Else>
              <iframe
                className="h-[calc(100vh-200px)] w-full"
                src={url}
                title="uploaded-file"
              />
            </Show.Else>
          </Show>
        )}
      </div>
      {showUploadedOn && (
        <div className="text-sm basis-[100%] mt-2">
          {t('uploadedOn', {
            date: formatDate(uploadedOn, DateFormatTypes.fullDateWithTime),
          })}
        </div>
      )}
    </div>
  )
}
