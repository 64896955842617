import {
  AddressCategoryTypes,
  ApplicantCheckSummaryResponse,
  ApplicantResponse,
  Name,
} from 'entities/applicant'

import { I18nFunction } from 'shared/i18n'
import { Nullable } from 'shared/typescript'

import { applicantPersonalInfoEn } from '../locale/applicant-personal-info.en'

export enum DetailViewTypes {
  DOCUMENT_INFO = 'DOCUMENT_INFO',
  RULESET_MATCHES = 'RULESET_MATCHES',
  PERSONAL_INFO = 'PERSONAL_INFO',
}

export const getVerifyColor = (
  isVerified: Nullable<boolean>,
  isManualKYC: Nullable<boolean>,
  isEntityPassed: Nullable<boolean>,
) => {
  if (isManualKYC || isVerified) return 'text-tertiary-green-400'
  if (isVerified === null || isEntityPassed) return 'text-tertiary-grey-400'
  return 'text-tertiary-red-500'
}

export const getVerifyBgColor = (
  isVerified: Nullable<boolean>,
  isManualKYC: Nullable<boolean>,
  isEntityPassed: Nullable<boolean>,
) => {
  if (isManualKYC || isVerified) return 'bg-tertiary-green-400'
  if (isVerified === null || isEntityPassed) return 'bg-tertiary-grey-400'
  return 'bg-tertiary-red-500'
}

export const bannerBgColor = (value: Nullable<boolean>) => {
  if (value) return 'bg-tertiary-green-100'
  if (value === false) return 'bg-tertiary-red-50'
  return 'bg-tertiary-grey-100'
}

export const getStatusIcon = (
  isVerified: Nullable<boolean>,
  isManualKYC: Nullable<boolean>,
) => {
  if (isManualKYC) return 'mdiCheckCircle'
  if (isVerified === null) return 'mdiAlertCircle'
  return isVerified ? 'mdiCheckCircle' : 'mdiCloseCircle'
}

export const bannerTextColor = (value: Nullable<boolean>) => {
  if (value) return 'text-tertiary-green-400'
  if (value === false) return 'text-tertiary-red-500'
  return 'text-tertiary-grey-400'
}

export enum StatusTypes {
  Verified = 'verified',
  Checked = 'checked',
}

export const checkManualKYCPass = (
  kycMethod: string,
  checkResults: ApplicantCheckSummaryResponse['checkResults'],
) => {
  if (kycMethod === 'manual' && checkResults.length) {
    const manualKYCResult = checkResults.find(i => i.type === 'manual')
    return !!(
      manualKYCResult?.result.type &&
      ['pass', 'passed'].includes(manualKYCResult.result.type.toLowerCase())
    )
  }
  return false
}

export enum GenderMapTypes {
  M = 'Male',
  F = 'Female',
  U = 'Unknown',
  O = 'Other',
}

// ["pass", "no-match", "partial-match", "unchecked"]
export enum BannerStatesTypes {
  Pass = 'pass',
  NoMatch = 'no-match',
  PartialMatch = 'partial-match',
  Unchecked = 'unchecked',
}

export const getApplicantName = (
  data: ApplicantResponse['applicantDetails']['name'],
) => `${data.givenName ?? ''} ${data.middleName ?? ''} ${data.familyName ?? ''}`

export const getAddress = (
  applicantData: ApplicantResponse,
  addressType: AddressCategoryTypes,
) => {
  const allAddress = applicantData.applicantDetails.addresses

  const isAddressTypeExist = allAddress?.some(
    address => address.addressType !== null,
  )
  if (
    !isAddressTypeExist &&
    allAddress &&
    addressType === AddressCategoryTypes.current
  ) {
    return { ...allAddress[0], addressType: AddressCategoryTypes.current }
  }

  const address =
    allAddress?.filter(address => address.addressType === addressType)?.[0] ??
    null

  return address
}

export const isAddressAddedByCustomer = (
  applicantData: ApplicantResponse,
  addressType: string,
) => {
  const address = applicantData.applicantDetails.addresses?.find(
    address => address.addressType === addressType,
  )
  if (!address) return null
  return address.isAddedByCustomer
}

export const getUboDisplayName = (name: Nullable<Name>) => {
  if (name) {
    const fullName = [name.givenName, name.middleName, name.familyName]
      .filter(i => !!i)
      .join(' ')
    return fullName
  }
  return null
}

export const getUboAddressLongForm = (
  applicantData: ApplicantResponse,
  addressType: AddressCategoryTypes,
) => {
  const addresses = applicantData.applicantDetails.originalUboDetails?.addresses
  if (!addresses?.length) return null

  const longForm = addresses.find(
    address => address.addressType === addressType,
  )?.longForm

  return longForm ?? null
}

export const isResolveDuplicateEntity = (applicantData: ApplicantResponse) =>
  applicantData.checkSummary.issues.DUPLICATE_RESOLVED &&
  applicantData.checkSummary.alertList?.some(issue =>
    ['duplicate'].includes(issue.term),
  )

export const isBlocklistEntity = (applicantData: ApplicantResponse) => {
  const alertList = applicantData.checkSummary.alertList ?? []
  return !!alertList.find(alert => alert.term === 'blacklist')
}

export const isResolvedBlacklistEntity = (applicantData: ApplicantResponse) =>
  applicantData.checkSummary.issues.DUPLICATE_RESOLVED &&
  isBlocklistEntity(applicantData)

export const isManualBlocklist = (applicantData: ApplicantResponse) =>
  applicantData.checkSummary.issues.MANUAL_BLOCKLIST

export const isProfileInActive = (applicantData: ApplicantResponse) =>
  applicantData.checkSummary.status.key === 'INACTIVE'

export const isEditProfileDisabled = (applicantData: ApplicantResponse) =>
  !!(
    isProfileInActive(applicantData) ||
    isManualBlocklist(applicantData) ||
    isResolvedBlacklistEntity(applicantData) ||
    isResolveDuplicateEntity(applicantData)
  )

export const getTooltipDesc = (
  applicantData: ApplicantResponse,
  t: I18nFunction<typeof applicantPersonalInfoEn>,
) => {
  const profileStatus = isProfileInActive(applicantData)

  if (profileStatus) {
    return t('inactiveProfileTooltip')
  }
  if (isResolveDuplicateEntity(applicantData)) {
    return t('trueDuplicate')
  }
  if (
    isResolvedBlacklistEntity(applicantData) ||
    isManualBlocklist(applicantData)
  ) {
    return t('blocklistedProfile')
  }

  return ''
}
