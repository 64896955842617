import React from 'react'

import { NavLink } from 'react-router-dom'

import { FrankieBadge, FrankieButton } from 'frankify/src'

import { useApplicantPaths } from 'entities/applicant'
import { WorkflowStepResultEnum } from 'entities/entity'
import { PermissionTypes } from 'entities/role'
import { useHasPermission } from 'entities/session'

import { useI18n } from 'shared/i18n'
import { TrackingEventsTypes, trackingManager } from 'shared/tracking'

import { workflowEventsEn } from '../../locale/workflow-events.en'
import { passVariants } from '../../model/applicant-workflow-events.model'
import { verificationHeaderQa } from '../../qa/applicant-workflow-events.qa'
import { WORKFLOW_EVENTS_KEY } from '../../workflow-events.key'
import { IconConfig } from '../entity-verification-helper/entity-verification-helper'

type Props = {
  eventResult?: WorkflowStepResultEnum
  isArchived?: boolean
  isManuallyVerified?: boolean
}

export function VerificationHeader({
  eventResult,
  isArchived,
  isManuallyVerified,
}: Props) {
  const t = useI18n([WORKFLOW_EVENTS_KEY], {
    keys: workflowEventsEn,
  })

  const { getApplicantPath } = useApplicantPaths()

  const { editIndividualPermission } = useHasPermission({
    editIndividualPermission: PermissionTypes.ManualIdvUpdateAll,
  })

  const shouldShowResolveCta =
    editIndividualPermission &&
    eventResult &&
    !isArchived &&
    !isManuallyVerified &&
    !passVariants.includes(eventResult)

  return (
    <div
      id="KYC"
      className="flex justify-between mb-4"
      data-qa={verificationHeaderQa.container}
    >
      <div className="flex items-center gap-3 w-fit">
        <IconConfig type={eventResult} />
        <div
          className="font-bold text-xl text-tertiary-grey-800"
          data-qa={verificationHeaderQa.verificationEventTextTitle}
        >
          {t('verificationEventText.title')}
        </div>
        {eventResult === WorkflowStepResultEnum.UNCHECKED && (
          <FrankieBadge
            text={t('verificationEventText.badgeText')}
            theme="grey"
            className="!bg-tertiary-grey-100 !text-tertiary-grey-700"
            testId={{
              badge: verificationHeaderQa.verificationEventTextBadgeText,
            }}
          />
        )}

        {isManuallyVerified && (
          <FrankieBadge text={t('statusText.manuallyVerified')} theme="green" />
        )}
      </div>

      {shouldShowResolveCta && (
        <NavLink to={getApplicantPath('resolveEntity')}>
          <FrankieButton
            intent="secondary"
            onClick={() => {
              trackingManager.track(
                TrackingEventsTypes.WorkflowEventEntityVerificationResolveIndividual,
              )
            }}
            className="border-none font-semibold"
            testId={{ button: verificationHeaderQa.verificationEventTextCta }}
          >
            {t('verificationEventText.cta')}
          </FrankieButton>
        </NavLink>
      )}
    </div>
  )
}
