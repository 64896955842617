import { useMutation } from '@tanstack/react-query'

import { useApplicantDataQuery } from 'entities/applicant'
import { organisationApi } from 'entities/organisation'

import { ErrorResponse } from 'shared/http'
import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'

import { APPLICANT_BIOMETRICS_OCR_KEY } from '../../applicant-biometrics-ocr.key'
import { applicantBiometricsOcrEn } from '../../locale/applicant-biometrics-ocr.en'
import { useApplicantBiometricsOcrChecksQuery } from '../applicant-biometrics-ocr-check-state/applicant-biometrics-ocr-check.state'

type Args = {
  applicantId: string
}

export const useApplicantBiometricReRunCheck = ({ applicantId }: Args) => {
  const t = useI18n([APPLICANT_BIOMETRICS_OCR_KEY], {
    keys: applicantBiometricsOcrEn,
  })

  const { updateChecksData } = useApplicantBiometricsOcrChecksQuery({
    applicantId,
  })

  const { refetch: refetchApplicantData } = useApplicantDataQuery({
    applicantId,
  })

  const mutation = useMutation({
    mutationFn: () =>
      organisationApi.checkAmlPep(applicantId, {
        forceCheck: false,
        noInvalidate: true,
        uploadDocs: false,
      }),

    onSuccess: () => {
      void refetchApplicantData()
      updateChecksData({ showTriggerCheckBanner: false })
      notification.success(t('success.reRunCheck'))
    },
    onError: (err: ErrorResponse) => {
      notification.error(err.response?.data.message)
    },
  })

  return mutation
}
