import { Nullable } from 'shared/typescript'

import { IssueTerm } from './applicant-issue.model'
import { RiskLevelTermTypes } from './applicant-risk.model'
import { EntityStatusRawTypes } from './applicant-status.model'

export type ApplicantId = string

// Frontend
export type FrankieApplicant = {
  id: ApplicantId
  type: 'individual' | 'organisation'
  name: string
  status: string
  issues: string[]
  riskLevel: string
  created: string
  updated: string
  recipe: string
  assignee: string
}

export enum ApplicantEntityTypes {
  Organisation = 'ORGANISATION',
  Individual = 'INDIVIDUAL',
}

// Backend
export type FrankieRawApplicant = {
  entityName: Nullable<string>
  entityType: ApplicantEntityTypes
  customerReference: Nullable<string>
  entityId: string | null
  applicantStatus: {
    key: EntityStatusRawTypes
    type: string
  }
  alertList: {
    term: IssueTerm
  }[]
  riskLevel: {
    term: RiskLevelTermTypes
    label: string
  }
  createdDate: string
  timestamp: string
  profileName: string // -> recipe
  assignee: Nullable<string>
  extraData: object
}

export type F2ApplicantTabTypes =
  | 'workflowEvents'
  | 'editEntity'
  | 'resolveEntity'
  | 'profileInsight'
  | 'amlHistory'
  | 'resolveAmlIssue'
  | 'amlScreening'

export type ApplicantTabTypes =
  | 'generalInformation'
  | 'personalInfoKyc'
  | 'blocklistedInfo'
  | 'blocklistMatches'
  | 'pepSanctions'
  | 'businessOwnership'
  | 'riskIndicators'
  | 'biometryOcr'
  | 'fraudCheck'
  | 'potentialMatches'
  | 'duplicateMatches'
  | 'transactions'
  | 'characteristics'
  | 'auditReport'
  | 'supportingDocuments'
  | 'iKybAmlScreening'
  | F2ApplicantTabTypes

/**
 * Routes or pages not directly visible as tabs
 */
export type AdditionalApplicantPathTypes = 'manualIdv' | 'editBusiness'

export type ApplicantRouteConfig<TPathValue = string> = {
  applicantIdParamKey: ApplicantId
  paths: Record<ApplicantTabTypes | AdditionalApplicantPathTypes, TPathValue>
}

export type ApplicantHostedFlowPayload = {
  pushToSms?: boolean
  smsPhoneNumber?: Nullable<string>
  comment?: Nullable<string>
  recipe: string
}

export type SearchApplicantParams = {
  appendList?: boolean
  entityIdFilter?: string
  limit?: number
  matchStatusFilter?: string
  nameFilter?: string
  entityTypeFilter?: ApplicantEntityTypes
  stage?: 'monitoring' | 'onboarding'
  nextPageMarker?: Nullable<number>
}

export type SearchApplicantResponse = {
  nextPageMarker: Nullable<number>
  applicants: FrankieRawApplicant[]
}
