import { ApplicantAddressResponse } from './applicant-response.model'

export enum AdditionalAddressCategoryTypes {
  RESIDENTIAL = 'RESIDENTIAL', // Some how this is getting used if RESIDENTIAL1 is not found as current address
}

export enum AddressCategoryTypes {
  current = 'RESIDENTIAL1',
  previous = 'RESIDENTIAL2',
  mailing = 'POSTAL',
}

export enum AddressCategoryReverseTypes {
  RESIDENTIAL1 = 'current',
  RESIDENTIAL2 = 'previous',
  POSTAL = 'mailing',
}

export const getLongAddressName = (
  addresses: ApplicantAddressResponse[],
  addressType: AddressCategoryTypes,
) =>
  addresses.find(address => address.addressType === addressType)?.longForm || ''
