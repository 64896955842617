import { AxiosError } from 'axios'

export enum ErrorCodeTypes {
  E401 = 401,
  E403 = 403,
  E404 = 404,
  E500 = 500,
  E503 = 503,
}

type CommonErrorResponse = { requestId: string }
export type CommonErrorType<T = CommonErrorResponse> = AxiosError<{
  responseData: T & CommonErrorResponse
}>
