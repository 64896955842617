import React from 'react'

import classNames from 'classnames'

import { RiskLevel } from 'entities/entity'

import { useI18n } from 'shared/i18n'

import { workflowEventsEn } from '../../../locale/workflow-events.en'
import { getRiskLevelConfig } from '../../../model/applicant-workflow-events-mapping.model'
import { WORKFLOW_EVENTS_KEY } from '../../../workflow-events.key'

type Props = {
  riskLevel?: RiskLevel
  testId?: {
    container?: string
    circleIconContainer?: string
    riskLevelContainer?: string
  }
}
export function RiskLevelComponent({ riskLevel, testId }: Props) {
  const t = useI18n(WORKFLOW_EVENTS_KEY, { keys: workflowEventsEn })
  if (!riskLevel) return null
  return (
    <span className="flex gap-2 items-center" data-qa={testId?.container}>
      <div
        data-qa={testId?.circleIconContainer}
        className={classNames(
          `h-3 w-3 rounded-full ${
            getRiskLevelConfig(riskLevel, t).circleStyle
          }`,
        )}
      />
      <div
        data-qa={testId?.riskLevelContainer}
        className={classNames(
          `font-semibold ${getRiskLevelConfig(riskLevel, t).textStyle}`,
        )}
      >
        {getRiskLevelConfig(riskLevel, t).text}
      </div>
    </span>
  )
}
