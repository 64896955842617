import { useQuery, useQueryClient } from '@tanstack/react-query'

import { useHasFeatureFlag } from 'entities/session'

import { entityApi } from '../../api/entity.api'
import {
  IndividualsEntityResponse,
  ServiceProfile,
} from '../../model/entity.model'
import {
  IProfile,
  IProfileResponse,
  PROFILES_QUERY,
} from '../../model/profile.model'

export const EntityDataQueryKey = 'entity-data-query'

export const useEntityDataQuery = (entityId?: string, level?: 'base64') =>
  useQuery({
    queryKey: [EntityDataQueryKey, entityId, level],
    enabled: !!entityId,
    queryFn: async () => {
      const { data } = await entityApi.getEntity(entityId!, level)
      return data
    },
  })

export const useFrankie2R2Customer = () => {
  const { isFrankie2, isFrankie2R2 } = useHasFeatureFlag({
    isFrankie2: ['frankie2customer'],
    isFrankie2R2: ['frankie2customerR2'],
  })

  return isFrankie2R2 && isFrankie2
}

export const useUpdateProfileData = (entityId?: string) => {
  const queryClient = useQueryClient()
  const updateProfileData = (serviceProfile?: ServiceProfile) => {
    if (serviceProfile) {
      queryClient.setQueriesData(
        [EntityDataQueryKey, entityId],
        (oldData?: IndividualsEntityResponse) => {
          if (oldData) {
            return { ...oldData, serviceProfiles: [serviceProfile] }
          }
          return oldData
        },
      )

      queryClient.setQueriesData(
        [PROFILES_QUERY],
        (oldData?: { pages?: IProfileResponse[] }) => {
          if (oldData?.pages) {
            for (const page of oldData.pages) {
              for (const match of page.matches) {
                if (match.serviceProfile.entityId === entityId) {
                  match.serviceProfile = serviceProfile as unknown as IProfile
                }
              }
            }
          }
          return oldData
        },
      )
    }
  }

  return { updateProfileData }
}
