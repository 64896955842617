import React, { useMemo } from 'react'

import cx from 'classnames'
import { navigateToUrl } from 'single-spa'

import { FrankieButton, FrankieIcon, FrankiePopover } from 'frankify/src'

import { CustomerChildId } from 'entities/organisation'
import { useSessionQuery } from 'entities/session'

import { useI18n } from 'shared/i18n'

import { ACCOUNT_CHANGE_KEY } from '../../account-change.key'
import { accountChangeQa } from '../../qa/account-change.qa'
import { useViewAsChildMutation } from '../../state/view-as-child.mutation'

type Account = {
  name: string
  id: CustomerChildId
  isParent: boolean
}

type Props = {
  applicantsPath: string
}

export function AccountSelector({ applicantsPath }: Props) {
  const t = useI18n(ACCOUNT_CHANGE_KEY)
  const { data: session } = useSessionQuery()
  const { mutateAsync: viewAsChild, isLoading } = useViewAsChildMutation()

  const accounts = useMemo<Account[]>(() => {
    if (!session?.organisation) return []

    const isChildAccount = !session.organisation.isRoot
    const hasParent = session.organisation.parent

    if (isChildAccount && hasParent) {
      return [
        {
          name: session.organisation.parent?.name ?? '',
          id: String(session.organisation.parent?.id ?? 0),
          isParent: true,
        },
      ]
    }

    return [
      {
        name: session.organisation.name,
        id: session.organisation.customerChildId,
        isParent: true,
      },
      ...(session.organisation.children?.map(child => ({
        name: child.name,
        id: child.customerChildId,
        isParent: false,
      })) ?? []),
    ]
  }, [session])

  const activeChildAccountId = useMemo<CustomerChildId>(() => {
    if (!session?.user) return null
    return session.user.activeChildCustomerId
  }, [session])

  const currentAccount = accounts.find(acc => acc.id === activeChildAccountId)
  const handleSetActiveChildAccount =
    (customerChildId: CustomerChildId) => async () => {
      await viewAsChild({ customerChildId })
      navigateToUrl(applicantsPath)
      window.location.reload()
    }

  const isChildAccount = !session?.organisation.isRoot
  const childAccountName = session?.organisation.name

  return (
    <div>
      <FrankiePopover
        // eslint-disable-next-line react/no-unstable-nested-components
        trigger={open => (
          <FrankieButton
            testId={{ button: accountChangeQa.accountSelectorCta }}
            noStyles
            className={`max-w-[216px] w-full h-[36px] rounded-sm p-2 ${
              open ? 'bg-primary-50' : 'hover:bg-tertiary-grey-100'
            } `}
          >
            <div className="flex w-full justify-between items-center gap-1">
              <div className="min-w-0 truncate text-sm text-tertiary-grey-700 font-semibold">
                {currentAccount?.name ?? childAccountName}
              </div>
              <FrankieIcon
                size="xs"
                name={open ? 'mdiChevronUp' : 'mdiChevronDown'}
              />
            </div>
          </FrankieButton>
        )}
      >
        <div className="bg-mono-white flex flex-col top-0 right-0 w-[216px] pt-3 pb-2 shadow-md rounded">
          <div className="px-4 mb-1 text-sm font-medium text-tertiary-grey-500">
            {t('title')}
          </div>
          {accounts.map(account => (
            <FrankieButton
              key={account.id}
              noStyles
              disabled={isLoading}
              onClick={
                isChildAccount
                  ? () => null
                  : handleSetActiveChildAccount(account.id)
              }
              className={cx(
                'w-full flex-shrink-0 ',
                { 'hover:bg-primary-50': !isChildAccount },
                { 'cursor-default': isChildAccount },
              )}
              testId={{
                button: account.isParent
                  ? accountChangeQa.parentAccountOption
                  : accountChangeQa.childAccountOption,
              }}
            >
              <div className="flex flex-initial gap-2 flex-nowrap py-1.5 px-4">
                {account.id === activeChildAccountId ? (
                  <FrankieIcon
                    name="mdiCheckCircle"
                    size="sm"
                    className="text-primary-800 flex-shrink-0"
                  />
                ) : (
                  <div className="h-5 w-5">
                    <div className="h-[16.67px] w-[16.67px] rounded-full bg-tertiary-grey-200" />
                  </div>
                )}

                <div>
                  <div
                    className={`text-left text-sm font-semibold ${
                      account.id === activeChildAccountId
                        ? 'text-tertiary-grey-700'
                        : 'text-tertiary-grey-500'
                    }`}
                  >
                    {account.name}
                  </div>
                  <div className="text-left text-xs text-tertiary-grey-500">
                    {account.isParent ? t('parentAccount') : t('childAccount')}
                  </div>
                </div>
              </div>
            </FrankieButton>
          ))}
        </div>
      </FrankiePopover>
    </div>
  )
}
