export const VALIDATION_LOCALE = 'validation'

export const validationEn = {
  email: {
    label: 'Email',
    required: 'Email is required',
    pattern: 'Please enter a valid email address',
  },
  password: {
    label: 'Password',
    required: 'Password is required',
  },
  form: {
    input: {
      email: {
        label: 'Email',
        validation: {
          required: 'Email is required',
          pattern: 'Invalid email format',
        },
      },
      password: {
        label: 'Password',
        validation: {
          required: 'Password is required',
          minLength: 'Password must be minimum 8 characters',
        },
      },
    },
  },
  validation: {
    alphabetic: 'Please enter alphabetic characters only',
    alphaNumeric: 'Please enter alphanumeric values only',
    numeric: 'Please enter numeric values only',
    nonNumeric: 'Please enter a non-numeric character',
  },
}
