import React from 'react'

import { FrankieUtilityImg } from 'shared/FrankieImg'
import { useI18n } from 'shared/i18n'

import { INDIVIDUAL_IDV_CHECK_KEY } from '../../individual-idv-check.key'
import { individualIdvCheckEn } from '../../locale/individual-idv-check.en'
import { DocumentInfo } from '../../state/individual-idv-check/individual-idv-check.state'

const images = [
  'https://imgs.search.brave.com/7YXbKmxQL2eIzdcvXakbf_tAq0crm1yo2QZlCYOgpqA/rs:fit:500:0:0:0/g:ce/aHR0cHM6Ly9pZ2x1/Lm5ldC93cC1jb250/ZW50L3VwbG9hZHMv/MjAxNy8wMS90aGFp/LWRyaXZlcnMtbGlj/ZW5zZS5qcGVn',
  'https://upload.wikimedia.org/wikipedia/commons/5/51/Imitation_official_document.jpg',
]

type Props = {
  documentInfo: DocumentInfo
}

export function IndividualIdvModal({ documentInfo }: Props) {
  const t = useI18n([INDIVIDUAL_IDV_CHECK_KEY], { keys: individualIdvCheckEn })
  const { title, ocrData, entityData } = documentInfo

  return (
    <div>
      <div className="font-bold text-xl leading-[30px] text-tertiary-grey-800 mb-4">
        {title}
      </div>

      <div className="flex gap-6">
        <div className="flex flex-col gap-3">
          <FrankieUtilityImg className="" gallery={images} />

          <div className="text-sm leading-5 text-tertiary-grey-500">
            {t('modal.uploadedOn', {
              via: 'via direct capture',
              date: '10/06/23',
              time: '15:03:32',
            })}
          </div>
        </div>
        <div className="grid grid-rows-[repeat(12,_1fr)] grid-cols-2 grid-flow-col gap-x-4 max-h-[462px] overflow-y-auto [&>*]:bg-tertiary-grey-50 [&>*]:pt-4 [&>*]:px-4 ">
          <div className="text-tertiary-grey-800 font-semibold text-md leading-4 ">
            {t('modal.ocrOutput')}
          </div>

          {ocrData.map((data, idx) => (
            <div
              key={data.label}
              className={`flex flex-col ${
                idx === ocrData.length - 1 ? 'pb-4' : ''
              }`}
            >
              <div
                className={`${
                  data.noMatch ? 'text-danger' : 'text-tertiary-grey-800'
                } font-medium text-sm leading-5`}
              >
                {data.src}
              </div>
              <div
                className={`${
                  data.noMatch
                    ? 'text-tertiary-red-600'
                    : 'text-tertiary-grey-800'
                } text-tertiary-grey-700  max-w-[155px]`}
              >
                {data.label}
              </div>
            </div>
          ))}

          <div className="text-tertiary-grey-800 font-semibold text-md leading-4 ">
            {t('modal.entityData')}
          </div>

          {entityData.map(data => (
            <div key={data.label} className="flex flex-col">
              <div
                className={`${
                  data.noMatch ? 'text-danger' : 'text-tertiary-grey-800'
                } font-medium text-sm leading-5`}
              >
                {data.src}
              </div>
              <div
                className={`${
                  data.noMatch
                    ? 'text-tertiary-red-600'
                    : 'text-tertiary-grey-800'
                } text-tertiary-grey-700 max-w-[155px]`}
              >
                {data.label}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
