import { useCallback, useMemo } from 'react'

import { useQuery, useQueryClient } from '@tanstack/react-query'

import { ApplicantId, useApplicantDataQuery } from 'entities/applicant'
import { useHasFeatureFlag } from 'entities/session'

import { convertIDTypeToHumanReadable } from '../../model/applicant-biometrics-ocr.model'
import { useApplicantBiometricsOcrQuery } from '../applicant-biometrics-ocr-query/applicant-biometrics-ocr.query'

type Args = {
  applicantId?: ApplicantId
}

const BiometricChecksQueryKey = 'biometric-checks-query'

type QueryCheckState = {
  showTriggerCheckBanner: boolean
  showIdvBanner: boolean
}

const defaultCheckState: QueryCheckState = {
  showIdvBanner: false,
  showTriggerCheckBanner: false,
}

/**
 * Custom checks management query for the applicant biometrics OCR checks
 */
export const useApplicantBiometricsOcrChecksQuery = ({ applicantId }: Args) => {
  const queryClient = useQueryClient()

  const queryKey = useMemo(
    () => [BiometricChecksQueryKey, applicantId],
    [applicantId],
  )

  const query = useQuery({
    queryKey,
    queryFn: () => {
      const data = queryClient.getQueryData<QueryCheckState>(queryKey)
      return data ?? defaultCheckState
    },
  })

  const updateChecksData = useCallback(
    (data: Partial<QueryCheckState> = {}) => {
      queryClient.setQueryData(queryKey, { ...query.data, ...data })
    },
    [query.data, queryClient, queryKey],
  )

  return { ...query, updateChecksData }
}

/**
 * Biometrics OCR checks state
 */
export const useApplicantBiometricsOcrChecksState = ({ applicantId }: Args) => {
  const { data: checkQueryData } = useApplicantBiometricsOcrChecksQuery({
    applicantId,
  })

  const { showRefreshResults, hasFacialDuplicatesFeature } = useHasFeatureFlag({
    showRefreshResults: ['biometrics', 'refreshResults'],
    hasFacialDuplicatesFeature: 'facialDuplicatesEnabled',
  })

  const { data: biometryData, isLoading } = useApplicantBiometricsOcrQuery({
    applicantId,
  })

  const { data: applicantData, isLoading: loadingApplicantData } =
    useApplicantDataQuery({ applicantId })

  const { hasBiometrics, idvOverrideResult } = useMemo(
    () => ({
      hasBiometrics: !!biometryData?.biometry.length,
      idvOverrideResult: biometryData?.idvOverrideResult,
    }),
    [biometryData],
  )

  const { hasDigitalDriversLicense } = useMemo(
    () => ({
      hasDigitalDriversLicense: !!applicantData?.documents.find(
        doc => doc.idType === 'DRIVERS_LICENCE',
      )?.extraData?.digital_licence,
    }),
    [applicantData],
  )

  return {
    checks: {
      showRefreshResults,
      hasBiometrics,
      hasIdvOverrideResult: !!idvOverrideResult,

      showDigitalLicenseBanner:
        hasBiometrics && !!idvOverrideResult && hasDigitalDriversLicense,

      showTriggerCheckBanner: checkQueryData?.showTriggerCheckBanner ?? false,
      showIDVBanner: checkQueryData?.showIdvBanner ?? false,

      isFacialDuplicatesEnabled: hasFacialDuplicatesFeature,
      isIdvOverrideResultPassed: idvOverrideResult === 'PASSED',
      idvOverrideResult,

      availableBiometryDocs:
        biometryData?.biometry.map(
          item =>
            `${convertIDTypeToHumanReadable(
              item.referenceDocType,
            )} Document Scan`,
        ) || [],
    },
    loading: isLoading || loadingApplicantData,
  }
}
