import React, { useState } from 'react'

import { Collapse } from '@mui/material'
import cv from 'classnames'

import {
  FrankieBadge,
  FrankieButton,
  FrankieIcon,
  FrankieTooltip,
} from 'frankify/src'

import { useGlobalAppState } from 'entities/routing'

import { DocumentImg } from 'shared/documentImg'
import { useI18n } from 'shared/i18n'
import { useOverlay } from 'shared/overlay'
import { TrackingEventsTypes, useTrackingShowEvents } from 'shared/tracking'

import { INDIVIDUAL_IDV_CHECK_KEY } from '../../individual-idv-check.key'
import { individualIdvCheckEn } from '../../locale/individual-idv-check.en'
import { DocumentInfo } from '../../state/individual-idv-check/individual-idv-check.state'
import { IndividualIdvModal } from '../individual-idv-modal/individiual-idv-modal'

export const REACT_SIDEBAR_CUSTOM_EVENT = 'react-portal-sidebar'
export const REACT_SIDEBAR_CUSTOM_EVENT_DETAIL = 'isOpen'

type Props = {
  documentInfo: DocumentInfo
}

const images = [
  'https://imgs.search.brave.com/7YXbKmxQL2eIzdcvXakbf_tAq0crm1yo2QZlCYOgpqA/rs:fit:500:0:0:0/g:ce/aHR0cHM6Ly9pZ2x1/Lm5ldC93cC1jb250/ZW50L3VwbG9hZHMv/MjAxNy8wMS90aGFp/LWRyaXZlcnMtbGlj/ZW5zZS5qcGVn',
  'https://upload.wikimedia.org/wikipedia/commons/5/51/Imitation_official_document.jpg',
]

export function IDVDocumentRow({ documentInfo }: Props) {
  const { title, values, report } = documentInfo
  const t = useI18n([INDIVIDUAL_IDV_CHECK_KEY], { keys: individualIdvCheckEn })
  const { data } = useGlobalAppState()

  const [open, setOpen] = useState(false)

  useTrackingShowEvents({
    data: open,
    eventsType: TrackingEventsTypes.WorkflowEventsDocumentFullReportShow,
  })

  const greenRedPercentClasses = (value: number) => {
    if (value >= 70) {
      return 'text-tertiary-green-600'
    }
    return 'text-tertiary-red-700'
  }

  const failingSections = report.filter(
    reportSection => reportSection.success === false,
  )

  const failedCount = failingSections.length
  const idvScore = ((report.length - failedCount) / report.length) * 100
  const ocrScore = (report.length / report.length) * 100

  const [createOverlay] = useOverlay()

  const handleExpand = () => {
    createOverlay(<IndividualIdvModal documentInfo={documentInfo} />, {
      closeButtonClassName: '!top-6 !right-6',
      className: '!p-6',
    })
  }

  return (
    <div className="flex flex-col gap-3">
      <div className="text-md text-tertiary-grey-800 gap-2 font-semibold flex items-center">
        {title}
      </div>

      <div className="flex flex-col tablet:flex-row gap-6 ">
        <div className="flex max-w-[724px] gap-6">
          <div className="desktop:w-64 w-36">
            <DocumentImg
              gallery={images}
              handleExpand={handleExpand}
              thumbnailTexts={{
                front: t('front'),
                photoPage: t('photoPage'),
                back: t('back'),
              }}
              className=""
            />
          </div>

          <div className="flex border-tertiary-grey-200 border-r-solid border-r flex-col gap-3">
            {values.map(value => (
              <div className="" key={value.label}>
                <div className="text-tertiary-grey-800 w-36 font-medium !inline-block">
                  {value.label}
                </div>
                <span className="text-tertiary-grey-700  ">{value.value}</span>
                {value.success && (
                  <FrankieIcon
                    name="mdiCheckCircle"
                    size="xs"
                    className="text-tertiary-green-600 !inline-block align-text-top ml-1"
                  />
                )}
                {value.success === false && (
                  <FrankieIcon
                    name="mdiCloseCircle"
                    size="xs"
                    className="text-tertiary-red-700 !inline-block align-text-top ml-1"
                  />
                )}
              </div>
            ))}
          </div>
        </div>

        <div
          className={cv(
            data?.isSidebarOpen
              ? 'basis-[25%] min-w-[25%] desktop:basis-[40%] desktop:min-w-[40%]'
              : 'basis-[40%] min-w-[40%]',
          )}
        >
          <div className="flex flex-wrap gap-y-2">
            <div className="w-40">
              <div className="font-semibold text-tertiary-grey-800">
                {t('idScore')}
              </div>
              <div
                className={cv(
                  'font-bold text-2xl',
                  greenRedPercentClasses(idvScore),
                )}
              >
                {t('score', { score: idvScore })}
              </div>
            </div>
            <div className="w-40">
              <div className="font-semibold text-tertiary-grey-800">
                {t('ocrScore')}
              </div>
              <div
                className={cv(
                  'font-bold text-2xl',
                  greenRedPercentClasses(ocrScore),
                )}
              >
                {t('score', { score: ocrScore })}
              </div>
            </div>
          </div>

          <div className="font-semibold text-tertiary-grey-800 mt-3 mb-2">
            {t('documentIssues')}
          </div>

          <div className="flex flex-wrap gap-2">
            {!failedCount && t('noIssues')}

            {failingSections.map(section => (
              <FrankieTooltip
                key={section.title}
                position="top"
                title={section.description}
              >
                <FrankieBadge theme="red" text={section.title} />
              </FrankieTooltip>
            ))}
          </div>
        </div>
      </div>

      <div className="flex gap-2 py-2">
        {!!failedCount && (
          <div className="bg-tertiary-red-700 text-mono-white  text-center rounded-full aspect-square h-5 w-5">
            {failedCount}
          </div>
        )}

        <div className="font-semibold text-tertiary-grey-800">
          {t('showFullReport')}
        </div>

        <FrankieButton
          onClick={() => setOpen(o => !o)}
          noStyles
          singleIcon={{ name: 'mdiChevronDown', size: 'xs' }}
        />
      </div>

      <Collapse className={open ? 'mb-2' : '-mb-4'} in={open}>
        <div className="flex flex-wrap ">
          {report.map(reportSection => (
            <div
              key={reportSection.title}
              className="basis-1/5 pr-4 min-w-[199px]"
            >
              <div className="text-tertiary-grey-800 font-medium flex gap-1.5 mb-2">
                {reportSection.success && (
                  <FrankieIcon
                    name="mdiCheckCircle"
                    size="xs"
                    className="text-tertiary-green-600 mt-[2.5px]"
                  />
                )}
                {reportSection.success === false && (
                  <FrankieIcon
                    name="mdiCloseCircle"
                    size="xs"
                    className="text-tertiary-red-700 mt-[2.5px]"
                  />
                )}
                {reportSection.title}
              </div>
              {reportSection.children.map(report => (
                <div
                  key={report.title}
                  className="text-tertiary-grey-500 font-medium text-xs flex gap-1.5 ml-4 mb-1.5"
                >
                  {report.success && (
                    <FrankieIcon
                      name="mdiCheckCircle"
                      size="2xs"
                      className="text-tertiary-green-600 mt-[3px]"
                    />
                  )}
                  {report.success === false && (
                    <FrankieIcon
                      name="mdiCloseCircle"
                      size="2xs"
                      className="text-tertiary-red-700 mt-[3px]"
                    />
                  )}
                  {report.title}
                </div>
              ))}
            </div>
          ))}
        </div>
      </Collapse>
    </div>
  )
}
