import React, { ReactNode, useMemo } from 'react'

import { Control, UseFormRegister, UseFormWatch } from 'react-hook-form'

import { FrankieTextField } from 'frankify/src'

import { trustAnalyserEditFormQa } from 'features/applicant-supporting-documents/qa/applicant-support-documents.qa'

import { SelectFormField } from 'shared/form'
import { useI18n } from 'shared/i18n'

import { APPLICANT_SUPPORTING_DOCUMENTS_KEY } from '../../../applicant-supporting-documents.key'
import { applicantSupportingDocumentsEn } from '../../../locale/applicant-supporting-documents.en'
import {
  applicantEntityOption,
  ApplicantTrustTypes,
} from '../../../model/applicant-supporting-documents.model'
import {
  ITrustDeedForm,
  SpecificTrustFormTypes,
  TrustFormLabelProps,
  TrustFormTypes,
} from '../../../model/applicant-supporting-trust-deed.model'
import {
  FormHeader,
  FormFooter,
} from '../form-header-footer/form-header-footer'
import { TrustGeneralBeneficiaryForm } from '../trust-general-beneficiary-form/trust-general-beneficiary-form'

export type TrustCommonFormProps = {
  label?: TrustFormLabelProps
  title: string
  formKey: SpecificTrustFormTypes
  isLastItem: boolean
  index: number
  register: UseFormRegister<ITrustDeedForm>
  control: Control<ITrustDeedForm, unknown>
  watch: UseFormWatch<ITrustDeedForm>
  onAdd: () => void
  onRemove: () => void
  tag?: ReactNode
}

export function TrustCommonForm({
  label,
  title,
  formKey,
  isLastItem,
  index,
  register,
  control,
  watch,
  onAdd,
  onRemove,
  tag,
}: TrustCommonFormProps) {
  const t = useI18n([APPLICANT_SUPPORTING_DOCUMENTS_KEY], {
    keys: applicantSupportingDocumentsEn,
  })

  const applicantEntityOptions = useMemo(
    () =>
      applicantEntityOption.map(({ tKey, value }) => ({
        label: t(tKey),
        value,
      })),
    [t],
  )

  const headerFooterText = useMemo(() => {
    if (formKey === TrustFormTypes.GeneralBeneficiary) {
      return t('generalBeneficiaries')
    }
    if (formKey === TrustFormTypes.SpecifiedBeneficiary) {
      return t('specifiedBeneficiary')
    }
    return t(formKey)
  }, [formKey, t])

  if (formKey === TrustFormTypes.GeneralBeneficiary)
    return (
      <TrustGeneralBeneficiaryForm
        control={control}
        formKey={TrustFormTypes.GeneralBeneficiary}
        index={index}
        isLastItem={isLastItem}
        onAdd={onAdd}
        onRemove={onRemove}
        register={register}
        title={title}
        tag={tag}
      />
    )

  const updatedFormKey =
    formKey === TrustFormTypes.SpecifiedBeneficiary
      ? 'beneficiary.specifiedBeneficiary'
      : formKey

  const type = watch(`${updatedFormKey}.${index}.type`)

  return (
    <>
      {index === 0 && title && (
        <div className="font-semibold text-lg mb-3">{title}</div>
      )}
      <div className="bg-tertiary-grey-50 rounded-md p-4 my-4">
        <FormHeader
          number={index + 1}
          showRemove={!(isLastItem && index === 0)}
          onRemove={onRemove}
          text={headerFooterText}
          tag={tag}
        />
        <div className="grid grid-cols-3 gap-4">
          <SelectFormField
            label={label?.type}
            placeholder={t('selectType')}
            control={control}
            name={`${updatedFormKey}.${index}.type`}
            options={applicantEntityOptions}
            rules={{ required: t('errorTypeUnknown') }}
            showErrorText
            testId={{ input: trustAnalyserEditFormQa.type(formKey, index + 1) }}
          />
          <FrankieTextField
            {...register(`${updatedFormKey}.${index}.name`)}
            label={label?.name}
            className="col-span-2"
            testId={{ input: trustAnalyserEditFormQa.name(formKey, index + 1) }}
            data-hj-suppress
          />
          {type === ApplicantTrustTypes.Organization ? (
            <FrankieTextField
              {...register(`${updatedFormKey}.${index}.abnOrAcn`)}
              value={watch(`${updatedFormKey}.${index}.abnOrAcn`)}
              label={label?.dateOrganisation}
              placeholder="e.g. 123456789"
              testId={{
                input: trustAnalyserEditFormQa.abnOrAcn(formKey, index + 1),
              }}
              data-hj-suppress
            />
          ) : (
            <FrankieTextField
              {...register(`${updatedFormKey}.${index}.dateOfBirth`)}
              value={watch(`${updatedFormKey}.${index}.dateOfBirth`)}
              type="date"
              label={label?.dateIndividual}
              maxLength={4}
              placeholder="DD/MM/YYYY"
              testId={{
                input: trustAnalyserEditFormQa.dateOfBirth(formKey, index + 1),
              }}
              data-hj-suppress
            />
          )}

          {type === ApplicantTrustTypes.Organization ? (
            <FrankieTextField
              {...register(`${updatedFormKey}.${index}.address`)}
              label={label?.addressOrganisation}
              className="col-span-2"
              placeholder={t('searchAddress')}
              testId={{
                input: trustAnalyserEditFormQa.address(formKey, index + 1),
              }}
              data-hj-suppress
            />
          ) : (
            <FrankieTextField
              {...register(
                formKey === TrustFormTypes.Member
                  ? `member.${index}.role`
                  : `${updatedFormKey}.${index}.address`,
              )}
              label={label?.addressIndividual}
              className="col-span-2"
              placeholder={t('searchAddress')}
              testId={{
                input: trustAnalyserEditFormQa.address(formKey, index + 1),
              }}
              data-hj-suppress
            />
          )}
        </div>
      </div>
      <FormFooter
        onAdd={onAdd}
        showFooter={isLastItem}
        text={headerFooterText}
      />
    </>
  )
}
