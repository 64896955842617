import { useMutation, useQueryClient } from '@tanstack/react-query'

import {
  useGetWorkflowEventsData,
  EntityDataQueryKey,
  useUpdateProfileData,
} from 'entities/entity'

import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'

import { individualApiF2 } from '../api/individual-f2.api'
import { INDIVIDUAL_PROFILE_F2_KEY } from '../individual-profile-f2.key'
import { individualProfileF2En } from '../locale/individual-profile-f2.en'
import { IIndividualProfileF2FormType } from '../model/individual-profile-f2-form.model'
import { useGetIndividualFormData } from '../state/getIndividualForm.data'

export const useUpdateIndividual = (entityId?: string, saveOnly = true) => {
  const t = useI18n(INDIVIDUAL_PROFILE_F2_KEY, { keys: individualProfileF2En })
  const { updateProfileData } = useUpdateProfileData(entityId)
  const { remove } = useGetIndividualFormData(entityId)
  const queryClient = useQueryClient()
  const { remove: removeWorkflowData } = useGetWorkflowEventsData({
    entityId: entityId!,
  })

  return useMutation({
    mutationFn: async (body: IIndividualProfileF2FormType) => {
      const res = await individualApiF2.updateIndividual(body, saveOnly)
      return res
    },

    onSuccess: ({ data }) => {
      const serviceProfile = data.serviceProfiles?.at(0)
      updateProfileData(serviceProfile)

      notification.success(
        t('hasBeenUpdated', {
          fullName: data.individual?.name?.displayName,
        }),
      )

      queryClient.removeQueries([EntityDataQueryKey, entityId])

      void remove()
      void removeWorkflowData()
    },

    onError: () => notification.error(t('saveOnlyErrorUpdate')),
  })
}
