import React, { useState } from 'react'

import { MenuItem } from '@mui/material'

import { FrankieButton, FrankiePopover } from 'frankify/src'

import { ApplicantId } from 'entities/applicant'

import { useI18n } from 'shared/i18n'
import { useOverlay } from 'shared/overlay'
import { isNonEmptyObject } from 'shared/typescript'

import { APPLICANT_BUSINESS_OWNERSHIP_KEY } from '../../applicant-business-ownership.key'
import { applicantBusinessOwnershipEn } from '../../locale/applicant-business-ownership.en'
import { applicantAddBusinessEntityQa } from '../../qa/applicant-business-ownership.qa'
import { useApplicantBusinessOwnershipQuery } from '../../state/applicant-business-ownership-query/applicant-business-ownership.query'
import { ApplicantAssociateParties } from '../applicant-associate-parties/applicant-associate-parties'

type Props = {
  applicantId: ApplicantId
  className?: string
}

export function ApplicantAddBusinessEntity({
  applicantId,
  className = '',
}: Props) {
  const t = useI18n([APPLICANT_BUSINESS_OWNERSHIP_KEY], {
    keys: applicantBusinessOwnershipEn,
  })

  const { data: ownershipData } = useApplicantBusinessOwnershipQuery({
    applicantId,
  })

  const [open, setOpen] = useState(false)
  const [createOverlay, closeOverlay] = useOverlay()

  const associateParties = ({ isNew = false }) => {
    setOpen(false)
    createOverlay(
      <ApplicantAssociateParties
        applicantId={applicantId}
        associationType={isNew ? 'new' : 'existing'}
        onClose={closeOverlay}
      />,
      {
        className: 'p-0',
        closeButtonClassName: '!top-4 !right-5',
      },
    )
  }

  return (
    <div className={className} data-qa={applicantAddBusinessEntityQa.wrapper}>
      <FrankiePopover
        onOpenChange={setOpen}
        open={open}
        popoverRest={{ placement: 'bottom-start' }}
        trigger={
          <FrankieButton
            size="sm"
            disabled={!isNonEmptyObject(ownershipData)}
            onClick={() => setOpen(!open)}
            testId={{ button: applicantAddBusinessEntityQa.btn }}
          >
            {t('addEntity')}
          </FrankieButton>
        }
      >
        <div
          className="bg-mono-white shadow-md rounded-sm py-1"
          data-qa={applicantAddBusinessEntityQa.menu}
        >
          <MenuItem
            onClick={() => associateParties({ isNew: false })}
            className="py-2 font-bold text-neutral-90 text-sm hover:text-primary-600 hover:bg-primary-50"
          >
            {t('searchExistingParties')}
          </MenuItem>
          <MenuItem
            onClick={() => associateParties({ isNew: true })}
            className="py-2 font-bold text-neutral-90 text-sm hover:text-primary-600 hover:bg-primary-50"
          >
            {t('createNewParties')}
          </MenuItem>
        </div>
      </FrankiePopover>
    </div>
  )
}
