import React from 'react'

import { FrankieButton } from 'frankify/src'

import { useOverlay } from 'shared/overlay'

import { IndividualSendVerificationLinkForm } from '../individual-send-verification-link-form/individual-send-verification-link-form'

type Props = {
  entityId?: string
}

function IndividualSendVerificationLink({ entityId }: Props) {
  const [createOverlay] = useOverlay()

  if (!entityId) {
    return null
  }

  const handleClick = () => {
    createOverlay(<IndividualSendVerificationLinkForm entityId={entityId} />, {
      className: '!p-6',
      closeButtonClassName: '!top-4 !right-4',
    })
  }

  return (
    <FrankieButton
      noStyles
      onClick={handleClick}
      singleIcon={{
        name: 'mdiSendOutline',
        size: 'sm',
        className: 'text-tertiary-grey-700',
      }}
    />
  )
}

export { IndividualSendVerificationLink }
