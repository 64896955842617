import React from 'react'

import { Link } from 'react-router-dom'

import { INDIVIDUAL_PROFILE_INSIGHT_KEY } from 'features/individual-profile-insight/individual-profile-insight.key'
import { individualProfileInsightEn } from 'features/individual-profile-insight/locale/individual-profile-insight.en'
import { IndividualProfileAmlQa } from 'features/individual-profile-insight/qa/individual-profile-insight.qa'

import { useApplicantRoute } from 'entities/routing'

import { useI18n } from 'shared/i18n'
import { TrackingEventsTypes, trackingManager } from 'shared/tracking'

type Props = {
  entityId: string
}

// TODO: the code that are commented is for the future implementation

export function IndividualProfileAml({ entityId }: Props) {
  const t = useI18n(INDIVIDUAL_PROFILE_INSIGHT_KEY, {
    keys: individualProfileInsightEn,
  })

  const { generateRoute } = useApplicantRoute()

  return (
    <div
      className="flex flex-col gap-3"
      data-qa={IndividualProfileAmlQa.container}
    >
      <div className="flex justify-between">
        <div
          data-qa={IndividualProfileAmlQa.title}
          className="font-bold text-xl leading-7 text-tertiary-grey-800"
        >
          {t('amlSection.title')}
        </div>
        <div>
          <Link
            to={generateRoute({
              routeKey: 'amlScreening',
              overrideParams: { entityId },
            })}
            onClick={() => {
              trackingManager.track(
                TrackingEventsTypes.ProfileInsightViewAllClickIndividual,
              )
            }}
            className="text-primary-800 text-sm font-semibold"
            data-qa={IndividualProfileAmlQa.routeBtn}
          >
            {t('amlSection.cta')}
          </Link>
        </div>
      </div>
      <div className="flex justify-between gap-6 items-stretch flex-wrap">
        <Link
          to={generateRoute({
            routeKey: 'amlScreening',
            overrideParams: { entityId },
          })}
          onClick={() => {
            trackingManager.track(
              TrackingEventsTypes.ProfilesInsightAmlTilesClickIndividual.replace(
                '{tiles}',
                'pep',
              ),
            )
          }}
          data-qa={IndividualProfileAmlQa.routeFromTiles}
          className="w-[calc(50%-12px)] laptop:w-[calc(25%-18px)]"
        >
          <div className=" p-4 border h-full border-tertiary-grey-200 rounded-2">
            <div className="flex flex-col">
              <div className="font-semibold text-md leading-6 text-tertiary-grey-700">
                PEP
              </div>
              <div className="flex gap-6">
                <div className="flex flex-col">
                  <h1 className="font-bold text-2xl text-tertiary-grey-800">
                    2
                  </h1>
                  <p className="text-xs leading-4 text-tertiary-grey-700">
                    {t('amlSection.truePositive')}
                  </p>
                </div>
                <div className="flex flex-col">
                  <h1 className="font-bold text-2xl text-tertiary-grey-800">
                    1
                  </h1>
                  <p className="text-xs leading-4 text-tertiary-grey-700">
                    {t('amlSection.unknown')}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Link>

        <Link
          to={generateRoute({
            routeKey: 'amlScreening',
            overrideParams: { entityId },
          })}
          onClick={() => {
            trackingManager.track(
              TrackingEventsTypes.ProfilesInsightAmlTilesClickIndividual.replace(
                '{tiles}',
                'sanctions',
              ),
            )
          }}
          className="w-[calc(50%-12px)] laptop:w-[calc(25%-18px)]"
        >
          <div className="p-4 border h-full border-tertiary-grey-200 rounded-2">
            <div className="flex flex-col">
              <div className="font-semibold text-md leading-6 text-tertiary-grey-700">
                {t('amlSection.sanctions')}
              </div>
              <div className="flex gap-6">
                <div className="flex flex-col">
                  <h1 className="font-bold text-2xl text-tertiary-grey-800">
                    2
                  </h1>
                  <p className="text-xs leading-4 text-tertiary-grey-700">
                    {t('amlSection.truePositive')}
                  </p>
                </div>
                <div className="flex flex-col">
                  <h1 className="font-bold text-2xl text-tertiary-grey-800">
                    1
                  </h1>
                  <p className="text-xs leading-4 text-tertiary-grey-700">
                    {t('amlSection.truePositive')}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Link>

        <Link
          to={generateRoute({
            routeKey: 'amlScreening',
            overrideParams: { entityId },
          })}
          onClick={() => {
            trackingManager.track(
              TrackingEventsTypes.ProfilesInsightAmlTilesClickIndividual.replace(
                '{tiles}',
                'watchlist',
              ),
            )
          }}
          className="w-[calc(50%-12px)] laptop:w-[calc(25%-18px)]"
        >
          <div className="p-4 h-full border border-tertiary-grey-200 rounded-2">
            <div className="flex flex-col">
              <div className="font-semibold text-md leading-6 text-tertiary-grey-700">
                {t('amlSection.watchlist')}
              </div>
              <div className="flex gap-6">
                <div className="flex flex-col">
                  <h1 className="font-bold text-2xl text-tertiary-grey-800">
                    1
                  </h1>
                  <p className="text-xs leading-4 text-tertiary-grey-700">
                    {t('amlSection.truePositive')}
                  </p>
                </div>
                {/* <div className="flex flex-col">
                <h1 className="font-bold text-2xl text-tertiary-grey-800">1</h1>
                <p className="text-xs leading-4 text-tertiary-grey-700">
                True Positive
                </p>
              </div> */}
              </div>
            </div>
          </div>
        </Link>

        <Link
          to={generateRoute({
            routeKey: 'amlScreening',
            overrideParams: { entityId },
          })}
          onClick={() => {
            trackingManager.track(
              TrackingEventsTypes.ProfilesInsightAmlTilesClickIndividual.replace(
                '{tiles}',
                'media',
              ),
            )
          }}
          className="w-[calc(50%-12px)] laptop:w-[calc(25%-18px)]"
        >
          <div className="p-4 h-full border border-tertiary-grey-200 rounded-2 ">
            <div className="flex flex-col">
              <div className="font-semibold text-md leading-6 text-tertiary-grey-700">
                {t('amlSection.adverseMedia')}
              </div>
              <div className="flex gap-6">
                <div className="flex flex-col">
                  <h1 className="font-bold text-2xl text-tertiary-grey-800">
                    0
                  </h1>
                  {/* <p className="text-xs leading-4 text-tertiary-grey-700">
                  True Positive
                </p> */}
                </div>
                {/* <div className="flex flex-col">
                <h1 className="font-bold text-2xl text-tertiary-grey-800">1</h1>
                <p className="text-xs leading-4 text-tertiary-grey-700">
                  True Positive
                </p>
              </div> */}
              </div>
            </div>
          </div>
        </Link>
      </div>
    </div>
  )
}
