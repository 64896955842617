import { FrankieBadgeTheme } from 'frankify/src'

import { profileRisksI18n, profilesFilterEn } from 'features/profiles-filter'

import {
  IProfile,
  ProfileRiskLevelTypes,
  ProfileStateTypes,
  WorkflowStatusKeysTypes,
  getWorkflowStatusKey,
} from 'entities/entity'

import { I18nFunction } from 'shared/i18n'
import { capitalizeStringBySeparator } from 'shared/string'

import {
  profileIssuesCategoriesI18n,
  profileStatesI18n,
} from '../../profiles-filter/model/profiles-filter-form.model'

const badgeThemeMapping: Record<ProfileStateTypes, FrankieBadgeTheme> = {
  [ProfileStateTypes.ARCHIVED]: 'grey',
  [ProfileStateTypes.INACTIVE]: 'grey',
  [ProfileStateTypes.BLOCKLISTED]: 'red',
  [ProfileStateTypes.DELETED]: 'red',
  [ProfileStateTypes.AUTO]: 'yellow',
  [ProfileStateTypes.ACTIVE]: 'green',
  [ProfileStateTypes.INIT]: 'blue',
}

const workFlowBadgeThemeMapping: Record<
  WorkflowStatusKeysTypes,
  FrankieBadgeTheme
> = {
  [WorkflowStatusKeysTypes.PASSED]: 'green',
  [WorkflowStatusKeysTypes.FAILED]: 'red',
  [WorkflowStatusKeysTypes.NEEDS_ATTENTION]: 'yellow',
  [WorkflowStatusKeysTypes.UNCHECKED]: 'grey',
  [WorkflowStatusKeysTypes.MANUALLY_PASSED]: 'green',
  [WorkflowStatusKeysTypes.MANUALLY_FAILED]: 'red',
}

export function getWorkFlowStatusBadge(profile: IProfile): {
  theme: FrankieBadgeTheme
  text: string
} {
  const status = profile.workflowSummaries?.[0]?.status
  if (status) {
    const workflowStatusKey = getWorkflowStatusKey(
      profile.workflowSummaries?.at(0),
    )
    return {
      theme: workFlowBadgeThemeMapping[workflowStatusKey],
      text: capitalizeStringBySeparator(workflowStatusKey, '_', true),
    }
  }
  return {
    theme: workFlowBadgeThemeMapping[WorkflowStatusKeysTypes.UNCHECKED],
    text: capitalizeStringBySeparator(
      WorkflowStatusKeysTypes.UNCHECKED,
      '_',
      true,
    ),
  }
}

export function getStatusBadge(
  profile: IProfile,
  filterT: I18nFunction<typeof profilesFilterEn>,
): {
  theme: FrankieBadgeTheme
  text: string
} {
  return {
    theme: badgeThemeMapping[profile.state],
    text: filterT(profileStatesI18n[profile.state]),
  }
}

export function getRiskIndicator(
  profile: IProfile,
  filterT: I18nFunction<typeof profilesFilterEn>,
): {
  textClassName: string
  iconClassName: string
  text: string
} {
  const risk =
    profile.workflowSummaries?.at(0)?.riskAssessment?.riskLevel ||
    ProfileRiskLevelTypes.UNKNOWN

  const riskTextColorMapping: Record<string, string> = {
    [ProfileRiskLevelTypes.HIGH]: 'text-tertiary-red-700',
    [ProfileRiskLevelTypes.UNACCEPTABLE]: 'text-tertiary-red-700',
    [ProfileRiskLevelTypes.UNKNOWN]: 'text-tertiary-grey-600',
    [ProfileRiskLevelTypes.MEDIUM]: 'text-tertiary-yellow-700',
    [ProfileRiskLevelTypes.LOW]: 'text-tertiary-green-600',
  }
  const riskColorMapping: Record<string, string> = {
    [ProfileRiskLevelTypes.HIGH]: 'text-tertiary-red-500',
    [ProfileRiskLevelTypes.UNACCEPTABLE]: 'text-tertiary-red-500',
    [ProfileRiskLevelTypes.UNKNOWN]: 'text-tertiary-grey-400',
    [ProfileRiskLevelTypes.MEDIUM]: 'text-tertiary-yellow-300',
    [ProfileRiskLevelTypes.LOW]: 'text-tertiary-green-500',
  }

  return {
    textClassName: riskTextColorMapping[risk],
    iconClassName: riskColorMapping[risk],
    text: filterT(profileRisksI18n[risk]),
  }
}

export function getEntityIssues(
  profile: IProfile,
  filterT: I18nFunction<typeof profilesFilterEn>,
): string[] {
  return (
    profile.workflowSummaries?.at(0)?.issues?.map(
      issue =>
        // show the translated issue category if it exists
        filterT(profileIssuesCategoriesI18n[issue.category]) ||
        capitalizeStringBySeparator(issue.category, '_', true),
    ) || []
  )
}
