import {
  ServiceProfile,
  WorkflowExecutionResultEnum,
  WorkflowStepResultEnum,
  WorkflowStatusKeysTypes,
  getWorkflowStatusKey,
  ProcessResultManualStatusEnumAML,
  ProcessResultManualStatusEnumKYC,
} from 'entities/entity'

/**
 * @remarks we can add the modified types here
 */
export type ApplicantWorkflowEventsTypes = ServiceProfile

type FormateDateParamsType = {
  dateString: string
  calenderType?: string
}
function reverseDate(dateString?: string) {
  const [year, month, day] = dateString?.split('-') ?? ''
  return `${day}-${month}-${year}`
}
/**
 *
 * @param dateString date in string
 * @returns formatted date
 * @example formatDate('19-10-1994') => '19 Oct 1994'
 */

export function formatDate({
  dateString,
  calenderType = 'gregory',
}: FormateDateParamsType): string {
  // eslint-disable-next-line no-param-reassign
  dateString = reverseDate(dateString)
  const [day, month, year] = dateString
    .split('-')
    .map(part => parseInt(part, 10))
  const date = new Date(`${year}-${month}-${day}`)

  const options: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  } as Intl.DateTimeFormatOptions & {
    calendar: FormateDateParamsType['calenderType']
  }

  options.calendar = calenderType

  return date.toLocaleDateString('en-GB', options)
}

export function getSourceMatch({
  objectType,
  idType,
  workflowSummaries,
}: {
  idType?: string
  objectType: 'NAME' | 'DATE_OF_BIRTH' | 'ADDRESS' | 'DOCUMENT'
  workflowSummaries?: ServiceProfile['workflowSummaries']
}) {
  const currentWorkflowResultData = workflowSummaries?.at(0)?.workflowResultData
  const filteredStepResult =
    currentWorkflowResultData?.workflowStepResults?.filter(
      item => item.stepName === 'KYC' && item.objectType === 'INDIVIDUAL',
    )

  const isManuallyVerified =
    filteredStepResult?.at(0)?.result === WorkflowStepResultEnum.MATCH &&
    filteredStepResult
      .at(0)
      ?.processResults?.every(
        i => i.manualStatus === ProcessResultManualStatusEnumKYC.PASS,
      )

  if (isManuallyVerified) {
    return undefined
  }

  const isNotUnchecked =
    workflowSummaries?.at(0)?.status &&
    getWorkflowStatusKey(workflowSummaries.at(0)) !==
      WorkflowStatusKeysTypes.UNCHECKED

  if (
    filteredStepResult?.every(
      item => item.result === WorkflowStepResultEnum.UNCHECKED,
    ) ||
    !isNotUnchecked
  ) {
    return undefined
  }

  return filteredStepResult
    ?.flatMap(item => item.processResults)
    ?.filter(
      result =>
        result?.objectType === objectType &&
        (idType ? result.notes?.document_idtype?.value === idType : true) &&
        result.providerResult?.source &&
        result.result === 'MATCH',
    )
    ?.map(result => result?.providerResult?.sourceDisplayName as string)
}

export const failVariants = [
  WorkflowStepResultEnum.FAIL,
  WorkflowStepResultEnum.NO_MATCH,
  WorkflowStepResultEnum.ERROR,
  WorkflowStepResultEnum.INCOMPLETE,
  WorkflowStepResultEnum.PARTIAL,
]

export const failVariantsAml = [
  ProcessResultManualStatusEnumAML.UNKNOWN,
  ProcessResultManualStatusEnumAML.UNKNOWN_REJECT,
  ProcessResultManualStatusEnumAML.TRUE_POSITIVE_REJECT,
]

export const passVariantsAml = [
  ProcessResultManualStatusEnumAML.FALSE_POSITIVE,
  ProcessResultManualStatusEnumAML.TRUE_POSITIVE_ACCEPT,
  ProcessResultManualStatusEnumAML.UNKNOWN_ACCEPT,
]

export const passVariants = [
  WorkflowStepResultEnum.PASS,
  WorkflowStepResultEnum.MATCH,
  WorkflowStepResultEnum.CLEAR,
  WorkflowStepResultEnum.CLEARED,
]

export const reviewVariants = [
  WorkflowStepResultEnum.MISSING_DATA,
  WorkflowStepResultEnum.EXPIRED,
  WorkflowStepResultEnum.HIT,
]

export type MutateManualStatusType = {
  serviceName: string
  workflowName: string
  workflowStatus: WorkflowStepResultEnum | WorkflowExecutionResultEnum
  workflowExecutionId: string
}

export type RiskFactorRow = {
  riskFactor?: string
  id: string
  riskScore?: number
  riskValue?: string
}
