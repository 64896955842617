import { INDIVIDUAL_PROFILE_F2_KEY } from '../individual-profile-f2.key'

export const consentFormF2Qa = {
  wrapper: `${INDIVIDUAL_PROFILE_F2_KEY}-wrapper`,
  title: `${INDIVIDUAL_PROFILE_F2_KEY}-title`,
  checkbox: `${INDIVIDUAL_PROFILE_F2_KEY}-checkbox`,
  textArea: `${INDIVIDUAL_PROFILE_F2_KEY}-testArea`,
} satisfies Record<string, `${typeof INDIVIDUAL_PROFILE_F2_KEY}-${string}`>

export const personalInfoFormF2Qa = {
  container: `${INDIVIDUAL_PROFILE_F2_KEY}-container`,
  title: `${INDIVIDUAL_PROFILE_F2_KEY}-title`,
  customerId: `${INDIVIDUAL_PROFILE_F2_KEY}-customerId`,
  firstName: `${INDIVIDUAL_PROFILE_F2_KEY}-firstName`,
  middleName: `${INDIVIDUAL_PROFILE_F2_KEY}-middleName`,
  lastName: `${INDIVIDUAL_PROFILE_F2_KEY}-lastName`,
  dateOfBirth: `${INDIVIDUAL_PROFILE_F2_KEY}-dateOfBirth`,
  phoneNumber: `${INDIVIDUAL_PROFILE_F2_KEY}-phoneNumber`,
  email: `${INDIVIDUAL_PROFILE_F2_KEY}-email`,
} satisfies Record<string, `${typeof INDIVIDUAL_PROFILE_F2_KEY}-${string}`>

export const individualProfileF2Qa = {
  form: `${INDIVIDUAL_PROFILE_F2_KEY}-form`,
  workflow: `${INDIVIDUAL_PROFILE_F2_KEY}-workflow`,
  recipe: `${INDIVIDUAL_PROFILE_F2_KEY}-recipe`,
  saveButton: `${INDIVIDUAL_PROFILE_F2_KEY}-saveButton`,
  saveAndContinueButton: `${INDIVIDUAL_PROFILE_F2_KEY}-saveAndContinueButton`,
} satisfies Record<string, `${typeof INDIVIDUAL_PROFILE_F2_KEY}-${string}`>

export const AddressFormF2Qa = {
  wrapper: `${INDIVIDUAL_PROFILE_F2_KEY}-wrapper`,
  option: `${INDIVIDUAL_PROFILE_F2_KEY}-option-`,
  addAddress: `${INDIVIDUAL_PROFILE_F2_KEY}-add-address`,
} satisfies Record<string, `${typeof INDIVIDUAL_PROFILE_F2_KEY}-${string}`>

export const addressOptionQa = (index: string) =>
  `${AddressFormF2Qa.option}${index}`

const SINGLE_ADDRESS_QA = `${INDIVIDUAL_PROFILE_F2_KEY}-singleAddress`

export const singleAddressFormF2Qa = {
  wrapper: `${SINGLE_ADDRESS_QA}-wrapper`,
  country: `${SINGLE_ADDRESS_QA}-country`,
  buttonWrapper: `${SINGLE_ADDRESS_QA}-buttonWrapper`,
  unstructuredLongForm: `${SINGLE_ADDRESS_QA}-unstructuredLongForm`,
  placesData: `${SINGLE_ADDRESS_QA}-placesData`,
  noSearchResults: `${SINGLE_ADDRESS_QA}-noSearchResults`,
  enterAddressManually: `${SINGLE_ADDRESS_QA}-enterAddressManually`,
  useAddressSearch: `${SINGLE_ADDRESS_QA}-useAddressSearch`,
  addressSelectField: `${SINGLE_ADDRESS_QA}-addressSelectField-`,
  addressTextFormField: `${SINGLE_ADDRESS_QA}-addressTextFormField-`,
} satisfies Record<string, `${typeof SINGLE_ADDRESS_QA}-${string}`>

export const singleAddressDataQa = (addressField: string) =>
  `${singleAddressFormF2Qa.addressTextFormField}${addressField}`

const DOCUMENT_FORM_QA = `${INDIVIDUAL_PROFILE_F2_KEY}-documentForm`
// export const documentFormF2Qa =

export const getDocumentQa = (idx: number) =>
  ({
    wrapper: `${DOCUMENT_FORM_QA}-${idx}-wrapper`,
    country: `${DOCUMENT_FORM_QA}-${idx}-country`,
    type: `${DOCUMENT_FORM_QA}-${idx}-type`,
    toggle: `${DOCUMENT_FORM_QA}-${idx}-toggle`,
    fieldWrapper: `${DOCUMENT_FORM_QA}-${idx}-fieldWrapper`,
    documentFieldHeader: `${DOCUMENT_FORM_QA}-${idx}-documentFieldHeader`,
    addAnother: `${DOCUMENT_FORM_QA}-${idx}-addAnother`,
  } satisfies Record<string, `${typeof DOCUMENT_FORM_QA}-${string}`>)

export const getDriverLicenceQa = (idx: number) =>
  ({
    wrapper: `${DOCUMENT_FORM_QA}-${idx}-wrapper`,
    state: `${DOCUMENT_FORM_QA}-${idx}-state`,
    licenseNumber: `${DOCUMENT_FORM_QA}-${idx}-licenseNumber`,
    cardNumber: `${DOCUMENT_FORM_QA}-${idx}-cardNumber`,
    passportNumber: `${DOCUMENT_FORM_QA}-${idx}-passportNumber`,
  } satisfies Record<string, `${typeof DOCUMENT_FORM_QA}-${string}`>)

export const passportF2Qa = {
  wrapper: `${INDIVIDUAL_PROFILE_F2_KEY}-wrapper`,
  passportNumber: `${INDIVIDUAL_PROFILE_F2_KEY}-passportNumber`,
} satisfies Record<string, `${typeof INDIVIDUAL_PROFILE_F2_KEY}-${string}`>

export const medicareCardF2Qa = {
  container: `${INDIVIDUAL_PROFILE_F2_KEY}-medicare-container`,
  cardColor: `${INDIVIDUAL_PROFILE_F2_KEY}-cardColor`,
  icon: `${INDIVIDUAL_PROFILE_F2_KEY}-icon`,
  number: `${INDIVIDUAL_PROFILE_F2_KEY}-number`,
  positionCard: `${INDIVIDUAL_PROFILE_F2_KEY}-positionCard`,
  dateOfExpiry: `${INDIVIDUAL_PROFILE_F2_KEY}-dateOfExpiry`,
  normalizedDateOfExpiry: `${INDIVIDUAL_PROFILE_F2_KEY}-normalizedDateOfExpiry`,
  nameOnCard: `${INDIVIDUAL_PROFILE_F2_KEY}-nameOnCard`,
}
